import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {IonIcon, isPlatform, useIonRouter} from '@ionic/react';
import {callOutline, flag, homeOutline, mailOutline, starOutline} from 'ionicons/icons';
import {Service} from 'interfaces/services/service.interface';
import {servicesService} from 'api/services/services.service';
import {OrangeLine} from 'components/uiContainer/UIOrangeLine';
import FavouritesButton from 'pages/common/favourites/FavouritesButton';
import {UIClickImage} from 'components/uiImages/UIClickImage';
import {Link} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import UIButtonShare from 'components/uiButtons/UIButtonShare';
import BMiniMap from 'forms/common/places/BMiniMap';
import {accountState, isDarkTheme} from 'states/account/account.state';
import UICommentSpace from 'components/uiCommentSpace/UICommentSpace';
import {ratingState} from 'states/common/common.state';
import euroIcon from 'assets/icons/euro_icon.svg';

interface Props {
  fields: Service;
  setAvgRating: Dispatch<SetStateAction<string>>;
  avgRating: string;
  defaultFavorite: boolean;
  setDate: any;
  setIsPopUpVisible: Dispatch<SetStateAction<boolean>>;
  onClick?: any;
}

export const ServiceDetails: React.FC<Props> = ({
  fields,
  defaultFavorite,
  setAvgRating,
  avgRating,
  setDate,
  setIsPopUpVisible,
  onClick,
}) => {
  const [isFavorite, setIsFavorite] = useState(defaultFavorite);
  const accountData = useRecoilValue<any>(accountState);
  const ratingCounter = useRecoilValue(ratingState);
  const router = useIonRouter();

  useEffect(() => setIsFavorite(defaultFavorite), [defaultFavorite]);

  useEffect(() => {
    servicesService.getDetails(fields.id).then((response) => {
      setAvgRating(response.data.owner.userAvg);
    });
  }, [ratingCounter]);

  const favHandler = (id: number) => {
    servicesService.addFavoriteService(id);
  };
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <div
      className={`rounded-lg m-1 relative drop-shadow-md z-20
      ${isDark2 ? 'bg-[#393939] border-[#F0A249]' : 'bg-yellow-slight border-yellow-accent'}`}
    >
      <UIClickImage
        customClassName='w-full h-52 rounded-3xl'
        type='instruments'
        photoLink={fields.photos.length > 0 && fields.photos[0].photo}
        setIsPopUpVisible={setIsPopUpVisible}
      />
      <div className={`absolute ${isPlatform('ios') ? ' w-[94%] ' : ' w-full '} top-40`}>
        <div
          className={` rounded-t-3xl pl-4 border-t-2 border-orange-main
        ${isDark2 ? 'bg-[#393939]' : 'bg-white'}`}
        >
          <div className='flex justify-between'>
            <div className=''>
              <p
                className={`text-lg font-bold mt-2 pt-5
          ${isDark2 ? ' text-white' : 'text-gray-600 '}`}
              >
                {fields.companyName}
              </p>
              <p className={`font-thin text-sm ${isDark2 ? ' text-white' : 'text-black'}`}>
                {fields.industry}
              </p>
              <p className={`font-thin text-sm ${isDark2 ? ' text-white' : 'text-black'}`}>
                {fields.owner.firstName + ' ' + fields.owner.lastName}
              </p>
              <div className='flex text-sm font-thin'>
                <p className='font-bold pr-2'>Mo-Fr:</p>
                <p>{fields.mondayToFriday}</p>
              </div>
              <div className='flex text-sm font-thin'>
                <p className='font-bold pr-2'>Sa-So:</p>
                <p>{fields.saturdayToSunday}</p>
              </div>
            </div>
            <div>
              <div className='pt-9 text-black space-x-2 flex items-center mr-5'>
                <Link to={`/report/instrument/${fields.id}`}>
                  <IonIcon
                    icon={flag}
                    className={`mt-2 h-6 w-6 ${isDark2 ? 'text-[#F0A249]' : 'textyellow-accent'}`}
                  />
                </Link>
                {fields && accountData && accountData.id !== fields!.owner!.id ? (
                  <FavouritesButton
                    favHandler={favHandler}
                    fields={fields}
                    isFavorite={isFavorite}
                    setIsFavorite={setIsFavorite}
                  />
                ) : (
                  <IonIcon
                    icon={starOutline}
                    className='mt-1 text-yellow-400 h-6 w-6'
                    onClick={() => {
                      router.push('/sign-in');
                    }}
                  />
                )}
                <UIButtonShare />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex justify-end mr-4 absolute top-36 right-0'>
        <div className='flex flex-row space-x-4'>
          <div
            className='bg-yellow-accent text-white pr-2
             rounded-3xl flex p-1'
          >
            <IonIcon icon={euroIcon} className='mt-1 ml-3 text-white' />
            <p className='mr-2 text-sm mt-1 text-white'>{fields.pricePerHour} / pro Stunde</p>
          </div>
          <button className='bg-orange-rate rounded-3xl w-8 text-white text-xs' onClick={onClick}>
            {fields.owner.userAvg ? fields.owner.userAvg : '-'}
          </button>
        </div>
      </div>
      <div className='mt-24'>
        <OrangeLine name='Kontakte' />
        <div className={`ml-4 mr-8 space-y-2 ${isDark2 ? ' text-white' : 'text-gray-500 '}`}>
          {fields.isVisiblePhoneNumber && (
            <div className='flex'>
              <IonIcon icon={callOutline} className='mt-0.5' />
              <p className='text-sm ml-2 '>{fields.phoneNumber}</p>
            </div>
          )}
          <div className='flex'>
            <IonIcon icon={homeOutline} className='h-5 w-5' />
            <p className='text-sm ml-2'>
              {fields.postCode} {fields.location}
            </p>
          </div>
          {fields.email && (
            <div className='flex'>
              <IonIcon icon={mailOutline} className='h-5 w-5 mr-3' />
              <p className='text-sm'>{fields.email}</p>
            </div>
          )}
        </div>
        <div className='mx-3 mb-2'>
          <BMiniMap geolocation={{lat: +fields.lat, lng: +fields.lng}} />
        </div>
        <OrangeLine name='Beschreibung' />
        <div className='text-gray-400 mt-2 text-sm flex justify-between mx-3'>
          <p
            className={`mx-2
        ${isDark2 ? ' text-white' : 'text-gray-500 '}`}
          >
            {fields.description}
          </p>
        </div>
        <p
          className={`float-left text-sm mt-3 mr-1.5 ml-3
        ${isDark2 ? 'text-[#F0A249]' : 'text-yellow-accent'}`}
        >
          Kommentare
        </p>
        <hr className={`mr-3 mt-7 mb-4 ${isDark2 ? 'bg-[#F0A249]' : 'bg-yellow-accent'}`} />
        <UICommentSpace
          comments={fields.comments}
          id={fields.id}
          commented={fields.commented}
          type='services'
        />
        {/* <UICalendar onDatesSet={setDate} /> */}
      </div>
    </div>
  );
};

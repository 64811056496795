/* eslint-disable max-len */
/* eslint-disable curly */
import React, {useEffect} from 'react';
import {Instrument} from 'interfaces/instruments/instrument.interface';
import {Job} from 'interfaces/jobs/jobs.interface';
import {Product} from 'interfaces/products/product.interface';
import {Service} from 'interfaces/services/service.interface';
import InstrumentsList from 'pages/instruments/instrumentsList/InstrumentsList';
import ProductsListBuyPage from 'pages/products/productsList/ProductsList';
import {useHistory} from 'react-router';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {refreshCounterState} from 'states/common/common.state';
import {ServicesList} from 'pages/services/servicesList/ServiceList';
import {JobsList} from 'pages/jobs/jobsList/JobsList';
import {isDarkTheme} from 'states/account/account.state';

type Props = {
  itemsList: {
    werkzeugMieten?: Instrument[];
    baumaschinenMieten?: Instrument[];
    agricultureMieten?: Instrument[];
    services?: Service[];
    jobs?: Job[];
    werkzeugKaufen?: Product[];
    baumaschinenKaufen?: Product[];
    agricultureKaufen?: Product[];
  };
  setLocation: Function;
};

const SameLocationItemsList: React.FC<Props> = ({itemsList, setLocation}) => {
  const setRefreshCount = useSetRecoilState(refreshCounterState);
  const router = useHistory();
  useEffect(() => {
    if (itemsList.werkzeugMieten!.length > 0) setLocation(itemsList.werkzeugMieten![0].location);
    else if (itemsList.werkzeugKaufen!.length > 0)
      setLocation(itemsList.werkzeugKaufen![0].location);
    else if (itemsList.services!.length > 0) setLocation(itemsList.services![0].location!);
    else if (itemsList.jobs!.length > 0) setLocation(itemsList.jobs![0].location!);
    else if (itemsList.baumaschinenMieten!.length > 0)
      setLocation(itemsList.baumaschinenMieten![0].location!);
    else if (itemsList.baumaschinenKaufen!.length > 0)
      setLocation(itemsList.baumaschinenKaufen![0].location!);
    else if (itemsList.agricultureMieten!.length > 0)
      setLocation(itemsList.agricultureMieten![0].location!);
    else if (itemsList.agricultureKaufen!.length > 0)
      setLocation(itemsList.agricultureKaufen![0].location!);
  }, [itemsList]);
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <div
      className={`absolute z-20 w-full h-full overflow-auto
          ${isDark2 ? ':bg-[#393939] ' : 'bg-[#F8F8F8]'}`}
    >
      <InstrumentsList
        instruments={itemsList.werkzeugMieten!}
        imageType='instruments'
        onInstrumentClick={(instrument: Instrument) => {
          router.push(`/instrument/${instrument.category.name}/
            ${instrument.subcategory.name}/${instrument.id}`);
          setRefreshCount((refreshCounter) => refreshCounter + 1);
        }}
      />
      <InstrumentsList
        instruments={itemsList.baumaschinenMieten!}
        imageType='instruments'
        onInstrumentClick={(instrument: Instrument) => {
          router.push(`/instrument/${instrument.category.name}/
            ${instrument.subcategory.name}/${instrument.id}`);
          setRefreshCount((refreshCounter) => refreshCounter + 1);
        }}
      />
      <InstrumentsList
        instruments={itemsList.agricultureMieten!}
        imageType='instruments'
        onInstrumentClick={(instrument: Instrument) => {
          router.push(`/instrument/${instrument.category.name}/
            ${instrument.subcategory.name}/${instrument.id}`);
          setRefreshCount((refreshCounter) => refreshCounter + 1);
        }}
      />
      <ProductsListBuyPage
        products={itemsList.werkzeugKaufen!}
        type='products'
        onProductClick={(product: Product) => {
          router.push(`/product/${product.category.name}/
              ${product.subcategory.name}/${product.id}`);
          setRefreshCount((refreshCounter) => refreshCounter + 1);
        }}
      />
      <ProductsListBuyPage
        products={itemsList.baumaschinenKaufen!}
        type='products'
        onProductClick={(product: Product) => {
          router.push(`/product/${product.category.name}/
              ${product.subcategory.name}/${product.id}`);
          setRefreshCount((refreshCounter) => refreshCounter + 1);
        }}
      />
      <ProductsListBuyPage
        products={itemsList.agricultureKaufen!}
        type='products'
        onProductClick={(product: Product) => {
          router.push(`/product/${product.category.name}/
              ${product.subcategory.name}/${product.id}`);
          setRefreshCount((refreshCounter) => refreshCounter + 1);
        }}
      />
      <ServicesList
        services={itemsList.services!}
        onServiceClick={(service: Service) => {
          router.push(`/service/${service}`);
          setRefreshCount((refreshCounter) => refreshCounter + 1);
        }}
        isOwnService={false}
      />
      <JobsList
        jobs={itemsList.jobs!}
        onJobClick={(job: Job) => {
          router.push(`/job/${job}`);
          setRefreshCount((refreshCounter) => refreshCounter + 1);
        }}
        isOwnJob={false}
      />
    </div>
  );
};

export default SameLocationItemsList;

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTabBar,
  IonTabButton,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UILogo from 'components/uiLogo/UILogo';
import {
  location,
  locationOutline,
  notifications,
  notificationsOutline,
  pricetag,
  pricetagOutline,
  star,
  starOutline,
} from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import PriceList from './PriceList';
import UIYellowButton from 'components/uiButtons/UIYellowButton';
import {useRecoilValue} from 'recoil';
import {accountState, isDarkTheme} from 'states/account/account.state';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import {accountService} from 'api/account/account.service';
import {servicesService} from 'api/services/services.service';
import {User} from 'interfaces/users/user.interface';

const firstTab = 'issue';
const secondTab = 'list';
const thirdTab = 'map';
const fourthTab = 'push';

const PriceListPage = () => {
  const router = useIonRouter();
  const [promotions, setPromotions] = useState<any>();
  const [activeTab, setActiveTab] = useState(firstTab);
  const [accountType, setAccountType] = useState('');
  const accountData = useRecoilValue<User | undefined>(accountState);

  useEffect(() => {
    accountData &&
      accountService.getCurrentUser().then((response) => {
        setAccountType(response.data.accountType);
      });
  }, []);

  useEffect(() => {
    servicesService.getAllPromotions().then((res) => setPromotions(res.data));
  }, []);
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
      </IonHeader>
      <UITopTextBar>Preisliste</UITopTextBar>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <div className={`${isDark2 ? 'text-white' : 'text-black '}`}>
          <IonTabBar
            color={`${isDark2 ? 'dark' : 'light'}`}
            slot='bottom'
            className='h-[44px]'
            onIonTabsWillChange={(e) => setActiveTab(e.detail.tab)}
          >
            <IonTabButton
              tab={firstTab}
              className={` {$
                activeTab === firstTab
                  ? 'border-b-[1px] border-orange-300 text-orange-300 text-[17px]'
                  : 'text-[17px] border-b-[1px] border-gray-500'
              } ${isDark2 ? 'text-yellow-accent ' : 'text-black'}`}
            >
              {activeTab === firstTab ? (
                <IonIcon icon={pricetag} />
              ) : (
                <IonIcon icon={pricetagOutline} />
              )}
            </IonTabButton>
            <IonTabButton
              tab={secondTab}
              className={` {$
                activeTab === secondTab
                  ? 'border-b-[1px] border-orange-300 text-orange-300 text-[17px]'
                  : 'text-[17px] border-b-[1px] border-gray-500'
              }${isDark2 ? 'text-yellow-accent ' : 'text-black'}`}
            >
              {activeTab === secondTab ? <IonIcon icon={star} /> : <IonIcon icon={starOutline} />}
            </IonTabButton>
            <IonTabButton
              tab={thirdTab}
              className={` {$
                activeTab === thirdTab
                  ? 'border-b-[1px] border-orange-300 text-orange-300 text-[17px]'
                  : 'text-[17px] border-b-[1px] border-gray-500'
              } ${isDark2 ? 'text-yellow-accent ' : 'text-black'}`}
            >
              {activeTab === thirdTab ? (
                <IonIcon icon={location} />
              ) : (
                <IonIcon icon={locationOutline} />
              )}
            </IonTabButton>
            {accountType !== 'PRIVATE' && (
              <IonTabButton
                tab={fourthTab}
                className={` {$
                activeTab === fourthTab
                  ? 'border-b-[1px] border-orange-300 text-orange-300 text-[17px]'
                  : 'text-[17px] border-b-[1px] border-gray-500'
              } ${isDark2 ? 'text-yellow-accent ' : 'text-black'}`}
              >
                {activeTab === fourthTab ? (
                  <IonIcon icon={notifications} />
                ) : (
                  <IonIcon icon={notificationsOutline} />
                )}
              </IonTabButton>
            )}
          </IonTabBar>
          <div
            className={` border-2 border-t-8
    border-yellow-accent rounded-3xl mt-5 mx-4 pb-5
    ${isDark2 ? 'bg-[#393939]' : 'bg-[#F8F8F8]'}`}
          >
            <p className='font-bold text-center border-b border-yellow-accent py-2'>
              {activeTab === firstTab ? 'Hinzufügen eines Angebots:' : ''}
              {activeTab === secondTab ? 'Anzeige hervorheben:' : ''}
              {activeTab === thirdTab ? 'Logo einstellen bei Karte:' : ''}
              {activeTab === fourthTab ? 'Push Nachricht an Nutzer in deiner Nähe senden:' : ''}
            </p>
            {accountType === 'COMPANY' || accountType === '' ? (
              <>
                <p className='text-center mt-4 font-bold'>Gewerblicher Nutzer:</p>
                {promotions && (
                  <>
                    <PriceList
                      promotions={promotions}
                      activeTab={activeTab}
                      accType='company'
                      itemType='instrument'
                      nameOfShowingPrices='Werkzeug vermieten'
                      isPushPromotion={true}
                      category='Werkzeug'
                    />
                    <PriceList
                      promotions={promotions}
                      activeTab={activeTab}
                      accType='company'
                      itemType='instrument'
                      nameOfShowingPrices='Baumaschinen / Land & Forsttechnik vermieten'
                      category='Baumaschinen'
                    />
                    <PriceList
                      promotions={promotions}
                      activeTab={activeTab}
                      accType='company'
                      itemType='product'
                      nameOfShowingPrices='Werkzeug verkaufen'
                      category='Werkzeug'
                    />
                    <PriceList
                      promotions={promotions}
                      activeTab={activeTab}
                      accType='company'
                      itemType='product'
                      nameOfShowingPrices='Baumaschinen /
                      Land & Forsttechnik verkaufen'
                      category='Baumaschinen'
                    />
                    <PriceList
                      promotions={promotions}
                      activeTab={activeTab}
                      accType='company'
                      itemType='service'
                      nameOfShowingPrices='Dienstleistungen'
                      category='service'
                      mapHidden={true}
                    />
                    <PriceList
                      promotions={promotions}
                      activeTab={activeTab}
                      accType='company'
                      itemType='job'
                      nameOfShowingPrices='Jobinserat'
                      category='job'
                      mapHidden={true}
                    />
                    <PriceList
                      promotions={promotions}
                      activeTab={activeTab}
                      accType='company'
                      itemType='job'
                      nameOfShowingPrices='Jobinserat / Dienstleistungen'
                      category='job'
                    />
                  </>
                )}
              </>
            ) : (
              <></>
            )}{' '}
            {accountType === 'PRIVATE' || (accountType === '' && activeTab !== fourthTab) ? (
              <>
                <p className='text-center mt-4 font-bold'>Privater Nutzer:</p>
                {promotions && (
                  <>
                    <PriceList
                      promotions={promotions}
                      activeTab={activeTab}
                      accType='private'
                      itemType='instrument'
                      nameOfShowingPrices='Werkzeug vermieten'
                      category='Werkzeug'
                    />
                    <PriceList
                      promotions={promotions}
                      activeTab={activeTab}
                      accType='private'
                      itemType='instrument'
                      nameOfShowingPrices='Baumaschinen /
                       Land & Forsttechnik vermieten'
                      category='Baumaschinen'
                    />
                    <PriceList
                      promotions={promotions}
                      activeTab={activeTab}
                      accType='private'
                      itemType='product'
                      nameOfShowingPrices='Werkzeug verkaufen'
                      category='Werkzeug'
                    />
                    <PriceList
                      promotions={promotions}
                      activeTab={activeTab}
                      accType='private'
                      itemType='product'
                      nameOfShowingPrices='Baumaschinen /
                      Land & Forsttechnik verkaufen'
                      category='Baumaschinen'
                    />
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className='flex justify-center'>
          <div className='lg:w-1/3 w-full mb-5 space-y-3 pt-5'>
            <UIYellowButton text='Kontaktiere Uns' onClick={() => router.push('/contact')} />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PriceListPage;

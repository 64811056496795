import React from 'react';
import {IonIcon} from '@ionic/react';
import {addSharp} from 'ionicons/icons';

type Props = {
  onClick: Function;
};

const UIButtonAdd = ({onClick}: Props) => (
  <IonIcon
    icon={addSharp}
    onClick={() => onClick()}
    className='text-yellow-accent h-8 w-8'
  />
);

export default UIButtonAdd;

import {BFormFieldType} from 'forms/common/bForm/BFormField';

export const formLogin: BFormFieldType[] = [
  {
    fieldName: 'email',
    fieldType: 'text',
    placeHolder: 'E-mail',
    validation: {
      required: true,
    },
  },
  {
    fieldName: 'password',
    fieldType: 'password',
    placeHolder: 'Passwort',
    validation: {
      required: true,
    },
  },
];

import React from 'react';
import formatError from 'utils/formatError';

type FormInputProps = {
  register: Function;
  customClassName?: string;
  placeholder?: string;
  name: string;
  type?: string;
  min?: string | number;
  max?: string | number;
  defaultValue?: string | boolean | number;
  required?: string;
  validation?: object;
  errors?: any;
  onChange?: object;
  maxLength?: number | string;
  minLength?: number | string;
  isNumberPrefix?: boolean;
};

let inputStyles = 'w-full bg-white text-sm dark:bg-[#393939] ';

const UIInput: React.FC<FormInputProps> = ({
  customClassName,
  name,
  placeholder,
  register,
  max,
  min,
  type,
  defaultValue,
  required,
  validation,
  errors,
  onChange,
  maxLength,
  minLength,
  isNumberPrefix,
}) => {
  if (errors) {
    inputStyles = inputStyles + 'text-red-500';
    customClassName && customClassName + 'text-red-500';
  }
  const hasErrors: boolean = errors && name in errors;
  return (
    <div>
      {isNumberPrefix && <div className='absolute w-11 text-center'>+43</div>}
      <input
        autoComplete='new-password'
        required={required}
        className={
          (customClassName ? customClassName : inputStyles) +
          (isNumberPrefix && ' pl-12')
        }
        placeholder={placeholder}
        {...register(name, validation)}
        type={type}
        min={min}
        max={max}
        defaultValue={defaultValue}
        onChange={onChange}
        maxLength={maxLength}
        minLength={minLength}
      />
      {hasErrors && formatError(errors[name])}
    </div>
  );
};

export default UIInput;

import React, {useEffect, useState} from 'react';
import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar, useIonToast} from '@ionic/react';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import searchService from 'assets/community/services&jobs/search_service.svg';
import createService from 'assets/community/services&jobs/create_service.svg';
import UILogo from 'components/uiLogo/UILogo';
import {useRecoilValue} from 'recoil';
import {accountState, isDarkTheme} from 'states/account/account.state';
import UIResponsivePadding from 'components/uiResponsive/UIResponsivePadding';
import {userService} from 'api/users/users.service';
import UIOrangeCategoryBox from 'components/uiContainer/UIOrangeCategoryBox';
import {User} from 'interfaces/users/user.interface';

const ServicesMenuPage: React.FC = () => {
  const [present] = useIonToast();
  const isDark2 = useRecoilValue(isDarkTheme);
  const [accType, setAccType] = useState<string>('');
  const accountData = useRecoilValue<User | undefined>(accountState);

  const onHandleClick = () => {
    accType !== 'COMPANY' &&
      present(
        'um eine Anzeige erstellen zu können müssen Sie über ein Firmenkonto' +
          ' verfügen dieses können Sie im Profil unter „Einstellungen“ auswählen',
        3000
      );
  };

  useEffect(() => {
    accountData &&
      userService.getUserData().then((res) => {
        setAccType(res.data.accountType);
      });
  }, [onHandleClick]);

  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar>Dienstleistungen</UITopTextBar>
      </IonHeader>
      <IonContent fullscreen color={`${isDark2 ? 'medium' : 'light'}`}>
        <UIResponsivePadding>
          <UIOrangeCategoryBox
            name='SUCHE DIENSTLEISTUNGEN'
            src={searchService}
            alt='search service'
            linkTo='/offers'
          />
          <UIOrangeCategoryBox
            name='BIETE DIENSTLEISTUNGEN'
            src={createService}
            alt='offer service'
            linkTo={accType === 'COMPANY' && '/offers-add/1'}
            onClick={onHandleClick}
          />
        </UIResponsivePadding>
      </IonContent>
    </IonPage>
  );
};

export default ServicesMenuPage;

import React, {useEffect, useState} from 'react';
import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar, useIonRouter} from '@ionic/react';
import {useParams} from 'react-router';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {accountState, isDarkTheme} from 'states/account/account.state';
import LoadingIndicator from 'components/uiLoading/UILoadingIndicator';
import {Service} from 'interfaces/services/service.interface';
import {servicesService} from 'api/services/services.service';
import {accountService} from 'api/account/account.service';
import {ServiceDetails} from 'pages/services/serviceDetail/ServiceDetails';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import UIYellowButton from 'components/uiButtons/UIYellowButton';
import UILogo from 'components/uiLogo/UILogo';
import ServiceEditForm from 'forms/services/editService.form';
import {refreshCounterState} from 'states/common/common.state';
import {UIDeleteButton} from 'components/uiButtons/UIDeleteButton';
import UIPopUpPhoto from 'components/uiImages/UIPopUpPhoto';
import {User} from 'interfaces/users/user.interface';
import {datesTypeState, selectedDatesState} from 'states/messages/message.state';
import UIRatePopUp from 'components/uiRatePopUp/UIRatePopUp';

export const ServiceDetailPage = () => {
  const [isOwn, setIsOwn] = useState(false);
  const setDates = useSetRecoilState(selectedDatesState);
  const [date, setDate] = useState();
  const [isFavorite, setIsFavorite] = useState(false);
  const [service, setService] = useState<Service>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isPopUpVisible, setIsPopUpVisible] = useState<boolean>(false);
  const [showRatingPopUp, setShowRatingPopUp] = useState<boolean>(false);
  const [avgRating, setAvgRating] = useState('');

  const [refreshCounter, setRefreshCounter] = useRecoilState(refreshCounterState);
  const accountData = useRecoilValue<User | undefined>(accountState);
  const setDatesType = useSetRecoilState(datesTypeState);

  const router = useIonRouter();

  const {offerId: offerId} = useParams<{offerId: string}>();

  useEffect(() => {
    async function getOfferDetails() {
      try {
        setIsLoading(true);
        const response = await servicesService.getDetails(+offerId);
        if (accountData) {
          setService(response.data);
          setIsPopUpVisible(false);
          setIsOwn(accountData.id == response.data.owner.id);
          const userResponse = await accountService.getCurrentUser();
          const isFavourite = userResponse.data.favourites.services.some(
            (dto: any) => dto.id == offerId
          );
          setIsFavorite(isFavourite);
        }
        setService(response.data);
        setAvgRating(response.data.owner.userAvg);
        setIsPopUpVisible(false);
      } catch {
        console.log('error');
      } finally {
        setIsLoading(false);
      }
    }
    getOfferDetails();
  }, [refreshCounter]);

  const handleDelete = () => {
    servicesService.deleteService(service!.id).then(() => {
      setRefreshCounter(refreshCounter + 1);
      router.goBack();
    });
  };
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <IonPage>
      {isPopUpVisible && (
        <UIPopUpPhoto
          photoLink={service?.photos}
          onClosePopUp={() => {
            setIsPopUpVisible(false);
          }}
        />
      )}
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        {showRatingPopUp && (
          <UIRatePopUp closePopUp={() => setShowRatingPopUp(false)} id={service!.owner.id} />
        )}
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
          {isOwn && (
            <IonButtons slot='end'>
              <UIDeleteButton onDelete={handleDelete} />
            </IonButtons>
          )}
        </IonToolbar>
        <UITopTextBar>Dienstleistungen</UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          service && (
            <div className={`w-full h-full ${isDark2 ? 'bg-[#474747]' : ' bg-white'}`}>
              <div className='pt-2 px-2 pb-5 space-y-5 '>
                {!isOwn ? (
                  <ServiceDetails
                    fields={service}
                    setAvgRating={setAvgRating}
                    avgRating={avgRating}
                    defaultFavorite={isFavorite}
                    setDate={setDate}
                    setIsPopUpVisible={setIsPopUpVisible}
                    onClick={() => {
                      setShowRatingPopUp(true);
                    }}
                  />
                ) : (
                  <ServiceEditForm offerId={offerId} />
                )}
                <div>
                  {!isOwn && (
                    <div className='space-y-3'>
                      <UIYellowButton
                        text={`Andere Benutzeranzeigen`}
                        onClick={() => {
                          router.push(
                            `/user-items/${service.owner.id}/${service.owner.firstName}/`
                          );
                        }}
                      />
                      <UIYellowButton
                        text={`Bewertung hinzufügen`}
                        onClick={() => {
                          setShowRatingPopUp(true);
                        }}
                      />
                      <UIYellowButton
                        text='Dienstleister kontaktieren'
                        onClick={() => {
                          setDates(date);
                          setDatesType('services');
                          router.push(
                            `/message/services/${service.id}/${
                              service.name ? service.name : service.industry
                            }/${service.existedChat ? service.existedChat : 0}`
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        )}
      </IonContent>
    </IonPage>
  );
};

import React, {useEffect, useState} from 'react';
import {instrumentsService} from 'api/instruments/instruments.service';
import {useHistory, useParams} from 'react-router';
import {addToCart} from 'api/promotions/promotions.service';
import {UIImage} from 'components/uiImages/UIImage';
import {servicesService} from 'api/services/services.service';
import {jobsService} from 'api/jobs/jobs.service';
import {productsService} from 'api/products/products.service';
import UILoadingIndicator from 'components/uiLoading/UILoadingIndicator';
import {
  IonAlert,
  IonItem,
  IonRadio,
  IonRadioGroup,
  isPlatform,
  useIonActionSheet,
} from '@ionic/react';
import {User} from 'interfaces/users/user.interface';
import {userService} from 'api/users/users.service';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {promotionsState} from 'states/promotions/promotion.state';
import {isDarkTheme} from 'states/account/account.state';
interface Props {
  promotionType?: 'list' | 'map' | 'push';
  prefix: string;
  suffix: string;
  setActiveTab?: Function;
  nextTab?: string;
}

export const getService = (type: string) => {
  switch (type) {
    case 'products':
      return productsService;
    case 'instruments':
      return instrumentsService;
    case 'jobs':
      return jobsService;
    case 'services':
      return servicesService;
    default:
      return instrumentsService;
  }
};

export const PromotionsCostsList = ({
  promotionType,
  prefix,
  suffix,
  setActiveTab,
  nextTab,
}: Props) => {
  const [avaliablePromotions, setAvaliablePromotions] = useState<any>();
  const [promotion, setPromotion] = useState<any>();
  const [item, setItem] = useState<any>();
  const [picture, setPicture] = useState();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [subHeader, setSubHeader] = useState<string>();
  const [message, setMessage] = useState('');
  const {type, id} = useParams<{type: string; id: string}>();
  const router = useHistory();
  const [actionSheet] = useIonActionSheet();
  const setPromotionsState = useSetRecoilState(promotionsState);

  const fetchPromotions = async () => {
    const service = getService(type);
    try {
      setIsLoading(true);
      const account = await userService.getUserData();
      const itemResponse = await service.getDetails(+id);
      const categoryId = itemResponse.data.category?.id;

      setItem(itemResponse.data);

      setPicture(itemResponse.data.photos.length > 0 && itemResponse.data.photos[0].photo);

      const response = await service.getAvailablePromotions(+id);
      setPromotionsState(response.data);

      const pricesList = getPricesList(account.data, response.data, categoryId);

      if (promotionType) {
        const field = account.data.accountType!.toLowerCase();
        setAvaliablePromotions(pricesList[field]);
        return;
      }
      setAvaliablePromotions(pricesList);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPricesList = (account: User, data: any, categoryId: number) => {
    const accType = account!.accountType!.toLowerCase();
    if (!promotionType) {
      if (categoryId) {
        return account!.mine[type].filter(
          (item: any) => item.category.id == categoryId && item.issuedPromoted
        ).length == 0
          ? data.issuePromotions[accType]
          : data.issuePromotions[accType];
      }
      return account!.mine[type].length == 0
        ? data.issuePromotions[accType]
        : data.issuePromotions[accType];
    }

    switch (promotionType) {
      case 'list':
        return data.listPromotions;
      case 'map':
        return data.mapPromotions;
      case 'push':
        return data.pushPromotion;
    }
  };

  useEffect(() => {
    fetchPromotions();
  }, [id, type]);

  const handleAddToCart = async () => {
    let data: any;
    if (promotionType === 'list') data = {promotedPackage: promotion.id};
    if (promotionType === 'map') data = {mapPromotedPackage: promotion.id};
    if (promotionType === 'push') data = {notificationPromotedPackage: promotion.id};
    if (!promotionType) data = {issuedPromotedPackage: promotion.id};

    await addToCart(type, +id, data)
      .then(() => {
        setActiveTab ? setActiveTab(nextTab) : router.push(`/promote/${type}/${id}`);
      })
      .catch((err) => {
        if (
          (err.response.data.promotedPackage || err.response.data.mapPromotedPackage) ==
          `Can't promote item longer than item is displayed`
        ) {
          setSubHeader('Es kann nicht mehr, als die Anzeige selbst ausgewählt werden.');
          setIsOpen(true);
          return;
        }
        setSubHeader('Dieser Artikel befindet sich bereits in Ihrem Warenkorb!');
        setIsOpen(true);
      });
  };
  const setInfoMessage = () => {
    setMessage(
      'Wenn Sie dieses Angebot nicht zu Ihrem Einkaufswagen hinzufügen möchten,' +
        'klicken Sie bitte unten, um fortzufahren'
    );
  };
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <div className='w-full pb-20'>
      <IonAlert
        isOpen={isOpen}
        header='Fehler'
        subHeader={subHeader}
        buttons={['OK']}
        onDidDismiss={() => setIsOpen(false)}
      ></IonAlert>
      <div
        className={`items-center p-3 space-x-5 rounded-xl border border-t-8
        mx-6 my-3 drop-shadow-md 
       ${isDark2 ? 'bg-[#393939] border-[#F0A249] ' : 'bg-[#F8F8F8] border-yellow-accent'}`}
      >
        <div className='flex mb-2 space-x-3'>
          {picture && <UIImage customClassName='w-20 h-20' photoLink={picture!} type={type} />}
          <div className={`text-[17px] ${isDark2 ? ' text-white' : 'text-black'}`}>
            <b>{item?.name}</b>
          </div>
        </div>
      </div>
      {isLoading ? (
        <UILoadingIndicator />
      ) : (
        avaliablePromotions && (
          <>
            <div
              className={`rounded-t-xl border border-t-8 mx-6 my-3 drop-shadow-md 
              ${isDark2 ? 'border-[#F0A249]' : 'border-yellow-accent'}`}
            >
              <IonRadioGroup
                value={promotion}
                onClick={() => {
                  setInfoMessage();
                  isPlatform('ios') &&
                    actionSheet([
                      {
                        text: 'In den Warenkorb legen',
                        handler: () => handleAddToCart(),
                      },
                    ]);
                }}
                onIonChange={(e) => setPromotion(e!.detail.value)}
              >
                {avaliablePromotions.map((promotionItem: any) => {
                  if (
                    item.category &&
                    item.category.name &&
                    item.category.name != promotionItem.category
                  ) {
                    return null;
                  }
                  return (
                    <IonItem
                      color={`${isDark2 ? 'dark' : 'light'}`}
                      key={promotionItem.id}
                      className={`space-x-1
                      ${isDark2 ? ' text-white ' : 'text-black'}`}
                    >
                      <IonRadio slot='start' value={promotionItem} />
                      <div
                        className={`text-[12px]
                      ${isDark2 ? ' text-white' : 'text-black'}`}
                      >
                        {prefix} {promotionItem.duration}{' '}
                        {promotionItem.users && promotionItem.users} {suffix}
                      </div>
                      <div className='text-orange-400'>€{promotionItem.price}</div>
                    </IonItem>
                  );
                })}
              </IonRadioGroup>
            </div>
            <p className={`text-[13px] ml-6 text-center ${isDark2 ? ' text-white' : 'text-black'}`}>
              {message}
            </p>
            {promotion && !isPlatform('ios') ? (
              <div className='flex justify-center items-center' onClick={handleAddToCart}>
                <div className='w-3/4 h-[44px] rounded-md bg-yellow-accent'>
                  <p className='text-center text-[16px] py-3 font-bold text-white w-full'>
                    IN DEN WARENKORB LEGEN
                  </p>
                </div>
              </div>
            ) : (
              <div className='flex justify-center items-center'>
                <div className='w-3/4 h-[44px] rounded-md bg-gray-200'>
                  <p className='text-center text-[16px] py-3 font-bold text-gray-400 w-full'>
                    IN DEN WARENKORB LEGEN
                  </p>
                </div>
              </div>
            )}
            <div className='w-full bottom-2 flex justify-center'>
              <div
                className='rounded-md p-2 border-2 mb-12 font-bold w-3/4 mt-2
                   border-yellow-accent text-yellow-accent text-center hover:bg-gray-200'
                onClick={() =>
                  setActiveTab ? setActiveTab(nextTab) : router.push(`/promote/${type}/${id}`)
                }
              >
                Weiter
              </div>
            </div>
          </>
        )
      )}
    </div>
  );
};

import React, {useEffect, useState} from 'react';
import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar} from '@ionic/react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import UILogo from 'components/uiLogo/UILogo';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UIButtonSearch from 'components/uiButtons/UIButtonSearch';
import UIResponsivePadding from 'components/uiResponsive/UIResponsivePadding';
import {isDarkTheme} from 'states/account/account.state';
import UIOrangeCategoryBox from 'components/uiContainer/UIOrangeCategoryBox';
import {instrumentsService} from 'api/instruments/instruments.service';
import {productsService} from 'api/products/products.service';
import {servicesService} from 'api/services/services.service';
import {refreshCounterState} from 'states/common/common.state';
import {authService} from 'api/account/auth.service';

import buyAgricultureImg from 'assets/agriculture/land_kaufen.svg';
import sellAgricultureImg from 'assets/agriculture/land_verkaufen.svg';
import putAgricultureForRentImg from 'assets/agriculture/land_vermieten.svg';
import rentAgricultureImg from 'assets/agriculture/land_mieten.svg';
import searchService from 'assets/community/services&jobs/search_service.svg';
import createService from 'assets/community/services&jobs/create_service.svg';

const AgricultureMenuPage: React.FC = () => {
  const token = authService.tokenValue;
  const isDark2 = useRecoilValue(isDarkTheme);
  const [instrumentsCount, setInstrumentsCount] = useState<number>();
  const [productsCount, setProductsCount] = useState<number>();
  const [servicesCount, setServicesCount] = useState<number>();
  const setRefreshCounter = useSetRecoilState(refreshCounterState);

  useEffect(() => {
    instrumentsService.getInstruments(0, 3).then((res) => {
      setInstrumentsCount(res.data.length);
      setRefreshCounter((prev) => prev + 1);
    });
    productsService.getProducts(0, 3).then((res) => {
      setProductsCount(res.data.length);
      setRefreshCounter((prev) => prev + 1);
    });
    servicesService.getServices(2).then((res) => {
      setServicesCount(res.data.length);
      setRefreshCounter((prev) => prev + 1);
    });
  }, []);

  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
          <IonButtons slot='end'>
            <UIButtonSearch itemsType='products' />
          </IonButtons>
        </IonToolbar>
        <UITopTextBar>Land & Forsttechnik</UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <UIResponsivePadding>
          <div className='mt-4'>
            <UIOrangeCategoryBox
              name={'LAND & FORSTTECHNIK MIETEN'}
              src={rentAgricultureImg}
              alt={'RentL&F'}
              linkTo={'/instruments/categories/3/loan'}
              count={instrumentsCount}
            />
            <UIOrangeCategoryBox
              name={'LAND & FORSTTECHNIK VERMIETEN'}
              src={putAgricultureForRentImg}
              alt={'PutL&FForRent'}
              linkTo={!token ? '/sign-in' : '/instruments-add'}
            />
            <UIOrangeCategoryBox
              name={'LAND & FORSTTECHNIK KAUFEN'}
              src={buyAgricultureImg}
              alt={'BuyL&F'}
              linkTo={'/instruments/categories/3/buy'}
              count={productsCount}
            />
            <UIOrangeCategoryBox
              name={'LAND & FORSTTECHNIK VERKAUFEN'}
              src={sellAgricultureImg}
              alt={'SellL&F'}
              linkTo={!token ? '/sign-in' : '/products-add'}
            />
            <UIOrangeCategoryBox
              name={'SUCHE LAND & FORSTTECHNIK DIENSTLEISTUNGEN'}
              src={searchService}
              alt={'SellMachinery'}
              linkTo={'/agricultural-offers'}
              count={servicesCount}
            />
            <UIOrangeCategoryBox
              name={'BIETE LAND & FORSTTECHNIK DIENSTLEISTUNGEN'}
              src={createService}
              alt={'SellMachinery'}
              linkTo={!token ? '/sign-in' : '/offers-add/2'}
            />
          </div>
        </UIResponsivePadding>
      </IonContent>
    </IonPage>
  );
};

export default AgricultureMenuPage;

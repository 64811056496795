import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar, useIonRouter} from '@ionic/react';
import {userService} from 'api/users/users.service';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import UILoadingIndicator from 'components/uiLoading/UILoadingIndicator';
import UILogo from 'components/uiLogo/UILogo';
import {Instrument} from 'interfaces/instruments/instrument.interface';
import {Job} from 'interfaces/jobs/jobs.interface';
import {Product} from 'interfaces/products/product.interface';
import {Service} from 'interfaces/services/service.interface';
import InstrumentsList from 'pages/instruments/instrumentsList/InstrumentsList';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {useRecoilState, useRecoilValue} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';
import {refreshCounterState} from 'states/common/common.state';
import ProductsList from '../../products/productsList/ProductsList';
import {ServicesList} from 'pages/services/servicesList/ServiceList';
import {JobsList} from 'pages/jobs/jobsList/JobsList';

const UserItemsPage = () => {
  const user: {id: string; name: string} = useParams();
  const isDark2 = useRecoilValue(isDarkTheme);
  const router = useIonRouter();
  const [refreshCounter, setRefreshCounter] = useRecoilState(refreshCounterState);
  const [error, setError] = useState<boolean>(false);
  const [items, setItems] = useState<{
    instruments: Instrument[];
    products: Product[];
    services: Service[];
    jobs: Job[];
  }>();

  useEffect(() => {
    userService
      .getUserItems(user.id)
      .then((res) => setItems(res.data))
      .catch(() => setError(true));
  }, [refreshCounter]);

  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar>{user.name ? user.name : 'Andere Benutzeranzeigen'}</UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        {error && (
          <p className='text-center'>
            Um alle Benutzerelemente sehen zu können, müssen Sie angemeldet sein!
          </p>
        )}
        {!items ? (
          !error && <UILoadingIndicator />
        ) : (
          <>
            <InstrumentsList
              instruments={items.instruments}
              imageType='instruments'
              onInstrumentClick={(instrument: Instrument) => {
                router.push(
                  // eslint-disable-next-line max-len
                  `/instrument/${instrument.category.name}/${instrument.subcategory.name}/${instrument.id}`
                );
                setRefreshCounter((refreshCounter) => refreshCounter + 1);
              }}
            />
            <ProductsList
              products={items.products}
              type='products'
              onProductClick={(product: Product) => {
                router.push(
                  `/product/Land & Forsttechnik kaufen/${product.category.name}/${product.id}`
                );
                setRefreshCounter((v) => v + 1);
              }}
            />
            <ServicesList
              services={items.services}
              onServiceClick={(service: Service) => {
                router.push(`/service/${service}`);
              }}
              isOwnService={false}
            />
            <JobsList
              jobs={items.jobs}
              isOwnJob={false}
              onJobClick={(job: Job) => router.push(`/job/${job}`)}
            />
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default UserItemsPage;

import React from 'react';
import {Control, Controller} from 'react-hook-form';
import AddPhotoInner from './UIAddPhotoInner';

interface AddPhotoProps {
  control: Control;
  name: string;
  onValueChange?: Function;
};

const AddPhoto: React.FC<AddPhotoProps> = ({control, name, onValueChange}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({field: {onChange, value}}) => (
        <AddPhotoInner onChange={onChange} value={value} onValueChange={onValueChange} />
      )}
    />
  );
};

export default AddPhoto;

import {BFormFieldType} from 'forms/common/bForm/BFormField';

export const formRegisterSecStage: BFormFieldType[] = [
  {
    fieldName: 'street',
    fieldType: 'text',
    placeHolder: '*Straße',
    validation: {required: true},
  },
  {
    fieldName: 'houseNumber',
    fieldType: 'text',
    placeHolder: '*Hausnummer',
    validation: {required: true},
  },
  {
    fieldName: 'city',
    fieldType: 'text',
    placeHolder: '*Ort',
    validation: {required: true},
  },
  {
    fieldName: 'zipCode',
    fieldType: 'text',
    placeHolder: '*PLZ',
    validation: {required: true},
  },
  {
    fieldName: 'phoneNumber',
    fieldType: 'text',
    placeHolder: '*Telefonnummer',
    isNumberPrefix: true,
    validation: {required: true},
  },
  {
    fieldName: 'accountType',
    fieldType: 'checkbox',
    placeHolder: '*Auswahl des Kontotyps',
    validation: {required: false},
  },
];

import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {Link} from 'react-router-dom';
import {IonIcon, isPlatform} from '@ionic/react';
import {useIonRouter} from '@ionic/react';
import {flag, homeOutline, starOutline, mailOutline, callOutline} from 'ionicons/icons';
import {Instrument} from 'interfaces/instruments/instrument.interface';
import {accountState, isDarkTheme} from 'states/account/account.state';
import {instrumentsService} from 'api/instruments/instruments.service';
import FavouritesButton from 'pages/common/favourites/FavouritesButton';
import greenCircle from 'assets/temp/green_circle.png';
import redCircle from 'assets/temp/red_circle.png';
import {UIClickImage} from 'components/uiImages/UIClickImage';
import UIButtonShare from 'components/uiButtons/UIButtonShare';
import BMiniMap from 'forms/common/places/BMiniMap';
import UICommentSpace from 'components/uiCommentSpace/UICommentSpace';
import {ratingState} from 'states/common/common.state';
import euroIcon from 'assets/icons/euro_icon.svg';

interface Props {
  fields: Instrument;
  setAvgRating: Dispatch<SetStateAction<string>>;
  avgRating: string;
  isDefaultFavorite: boolean;
  setDate: any;
  setIsPopUpVisible: Dispatch<SetStateAction<boolean>>;
  onClick?: any;
}

export const DetailsInstrument = ({
  fields,
  setAvgRating,
  avgRating,
  isDefaultFavorite,
  setDate,
  setIsPopUpVisible,
  onClick,
}: Props) => {
  const router = useIonRouter();
  const accountData = useRecoilValue<any>(accountState);
  const [isFavorite, setIsFavorite] = useState(isDefaultFavorite);
  const ratingCounter = useRecoilValue(ratingState);

  useEffect(() => setIsFavorite(isDefaultFavorite), [isDefaultFavorite]);

  useEffect(() => {
    instrumentsService.getDetails(fields.id).then((response) => {
      setAvgRating(response.data.owner.userAvg);
    });
  }, [ratingCounter]);

  const favHandler = async (id: number) => {
    await instrumentsService.addFavoriteInstrument(id);
  };
  const isDark2 = useRecoilValue(isDarkTheme);

  return (
    <div
      className={`rounded-lg border m-1 drop-shadow-md 
    ${isDark2 ? 'bg-[#393939] border-[#F0A249]' : 'bg-yellow-slight border-yellow-accent'}`}
    >
      <UIClickImage
        customClassName='w-full h-52 rounded-3xl'
        type='instruments'
        photoLink={fields.photos.length > 0 && fields.photos[0].photo}
        setIsPopUpVisible={setIsPopUpVisible}
      />
      <div className='flex justify-end'>
        {fields.availability && (
          <img alt='greenCircle' src={greenCircle} className='h-4 mt-2 mr-3 absolute top-0' />
        )}
        {!fields.availability && (
          <img alt='redCircle' src={redCircle} className='h-4 mt-2 mr-3 absolute top-0' />
        )}
      </div>
      <div className={`absolute ${isPlatform('ios') ? ' w-[94%] ' : ' w-full '} top-40`}>
        <div
          className={`'rounded-t-3xl pl-4 border-t-2 border-orange-main
        ${isDark2 ? 'bg-[#393939]' : 'bg-[#F8F8F8]'}`}
        >
          <div className='flex justify-between'>
            <div>
              <p
                className={`text-lg font-bold mt-2 pt-5
                ${isDark2 ? 'text-white' : 'text-gray-600 ]'}`}
              >
                {fields.instrumentName}
              </p>
              <p className={`text-sm font-thin ${isDark2 ? 'text-white' : 'text-black ]'}`}>
                {fields.productionYear}
              </p>
              <p className={`text-sm font-thin ${isDark2 ? 'text-white' : 'text-black ]'}`}>
                {fields.purpose}
              </p>
              <p className={`text-sm font-thin ${isDark2 ? 'text-white' : 'text-black ]'}`}>
                {fields.owner.firstName + ' ' + fields.owner.lastName}
              </p>
            </div>
            <div>
              <div className='pt-9 text-black space-x-2 flex items-center mr-5'>
                <Link to={`/report/instrument/${fields.id}`}>
                  <IonIcon
                    icon={flag}
                    className={`mt-2 h-6 w-6  ${isDark2 ? 'text-[#F0A249]' : 'text-orange-main'}`}
                  />
                </Link>
                {fields && accountData && accountData.id !== fields!.owner!.id ? (
                  <FavouritesButton
                    favHandler={favHandler}
                    fields={fields}
                    isFavorite={isFavorite}
                    setIsFavorite={setIsFavorite}
                  />
                ) : (
                  <IonIcon
                    icon={starOutline}
                    className='mt-1 text-yellow-400 h-6 w-6'
                    onClick={() => {
                      router.push('/sign-in');
                    }}
                  />
                )}
                <UIButtonShare />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex justify-end mr-4 absolute top-36 right-0'>
        <div className='flex flex-row space-x-4'>
          <div
            className='bg-yellow-accent text-white pr-2
             rounded-3xl flex p-1'
          >
            <IonIcon
              icon={euroIcon}
              className={`mt-1 ml-3 
          ${isDark2 ? 'text-black' : 'text-white '}`}
            />
            <p className='mr-2 text-sm mt-1 text-white'>{fields.pricePerDay} / pro Tag</p>
          </div>
          <button
            className={`bg-orange-rate rounded-3xl w-8 text-xs
            ${isDark2 ? 'text-black' : 'text-white ]'}`}
            onClick={onClick}
          >
            {fields.owner.userAvg ? avgRating : '-'}
          </button>
        </div>
      </div>
      <div>
        <div
          className={`mb-3 font-sans text-gray-500 pt-14 rounded-b-2xl
         ${isDark2 ? ' bg-[#393939]' : 'bg-[#F8F8F8] '}`}
        >
          <p
            className={`text-yellow-accent float-left text-sm mr-1.5 ml-3
            ${isDark2 ? ' text-[#F0A249]' : 'text-yellow-accent'}`}
          >
            Kontakt
          </p>
          <hr
            className={` mr-3 mb-4 mt-7
      ${isDark2 ? ' text-[#F0A249]' : 'text-yellow-accent'}`}
          />
          <div
            className={`flex-col ml-4 mr-8
      ${isDark2 ? ' text-white' : 'text-gray-500'}`}
          >
            {fields.isVisiblePhoneNumber && (
              <div className='flex'>
                <IonIcon icon={callOutline} className='mt-0.5' />
                <p className='text-sm ml-2 '>{fields.phoneNumber}</p>
              </div>
            )}
            <div className='flex mt-3'>
              <IonIcon icon={homeOutline} className='h-5 w-5' />
              <p className='text-sm ml-2'>{fields.location}</p>
            </div>
            {fields.isVisibleEmail && (
              <div className='flex mt-3'>
                <IonIcon icon={mailOutline} className='h-5 w-5' />
                <p className='text-sm ml-2'>{fields.email}</p>
              </div>
            )}
          </div>
          <div className='mx-3'>
            <BMiniMap geolocation={{lat: +fields.lat, lng: +fields.lng}} />
          </div>
          <p
            className={`float-left text-sm mt-3 mr-1.5 ml-3
            ${isDark2 ? ' text-[#F0A249]' : 'text-yellow-accent'}`}
          >
            Beschreibung
          </p>
          <hr
            className={`mr-3 mt-7 mb-4
            ${isDark2 ? ' text-[#F0A249]' : 'text-yellow-accent'}`}
          />
          <div
            className={`text-sm ml-4
             ${isDark2 ? ' text-white' : 'text-black '}`}
          >
            {fields.description}
          </div>
          <p
            className={`float-left text-sm mr-1.5 ml-3
             ${isDark2 ? ' text-[#F0A249]' : 'text-yellow-accent'}`}
          >
            Kommentare
          </p>
          <hr
            className={`mr-3 mt-7 mb-4
          ${isDark2 ? 'bg-[#F0A249]' : 'bg-yellow-accent '}`}
          />
          <UICommentSpace comments={fields.comments} id={fields.id} commented={fields.commented} />
          {/* <UICalendar onDatesSet={setDate} /> */}
        </div>
      </div>
    </div>
  );
};

import {Storage} from '@capacitor/storage';

const LAST_PAYMENT_ID = 'LAST_PAYMENT_ID';

export const saveLastPayment = async (
  paymentId: string,
) => {
  Storage.set({key: LAST_PAYMENT_ID, value: paymentId});
};

export const getLastPayment = async () => {
  const {value} = await Storage.get({key: LAST_PAYMENT_ID});

  if (value) return value;
};

import {LatLng} from 'react-google-places-autocomplete/build/GooglePlacesAutocomplete.types';
import {earthRadius} from './constants';
import {toRadianConverter} from './toRadianConverter';

export const distanceCalculator = (
  externalLat: number,
  externalLng: number,
  geolocation: LatLng,
) => {
  const latDiffrence = toRadianConverter(externalLat - geolocation.lat);
  const lngDiffrence = toRadianConverter(externalLng - geolocation.lng);
  const radianLat = toRadianConverter(geolocation.lat);
  externalLat = toRadianConverter(externalLat);

  const squarehalfChordLength =
    Math.sin(latDiffrence / 2) * Math.sin(latDiffrence / 2) +
    Math.sin(lngDiffrence / 2) *
      Math.sin(lngDiffrence / 2) *
      Math.cos(radianLat) *
      Math.cos(externalLat);

  const angularDistance =
    2 *
    Math.atan2(
      Math.sqrt(squarehalfChordLength),
      Math.sqrt(1 - squarehalfChordLength),
    );
  const distance = earthRadius * angularDistance;
  return distance;
};

import React from 'react';
import {useRecoilValue} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';

interface Props {
  src: string;
  alt: string;
  name: string;
  onClick?: any;
  isEnable?: boolean;
  numberOfItems?: number;
  catId?: number;
  classname?: string;
}

const HomeMenuButton: React.FC<Props> = ({
  src,
  alt,
  name,
  onClick,
  isEnable = true,
  catId,
  classname = '',
  numberOfItems
}) => {
  if (!onClick) isEnable = false;
  const isDark2 = useRecoilValue(isDarkTheme);

  return (
    <div
      className={`p-2 ${!isEnable && `opacity-40`} relative`}
      onClick={isEnable ? onClick : undefined}
    >
      <div
        className={`border rounded-xl shadow-lg w-full xl:w-180
       ${isDark2 ? ' border-[#F0A249] shadow-[#F0A249]' : 'shadow-orange-main border-orange-main'}`}
      >
        <div className='flex flex-row hover:cursor-pointer'>
          <div className='w-1/2 flex items-center justify-start px-10 xl:text-lg font-medium'>
            {name}
          </div>
          <div className='w-1/2'>
            <div
              className={`flex justify-end w-full border-white rounded-r-xl
          ${isDark2 ? ' text-white' : 'text-grey'}`}
            >
              {typeof numberOfItems === 'number' && (
                <div>
                  <div
                    className='bg-white text-black border border-yellow-accent rounded-xl
               absolute mt-10 px-2 py-0.5 w-[54px] text-center z-20 ml-[-25px] xl:text-lg'
                  >
                    {numberOfItems}
                  </div>
                </div>
              )}
              <img
                src={src}
                alt={alt}
                style={{padding: 2}}
                className={`${classname ? classname : 'h-28 w-40 rounded-r-xl opacity-60'}`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeMenuButton;

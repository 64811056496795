import React from 'react';
import {IonIcon} from '@ionic/react';
import {useRecoilValue} from 'recoil';
import {locationOutline, logoEuro} from 'ionicons/icons';
import {Product} from 'interfaces/products/product.interface';
import {isDarkTheme} from 'states/account/account.state';
import {urlConverter} from 'utils/urlConverter';

import redCircle from 'assets/temp/red_circle.png';
import greenCircle from 'assets/temp/green_circle.png';

export interface ProductProps {
  fields: Product[];
  onProductClick: (product: Product) => void;
}

const FavoritesProductList: React.FC<ProductProps> = ({fields, onProductClick}) => {
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <div>
      {fields &&
        fields.map((item) => {
          let photo = '';
          if (item.photos.length > 0) {
            photo = urlConverter(item.photos[0].photo);
          }
          return (
            <div
              key={item.id}
              className={`w-[90%] md:w-[60%] max-w-screen-md mx-auto relative ${
                item.promoted
                  ? 'shadow-custom shadow-orange-promoted border rounded-3xl border-orange-promoted'
                  : 'border rounded-3xl border-yellow-accent '
              }  ${isDark2 ? 'bg-[#393939]' : 'bg-[#F8F8F8]'}`}
              onClick={() => onProductClick(item)}
            >
              <div>
                <div className='flex justify-center w-full h-44 md:h-64 rounded-3xl'>
                  <img
                    src={photo}
                    alt='item'
                    className='h-full rounded-t-3xl rounded-3xl w-full object-cover'
                  />
                  {item && (
                    <div className='flex justify-end'>
                      {item.availability ? (
                        <img
                          alt='image'
                          src={greenCircle}
                          className='h-4 mt-2 pr-3  absolute top-0'
                        />
                      ) : (
                        <img alt='image' src={redCircle} className='h-4 mt-2 mr-3 absolute top-0' />
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div
                className={`absolute top-28 md:top-48 rounded-b-3xl mt-1 bg-white w-full
             border-orange-main border-b ${isDark2 ? 'bg-[#393939]' : 'bg-[#f8f8f8]'}`}
              >
                <p
                  className={` text-md font-bold mt-1 pl-2
                ${isDark2 ? 'text-white' : 'text-gray-600'}`}
                >
                  {item.productName}
                </p>
                <div className='flex justify-between'>
                  <p
                    className={`text-[12px] ml-7 mb-1
                 ${isDark2 ? 'text-white' : 'text-gray-600 ]'}`}
                  >
                    {item.location}
                  </p>
                  {item.distance && (
                    <div className='flex'>
                      <IonIcon
                        icon={locationOutline}
                        className={`h-4 w-4 ml-3 ${isDark2 ? 'text-white' : 'text-gray-600 ]'}`}
                      />
                      <div className={`text-xs mr-3 ${isDark2 ? 'text-white' : 'text-gray-600 ]'}`}>
                        {item.distance.toFixed() + ' '}
                        km
                      </div>
                    </div>
                  )}
                </div>
                <p
                  className={`text-[12px] ml-7 mb-2
                 ${isDark2 ? 'text-white' : 'text-gray-600 ]'}`}
                >
                  <b>hinzugefügt: </b>
                  {item.dateAdded?.substring(0, 10)}
                </p>
              </div>
              <div className='flex justify-end mr-3'>
                <div
                  className='bg-yellow-accent text-white absolute p-2
                top-24 md:top-44 rounded-2xl flex mt-2'
                >
                  <IonIcon icon={logoEuro} className='text-sm' />
                  <p className='text-xs'>{item.price + '/ pro Tag'}</p>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default FavoritesProductList;

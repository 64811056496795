import {isPlatform} from '@ionic/core';
import {Camera, CameraResultType, CameraSource} from '@capacitor/camera';
import addphoto from 'assets/add_photo.png';
import {UIImage} from 'components/uiImages/UIImage';
import React, {useRef} from 'react';
import {ONE_MB_IN_BYTES} from 'utils/constants';
import {base64ToFile, urlToFile} from 'utils/base64ToBlobConverter';
import {useIonActionSheet} from '@ionic/react';

interface AddPhotosInnerProps {
  onPhotoAdd: (...event: any[]) => void;
  addedPhots: File[] | any;
  availableExtensions?: string[];
  oldPhotos?: {photo: string}[];
  setOldPhotos?: any;
  photosType?: string;
  disabled?: boolean;
}

const AddPhotosInner: React.FC<AddPhotosInnerProps> = ({
  onPhotoAdd,
  addedPhots: addedPhotos,
  oldPhotos,
  setOldPhotos,
  photosType,
  availableExtensions = ['image/jpg', 'image/jpeg', 'image/png'],
  disabled,
}) => {
  const imageInput = useRef<any>(null);
  const [present] = useIonActionSheet();
  const isDesktop = !isPlatform('android') && !isPlatform('ios');

  const handleAndroidCamera = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      source: CameraSource.Camera,
      resultType: CameraResultType.Base64,
    });
    if (!image) return;
    const file = base64ToFile(image.base64String!);

    handleChange([file]);
  };

  const handleAndroidGallery = async () => {
    const images = await Camera.pickImages({
      quality: 90,
      limit: 1,
    });
    if (!images.photos) return;

    const files: File[] = [];

    for (let i = 0; i < images.photos.length; i++) {
      const file = await urlToFile(images.photos[i].webPath!, images.photos[i].format);
      files.push(file);
    }

    handleChange([...files]);
  };

  const handleChange = (files: FileList | any) => {
    if (!files) {
      return;
    }

    let photos: any = [];

    for (let i = 0; i < files.length; i++) {
      const availableSizeInMb = 20;

      if (files[i].size > availableSizeInMb * ONE_MB_IN_BYTES) {
        // eslint-disable-next-line no-alert
        alert('Die Datei ist zu groß!');
        return;
      }

      photos = [...photos, files[i]];
      if (i == 3) {
        break;
      }
    }

    onPhotoAdd(photos);

    imageInput.current.value = null;

    setOldPhotos && setOldPhotos(undefined);
  };

  const handleClickButton = () => {
    !disabled &&
      !oldPhotos &&
      (isPlatform('android')
        ? present([
            {
              text: 'Fotomediathek',
              handler: () => handleAndroidGallery(),
            },
            {
              text: 'Foto aufnehmen',
              handler: () => handleAndroidCamera(),
            },
          ])
        : imageInput.current?.click());
  };
  return (
    <>
      <input
        type='file'
        onChange={(event) => handleChange(event.currentTarget.files!)}
        ref={imageInput}
        className='filetype'
        style={{display: 'none'}}
        accept={availableExtensions.join(', ')}
      />
      <div
        className={`border border-yellow-accent rounded-xl
        ${isDesktop ? 'w-[350px] h-[250px]' : 'w-[110px] h-[110px]'}`}
      >
        <div className={isDesktop ? 'h-full w-full' : 'h-full w-full'} onClick={handleClickButton}>
          <div className='flex justify-center'>
            {!addedPhotos && !oldPhotos && (
              <img
                alt='addphoto'
                src={addphoto}
                className={`${isDesktop ? 'mt-[100px]' : 'mt-9'} h-8 w-9`}
              />
            )}
          </div>
          <div className='flex justify-center'>
            {!addedPhotos && oldPhotos && oldPhotos.length < 1 && (
              <img alt='addphoto' src={addphoto} className='h-8 w-9' />
            )}
          </div>
          {oldPhotos && oldPhotos.length > 0 && !addedPhotos && (
            <UIImage
              type={photosType}
              customClassName={`rounded-xl ${isDesktop ? 'max-h-[250px]' : 'max-h-[110px]'}`}
              photoLink={oldPhotos[0].photo}
            />
          )}
          {addedPhotos && (
            <div className={isDesktop ? 'max-h-[250px] w-[350px] flex justify-center' : ''}>
              <img
                alt='photoUrl'
                src={URL.createObjectURL(addedPhotos[0])}
                className={`${
                  isDesktop ? 'max-h-[250px] max-w-[350px]' : 'h-[110px] w-[110px] centered'
                } rounded-xl`}
              />
            </div>
          )}
        </div>
        <div className='flex space-x-0.5 -ml-1.5'>
          {oldPhotos &&
            !addedPhotos &&
            oldPhotos
              .filter((_: any, index: number) => index != 0)
              .map((photo: {photo: string}, index: number) => (
                <UIImage
                  type={photosType}
                  key={index}
                  customClassName='h-[40px] w-[40px] rounded-md mt-2'
                  photoLink={photo.photo}
                />
              ))}
          {!oldPhotos && (
            <>
              {addedPhotos ? (
                addedPhotos
                  .filter((_: any, index: number) => index != 0)
                  .map((photo: File, index: number) => (
                    <img
                      key={index}
                      alt='added photo'
                      src={URL.createObjectURL(photo)}
                      className='centered h-[35px] w-[35px] rounded-md mt-3 ml-2'
                    />
                  ))
              ) : (
                <div className='flex space-x-1 text-xs bg-auto'>
                  <img
                    alt='next_photo'
                    src={addphoto}
                    width={35}
                    className='centered border border-yellow-accent
              rounded-xl'
                  />
                  <img
                    alt='next_photo'
                    src={addphoto}
                    width={35}
                    className='centered border border-yellow-accent
              rounded-xl'
                  />
                  <img
                    alt='next_photo'
                    src={addphoto}
                    width={35}
                    className='centered border border-yellow-accent
            rounded-xl'
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AddPhotosInner;

import {IonTextarea, useIonRouter} from '@ionic/react';
import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar, useIonAlert} from '@ionic/react';
import UIInput from 'components/uiInputs/UIInput';
import UIYellowButton from 'components/uiButtons/UIYellowButton';
import {OrangeLine} from 'components/uiContainer/UIOrangeLine';
import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {postsService} from 'api/forum/forum.service';
import UILogo from 'components/uiLogo/UILogo';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import {lastAddedPostState} from 'states/messages/message.state';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {refreshCounterState} from 'states/common/common.state';
import {isDarkTheme} from 'states/account/account.state';

const FormPost = () => {
  const setRefreshCount = useSetRecoilState(refreshCounterState);
  const setLastPost = useSetRecoilState(lastAddedPostState);
  const [apiResultMessage, setApiResultMessage] = useState('');

  const {
    handleSubmit,
    register,
    formState: {errors},
  } = useForm();

  const [presentAlert] = useIonAlert();
  const router = useIonRouter();

  const onSubmit = async (data: any) => {
    postsService
      .addPost(data)
      .then((res) => {
        setLastPost(data.title);
        setApiResultMessage('Erfolg!');
        setRefreshCount((v) => v + 1);
      })
      .then(() => {
        presentAlert({
          message: 'Erfolg',
          buttons: ['OK'],
        });
        router.push('/forum');
      })
      .catch((error) => {
        setApiResultMessage('Error! ' + error.response.data.message[0]);
      });
  };
  const isDark2 = useRecoilValue(isDarkTheme);

  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <form className='h-full mb-3 font-sans font-semibold ' onSubmit={handleSubmit(onSubmit)}>
          <div
            className={`border-2 border-orange-main
              rounded-md mb-5 mx-3
              ${isDark2 ? ' bg-[#393939]' : ' bg-[#F8F8F8]'}`}
          >
            <div className='flex my-4'></div>
            <div className='w-full m-3 text-sm'>
              <div className='w-full space-y-2'>
                <div className='flex space-x-1 w-full'>
                  <p className='text-gray-400'>Titel:</p>
                  <div className='flex w-full'>
                    <UIInput
                      customClassName={`w-60 text-sm pl-2
                        ${isDark2 ? ' text-white bg-[#393939] ' : ' text-black bg-[#F8F8F8] '}`}
                      name='title'
                      type='text'
                      placeholder='Titel...'
                      maxLength={30}
                      register={register}
                      validation={{required: true}}
                      errors={errors}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='flex items-center space-x-0.5 text-sm mt-2 mx-'></div>
            <OrangeLine name='Beschreibung' />
            <IonTextarea
              className={`input break-words text-sm pr-4
                ${isDark2 ? 'text-white bg-[#393939]' : 'text-black bg-[F8F8F8]'}`}
              placeholder='Text...'
              rows={6}
              maxlength={2000}
              {...register('description')}
            />
          </div>
          <div className='flex flex-col justify-end text-white'>
            <p className='text-yellow-accent text-center font-bold mb-5'>{apiResultMessage}</p>
            <UIYellowButton text='Weiter' />
          </div>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default FormPost;

import React, {useEffect, useState} from 'react';
import {IonContent, IonHeader, IonPage, IonToolbar, useIonRouter} from '@ionic/react';
import UILogo from 'components/uiLogo/UILogo';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import {Instrument} from 'interfaces/instruments/instrument.interface';
import {Job} from 'interfaces/jobs/jobs.interface';
import {Service} from 'interfaces/services/service.interface';
import {useRecoilValue} from 'recoil';
import {accountState, isDarkTheme} from 'states/account/account.state';
import {FavoritesServicesList} from './FavoritesOffersList';
import FavoritesInstrumentList from './FavoritesInstrumentList';
import {FavoritesJobsList} from './FavouritesJobsList';
import {Product} from 'interfaces/products/product.interface';
import FavoritesProductList from './FavoritesProductList';

const FavoritesPage: React.FC = () => {
  const [offers, setOffers] = useState<Service[]>();
  const [instruments, setInstruments] = useState<Instrument[]>();
  const [jobs, setJobs] = useState<Job[]>();
  const [products, setProducts] = useState<Product[]>();
  const accountData = useRecoilValue(accountState);
  const router = useIonRouter();

  useEffect(() => {
    if (accountData) {
      setOffers(accountData!.favourites?.services);
      setInstruments(accountData!.favourites?.instruments);
      setJobs(accountData!.favourites?.jobs);
      setProducts(accountData!.favourites?.products);
    }
  }, [accountData]);
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <UILogo />
        </IonToolbar>
        <UITopTextBar>Favoriten</UITopTextBar>
      </IonHeader>
      <IonContent fullscreen color={`${isDark2 ? 'medium' : 'light'}`}>
        <div className='space-y-12 mb-12'>
          {offers || instruments || jobs || products ? (
            ''
          ) : (
            <div className={`p-1 ${isDark2 ? ' text-white' : 'text-black '}`}>
              Sie haben noch keine Lieblings Angebote
            </div>
          )}
          {offers && (
            <FavoritesServicesList
              fields={offers}
              onOfferClick={(offerId: any) => router.push(`/service/${offerId}`)}
            />
          )}
          {instruments && (
            <FavoritesInstrumentList
              fields={instruments}
              onInstrumentClick={(instrument: Instrument) =>
                router.push(
                  `/instrument/${instrument.category.name}/ + 
                ${instrument.subcategory.name}/${instrument.id}`
                )
              }
            />
          )}
          {jobs && (
            <FavoritesJobsList
              fields={jobs}
              onJobClick={(jobId: any) => router.push(`/job/${jobId}`)}
            />
          )}
          {products && (
            <FavoritesProductList
              fields={products}
              onProductClick={(product: Product) =>
                router.push(
                  `/product/${product.category.name}/ + 
                ${product.subcategory.name}/${product.id}`
                )
              }
            />
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default FavoritesPage;

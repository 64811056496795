export function createFormData(formValues: any) {
  const objectKeys = Object.keys(formValues);
  const formData = new FormData();
  objectKeys.forEach((key) => {
    if (key === 'not_delete_photos') {
      for (let i = 0; i < formValues[key].length; i++) {
        formData.append('not_delete_photos', formValues[key][i]);
      }
    }
    if (key === 'photos' && typeof formValues[key] !== 'string') {
      if (formValues[key]) {
        formValues[key].forEach((image: any, idx: number) => {
          formData.append('photo_' + idx, image, image.name);
        });
      }
      return;
    }
    formData.append(key, formValues[key]);
  });
  return formData;
}

import React, {useEffect, useState} from 'react';
import {IonIcon, IonSelect, IonSelectOption, IonTextarea} from '@ionic/react';
import {useHistory} from 'react-router';
import {useForm} from 'react-hook-form';
import {BFormAddressField} from 'forms/common/places/BFormAddressField';
import {InstrumentRequest} from 'interfaces/instruments/instrument.interface';
import {productsService} from 'api/products/products.service';
import UIYellowButton from 'components/uiButtons/UIYellowButton';
import LineColored from '../instruments/LineColored';
import UIInput from 'components/uiInputs/UIInput';
import AddPhotos from 'components/uiAddPhoto/UIAddPhotos';
import {instrumentsService} from 'api/instruments/instruments.service';
import {errorCategoryTranslator} from 'utils/translateHelper';
import {authService} from 'api/account/auth.service';
import {userService} from 'api/users/users.service';
import {useRecoilValue} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';
import EditPhotos from 'pages/common/editPhotos/EditPhotos';
import euroIcon from 'assets/icons/euro_icon.svg';

function FormProduct() {
  const [categories, setCategories] = useState<any[]>([]);
  const [subcategories, setSubcategories] = useState<any[]>([]);
  const [subcatId, setSubcatId] = useState<string>('');
  const [catId, setCatId] = useState<string>('');
  const [productId, setProductId] = useState<any>();
  const [address, setAddress] = useState<any>();
  const [apiResultMessage, setApiResultMessage] = useState('');
  const [isPhotoPopUp, setIsPhotoPopUp] = useState<boolean>();
  const [photos, setPhotos] = useState<any>();

  const {
    handleSubmit,
    register,
    setValue,
    control,
    formState: {errors},
    watch,
  } = useForm();

  const history = useHistory();

  useEffect(() => {
    !authService.tokenValue && watch(() => history.replace('/sign-in'));

    userService.getUserData().then((response) => {
      setValue('email', response.data.email);
      setValue('phoneNumber', response.data.phoneNumber);
    });

    instrumentsService.getCategories('buy').then((response) => {
      setCategories(response.data.results);
    });
  }, []);

  useEffect(() => {
    instrumentsService.getCategories('buy').then((response) => {
      response.data.results.filter(
        (category: any) => category.id === catId && setSubcategories(category.subcategories)
      );
    });
  }, [catId]);

  useEffect(() => {
    if (photos) {
      const photoHelper = [];
      for (let i = 0; i < 4; i++) {
        if (typeof photos['photo_' + i] === 'undefined' || photos['photo_' + i] == 'deletePhoto') {
          continue;
        }
        photoHelper.push(photos['photo_' + i]);
      }
      photoHelper.length > 0 && setValue('photos', photoHelper);
    }
  }, [photos]);

  const onSubmit = (data: InstrumentRequest) => {
    data.name = data.name;
    data.productName = data.productName;
    data.category = catId;
    data.subcategory = subcatId;
    data.price = +data.price;
    data.productionYear = +data!.productionYear!;

    if (address) {
      data.location = address.label;
      data.postCode = address.postCode;
      data.lat = address.lat.toString();
      data.lng = address.lng.toString();
    } else if (data.Address) {
      data.location = data.Address.label;
      data.postCode = data.Address.postCode;
      data.lat = data.Address.lat.toFixed(7);
      data.lng = data.Address.lng.toFixed(7);
      setAddress(data.Address);
    }

    const method = productId
      ? productsService.updateProduct(data, productId)
      : productsService.addProducts(data);

    setApiResultMessage('Ladung...');

    method
      .then((res) => {
        setApiResultMessage('');
        setProductId(res.data.id);
        setAddress(data.Address);
        history.push(`/purchase/products/${res.data.id ? res.data.id : productId}`);
      })
      .catch((error) => {
        setApiResultMessage('Error! ' + errorCategoryTranslator(error.response.data.message[0]));
      });
  };
  const isDark2 = useRecoilValue(isDarkTheme);

  return (
    <>
      {isPhotoPopUp && (
        <EditPhotos setPhotos={setPhotos} photos={photos} setIsPhotoPopUp={setIsPhotoPopUp} />
      )}
      {!isPhotoPopUp && (
        <form className='h-full pb-3 ' onSubmit={handleSubmit(onSubmit)}>
          <div
            className={`border-2 border-orange-main text-black rounded-md
      ${isDark2 ? 'bg-[#393939]' : 'bg-[#f8f8f8]'}`}
          >
            <div className={`h-6 ${isDark2 ? 'bg-[#F0A249]' : ' bg-yellow-accent'}`}></div>
            <div className={`flex my-4 ${isDark2 ? 'bg-[#393939]' : 'bg-[#F8F8F8]'}`}>
              <div className='flex-col mx-2 w-2/5 space-y-2 text-sm pt-2 '>
                <div
                  className='flex-col w-2/5 space-y-2 text-sm mb-16 '
                  onClick={() => setIsPhotoPopUp(true)}
                >
                  <AddPhotos control={control} name='photos' disabled={true} />
                </div>
                <div>
                  <p className={`${isDark2 ? ' text-white' : 'text-black'}`}>Bezahlung:</p>
                  <div className='flex'>
                    <UIInput
                      customClassName={`w-full text-sm
                  ${isDark2 ? 'bg-[#393939] text-white' : 'bg-[#F8F8F8] text-black'}
                  `}
                      name='price'
                      type='number'
                      placeholder='80'
                      register={register}
                      validation={{required: true}}
                      errors={errors}
                    />
                    <IonIcon
                      icon={euroIcon}
                      className='mt-0.5 text-gray-500 lg:absolute lg:left-[160px]'
                    />
                  </div>
                  <div className='lg:w-[150px]'>
                    <LineColored error={errors.price} />
                  </div>
                  <div>
                    <p className={`${isDark2 ? ' text-white' : 'text-black'}`}>Baujahr:</p>
                    <UIInput
                      customClassName={`
                       ${isDark2 ? 'bg-[#393939] text-white' : 'bg-[#F8F8F8] text-black'}
                     `}
                      name='productionYear'
                      type='number'
                      placeholder='Jahr'
                      register={register}
                      validation={{required: true}}
                      errors={errors}
                    />
                    <LineColored error={errors.productionYear} />
                  </div>
                </div>
              </div>
              <div className='flex-col space-y-2 mr-[15px] w-3/5 text-sm'>
                <div className='space-y-2 mb-2'>
                  <div
                    className='w-44 h-6 p-4 flex items-center justify-between
              rounded-xl border-orange-main border text-orange-main'
                  >
                    <IonSelect
                      okText='Weiter'
                      cancelText='Abbrechen'
                      className={`${isDark2 ? ' text-white' : 'text-black'}`}
                      placeholder='Kategorie'
                      value={catId && catId}
                      onIonChange={(cat) => setCatId(cat.detail.value)}
                    >
                      {categories.map((cat) => (
                        <IonSelectOption key={cat.id} value={cat.id}>
                          {cat.name}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </div>
                  <div
                    className='w-44 h-6 p-4 flex items-center justify-between
              rounded-xl border-orange-main border text-orange-main'
                  >
                    <IonSelect
                      okText='Weiter'
                      cancelText='Abbrechen'
                      className={`${isDark2 ? ' text-white' : 'text-black'}`}
                      placeholder='Unterkategorie'
                      value={subcatId && subcatId}
                      onIonChange={(subcat) => setSubcatId(subcat.detail.value)}
                    >
                      {subcategories.map((subcat) => (
                        <IonSelectOption key={subcat.id} value={subcat.id}>
                          {subcat.name}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </div>
                </div>
                <div>
                  <p className={`${isDark2 ? ' text-white' : 'text-black'}`}>Name:</p>
                  <UIInput
                    customClassName={`
                    ${isDark2 ? 'bg-[#393939] text-white' : 'bg-[#F8F8F8] text-black'}
                  `}
                    required={'required'}
                    placeholder='Mischmaschine'
                    register={register}
                    name='productName'
                    validation={{required: true}}
                    errors={errors}
                  />
                  <LineColored error={errors.productName} />
                </div>
                <div>
                  <p className={`${isDark2 ? ' text-white' : 'text-black'}`}>Geräte Bezeichnung:</p>
                  <UIInput
                    customClassName={`
                     ${isDark2 ? 'bg-[#393939] text-white' : 'bg-[#F8F8F8] text-black'}
                   `}
                    name='name'
                    placeholder='Zweck'
                    required={'required'}
                    register={register}
                    validation={{required: true}}
                    errors={errors}
                  />
                  <LineColored error={errors.purpose} />
                </div>
                <div>
                  <p className={`${isDark2 ? ' text-white' : 'text-black'}`}>Kontakt:</p>
                  <UIInput
                    customClassName={`
                     ${isDark2 ? 'bg-[#393939] text-white' : 'bg-[#F8F8F8] text-black'}
                   `}
                    name='email'
                    required='required'
                    placeholder='beispiel@email.com'
                    register={register}
                    validation={{required: true}}
                    errors={errors}
                  />
                  <LineColored error={errors.email} />
                </div>
                <div>
                  <p className={`${isDark2 ? ' text-white' : 'text-black'}`}>Telefonnummer:</p>
                  <UIInput
                    customClassName={`
                     ${isDark2 ? 'bg-[#393939] text-white' : 'bg-[#F8F8F8] text-black'}
                   `}
                    name='phoneNumber'
                    type='tel'
                    placeholder='0660 1234567'
                    minLength={9}
                    maxLength={16}
                    register={register}
                    validation={{required: true}}
                    errors={errors}
                  />
                  <LineColored error={errors.phoneNumber} />
                </div>
                <div className='text-yellow-accent text-center font-bold mb-5 clear-both'>
                  {apiResultMessage}
                </div>
              </div>
            </div>
            <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
              <div
                className={`${isDark2 ? 'text-sm text-[#F0A249]' : 'text-orange-main text-sm '}`}
              >
                Adresse
              </div>
              <div
                className={`${isDark2 ? 'w-full h-px bg-[#F0A249]' : 'bg-orange-main w-full h-px'}`}
              ></div>
            </div>
            <div className='mx-2 mt-2'>
              <BFormAddressField
                control={control}
                placeholder={address?.label}
                fieldName='Address'
                key={Math.random()}
              />
            </div>
            <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
              <div
                className={`${isDark2 ? 'text-sm text-[#F0A249]' : 'text-orange-main text-sm '}`}
              >
                Beschreibung
              </div>
              <div
                className={`${isDark2 ? 'w-4/5 h-px bg-[#F0A249]' : 'bg-orange-main w-4/5 h-px'}`}
              ></div>
            </div>
            <IonTextarea
              rows={4}
              className={`ipnut mx-2 text-sm h-[100px]
          ${isDark2 ? ' text-white' : 'text-black'}`}
              {...register('description')}
              placeholder='230 V Mischmaschine mit 140 Liter Fassungsvermögen
          in sehr guten Zustand inkl 10m Anschlusskabel'
            />
          </div>
          <div className='flex flex-col mt-2 justify-end text-white'>
            <UIYellowButton type='submit' text='Weiter' />
          </div>
        </form>
      )}
    </>
  );
}

export default FormProduct;

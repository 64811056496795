import React from 'react';
import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar} from '@ionic/react';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UILogo from 'components/uiLogo/UILogo';
import SearchComponent from 'pages/common/search/SearchComponent';
import {useParams} from 'react-router';
import {isDarkTheme} from 'states/account/account.state';
import {useRecoilValue} from 'recoil';

export default function SearchPage() {
  const {itemsType, category}: any = useParams();
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <SearchComponent itemsType={itemsType} category={category == 0 ? '' : category} />
      </IonContent>
    </IonPage>
  );
}

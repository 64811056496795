import {CommentInterface} from 'interfaces/comments/comments.interface';
import React from 'react';
import {useRecoilValue} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';
import {dateHandler} from 'utils/dateHandler';

type Props = {
  comment: CommentInterface;
};

const CommentBox: React.FC<Props> = ({comment}) => {
  const date = dateHandler(comment.dateAdded, ' ', true);
  const isDark2 = useRecoilValue(isDarkTheme);

  return (
    <div
      className='mx-5 rounded-lg border mt-2 border-t-[5px]
    border-yellow-accent text-gray-500 pl-2 pb-2 mb-5'
    >
      <div className='flex justify-between'>
        <p className={`text-[11px]  mt-3 mr-1 ${isDark2 ? 'text-white' : 'text-black'}`}>{date}</p>
      </div>
      <p
        className={`text-[11px] mt-1 font-sans ml-1 mr-2
      ${isDark2 ? 'text-white' : 'text-black'}`}
      >
        {comment.comment}
      </p>
    </div>
  );
};

export default CommentBox;

/* eslint-disable space-before-function-paren */
import OneSignal from 'onesignal-cordova-plugin';
import {Storage} from '@capacitor/storage';
import {BehaviorSubject} from 'rxjs';
import {OpenedEvent} from 'onesignal-cordova-plugin/dist/models/NotificationOpened';
import {DeviceState} from 'onesignal-cordova-plugin/dist/Subscription';

const OPEN_NOTIFICATIONS_REDIRECT_KEY = 'OPEN_NOTIFICATIONS_REDIRECT_KEY';

type AdditionalData = {
  id: number;
  type: string;
  name?: string;
};

export const notificationRedirect = new BehaviorSubject('');

export function oneSignalInit(): void {
  OneSignal.setAppId('f847b12c-0746-4a48-9044-8c5e5a253a63');

  OneSignal.setNotificationOpenedHandler(function (jsonData: OpenedEvent) {
    const additionalData = jsonData.notification.additionalData as AdditionalData;
    const redirectUrl = getRedirectUrl(additionalData);
    if (redirectUrl == null) {
      return;
    }

    Storage.set({key: OPEN_NOTIFICATIONS_REDIRECT_KEY, value: redirectUrl});
    notificationRedirect.next(redirectUrl);
  });

  OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {});
}

const getRedirectUrl = ({id, type, name}: AdditionalData) => {
  if (id == null || type == null) return null;
  if (type === 'SERVICE') return `/service/${id}`;
  if (type === 'MESSAGE' && name != null) return `/message/${id}/${name}`;

  return `/job/${id}`;
};

export const getNotificationRedirect = async () => {
  const {value} = await Storage.get({key: OPEN_NOTIFICATIONS_REDIRECT_KEY});
  return value;
};

export const clearNotificationRedirect = () => {
  Storage.remove({key: OPEN_NOTIFICATIONS_REDIRECT_KEY});
};

export const setAdditonalOSData = (user: any) => {
  if (user !== null) {
    OneSignal.setExternalUserId(user.id);
    OneSignal.setEmail(user.email);
  }
};

export const getPlayerId = () => {
  return new Promise<string>((resolve) => {
    OneSignal.getDeviceState((state: DeviceState) => {
      if (state.userId !== undefined) {
        resolve(state.userId);
        return;
      }
    });
  });
};

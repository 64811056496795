import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar} from '@ionic/react';
import {instrumentsService} from 'api/instruments/instruments.service';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UIOrangeCategoryBox from 'components/uiContainer/UIOrangeCategoryBox';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import UILogo from 'components/uiLogo/UILogo';
import {getCategoryIcon} from 'utils/categoryIconProvider';
import {Subcategory} from 'interfaces/instruments/instrument.interface';
import React, {useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';
import UIButtonSearch from 'components/uiButtons/UIButtonSearch';
import UILoadingIndicator from 'components/uiLoading/UILoadingIndicator';

export const InstrumentsSubCatToolsListPage: React.FC = () => {
  const [subCategories, setCategories] = useState<Subcategory[]>([]);
  const isDark2 = useRecoilValue(isDarkTheme);
  const [isLoading, setIsLoading] = useState<boolean>();

  useEffect(() => {
    setIsLoading(true);
    instrumentsService.getCategories('loan').then((response) => {
      response.data.results.filter(
        (category: any) => category.id === 1 && setCategories(category.subcategories)
      );
      setIsLoading(false);
    });
  }, []);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
          <IonButtons slot='end'>
            <UIButtonSearch itemsType='instruments' category={'2'} />
          </IonButtons>
        </IonToolbar>
        <UITopTextBar>Werkzeug mieten</UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        {isLoading ? (
          <UILoadingIndicator />
        ) : (
          <div className='mb-10'>
            {subCategories.map((subCat, id) => (
              <UIOrangeCategoryBox
                key={subCat.id}
                name={subCat.name}
                src={getCategoryIcon(id + 1)}
                alt={subCat.name}
                subCatId={subCat.id}
                type='instruments'
                linkTo={`/instruments/subcategories/${subCat.id}/Werkzeug/${subCat.name}`}
              />
            ))}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

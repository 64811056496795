import {Browser} from '@capacitor/browser';
import {applyPromoCode, deleteCart, getCart, promote} from 'api/promotions/promotions.service';
import {userService} from 'api/users/users.service';
import UIYellowButton from 'components/uiButtons/UIYellowButton';
import {UIImage} from 'components/uiImages/UIImage';
import UILoadingIndicator from 'components/uiLoading/UILoadingIndicator';
import LineColored from 'forms/instruments/LineColored';
import {Instrument} from 'interfaces/instruments/instrument.interface';
import {Job} from 'interfaces/jobs/jobs.interface';
import {Product} from 'interfaces/products/product.interface';
import {Service} from 'interfaces/services/service.interface';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {refreshCounterState} from 'states/common/common.state';
import {promotionsState} from 'states/promotions/promotion.state';
import {getService} from '../promotionsCostsList/PromotionsCostsList';
import {isDarkTheme} from 'states/account/account.state';
import {BASE_URL_APP} from 'utils/constants';
import {authService} from 'api/account/auth.service';
import {isPlatform} from '@ionic/react';

type Props = {
  type: string;
};

const PromotionsCart: React.FC<Props> = ({type}) => {
  const [cartData, setCartData] = useState<any>();
  const [itemData, setItemData] = useState<Instrument | Service | Job | Product>();
  const [accountType, setAccountType] = useState<string>();
  const [promoCode, setPromoCode] = useState<string>();
  const [responseText, setReponseText] = useState<string>();
  const [premium, setPremium] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const setRefreshCounter = useSetRecoilState(refreshCounterState);
  const promotions = useRecoilValue(promotionsState);
  const service = getService(type);
  const router = useHistory();

  useEffect(() => {
    setLoading(true);
    userService.getUserData().then((res) => {
      setAccountType(res.data.accountType.toLowerCase());
      setPremium(res.data.isPremium);
    });

    getCart()
      .then((response) => {
        setCartData(response.data);
        service.getDetails(response.data[type][0].id).then((res) => {
          setItemData(res.data);
        });
      })
      .finally(() => setLoading(false));
  }, [responseText]);

  const getNameBasedOnType = (object: any) => {
    if (object.name) {
      return object.name;
    }
    return object.name;
  };

  const handleDeleteCart = () => {
    deleteCart(cartData.id).then(() => {
      setRefreshCounter((prev) => prev + 1);
      itemData ? router.push(`/purchase/${type}/${itemData.id}`) : router.push('/');
    });
  };

  const handleUsePromoCode = () => {
    promoCode &&
      applyPromoCode(promoCode)
        .then((res) => {
          setReponseText('Aktionscode angewendet!');
        })
        .catch(() => setReponseText('Ungültiger Promo-Code!'));
  };

  const handlePaymentClick = async () => {
    setLoading(true);
    try {
      await promote(cartData.id).then((res) => {
        setRefreshCounter((prev) => prev + 1);
        if (premium) {
          router.push(`/payment-result/${authService.tokenValue}/${res.id}/${cartData.id}`);
          return;
        }

        // eslint-disable-next-line max-len
        const path = `/payment/${res.id}/${authService.tokenValue}/${res.checkoutId}/${cartData.id}`;
        if (isPlatform('ios') || isPlatform('android')) {
          router.replace('/');
          Browser.open({url: BASE_URL_APP + path});
          return;
        }

        router.push(path);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const findPromotion = (promotionType: string, promotionId: number) => {
    let result = '-';
    accountType &&
      promotions[promotionType][accountType].filter((x: any) => {
        if (x.id === promotionId) result = x.duration;
      });
    return result;
  };

  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <div className='flex justify-center'>
      <div className='md:w-3/5'>
        {loading ? (
          <UILoadingIndicator />
        ) : cartData && itemData && cartData[type][0].id ? (
          <div
            className={`border-t-4 border
           border-yellow-accent mx-4 my-2 rounded-lg
          ${isDark2 ? 'text-white bg-[#393939]' : 'text-black bg-[#F8F8F8]'}`}
          >
            <p className='text-center font-bold'>Zusammenfassung</p>
            <div className='flex justify-start mb-2 pl-6'>
              {itemData.photos && itemData.photos.length > 0 && (
                <UIImage
                  customClassName='md:w-40 md:h-40 w-20 h-20 rounded-lg'
                  photoLink={itemData.photos[0].photo}
                  type={type}
                />
              )}
              <div
                className={`text-[17px] mt-7 ml-4
            ${isDark2 ? 'text-white' : 'text-black '}`}
              >
                <b>{getNameBasedOnType(itemData)}</b>
              </div>
            </div>
            <div className='mx-4 mt-2'>
              <LineColored color='yellow' colorIntensity='accent' />
            </div>
            <div className='mx-2 flex justify-between text-sm mt-2'>
              <p>Anzeige</p>
              <p className='font-bold'>
                {findPromotion('issuePromotions', cartData[type][0].issuedPromotedPackage)} Tage
              </p>
            </div>
            <div className='mx-2 flex justify-between text-sm mt-2'>
              <p>Hervorhebung</p>
              <p className='font-bold'>
                {findPromotion('listPromotions', cartData[type][0].promotedPackage)} Tage
              </p>
            </div>
            {type !== 'products' && (
              <div className='mx-2 flex justify-between text-sm mt-2'>
                <p>Logo auf der Karte</p>
                <p className='font-bold'>
                  {findPromotion('mapPromotions', cartData[type][0].mapPromotedPackage)} Tage
                </p>
              </div>
            )}
            {type === ('services' || 'jobs') && (
              <div className='mx-2 flex justify-between text-sm mt-2'>
                <p>Benachrichtigungen</p>
                <p className='font-bold'>
                  {findPromotion('pushPromotion', cartData[type][0].notificationPromotedPackage)}
                  Benutzer
                </p>
              </div>
            )}
            <div className='mx-4 mt-2'>
              <LineColored color='yellow' colorIntensity='accent' />
            </div>
            {accountType === 'company' && (
              <>
                <div className='mx-2 flex justify-between text-sm mt-2'>
                  <p>Nettopreis</p>
                  <p className='font-bold'>{cartData.netAmount}€</p>
                </div>
                <div className='mx-2 flex justify-between text-sm mt-2'>
                  <p>MwSt.-Wert</p>
                  <p className='font-bold'>{cartData.vatAmount}€</p>
                </div>
                <div className='mx-2 flex justify-between text-sm mt-2'>
                  <p>MwSt.-Prozentsatz</p>
                  <p className='font-bold'>{cartData.vatRate}%</p>
                </div>
              </>
            )}
            <div className='mx-2 flex justify-between text-sm mt-2'>
              {accountType === 'company' ? <p>Bruttopreis</p> : <p>Preis</p>}
              <p className='font-bold'>{cartData.grossAmount}€</p>
            </div>
            <div className='mx-4 mt-2'>
              <LineColored color='yellow' colorIntensity='accent' />
            </div>
            <p className='font-bold text-sm ml-5 mb-2'>Haben Sie Promo-Codes?</p>
            <div className='flex justify-center ml-5'>
              <input
                onChange={(v) => setPromoCode(v.target.value)}
                className={`rounded-lg p-1 border border-yellow-accent bg-transparent
              ${isDark2 ? 'text-white' : 'text-black '}`}
              />
              <UIYellowButton text='Anwenden' customClassName='px-3' onClick={handleUsePromoCode} />
            </div>
            {responseText && (
              <p
                className={`text-xs text-center mt-2 ${
                  responseText[0] == 'A' ? 'text-green-500' : 'text-red-500'
                }`}
              >
                {responseText}
              </p>
            )}
            <div className='mx-4 mt-5'>
              <LineColored color='yellow' colorIntensity='accent' />
            </div>
            <div className='flex mt-20 mb-5'>
              <div className='w-1/2'>
                <UIYellowButton text='Einkaufswagen löschen' onClick={handleDeleteCart} />
              </div>
              <div className='w-1/2 self-center'>
                <UIYellowButton text='Zahlen' onClick={handlePaymentClick} />
              </div>
            </div>
          </div>
        ) : (
          <div className={`text-center mt-3 mx-3 ${isDark2 ? 'text-white' : 'text-black '}`}>
            Anscheinend haben Sie diesen Artikel nicht in Ihrem Einkaufswagen.
          </div>
        )}
      </div>
    </div>
  );
};

export default PromotionsCart;

import React from 'react';
import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar} from '@ionic/react';
import UILogo from 'components/uiLogo/UILogo';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import UIButtonBack from 'components/uiButtons/UIButtonBack';

import {PromotionsCostsList} from 'pages/promotions/promotionsCostsList/PromotionsCostsList';
import {isDarkTheme} from 'states/account/account.state';
import {useRecoilValue} from 'recoil';

export const PurchasePage = () => {
  const isDark2 = useRecoilValue(isDarkTheme);
  const type = location.pathname.split('/')[2];
  let title;
  switch (type) {
    case 'instruments':
      title = 'Geräte Zum Vermieten';
      break;
    case 'jobs':
      title = 'Jobinserat erstellen';
      break;
    case 'products':
      title = 'Geräte zum Verkauf';
      break;
    case 'services':
      title = 'Biete Dienstleistung';
      break;
  }
  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar>{title}</UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <PromotionsCostsList prefix='Hinzufügen eines Angebots für' suffix='Tage' />
      </IonContent>
    </IonPage>
  );
};

import React from 'react';
import {Service} from 'interfaces/services/service.interface';
import {FavoritesOffersListItem} from './FavoritesOffersListItem';

export interface Props {
  fields: Service[];
  onOfferClick: (offerId: number) => void;
}

export const FavoritesServicesList: React.FC<Props> = ({fields, onOfferClick}) => {
  return (
    <div>
      {fields.map((field) => (
        <FavoritesOffersListItem field={field} onOfferClick={onOfferClick} key={field.id} />
      ))}
    </div>
  );
};

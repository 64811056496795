import {atom} from 'recoil';

export const selectedDatesState = atom<any[] | undefined>({
  key: 'selectedDatesState',
  default: undefined,
});

export const datesTypeState = atom<'instruments' | 'services'>({
  key: 'datesType',
  default: 'instruments',
});

export const lastAddedPostState = atom<string | undefined>({
  key: 'lastAddedPost',
  default: undefined,
});

export const newMessagesState = atom<boolean>({
  key: 'newMessagesState',
  default: false,
});

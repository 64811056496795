import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar} from '@ionic/react';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UILogo from 'components/uiLogo/UILogo';
import React from 'react';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import FormOffer from 'forms/services/service.form';
import {isDarkTheme} from 'states/account/account.state';
import {useRecoilValue} from 'recoil';
import {useParams} from 'react-router';

const ServiceAddPage: React.FC = () => {
  const {agricultureCategory}: any = useParams();
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar>Biete Dienstleistung</UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <div className='m-3'>
          <FormOffer agricultureCategory={agricultureCategory} />
        </div>
      </IonContent>
    </IonPage>
  );
};
export default ServiceAddPage;

import React, {useEffect, useState} from 'react';
import {IonIcon, useIonRouter} from '@ionic/react';
import {Instrument} from 'interfaces/instruments/instrument.interface';
import {Job} from 'interfaces/jobs/jobs.interface';
import {Product} from 'interfaces/products/product.interface';
import {Service} from 'interfaces/services/service.interface';
import {UIImage} from 'components/uiImages/UIImage';
import IconLocalization from 'assets/icons/localization_icon.svg';
import euroIcon from 'assets/icons/euro_icon.svg';

type Props = {
  item: any;
  product?: Product;
  instrument?: Instrument;
  job?: Job;
  service?: Service;
};

const ActivePromotionItem: React.FC<Props> = ({item, product, instrument, job, service}) => {
  const [itemName, setItemName] = useState<string>();
  const [itemPrice, setItemPrice] = useState<string | number>();
  const [itemImage, setItemImg] = useState<string>();
  const [itemInfo, setItemInfo] = useState<string>();

  const router = useIonRouter();

  useEffect(() => {
    fetchImg();
    fetchName();
    fetchPrice();
    fetchAdditionalInfo();
  }, []);

  const clickRouter = () => {
    if (item.category?.id === 1) {
      return router.push(`/product/${item.category.name}/${item.subcategory.name}/${item.id}`);
    }
    if (item.category?.id === 2) {
      return router.push(`/instrument/${item.category.name}/${item.subcategory.name}/${item.id}`);
    }
    if (item.jobOffer) return router.push(`/job/${item.id}`);
    if (item.industry) return router.push(`/service/${item.id}`);
  };

  const fetchImg = () => {
    if (product != undefined) return setItemImg('products');
    if (instrument != undefined) return setItemImg('instruments');
    if (job != undefined) return setItemImg('jobs');
    if (service != undefined) return setItemImg('services');
  };

  const fetchName = () => {
    if (product != undefined) return setItemName(product.productName);
    if (instrument != undefined) return setItemName(instrument.name);
    if (job != undefined) return setItemName(job.jobOffer);
    if (service != undefined) return setItemName(service.name);
  };

  const fetchPrice = () => {
    if (product != undefined) return setItemPrice(product.price);
    if (instrument != undefined) return setItemPrice(instrument.pricePerDay + ' / pro Tag');
    if (job != undefined) return setItemPrice(job.salary);
    if (service != undefined) return setItemPrice(service.pricePerHour + ' / h');
  };

  const fetchAdditionalInfo = () => {
    if (product != undefined) return setItemInfo('Verkauf');
    if (instrument != undefined) return setItemInfo('Mietgerät');
    if (job != undefined && job.jobType === 'FULL_TIME') return setItemInfo('Vollzeit');
    if (job != undefined && job.jobType === 'PART_TIME') return setItemInfo('Teilzeit');
    if (job != undefined && job.jobType === 'FLEXIBLE') return setItemInfo('Gleitzeit');
    if (service != undefined) return setItemInfo('');
  };

  return (
    <div
      key={Math.random()}
      onClick={clickRouter}
      className='w-[90%] md:w-2/3 mx-auto max-w-screen-md
      rounded-lg border border-orange-main'
    >
      <div
        className='w-full h-8 px-2 flex justify-between items-center bg-orange bg-orange-main
        rounded-t-lg text-xs text-white font-semibold'
      >
        <div>{itemInfo}</div>
        <div className='absolute'>
          {item.industry && (
            <>
              Mo-Fr: {item.mondayToFriday} Sa-So: {item.saturdayToSunday}
            </>
          )}
        </div>
        {/* Active Until: {item.promotedUntil.slice(0, 10)} */}
        Active Until: {new Date(item.promotedUntil).toLocaleDateString()}
      </div>
      <div className='py-2 flex items-center md:space-x-10'>
        <div className='w-1/3 ml-2.5 md:ml-8 md:flex md:justify-center'>
          <UIImage
            photoLink={item.photos[0].photo}
            type={itemImage}
            customClassName='w-20 h-20 rounded-md'
          />
        </div>
        <div className='w-2/3 flex flex-col lg:mr-3'>
          <div className='text-gray-600 text-md font-bold'>{itemName}</div>
          <div>{item.position}</div>
          <div>{item.industry}</div>
          <div className='mb-1 truncate ...'>{item.description}</div>
          <div className='flex text-gray-400 text-xs space-x-0.5'>
            <IonIcon src={IconLocalization} className='mt-0.5' />
            <div>{item.location}</div>
          </div>
          <div className='flex text-gray-400 text-xs space-x-0.5'>
            <IonIcon src={euroIcon} className='mt-0.5' />
            <div className='flex'>{itemPrice}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivePromotionItem;

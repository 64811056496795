import React, {useEffect, useState} from 'react';

import {useRecoilValue} from 'recoil';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonToolbar,
  useIonToast,
  IonToggle,
  IonButtons,
} from '@ionic/react';
import UILogo from 'components/uiLogo/UILogo';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import {UIImage} from 'components/uiImages/UIImage';
import {refreshCounterState} from 'states/common/common.state';
import {accountService} from 'api/account/account.service';
import {User} from 'interfaces/users/user.interface';
import UILoadingIndicator from 'components/uiLoading/UILoadingIndicator';
import UIResponsivePadding from 'components/uiResponsive/UIResponsivePadding';
import UIOrangeSquareBox from 'components/uiContainer/UIOrangeSquareBox';
import {useDarkTheme} from 'states/account/account.state';
import UIButtonBack from 'components/uiButtons/UIButtonBack';

import baggerIcon from 'assets/icons/bagger_icon.svg';
import settingsIcon from 'assets/icons/setting_profile_icon.svg';
import noteIcon from 'assets/icons/note_icon.svg';
import workerIcon from 'assets/icons/worker_icon.svg';
import avatarPlaceholder2 from 'assets/avatar_placeholder2.png';
import avatarPlaceholder3 from 'assets/avatar_placeholder3.png';

const AccountDatail: React.FC = () => {
  const [user, setUser] = useState<User>();
  const [isLoading, setIsLoading] = useState(true);
  const refreshCounter = useRecoilValue(refreshCounterState);
  const [present] = useIonToast();

  useEffect(() => {
    accountService.getCurrentUser().then((response) => {
      setUser(response.data);
      setIsLoading(false);
    });
  }, [refreshCounter]);

  const {isDark2, toggleDarkTheme} = useDarkTheme();

  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo
            customClassName={`flex justify-center w-ful  ${
              isDark2 ? 'bg-[#474747]' : 'bg-[#F8F8F8]'
            }`}
          />
          <IonButtons slot='end'>
            <IonToggle checked={isDark2} onClick={toggleDarkTheme} />
          </IonButtons>
        </IonToolbar>
        <UITopTextBar>Profil</UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <UIResponsivePadding>
          {isLoading ? (
            <UILoadingIndicator />
          ) : (
            <div
              className={`min-h-full h-2/5 flex flex-col
            ${isDark2 ? 'bg-[#474747]' : 'bg-[#F8F8F8]'}`}
            >
              <div className={`flex justify-center ${isDark2 ? 'bg-[#474747]' : 'bg-[#F8F8F8]'}`}>
                <div className='flex-col text-center'>
                  {user && user.profilePicture ? (
                    <UIImage photoLink={user.profilePicture} type='profilePicture' />
                  ) : (
                    <img
                      alt='Avatar'
                      width={180}
                      className='mt-1'
                      src={isDark2 ? avatarPlaceholder3 : avatarPlaceholder2}
                    />
                  )}
                  <b className={`${isDark2 ? ' text-white' : ' text-gray-500'}`}>
                    {user ? user.firstName : 'Your Name'}
                  </b>
                </div>
              </div>
              <IonGrid fixed>
                <IonRow>
                  <IonCol>
                    <UIOrangeSquareBox
                      name='EINSTELLUNGEN'
                      src={settingsIcon}
                      alt={'settings'}
                      linkTo={'/profile-edit'}
                    />
                    <UIOrangeSquareBox
                      name='MEINE ANGEBOTE'
                      src={workerIcon}
                      alt={'settings'}
                      disabled={user?.accountType === 'COMPANY' ? false : true}
                      linkTo={user?.accountType === 'COMPANY' && 'offers-my'}
                      onClick={() =>
                        user?.accountType !== 'COMPANY' &&
                        present(
                          'um eine Anzeige erstellen zu können müssen Sie über ein Firmenkonto' +
                            ' verfügen dieses können Sie im Profil unter „Einstellungen“ auswählen',
                          3000
                        )
                      }
                    />
                  </IonCol>
                  <IonCol>
                    <UIOrangeSquareBox
                      name='MEINE VERLEIHGERÄTE'
                      src={baggerIcon}
                      alt={'settings'}
                      linkTo={'/instruments-my/preview'}
                    />
                    <UIOrangeSquareBox
                      name='MEINE WERBUNG'
                      src={noteIcon}
                      alt={'settings'}
                      linkTo={'/promotions'}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          )}
        </UIResponsivePadding>
      </IonContent>
    </IonPage>
  );
};

export default AccountDatail;

import {BFormFieldType} from 'forms/common/bForm/BFormField';
import {isMailRegex, isMinSixCharsRegex} from '../../../utils/regexCombinations';

export const formRegister: BFormFieldType[] = [
  {
    fieldName: 'name',
    fieldType: 'text',
    placeHolder: '*Name',
    validation: {
      required: true,
    },
  },
  {
    fieldName: 'email',
    fieldType: 'email',
    placeHolder: '*E-Mail',
    validation: {
      required: true,
      pattern: {
        value: isMailRegex,
        message: 'Ungültige E-Mail',
      },
    },
  },
  {
    fieldName: 'password',
    fieldType: 'password',
    placeHolder: '*Passwort',
    validation: {
      required: true,
      pattern: {
        value: isMinSixCharsRegex,
        message: 'Das Passwort muss mindestens 6 Zeichen lang sein',
      },
    },
  },
];

import React, {useEffect, useState} from 'react';
import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar} from '@ionic/react';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import UILogo from 'components/uiLogo/UILogo';
import {useParams} from 'react-router';
import LoadingIndicator from 'components/uiLoading/UILoadingIndicator';
import {diysService} from 'api/diy/diy.service';
import {DIYInterface} from 'interfaces/diy/diy.interface';
import CommentBox from 'components/uiComments/CommentBox';
import CreateComment from 'components/uiComments/CreateComment';
import {BASE_URL_API} from 'utils/constants';
import {isDarkTheme} from 'states/account/account.state';
import {useRecoilValue} from 'recoil';

const DIYDetailPage = () => {
  const [data, setData] = useState<DIYInterface>();
  const [count, setCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {id: id} = useParams<{id: string}>();

  useEffect(() => {
    setIsLoading(true);
    diysService
      .getDIY(id)
      .then((res) => {
        setData(res.data);
      })
      .finally(() => setIsLoading(false));
  }, [count]);
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar>{data && data.title}</UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <div
          className={`border border-gray-300 shadow-lg
          mt-1 mx-1.5 rounded-md text-gray-600 px-2 border-b-yellow-accent
          ${isDark2 ? 'bg-[#393939] ' : 'bg-gray-100 '}`}
        >
          <img
            src={`${BASE_URL_API}media/diys/${data?.image}`}
            className='w-full h-[200px] my-2 rounded-md'
          />
          <p className={`font-sans font-bold ${isDark2 ? ' text-white' : 'text-black'}`}>
            {data?.title}
          </p>
          <p
            className={`text-sm font-sans font-semibold mb-3
          ${isDark2 ? ' text-white' : 'text-black'}`}
          >
            {data?.description}
          </p>
        </div>
        <div className='mx-3 mt-3'>
          <CreateComment count={count} setCount={setCount} id={id} type='diy' />
        </div>
        <p className={`font-bold text-sm pl-5 ${isDark2 ? ' text-white' : 'text-gray-700'}`}>
          Bemerkungen
        </p>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          data && data.comments.map((comment) => <CommentBox key={comment.id} comment={comment} />)
        )}
      </IonContent>
    </IonPage>
  );
};

export default DIYDetailPage;

import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {IonIcon, isPlatform} from '@ionic/react';
import {flag, homeOutline, starOutline, mailOutline, callOutline} from 'ionicons/icons';
import {useRecoilValue} from 'recoil';
import {accountState, isDarkTheme} from 'states/account/account.state';
import FavouritesButton from 'pages/common/favourites/FavouritesButton';
import {UIClickImage} from 'components/uiImages/UIClickImage';
import {Link} from 'react-router-dom';
import UIButtonShare from 'components/uiButtons/UIButtonShare';
import {useIonRouter} from '@ionic/react';
import BMiniMap from 'forms/common/places/BMiniMap';
import {Instrument} from 'interfaces/instruments/instrument.interface';
import {productsService} from 'api/products/products.service';
import euroIcon from 'assets/icons/euro_icon.svg';

interface Props {
  fields: Instrument;
  isDefaultFavorite: boolean;
  setIsPopUpVisible: Dispatch<SetStateAction<boolean>>;
  onClick?: any;
}

export const DetailsProducts: React.FC<Props> = ({
  fields,
  isDefaultFavorite,
  setIsPopUpVisible,
  onClick,
}) => {
  const router = useIonRouter();
  const accountData = useRecoilValue<any>(accountState);
  const [isFavorite, setIsFavorite] = useState(isDefaultFavorite);

  useEffect(() => setIsFavorite(isDefaultFavorite), [isDefaultFavorite]);

  const favHandler = async (id: number) => {
    await productsService.addFavoriteProducts(id);
  };
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <div
      className={`rounded-lg border border-yellow-accent mx-4 mt-3
    drop-shadow-md ${isDark2 ? 'bg-[#393939]' : 'bg-yellow-slight'}`}
    >
      <UIClickImage
        customClassName='w-full h-52 rounded-3xl'
        type='instruments'
        photoLink={fields.photos.length > 0 && fields.photos[0].photo}
        setIsPopUpVisible={setIsPopUpVisible}
      />
      <div className={`absolute ${isPlatform('ios') ? ' w-[94%] ' : ' w-full '} top-40`}>
        <div
          className={`rounded-t-3xl pl-4 border-t-2 border-orange-main
        ${isDark2 ? 'bg-[#393939]' : 'bg-white'}`}
        >
          <div className='flex justify-between'>
            <div>
              <p
                className={`text-lg font-bold mt-2 pt-5
              ${isDark2 ? ' text-white' : 'text-gray-600 '}`}
              >
                {fields.productName}
              </p>
              <p
                className={`text-sm font-thin
              ${isDark2 ? ' text-white' : 'text-black '}`}
              >
                {fields.productionYear}
              </p>
              <p
                className={`text-sm font-thin
              ${isDark2 ? ' text-white' : 'text-black '}`}
              >
                {fields.owner.firstName + ' ' + fields.owner.lastName}
              </p>
              <p
                className={`text-sm font-thin
              ${isDark2 ? ' text-white' : 'text-black '}`}
              >
                {fields.purpose}
              </p>
            </div>

            <div className=''>
              <div className='pt-9 text-black space-x-2 flex items-center mr-5'>
                <Link to={`/report/instrument/${fields.id}`}>
                  <IonIcon
                    icon={flag}
                    className={`mt-2 h-6 w-6
                    ${isDark2 ? 'text-[#F0A249]' : 'text-yellow-accent'}`}
                  />
                </Link>
                {fields && accountData && accountData.id !== fields!.owner!.id ? (
                  <FavouritesButton
                    favHandler={favHandler}
                    fields={fields}
                    isFavorite={isFavorite}
                    setIsFavorite={setIsFavorite}
                  />
                ) : (
                  <IonIcon
                    icon={starOutline}
                    className='mt-1 text-yellow-400 h-6 w-6'
                    onClick={() => {
                      router.push('/sign-in');
                    }}
                  />
                )}
                <UIButtonShare />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex justify-end mr-4 absolute top-36 right-0'>
        <div className='flex flex-row space-x-4'>
          <div
            className='bg-yellow-accent text-white pr-2
             rounded-3xl flex p-1'
          >
            <IonIcon icon={euroIcon} className='mt-1 ml-3 text-white' />
            <p className='mr-2 text-sm mt-1 text-white'>{fields.price}</p>
          </div>
          <button className='bg-orange-rate rounded-3xl w-8 text-white text-xs' onClick={onClick}>
            {fields.owner.userAvg ? fields.owner.userAvg : '-'}
          </button>
        </div>
      </div>
      <div>
        <div
          className={`mb-3 font-sans text-gray-500 pt-14 rounded-b-2xl
            ${isDark2 ? 'bg-[#393939] ]' : 'bg-[#F8F8F8]'}`}
        >
          <p
            className={`float-left text-sm mt-3 mr-1.5 ml-3
          ${isDark2 ? 'text-[#F0A249]' : 'text-yellow-accent'}`}
          >
            Kontakt
          </p>
          <hr className={` mr-3 mb-4 mt-7 ${isDark2 ? 'text-[#F0A249]' : 'text-yellow-accent'}`} />
          <div className={`flex-col ml-4 mr-8 ${isDark2 ? 'text-white' : 'text-gray-500'}`}>
            {fields.isVisiblePhoneNumber && (
              <div className='flex'>
                <IonIcon icon={callOutline} className='mt-0.5' />
                <p className='text-sm ml-2 '>{fields.phoneNumber}</p>
              </div>
            )}
            <div className='flex mt-3'>
              <IonIcon icon={homeOutline} className='h-5 w-5' />
              <p className='text-sm ml-2'>{fields.location}</p>
            </div>
            {fields.isVisibleEmail && (
              <div className='flex mt-3'>
                <IonIcon icon={mailOutline} className='h-5 w-5' />
                <p className='text-sm ml-2'>{fields.email}</p>
              </div>
            )}
          </div>
          <div className='mx-3'>
            <BMiniMap geolocation={{lat: +fields.lat, lng: +fields.lng}} />
          </div>
          <p
            className={`'float-left text-sm mt-3 mr-1.5 ml-3
          ${isDark2 ? 'text-[#F0A249]' : 'text-yellow-accent'}`}
          >
            Beschreibung
          </p>
          <hr
            className={`mr-3 mt-7 mb-4 
          ${isDark2 ? 'bg-[#F0A249]' : 'bg-yellow-accent'}`}
          />
          <div
            className={`text-sm ml-4 pb-5
          ${isDark2 ? 'text-white' : 'text-gray-400'}`}
          >
            {fields.description}
          </div>
        </div>
      </div>
    </div>
  );
};

import client from 'api/common/client';
import {InstrumentRequest} from 'interfaces/instruments/instrument.interface';

import {createFormData} from 'utils/formDataHelper';

const getInstruments = async (subCatId?: number, catId?: number) => {
  if (subCatId) {
    const response = await client.get(`/instruments/?subcategory=${subCatId}`);
    return response;
  }
  const response = await client.get(`/instruments/?category=${catId}`);
  return response;
};

const getSortedInstruments = async (orderBy: string, subCatId?: number, catId?: number) => {
  if (subCatId) {
    const response = await client.get(`/instruments/?subcategory=${subCatId}&ordering=${orderBy}`);
    return response;
  }
  const response = await client.get(`/instruments/?category=${catId}&ordering=${orderBy}`);
  return response;
};

const getInstrumentsCount = async () => {
  const response = await client.get('/common/count_entities');
  return response;
};

const getAll = async () => {
  const response = await client.get('/instruments/');
  return response;
};

const getCategories = (option?: string) => {
  if (option === 'buy') return client.get('products/categories/');
  return client.get('instruments/categories/');
};

const getSubcategories = (option: string | number) => {
  if (option == 'loan') return client.get(`instruments/subcategories/`);
  return client.get(`products/subcategories/`);
};

const search = async (q: string, category: string) => {
  const response = await client.get(`/instruments?search=${q}&category=${category}`);
  return response.data;
};

const getDetails = async (instrumentId: number) => {
  const response = await client.get(`/instruments/${instrumentId}/`);
  return response;
};

const addInstrument = async (data: InstrumentRequest) => {
  const formData = createFormData(data);
  const response = await client.post('/instruments/', formData);
  return response;
};

const updateInstrument = async (instrument: any, instrumentId: any) => {
  const formData = createFormData(instrument);
  const response = client.patch(`instruments/${instrumentId}/`, formData);
  return response;
};

const rateInstrument = async (rate: object, instrumentId: number) => {
  const formData = createFormData(rate);
  const response = await client.post(`/instruments/${instrumentId}/rate/`, formData);
  return response;
};

const commentInstrument = async (comment: object, instrumentId: number) => {
  const formData = createFormData(comment);
  const response = await client.post(`/instruments/${instrumentId}/comments/`, formData);
  return response;
};

const updatePhotoInstrument = async (data: any, instrumentId: number) => {
  const fd = new FormData();
  fd.append('photos', data);

  return client.patch(`/instruments/${instrumentId}/`, fd);
};

const deleteInstrument = async (id: number) => {
  const response = await client.delete(`/instruments/${id}/`, {
    data: {id},
  });
  return response;
};

const addFavoriteInstrument = async (id: number) => {
  const response = await client.put(`/instruments/favourites/${id}/`);
  return response;
};

const getAvailablePromotions = async (id: number) => {
  const response = await client.get(`/payments/promotions/?instrument=${id}`);
  return response;
};

const initializeChat = async (instrumentId: string) => {
  const response = await client.post(`/instruments/${instrumentId}/chat/`);
  return response;
};

export const instrumentsService = {
  getInstruments,
  getAll,
  getDetails,
  getCategories,
  getSubcategories,
  addInstrument,
  search,
  getSortedInstruments,
  addFavoriteInstrument,
  getAvailablePromotions,
  deleteInstrument,
  updateInstrument,
  rateInstrument,
  commentInstrument,
  updatePhotoInstrument,
  initializeChat,
  getInstrumentsCount,
};

import React, {useEffect, useState} from 'react';
import {IonIcon, IonTextarea, IonSelect, IonSelectOption, useIonRouter} from '@ionic/react';
import {useForm} from 'react-hook-form';
import {instrumentsService} from 'api/instruments/instruments.service';
import {BFormAddressField} from 'forms/common/places/BFormAddressField';
import UIInput from 'components/uiInputs/UIInput';
import {
  InstrumentRequest,
  DefaultInstrumentRequestValues,
} from 'interfaces/instruments/instrument.interface';
import LineColored from '../instruments/LineColored';
import UIYellowButton from 'components/uiButtons/UIYellowButton';
import AddPhotos from 'components/uiAddPhoto/UIAddPhotos';
import {productsService} from 'api/products/products.service';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {refreshCounterState} from 'states/common/common.state';
import {eyeOutline, star} from 'ionicons/icons';
import {isDarkTheme} from 'states/account/account.state';
import euroIcon from 'assets/icons/euro_icon.svg';

function FormEditProduct({productId}: any) {
  const setRefreshCount = useSetRecoilState(refreshCounterState);
  const [categories, setCategories] = useState<any[]>([]);
  const [subcategories, setSubcategories] = useState<any[]>([]);
  const [subcatId, setSubcatId] = useState<number>();
  const [catId, setCatId] = useState<number>();
  const [catValue, setCatValue] = useState<string>('Kategorie');
  const [subCatValue, setSubCatValue] = useState<string>('Unterkategorie');
  const [catDefaultId, setCatDefaultId] = useState<number>();
  const [subCatDefaultId, setSubCatDefaultId] = useState<number>();
  const [addressPlaceholder, setAddressPlaceholder] = useState<string>('Wähle einen Adresse');
  const [oldPhotos, setOldPhotos] = useState<{photo: string}[]>();
  const [product, setProduct] = useState<InstrumentRequest>(DefaultInstrumentRequestValues);
  const router = useIonRouter();

  const {
    handleSubmit,
    register,
    setValue,
    formState: {errors},
    control,
  } = useForm();
  const [apiResultMessage, setApiResultMessage] = useState('');

  useEffect(() => {
    productsService.getDetails(productId).then((response) => {
      setProduct(response.data);
      setCatDefaultId(response.data.category.id);
      setSubCatDefaultId(response.data.subcategory.id);
      setCatValue(response.data.category.name);
      setSubCatValue(response.data.subcategory.name);

      setValue('weekHours', response.data.weekHours);
      setValue('weekendHours', response.data.weekendHours);
      setValue('price', response.data.price);
      setValue('category', response.data.category.id);
      setValue('subcategory', response.data.subcategory.id);
      setValue('instrumentName', response.data.instrumentName);
      setValue('name', response.data.name);
      setValue('purpose', response.data.name);
      setValue('productName', response.data.productName);
      setValue('email', response.data.email);
      setValue('productionYear', response.data.productionYear);
      setValue('phoneNumber', response.data.phoneNumber);
      setValue('description', response.data.description);
      setValue('lat', response.data.lat.toString());
      setValue('lng', response.data.lng.toString());
      setValue('postCode', response.data.postCode);
      setValue('Address', response.data.Address);
      setValue('location', response.data.location);
      setValue('availability', response.data.availability);

      setOldPhotos(response.data.photos);
      setAddressPlaceholder(response.data.location);
    });
  }, [productId]);

  useEffect(() => {
    instrumentsService.getCategories('buy').then((response) => {
      setCategories(response.data.results);
    });
  }, []);

  useEffect(() => {
    instrumentsService.getCategories('buy').then((response) => {
      response.data.results.filter(
        (category: any) => category.id === catId && setSubcategories(category.subcategories)
      );
    });
  }, [catId]);

  const onSubmit = (data: InstrumentRequest) => {
    if (oldPhotos) {
      data.not_delete_photos = [];
      oldPhotos.forEach((photo) => {
        data.not_delete_photos.push(photo.photo.substring(33, photo.photo.length));
      });
    }

    data.category = catId ? catId : catDefaultId;
    data.subcategory = subcatId ? subcatId : subCatDefaultId;
    data.productionYear = +data!.productionYear!;

    data.photos && (data.photos = data.photos[0]);
    if (typeof data.photos === 'undefined') delete data.photos;

    if (data.Address) {
      data.location = data.Address.label;
      data.postCode = data.Address.postCode;
      data.lat = data.Address.lat.toFixed(7);
      data.lng = data.Address.lng.toFixed(7);
      setAddressPlaceholder(data.location!);
    }

    productsService
      .updateProduct(data, productId)
      .then((res) => {
        setApiResultMessage('Erfolg!');
        setRefreshCount((v) => v + 1);
        if (!product.issuedPromoted) {
          router.push(`/purchase/products/${product.id}`);
          setApiResultMessage('');
        }
      })
      .catch((error) => {
        setApiResultMessage('Error! ' + error.response.data.message[0]);
      });
  };
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <form className='h-full pb-3 ' onSubmit={handleSubmit(onSubmit)}>
      <div
        className={`border-2 border-orange-main text-black rounded-md
      ${isDark2 ? 'bg-[#393939]' : ' bg-[#F8F8F8] '}`}
      >
        <div className={`h-6 ${isDark2 ? 'bg-[#F0A249]' : 'bg-yellow-accent'}`}></div>
        <div className='flex my-4'>
          <div className='flex-col mx-2 w-2/5 space-y-2 text-sm'>
            <div
              className='flex-col w-2/5 space-y-2 text-sm mb-16'
              onClick={() => router.push(`/photos/${productId}/products`)}
            >
              <AddPhotos
                photosType={'products'}
                photos={oldPhotos}
                setPhotos={setOldPhotos}
                control={control}
                name='photos'
              />
            </div>
            <div>
              <p className={`${isDark2 ? ' text-white' : 'text-black'}`}>Preis:</p>
              <div className='flex'>
                <UIInput
                  customClassName={`w-full bg-white text-sm ${
                    isDark2 ? ' bg-[#393939]  text-white ' : ' bg-[#F8F8F8] text-black '
                  }`}
                  type='number'
                  placeholder='80'
                  name='price'
                  register={register}
                  validation={{required: true}}
                  errors={errors}
                />
                <IonIcon
                  icon={euroIcon}
                  className={`mt-0.5 ${isDark2 ? ' text-white ' : 'text-gray-500 '}`}
                />
              </div>
              <LineColored error={errors.price} />
            </div>
            <div>
              <p className={`${isDark2 ? 'text-white' : 'text-black'}`}>Baujahr: </p>
              <UIInput
                customClassName={`${
                  isDark2 ? 'bg-[#393939] text-white ' : 'text-black bg-[#F8F8F8] '
                }`}
                name='productionYear'
                type='number'
                placeholder='Jahr'
                register={register}
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.productionYear} />
            </div>
          </div>
          <div className='flex-col space-y-2 mr-[15px] w-3/5 text-sm'>
            <div className='space-y-2 mb-2'>
              <div
                className='w-44 h-6 p-4 flex items-center justify-between
              rounded-xl border-orange-main border text-orange-main'
              >
                <IonSelect
                  className={`${isDark2 ? 'text-white ' : 'text-black '}`}
                  okText='Weiter'
                  cancelText='Abbrechen'
                  placeholder={catValue}
                  onIonChange={(cat) => setCatId(cat.detail.value)}
                  value={catId}
                >
                  {categories.map((cat) => (
                    <IonSelectOption key={cat.id} value={cat.id}>
                      {cat.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </div>
              <div
                className='w-44 h-6 p-4 flex items-center justify-between
              rounded-xl border-orange-main border text-orange-main'
              >
                <IonSelect
                  okText='Weiter'
                  cancelText='Abbrechen'
                  className={`${isDark2 ? 'text-white ' : 'text-black '}`}
                  placeholder={subCatValue}
                  onIonChange={(cat) => setSubcatId(cat.detail.value)}
                  value={subcatId}
                >
                  {subcategories.map((subcat) => (
                    <IonSelectOption key={subcat.id} value={subcat.id}>
                      {subcat.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </div>
            </div>
            <div>
              <p className={`${isDark2 ? 'text-white ' : 'text-black '}`}>Name:</p>
              <UIInput
                customClassName={`${
                  isDark2 ? 'bg-[#393939] text-white ' : 'text-black bg-[#F8F8F8] '
                }`}
                name='productName'
                placeholder='Mischmaschine'
                required={'required'}
                register={register}
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.instrumentName} />
            </div>
            <div>
              <p className={`${isDark2 ? 'text-white ' : 'text-black '}`}>Geräte Bezeichnung:</p>
              <UIInput
                customClassName={`${
                  isDark2 ? 'bg-[#393939] text-white ' : 'text-black bg-[#F8F8F8] '
                }`}
                name='purpose'
                placeholder='Zweck'
                required={'required'}
                register={register}
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.purpose} />
            </div>
            <div>
              <p className={`${isDark2 ? 'text-white ' : 'text-black '}`}>Kontakt:</p>
              <UIInput
                customClassName={`${
                  isDark2 ? 'bg-[#393939] text-white ' : 'bg-[#F8F8F8] text-black '
                }`}
                required='required'
                placeholder='beispiel@email.com'
                register={register}
                name='email'
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.email} />
            </div>
            <div>
              <p className={`${isDark2 ? 'text-white ' : 'text-black '}`}>Telefonnummer:</p>
              <UIInput
                customClassName={`${
                  isDark2 ? 'bg-[#393939] text-white ' : 'text-black bg-[#F8F8F8] '
                }`}
                type='tel'
                placeholder='0660 1234567'
                register={register}
                name='phoneNumber'
                validation={{required: true}}
                errors={errors}
                maxLength={16}
                minLength={9}
              />
              <LineColored error={errors.phoneNumber} />
            </div>
            <div className='text-yellow-accent text-center font-bold mb-5 clear-both'>
              {apiResultMessage}
            </div>
          </div>
        </div>
        <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
          <div className='text-orange-main text-sm'>Adresse</div>
          <div className='bg-orange-main w-full h-px'></div>
        </div>
        <div className='mx-2 mt-2'>
          <BFormAddressField
            control={control}
            fieldName='Address'
            key={Math.random()}
            placeholder={addressPlaceholder}
            latLng={{lat: +product.lat, lng: +product.lng}}
          />
        </div>
        <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
          <div className='text-orange-main text-sm'>Beschreibung</div>
          <div className='bg-orange-main w-4/5 h-px'></div>
        </div>
        <IonTextarea
          rows={4}
          className={`ipnut mx-2 text-sm h-[100px] ${isDark2 ? 'text-white ' : 'text-black '}`}
          {...register('description')}
          placeholder='230 V Mischmaschine mit 140 Liter Fassungsvermögen
          in sehr guten Zustand inkl 10m Anschlusskabel'
          value={product?.description}
          name='description'
        />
        <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
          <div className='text-orange-main text-sm'>Datum und Statistik</div>
          <div className='bg-orange-main w-[1400px] h-px'></div>
        </div>
        <div
          className={`flex text-sm place-content-center
            ${isDark2 ? 'text-gray-300 ' : 'text-black '}`}
        >
          <div className='ml-1'>
            <div className='flex justify-between ml-2'>
              <p className={`${isDark2 ? 'text-gray-300 pr-2' : 'text-black pr-2'}`}>
                Anzeige verfügbar bis:{' '}
                {product.issuedPromoted && product.issuedPromotedUntil
                  ? new Date(product.issuedPromotedUntil).toLocaleDateString()
                  : '-'}
              </p>
            </div>
            <div className='flex ml-2'>
              <p className={`${isDark2 ? 'text-gray-300 pr-2' : 'text-black pr-2'}`}>
                Anzeige Hervorhebung verfügbar bis:{' '}
                {product.promoted && product.promotedUntil
                  ? new Date(product.promotedUntil).toLocaleDateString()
                  : '-'}
              </p>
            </div>
            <div className='flex ml-2'>
              <p className={`${isDark2 ? 'text-gray-300 pr-2' : 'text-black pr-2'}`}>
                Anzeige Logo verfügbar bis:{' '}
                {product.mapPromoted && product.mapPromotedUntil
                  ? new Date(product.mapPromotedUntil).toLocaleDateString()
                  : '-'}
              </p>
            </div>
          </div>
          <div className='flex justify-center mt-2'>
            <IonIcon
              icon={star}
              className={`h-4 w-5 
                  ${isDark2 ? 'text-[#F0A249]' : 'text-yellow-400 '}`}
            />
            <p className='pr-5 text-md'>{product.countFavourite ? product.countFavourite : 0}</p>
            <IonIcon icon={eyeOutline} className='h-5 w-5 pr-1' />
            <p className='pr-7'>{product.visitors}</p>
          </div>
        </div>
      </div>
      {apiResultMessage !== 'Erfolg!' && (
        <div className='flex flex-col py-5 justify-end text-white'>
          <UIYellowButton type='submit' text='Weiter' />
        </div>
      )}
    </form>
  );
}

export default FormEditProduct;

import React, {useState, useEffect} from 'react';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonToolbar,
} from '@ionic/react';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import UILogo from 'components/uiLogo/UILogo';
import {userService} from 'api/users/users.service';
import {User, UserDataDefaultValues} from 'interfaces/users/user.interface';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {accountState, isDarkTheme} from 'states/account/account.state';
import {refreshCounterState} from 'states/common/common.state';

const AccountTypeEdit = () => {
  const [user, setUser] = useState<User>(UserDataDefaultValues);
  const setAccountState = useSetRecoilState(accountState);
  const setRefreshCounter = useSetRecoilState(refreshCounterState);
  const [selected, setSelected] = useState<string>();

  useEffect(() => {
    userService.getUserData().then((res) => {
      setUser(res.data);
      if (res.data.accountType === 'PRIVATE') {
        setSelected('Privater Account');
      } else setSelected('Geschäftskonto');
    });
  }, []);

  const chaneAccountType = (event: any) => {
    if (!user.birthDate) user.birthDate = '';
    if (!user.gender) user.gender = '';
    if (event.detail.value === 'Privater Account') {
      user.accountType = 'PRIVATE';
    } else user.accountType = 'COMPANY';
    delete user.profilePicture;
    userService.updateUser(user).then((res) => {
      setAccountState(res.data);
      setSelected(event.detail.value);
      setRefreshCounter((val) => val + 1);
    });
  };
  const isDark2 = useRecoilValue(isDarkTheme);

  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar>Geschäftlich oder Privat</UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <div className='text-black mx-4 font-sans'>
          <p className='font-bold mt-2'>Konto Typ</p>
          <div className='border border-yellow-accent border-t-8 rounded-md mt-2 py-0.5'>
            <IonRadioGroup
              value={selected}
              onIonChange={(e: any) => {
                chaneAccountType(e);
              }}
            >
              <IonItem color={`${isDark2 ? 'medium' : 'light'}`} lines='none'>
                <IonLabel>
                  <b className={`font-bold text-xs${isDark2 ? ' text-white' : 'text-black'}`}>
                    Privates Konto
                  </b>
                </IonLabel>
                <IonRadio slot='start' value='Privater Account' />
              </IonItem>
              <hr className='bg-yellow-accent' />
              <IonItem color={`${isDark2 ? 'medium' : 'light'}`} lines='none'>
                <IonLabel>
                  <b className={`font-bold text-xs${isDark2 ? ' text-white' : 'text-black'}`}>
                    Geschäftskonto
                  </b>
                </IonLabel>
                <IonRadio slot='start' value='Geschäftskonto' />
              </IonItem>
            </IonRadioGroup>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AccountTypeEdit;

import React from 'react';
import AppLoginBackground from 'pages/account/accountLogin/AccountLoginBackground';
import BForm from 'forms/common/bForm/BForm';
import {formRecoveryCode} from 'forms/account/formPassword/recoveryCode.form';

import background from 'assets/background.png';
import logo from 'assets/logo.png';

type Props = {
  isLoading: boolean;
  onSubmit: Function;
  errors: any;
};

const AccountRecoveryCodePage = ({isLoading, onSubmit, errors}: Props) => {
  return (
    <div className='lg:flex lg:justify-center bg-gray-200'>
      <div className='h-screen bg-gray-200 lg:w-1/3'>
        <img src={background} className='absolute w-screen h-screen z-0 blur-sm lg:hidden' />
        <div className='flex justify-center h-2/6 flex-col'>
          <div className='h-1/6'></div>
          <div className='flex justify-center'>
            <img src={logo} className='w-64 h-28 z-20' />
          </div>
        </div>
        <AppLoginBackground>
          <div>
            {isLoading && <p>Loading...</p>}
            <p className='text-xl mt-4 mx-3 font-bold'>Passwort Vergessen</p>
            <p className='text-sm text-gray-400 mx-3'>Geben Sie Ihre sechsstelligen Code ein</p>
            <div className='mx-3 mb-5'>
              <BForm
                btnMargin={1}
                btnText='Weiter'
                fields={formRecoveryCode}
                preloadedData={{}}
                submit={onSubmit}
                formErrors={errors}
              />
            </div>
            <hr className='bg-yellow-accent mx-6 my-2' />
            <p className='text-xs text-center mt-4'>Sie haben den Code nicht erhalten?</p>
            <button className='text-yellow-accent w-full'>
              <div
                className='bg-white mt-2 mb-6 mx-4 text-sm
            text-center rounded-lg border border-yellow-accent p-2'
              >
                <b>Erneut Senden</b>
              </div>
            </button>
          </div>
        </AppLoginBackground>
      </div>
    </div>
  );
};

export default AccountRecoveryCodePage;

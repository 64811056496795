import React, {useEffect, useState} from 'react';
import {IonContent, IonHeader, IonIcon, IonPage, IonToolbar} from '@ionic/react';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UILogo from 'components/uiLogo/UILogo';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';
import {useForm} from 'react-hook-form';
import {instrumentsService} from 'api/instruments/instruments.service';
import {useParams} from 'react-router';
import AddPhoto from 'components/uiAddPhoto/UIAddPhoto';
import {closeCircle} from 'ionicons/icons';
import {refreshCounterState} from 'states/common/common.state';
import {getService} from 'pages/promotions/promotionsCostsList/PromotionsCostsList';
import {productsService} from 'api/products/products.service';
import {servicesService} from 'api/services/services.service';
import {jobsService} from 'api/jobs/jobs.service';

const EditPhotosPage = () => {
  const {itemId, type}: any = useParams();
  const isDark2 = useRecoilValue(isDarkTheme);
  const {handleSubmit, control, setValue} = useForm();
  const [requestResponse, setRequestResponse] = useState<string>('');
  const [arePhotos, setArePhotos] = useState<boolean[]>();
  const arePhotosDefault = [false, false, false, false];
  const setRefreshCounter = useSetRecoilState(refreshCounterState);
  const service = getService(type);

  useEffect(() => {
    service.getDetails(itemId).then((response: any) => {
      response.data.photos.map((photo: {photo: string}, idx: number) => {
        setValue('photo_' + idx, photo.photo);
        arePhotosDefault[idx] = true;
      });
      setArePhotos(arePhotosDefault);
    });
  }, []);

  const onSubmit = (photos: any) => {
    const notDeletedPhotos = [];
    for (let i = 0; i < 4; i++) {
      if (photos[`photo_` + i] === undefined) delete photos[`photo_` + i];
      const photo = photos[`photo_` + i];
      if (typeof photo === 'string') {
        if (photo === 'deletePhoto') continue;
        notDeletedPhotos.push(photo.substring(33, photos[`photo_` + i].length));
      }
    }
    if (notDeletedPhotos.length > 0) photos.not_delete_photos = notDeletedPhotos;

    switch (type) {
      case 'products':
        productsService.updateProduct(photos, itemId).then(() => {
          setRefreshCounter((prev) => prev + 1);
          setRequestResponse('Erfolg!');
        });
        break;
      case 'services':
        servicesService.updateService(photos, itemId).then(() => {
          setRefreshCounter((prev) => prev + 1);
          setRequestResponse('Erfolg!');
        });
        break;
      case 'jobs':
        jobsService.updateJob(photos, itemId).then(() => {
          setRefreshCounter((prev) => prev + 1);
          setRequestResponse('Erfolg!');
        });
        break;
      default:
        instrumentsService.updateInstrument(photos, itemId).then(() => {
          setRefreshCounter((prev) => prev + 1);
          setRequestResponse('Erfolg!');
        });
        break;
    }
  };

  const handleDelete = (photoId: number) => {
    setValue('photo_' + photoId, 'deletePhoto');
    switch (photoId) {
      case 0:
        setArePhotos([false, arePhotos![1], arePhotos![2], arePhotos![3]]);
        break;
      case 1:
        setArePhotos([arePhotos![0], false, arePhotos![2], arePhotos![3]]);
        break;
      case 2:
        setArePhotos([arePhotos![0], arePhotos![1], false, arePhotos![3]]);
        break;
      case 3:
        setArePhotos([arePhotos![0], arePhotos![1], arePhotos![2], false]);
        break;
    }
  };

  const handleChangePhoto = (photoId: number) => {
    switch (photoId) {
      case 0:
        setArePhotos([true, arePhotos![1], arePhotos![2], arePhotos![3]]);
        break;
      case 1:
        setArePhotos([arePhotos![0], true, arePhotos![2], arePhotos![3]]);
        break;
      case 2:
        setArePhotos([arePhotos![0], arePhotos![1], true, arePhotos![3]]);
        break;
      case 3:
        setArePhotos([arePhotos![0], arePhotos![1], arePhotos![2], true]);
        break;
    }
  };

  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <UIButtonBack />
          <UILogo />
        </IonToolbar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <form className='h-full pb-3 space-y-2' onSubmit={handleSubmit(onSubmit)}>
          {arePhotos && (
            <div className='flex flex-wrap'>
              {arePhotos.map((isPhoto: boolean, idx: number) => (
                <div className='mt-4 ml-4' key={idx}>
                  <AddPhoto
                    name={'photo_' + idx}
                    control={control}
                    onValueChange={() => handleChangePhoto(idx)}
                  />
                  {isPhoto === true && (
                    <IonIcon
                      icon={closeCircle}
                      className='text-yellow-accent text-4xl mt-[-10px] ml-[79px]'
                      onClick={() => handleDelete(idx)}
                    />
                  )}
                </div>
              ))}
            </div>
          )}
          <div className='w-full flex justify-center'>
            <div className='text-yellow-accent'>{requestResponse}</div>
            <div
              className='border-[2px] border-yellow-accent text-yellow-accent rounded-xl
          absolute top-[90%] h-10 text-xl text-center
          pt-1 hover:bg-yellow-50 w-11/12'
            >
              <button type='submit'>Fotos speichern</button>
            </div>
          </div>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default EditPhotosPage;

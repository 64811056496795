import React, {useEffect, useState} from 'react';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import {funnel, chevronForward} from 'ionicons/icons';
import UILogo from 'components/uiLogo/UILogo';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import {Instrument} from 'interfaces/instruments/instrument.interface';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import LoadingIndicator from 'components/uiLoading/UILoadingIndicator';
import sorting from 'assets/sorting.svg';
import {useParams} from 'react-router';
import UIFilter from 'components/uiFilter/UIFilter';
import {distanceCalculator} from 'utils/distanceCalculator';
import {productsService} from 'api/products/products.service';
import ProductsList from './ProductsList';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {geolocationState, refreshCounterState} from 'states/common/common.state';
import {
  defaultDistanceMax,
  defaultDistanceMin,
  defaultPriceMax,
  defaultPriceMin,
} from 'utils/constants';
import UIResponsivePadding from 'components/uiResponsive/UIResponsivePadding';
import {isDarkTheme} from 'states/account/account.state';

const ProductsListPage = () => {
  const [originalProducts, setOriginalProducts] = useState<Instrument[]>();
  const [products, setProducts] = useState<Instrument[]>();
  let {subCatId, catName, subCatName}: any = useParams();
  const [isFilterShown, setIsFilterShown] = useState(false);
  const [priceMin, setPriceMin] = useState(defaultPriceMin);
  const [priceMax, setPriceMax] = useState(defaultPriceMax);
  const [distanceMin, setDistanceMin] = useState(defaultDistanceMin);
  const [distanceMax, setDistanceMax] = useState(defaultDistanceMax);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const geolocation = useRecoilValue(geolocationState);

  const shouldRefreshData = useRecoilValue(refreshCounterState);
  const setRefreshCount = useSetRecoilState(refreshCounterState);
  const router = useIonRouter();

  useEffect(() => {
    setProducts(undefined);
    setIsLoading(true);
    productsService
      .getProducts(subCatId)
      .then((response) => {
        response.data &&
          response.data.forEach((instrument: Instrument) => {
            instrument.distance = distanceCalculator(instrument.lat, instrument.lng, geolocation);
          });
        setProducts(response.data);
        setOriginalProducts(response.data);
      })
      .finally(() => {
        setIsLoading(false);
        subCatId = null;
      });
  }, [shouldRefreshData, subCatId]);

  useEffect(() => {
    products && getOrderBy('Entfernung aufsteigend');
  }, [originalProducts, shouldRefreshData]);

  const handleFilter = () => {
    let tempData = originalProducts;
    tempData = tempData?.filter((item) => {
      return (
        item.price >= priceMin &&
        item.price <= priceMax &&
        item.distance! >= distanceMin &&
        item.distance! <= distanceMax
      );
    });

    setProducts(tempData);
  };
  const handleSort = (sortValue: string) => {
    getOrderBy(sortValue);
  };

  const getOrderBy = (sortValue: string) => {
    switch (sortValue) {
      case 'Datum aufsteigend':
        productsService.getSortedProducts('production_year', subCatId).then((res) => {
          setProducts(res.data.results);
        });
        return 'dateAdded';
      case 'Datum absteigend':
        productsService.getSortedProducts('-production_year', subCatId).then((res) => {
          setProducts(res.data.results);
        });
        return '-dateAdded';
      case 'Preis aufsteigend':
        productsService.getSortedProducts('price', subCatId).then((res) => {
          setProducts(res.data.results);
        });
        return 'salary';
      case 'Preis absteigend':
        productsService.getSortedProducts('-price', subCatId).then((res) => {
          setProducts(res.data.results);
        });
        return '-salary';
      case 'Entfernung aufsteigend':
        const sorted5 = [
          ...products!.sort((a, b) => (Number(a.distance!) > Number(b.distance!) ? 1 : -1)),
        ];
        setProducts(sorted5);
        return 'distance';
      case 'Entfernung absteigend':
        const sorted6 = [
          ...products!.sort((a, b) => (Number(a.distance!) < Number(b.distance!) ? 1 : -1)),
        ];
        setProducts(sorted6);
        return '-distance';
    }
  };
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar
          leftComponent={
            <button title='button' onClick={() => setIsFilterShown(!isFilterShown)}>
              <IonIcon className='mt-1 ml-3 text-white' icon={funnel} />
            </button>
          }
          customTextClassName='absolute mt-3 left-10 transform'
          rightComponent={
            <div className='flex w-7/8 mr-2'>
              <IonSelect
                okText='Weiter'
                cancelText='Abbrechen'
                onIonChange={(e) => handleSort(e.detail.value)}
                placeholder='Sortieren'
                className='text-xs text-transparent w-8 -mr-8 z-10'
              >
                <IonSelectOption className='text-black'>Datum aufsteigend</IonSelectOption>
                <IonSelectOption className='text-black'>Datum absteigend</IonSelectOption>
                <IonSelectOption className='text-black'>Preis aufsteigend</IonSelectOption>
                <IonSelectOption className='text-black'>Preis absteigend</IonSelectOption>
                <IonSelectOption className='text-black'>Entfernung aufsteigend</IonSelectOption>
                <IonSelectOption className='text-black'>Entfernung absteigend</IonSelectOption>
              </IonSelect>
              <img alt='img' className='my-2 mr-3' src={sorting} />
            </div>
          }
        >
          <div className='text-white text-xs flex w-80 ml-2'>
            <p className='font-thin text-[11px]'>{catName} kaufen</p>
            <IonIcon icon={chevronForward} className='mt-0.5 mx-1' />
            <p>{subCatName}</p>
          </div>
        </UITopTextBar>
      </IonHeader>
      {isFilterShown && (
        <UIFilter
          onFilter={handleFilter}
          setPriceMin={setPriceMin}
          setPriceMax={setPriceMax}
          setDistanceMin={setDistanceMin}
          setDistanceMax={setDistanceMax}
        />
      )}
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <UIResponsivePadding>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            products && (
              <ProductsList
                products={products}
                type='products'
                onProductClick={(products: any) => {
                  router.push(`/product/${catName}/${subCatName}/${products.id}`);
                  setRefreshCount((v) => v + 1);
                }}
              />
            )
          )}
        </UIResponsivePadding>
      </IonContent>
    </IonPage>
  );
};

export default ProductsListPage;

import React from 'react';
import {Instrument} from 'interfaces/instruments/instrument.interface';
import UIListItem from 'components/uiListItems/UIListItem';

export interface InstrumentProps {
  instruments: Instrument[];
  isOwnInstruments?: boolean;
  onInstrumentClick: (instrument: any) => void;
  imageType?: string;
}

const InstrumentsList = ({
  instruments,
  isOwnInstruments,
  onInstrumentClick,
  imageType,
}: InstrumentProps) => {
  return (
    <div className=' space-y-12 mt-4'>
      {instruments.map((instrument) => (
        <UIListItem
          key={instrument.id}
          instrument={instrument}
          onItemClick={onInstrumentClick}
          isOwn={isOwnInstruments}
          type={imageType}
        />
      ))}
    </div>
  );
};

export default InstrumentsList;

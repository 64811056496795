import React from 'react';
import {Marker} from 'react-google-maps';
import {useSetRecoilState} from 'recoil';
import {geolocationState} from 'states/common/common.state';
import {urlConverter} from 'utils/urlConverter';

import localization from 'assets/gold_localization.svg';

type MapMarkerProps = {
  key: any;
  lat?: number;
  lng?: number;
  selectedPlaceHandler: Function;
  id?: string;
  type: string;
  catName?: string;
  subCatName?: string;
  item?: any;
};

const MapMarker: React.FC<MapMarkerProps> = ({
  key,
  lat,
  lng,
  selectedPlaceHandler,
  id,
  type,
  catName,
  subCatName,
  item,
}) => {
  const setGeolocation = useSetRecoilState(geolocationState);

  const handleClick = () => {
    selectedPlaceHandler(id, type, lat, lng, catName && catName, subCatName && subCatName);
    lat &&
      lng &&
      setGeolocation({
        lat: lat,
        lng: lng,
      });
  };

  return (
    <div>
      {lat && lng && (
        <Marker
          defaultZIndex={item.mapPromoted ? 20 : 10}
          icon={{
            url:
              item.mapPromoted && item.photos[0]
                ? urlConverter(item.photos[0].photo)
                : localization,
            scaledSize: new window.google.maps.Size(32, 32),
          }}
          key={key}
          position={{
            lat: lat,
            lng: lng,
          }}
          onClick={() => handleClick()}
        />
      )}
    </div>
  );
};

export default MapMarker;

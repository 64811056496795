import React from 'react';
import {IonIcon} from '@ionic/react';
import {Job} from 'interfaces/jobs/jobs.interface';
import {logoEuro} from 'ionicons/icons';
import {UIImage} from 'components/uiImages/UIImage';
import {useRecoilValue} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';
import {urlConverter} from 'utils/urlConverter';

export interface JobsProps {
  jobs: Job[];
  isOwnJob: boolean;
  isPromoted?: boolean;
  onDelete?: (id: any) => void;
  onJobClick: Function;
  onContackClick?: () => void;
  type?: string;
}

export const JobsList = ({
  jobs,
  isOwnJob,
  isPromoted,
  onJobClick,
  onDelete,
  onContackClick: onConctactClick,
  type,
}: JobsProps) => {
  const isDark2 = useRecoilValue(isDarkTheme);

  return (
    <div className='my-5 space-y-5 z-10'>
      {jobs.map((job) => {
        let photo = '';
        if (job.photos.length > 0) {
          photo = urlConverter(job.photos[0].photo);
        }
        return (
          <div
            key={job.name! + job.id}
            onClick={() => onJobClick(job.id)}
            className='w-[90%] md:w-2/3 max-w-screen-md mx-auto'
          >
            <div
              className={`${
                job.promoted
                  ? 'shadow-custom shadow-orange-promoted border rounded-3xl border-orange-promoted'
                  : 'border rounded-3xl border-yellow-accent'
              } ${isDark2 ? 'bg-[#393939] drop-shadow-md' : 'bg-[#F8F8F8] drop-shadow-md'}`}
            >
              <div>
                <UIImage
                  type={type}
                  photoLink={photo}
                  customClassName='h-20 w-full rounded-t-3xl'
                />
                <div className='flex justify-end mr-3'>
                  <div className='bg-yellow-accent text-white absolute p-2 top-16 rounded-2xl flex'>
                    <IonIcon icon={logoEuro} className='text-sm' />
                    <p className='text-xs'>{job.salary}</p>
                  </div>
                </div>
              </div>
              <div>
                <div className='mt-2'>
                  <p
                    className={`text-md font-medium ml-5
              ${isDark2 ? 'text-white' : 'text-gray-600 '}`}
                  >
                    {job.jobOffer}
                  </p>
                  <p
                    className={`text-md font-sm ml-7 ${isDark2 ? 'text-white' : 'text-gray-600 '}`}
                  >
                    {job.companyName}
                  </p>
                  <div className='flex justify-between'>
                    <p
                      className={`text-md text-[12px] ml-7 mb-4
                ${isDark2 ? 'text-gray-300' : 'text-gray-600 '}`}
                    >
                      {job.location}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

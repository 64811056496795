import React from 'react';
import {Category, Subcategory} from 'interfaces/instruments/instrument.interface';
import {isDarkTheme} from 'states/account/account.state';
import {useRecoilValue} from 'recoil';

type Props = {
  categories: Subcategory[] | Category[];
  currentCategory?: Subcategory | Category;
  setCurrentCategory: Function;
};

const UICategoriesTopBar: React.FC<Props> = ({categories, currentCategory, setCurrentCategory}) => {
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <div className={`${isDark2 ? 'bg-[#474747]' : 'bg-[#F8F8F8]'}`}>
      <div
        className='overflow-auto border-b-[1px] border-gray-300
     flex font-sans'
      >
        <div
          onClick={() => setCurrentCategory({id: 0})}
          className={
            currentCategory && currentCategory.id === 0
              ? `border-b-4 border-orange-300 text-orange-300 pr-10
                   text-[13px] relative text-center h-10`
              : `border-transparent text-[13px] pr-10 relative text-center h-10
                   ${isDark2 ? 'text-white' : 'text-gray-500 '}`
          }
        >
          <p className='ml-5'>Alle Anzeigen</p>
        </div>
        {categories &&
          categories
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((category) => (
              <div
                key={category.id}
                onClick={() => setCurrentCategory(category)}
                className={
                  currentCategory && currentCategory.id === category.id
                    ? `border-b-4 border-orange-300 text-orange-300 pr-10
                   text-[13px] relative text-center h-10`
                    : `border-transparent text-[13px] pr-10 relative text-center h-10
                   ${isDark2 ? 'text-white' : 'text-gray-500 '}`
                }
              >
                <p className='text-transparent'>{category.name}</p>
                <p className='absolute bottom-0 mb-1 w-full'>{category.name}</p>
              </div>
            ))}
      </div>
    </div>
  );
};

export default UICategoriesTopBar;

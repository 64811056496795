import React from 'react';
import UIErrorText from 'components/uiLabels/UIErrorText';

const formatError = (error: any) => {
  const {message, type} = error;
  if (type === 'required') {
    return <UIErrorText>Dieses Feld ist erforderlich!</UIErrorText>;
  }
  return <UIErrorText>{message}</UIErrorText>;
};

export default formatError;

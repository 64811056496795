import client from 'api/common/client';

const getDIY = async (id: string) => {
  const response = await client.get(`/diy/${id}`);
  return response;
};

const getAll = async () => {
  const response = await client.get('/diy/');
  return response;
};

const postDIYComment = (id: string, data: object) => {
  const response = client.post(`/diy/${id}/comments/`, data);
  return response;
};

export const diysService = {
  postDIYComment,
  getAll,
  getDIY,
};

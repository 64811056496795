import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar} from '@ionic/react';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import UILogo from 'components/uiLogo/UILogo';
import React, {useEffect, useState} from 'react';
import buyMachineryImg from 'assets/buy_machinery.svg';
import sellMachineryImg from 'assets/sell_machinery.svg';
import putMachineryForRentImg from 'assets/put_machinery_for_rent.svg';
import rentMachineryImg from 'assets/rent_machinery.svg';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UIResponsivePadding from 'components/uiResponsive/UIResponsivePadding';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';
import UIOrangeCategoryBox from 'components/uiContainer/UIOrangeCategoryBox';
import {instrumentsService} from 'api/instruments/instruments.service';
import {refreshCounterState} from 'states/common/common.state';
import {authService} from 'api/account/auth.service';
import {ItemsCounterInterface} from 'interfaces/common/itemsCounter.interface';

const MachinesMenuPage: React.FC = () => {
  const token = authService.tokenValue;
  const isDark2 = useRecoilValue(isDarkTheme);
  const [itemsCounter, setItemsCounter] = useState<ItemsCounterInterface>();
  const setRefreshCounter = useSetRecoilState(refreshCounterState);

  useEffect(() => {
    instrumentsService.getInstrumentsCount().then((res)=>{
      setItemsCounter(res.data);
      setRefreshCounter((prev) => prev + 1);
    });
  }, []);

  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar>Baumaschinen</UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <UIResponsivePadding>
          {
            <div className=''>
              <UIOrangeCategoryBox
                name='BAUMASCHINEN MIETEN'
                src={rentMachineryImg}
                alt='RentMachines'
                linkTo={'/instruments/categories/2/loan'}
                count={itemsCounter?.baumaschinenInstruments}
              />
              <UIOrangeCategoryBox
                name={'BAUMASCHINEN VERMIETEN'}
                src={putMachineryForRentImg}
                alt={'PutMachinesForRent'}
                linkTo={!token ? '/sign-in' : '/instruments-add'}
              />
              <UIOrangeCategoryBox
                name={'BAUMASCHINEN KAUFEN'}
                src={buyMachineryImg}
                alt={'BuyMachines'}
                linkTo={'/instruments/categories/2/buy'}
                count={itemsCounter?.baumaschinenProducts}
              />
              <UIOrangeCategoryBox
                name={'BAUMASCHINEN VERKAUFEN'}
                src={sellMachineryImg}
                alt={'SellMachines'}
                linkTo={!token ? '/sign-in' : '/products-add'}
              />
            </div>
          }
        </UIResponsivePadding>
      </IonContent>
    </IonPage>
  );
};

export default MachinesMenuPage;

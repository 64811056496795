import React, {useEffect, useState} from 'react';
import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar, useIonRouter} from '@ionic/react';
import {useParams} from 'react-router';
import {accountService} from 'api/account/account.service';
import {Instrument} from 'interfaces/instruments/instrument.interface';
import InstrumentsList from 'pages/instruments/instrumentsList/InstrumentsList';
import UILogo from 'components/uiLogo/UILogo';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UIButtonAdd from 'components/uiButtons/UIButtonAdd';
import UITopBar from 'components/uiTopBar/UITopBar';
import ProductsListBuyPage from 'pages/products/productsList/ProductsList';
import {useRecoilValue} from 'recoil';
import {refreshCounterState} from 'states/common/common.state';
import {Product} from 'interfaces/products/product.interface';
import {isDarkTheme} from 'states/account/account.state';

const AccountInstrumentsListPage = () => {
  const loan = 'Mietgeräte';
  const buy = 'Verkaufen';

  const [activeTab, setActiveTab] = useState(loan);
  const [instruments, setInstruments] = useState<Instrument[]>();
  const [products, setProducts] = useState<Product[]>();
  const refresh = useRecoilValue(refreshCounterState);
  const {listType}: any = useParams();

  const router = useIonRouter();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userRes = await accountService.getCurrentUser();
        setInstruments(userRes.data.mine.instruments);
        setProducts(userRes.data.mine.products);
      } catch {}
    };
    fetchData();
  }, [refresh]);

  const handleItemClick = (item: Instrument | Product) => {
    if (listType === 'preview') {
      router.push(
        activeTab === loan
          ? `/instrument/${item.category.name}/${item.subcategory.name}/${item.id}`
          : `/product/${item.category.name}/${item.subcategory.name}/${item.id}`
      );
      return;
    }
    router.push(
      activeTab === loan ? `/promote/instruments/${item.id}` : `/promote/products/${item.id}`
    );
  };

  const handleAddClicked = () => {
    if (activeTab === loan) {
      router.push('/instruments-add');
    }
    if (activeTab === buy) {
      router.push('/products-add');
    }
  };
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
          <IonButtons slot='end'>
            <UIButtonAdd onClick={handleAddClicked} />
          </IonButtons>
        </IonToolbar>
        <UITopTextBar>Meine Geräte</UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <UITopBar
          firstTab={loan}
          secondTab={buy}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        {activeTab === loan && instruments && (
          <InstrumentsList
            instruments={instruments}
            isOwnInstruments={true}
            onInstrumentClick={handleItemClick}
            imageType='instrument'
          />
        )}
        {activeTab === buy && products && (
          <ProductsListBuyPage
            products={products}
            isOwnInstruments={true}
            onProductClick={handleItemClick}
            type='products'
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default AccountInstrumentsListPage;

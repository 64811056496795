import React, {useEffect, useState} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonToolbar,
  isPlatform,
  useIonRouter,
} from '@ionic/react';
import {Browser} from '@capacitor/browser';
import {Geolocation} from '@ionic-native/geolocation';
import HomeMenuButton from './HomeMenuButton';
import {accountService} from 'api/account/account.service';
import {accountState, isDarkTheme} from 'states/account/account.state';
import {
  geolocationState,
  refreshCounterState,
} from 'states/common/common.state';
import {getAllMessages, getMessagesFromStorage} from 'api/chat/chat.service';
import {newMessagesState} from 'states/messages/message.state';
import {authService} from 'api/account/auth.service';
import UIResponsivePadding from 'components/uiResponsive/UIResponsivePadding';
import {instrumentsService} from 'api/instruments/instruments.service';
import UILogo from 'components/uiLogo/UILogo';

import tools from 'assets/home/tools.jpg';
import instruments from 'assets/home/instruments.jpg';
import machine from 'assets/home/machine.png';
import job from 'assets/home/job_category.png';
import land from 'assets/home/land_category.png';
import services from 'assets/home/services.png';
import articlePhoto2 from 'assets/home/article_photo2.jpg';
import articlePhoto1 from 'assets/home/article_photo1.jpg';
import {ItemsCounterInterface} from 'interfaces/common/itemsCounter.interface';

const HomePage: React.FC = () => {
  const router = useIonRouter();

  const setRefreshCount = useSetRecoilState(refreshCounterState);
  const setAccountData = useSetRecoilState(accountState);
  const accountData = useRecoilValue(accountState);
  const setGeolocation = useSetRecoilState(geolocationState);
  const setNewMessagesState = useSetRecoilState(newMessagesState);
  const isDark2 = useRecoilValue(isDarkTheme);

  const [itemsCounter, setItemsCounter] = useState<ItemsCounterInterface>();

  useEffect(() => {
    instrumentsService.getInstrumentsCount().then((res) => {
      setItemsCounter(res.data);
    });
  }, []);

  useEffect(() => {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.documentElement.classList.add('dark');
    }
  }, []);

  useEffect(() => {
    const fetch = async () => {
      await authService.refreshAccessToken();
      if (accountData) {
        await fetchMessages();
        await fetchCurrentUser();
      }
    };

    const getGeolocation = async () => {
      const position = await Geolocation.getCurrentPosition();
      setGeolocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    };

    fetch();
    setRefreshCount((v) => v + 1);
    getGeolocation();
  }, []);

  const fetchCurrentUser = async () => {
    const res = await accountService.getCurrentUser();
    setAccountData(res.data);
  };

  const fetchMessages = async () => {
    const res = await getAllMessages();
    const oldMessages = await getMessagesFromStorage();
    const messages = JSON.stringify(res.data);
    oldMessages !== '' && setNewMessagesState(oldMessages !== messages);
  };

  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <UILogo
            customClassName={
              isPlatform('iphone')
                ? `mt-5 w-full flex justify-center`
                : `w-full flex justify-center`
            }
          />
        </IonToolbar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <UIResponsivePadding>
          <IonGrid
            className={`mt-24xl:w-2/3 w-full h-full mb-12
            ${isDark2 ? 'bg-[#474747] text-white' : 'bg-[#F8F8F8] text-black'}`}
          >
            <HomeMenuButton
              src={tools}
              alt='tools'
              name='Werkzeug'
              numberOfItems={
                itemsCounter &&
                itemsCounter.werkzeugInstruments + itemsCounter.werkzeugProducts
              }
              onClick={() => router.push('/tools-menu')}
              catId={2}
            />
            <HomeMenuButton
              src={machine}
              alt='vehicles'
              name='Baumaschinen'
              numberOfItems={
                itemsCounter &&
                itemsCounter.baumaschinenInstruments +
                  itemsCounter.baumaschinenProducts
              }
              onClick={() => router.push('/machines-menu')}
              catId={1}
            />
            <HomeMenuButton
              src={land}
              alt='shop'
              name='Land & Forsttechnik'
              numberOfItems={0}
              onClick={() => router.push('/agriculture-menu')}
              catId={3}
            />
            <HomeMenuButton
              src={services}
              alt='services'
              name='Dienstleistungen'
              numberOfItems={itemsCounter?.services}
              isEnable={true}
              onClick={() => router.push('/services-menu')}
            />
            <HomeMenuButton
              src={job}
              alt='jobs'
              name='Jobs'
              numberOfItems={itemsCounter?.jobs}
              isEnable={true}
              onClick={() => router.push('/jobs-menu')}
            />
            <HomeMenuButton
              src={instruments}
              alt='settings'
              name='WUB Preisliste'
              isEnable={true}
              onClick={() => router.push('/pricelist')}
            />
            <HomeMenuButton
              src={articlePhoto1}
              alt='page1'
              name='Finanzierung'
              isEnable={true}
              onClick={() =>
                Browser.open({
                  url: 'https://wubmal.com/anfrage-finanzierung',
                })
              }
            />
            <HomeMenuButton
              src={articlePhoto2}
              alt='page2'
              name='Versicherung'
              isEnable={true}
              onClick={() =>
                Browser.open({
                  url: 'https://wubmal.com/anfrage-versicherung',
                })
              }
            />
          </IonGrid>
        </UIResponsivePadding>
      </IonContent>
    </IonPage>
  );
};

export default HomePage;

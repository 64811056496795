import React, {useState} from 'react';
import {Share} from '@capacitor/share';
import {IonIcon, IonToast, isPlatform} from '@ionic/react';
import {shareSocial} from 'ionicons/icons';
import {useLocation} from 'react-router';
import {isDarkTheme} from 'states/account/account.state';
import {useRecoilValue} from 'recoil';
import {Clipboard} from '@capacitor/clipboard';

const UIButtonShare: React.FC = () => {
  const [showToast, setShowToast] = useState(false);
  if (isPlatform('mobileweb')) return <></>;

  const siteUrl = 'https://app.wubmal.com';

  const currentLocation = useLocation();

  const path = siteUrl + currentLocation.pathname.replace(/ /g, '');

  const isDark2 = useRecoilValue(isDarkTheme);

  const writeToClipboard = async () => {
    await Clipboard.write({
      string: path,
    }).then(() => {
      setShowToast(true);
    });
  };

  return (
    <>
      {isPlatform('mobileweb') ? (
        <></>
      ) : (
        <>
          <IonIcon
            icon={shareSocial}
            className={`mt-1 h-6 w-6 ${isDark2 ? 'text-white' : 'text-black'}`}
            onClick={() => {
              isPlatform('android')
                ? writeToClipboard()
                : Share.share({
                    text: path,
                    dialogTitle: 'Teilen',
                  });
            }}
          />
          <IonToast
            isOpen={showToast}
            message='URL erfolgreich kopiert!'
            duration={5000}
            color='#F5B966'
          />
        </>
      )}
    </>
  );
};

export default UIButtonShare;

import React, {useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';
import {useHistory, useLocation} from 'react-router';
import {IonPage} from '@ionic/react';
import BForm from 'forms/common/bForm/BForm';
import {formRegisterConfirmation} from 'forms/account/formRegister/registerConfirmation.form';
import {accountService} from 'api/account/account.service';
import AppLoginBackground from 'pages/account/accountLogin/AccountLoginBackground';
import background from 'assets/background.png';
import logo from 'assets/logo.png';

type LocationProps = {
  email: string;
  phoneNumber: string;
};

const AccountRegisterConfirmationPage = () => {
  const location = useLocation<LocationProps>();
  const history = useHistory();

  const [oldNumber, setOldNumber] = useState<string>(location.state.phoneNumber);
  const [newNumber, setNewNumber] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any[]>([]);
  const isDark2 = useRecoilValue(isDarkTheme);

  useEffect(() => {
    setOldNumber(location.state.phoneNumber);
  }, []);

  const yesFunction = () => {
    setIsLoading(true);
    newNumber &&
      accountService
        .resendSms(newNumber)
        .then(() => {
          history.push('/registerConfirmation', {phoneNumber: newNumber});
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
  };

  const onSubmit = (data: any) => {
    setIsLoading(true);
    accountService
      .activateAccount(location.state.email, data.activationCode)
      .then(() => {
        history.replace('/registerConfirmation', {
          email: location.state.email,
          phoneNumber: data.phoneNumber,
          data: data,
        });
        history.push('/login', {
          email: location.state.email,
          phoneNumber: data.phoneNumber,
          data: data,
        });
      })
      .catch(() => {
        setErrors([{name: 'activationCode', message: 'Code is invalid'}]);
      })
      .finally(() => {
        console.log(data.activationCode);
        setIsLoading(false);
      });
  };
  return (
    <IonPage className='bg-gray-200'>
      <div
        className={`lg:flex lg:justify-center
      ${isDark2 ? 'bg-[#474747]' : 'bg-[#F8F8F8]'}`}
      >
        <div
          className={`h-screen lg:w-1/3
        ${isDark2 ? 'bg-[#474747]' : 'bg-[#F8F8F8]'}`}
        >
          <img
            alt='background'
            src={background}
            className='absolute w-screen h-screen z-0 blur-sm lg:hidden'
          />
          <div className='flex justify-center h-2/6 flex-col'>
            <div className='h-1/6'></div>
            <div className='flex justify-center'>
              <img alt='logo' src={logo} className='w-60 h-24 z-20' />
            </div>
          </div>
          <AppLoginBackground>
            <div>
              {isLoading && <p>Loading...</p>}
              <p
                className={`text-xl mt-2 mx-3 font-bold
              ${isDark2 ? ' text-white' : 'text-black'}`}
              >
                Bestätigung
              </p>
              <p className={`text-xs mx-3 ${isDark2 ? ' text-white' : ' text-gray-600 '}`}>
                Geben Sie Ihren sechsstelligen Code ein
              </p>
              <div className='mx-3'>
                <BForm
                  btnMargin={2}
                  btnText='Weiter'
                  fields={formRegisterConfirmation}
                  preloadedData={{phoneNumber: oldNumber}}
                  submit={onSubmit}
                  formErrors={errors}
                  onChange={(e: any) => setNewNumber(e.target.value)}
                />
              </div>
              <hr className='bg-yellow-accent mx-6 my-2' />
              <p
                className={`text-xs text-center mt-4
              ${isDark2 ? ' text-white' : 'text-black'}`}
              >
                Sie haben den Code nicht?
              </p>
              <div
                className='bg-white mt-2 mb-6 mx-4 text-sm text-yellow-accent
                text-center rounded-lg border border-yellow-accent p-2'
                onClick={() => yesFunction()}
              >
                <b
                  className={`${
                    isDark2
                      ? 'border-[#F0A249] text-[#F0A249]'
                      : 'border-yellow-accent text-yellow-accent'
                  }`}
                >
                  Aktualisieren und erneut senden
                </b>
              </div>
            </div>
          </AppLoginBackground>
        </div>
      </div>
    </IonPage>
  );
};

export default AccountRegisterConfirmationPage;

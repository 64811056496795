import {Instrument} from 'interfaces/instruments/instrument.interface';
import {Job} from 'interfaces/jobs/jobs.interface';
import {Service} from 'interfaces/services/service.interface';
import {Product} from 'interfaces/products/product.interface';

export interface User {
  firstName?: string;
  email?: string;
  lastName?: string;
  favourites?: {
    products: Product[];
    jobs?: Job[];
    services: Service[];
    instruments: Instrument[];
  };
  street?: string;
  city?: string;
  zipCode?: string;
  houseNumber?: string;
  phoneNumber?: string;
  birthDate?: string;
  jobTitle?: string;
  gender?: string;
  profilePicture?: string | any;
  id?: number;
  mine?: any;
  accountType?: 'PRIVATE' | 'COMPANY';
  playerId?: string;
  areEmailNotificationsEnabled?: boolean;
  areAppNotificationsEnabled?: boolean;
  isVisibleEmail?: boolean;
  isVisiblePhoneNumber?: boolean;
  isPremium?: boolean;
}
export interface ShortUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: number;
  userAvg: number;
}

export const UserDataDefaultValues = {
  name: '',
  lastName: '',
  street: '',
  city: '',
  zipCode: '',
  phone: '',
  birthDate: '',
  jobTitle: '',
  gender: '',
  profilePicture: '',
};

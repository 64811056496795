import React from 'react';
import {Product} from 'interfaces/products/product.interface';
import {Instrument} from 'interfaces/instruments/instrument.interface';
import {Job} from 'interfaces/jobs/jobs.interface';
import {Service} from 'interfaces/services/service.interface';
import ActivePromotionItem from './ActivePromotionItem';

export type Props = {
  products: Product[];
  instruments: Instrument[];
  jobs: Job[];
  services: Service[];
};

const ActivePromotionList = ({products, instruments, jobs, services}: Props) => (
  <div className='my-5 space-y-5'>
    {products.map((product: Product) => (
      <ActivePromotionItem key={Math.random()} item={product} product={product} />
    ))}
    {instruments.map((instrument: Instrument) => (
      <ActivePromotionItem key={Math.random()} item={instrument} instrument={instrument} />
    ))}
    {jobs.map((job: Job) => (
      <ActivePromotionItem key={Math.random()} item={job} job={job} />
    ))}
    {services.map((service: Service) => (
      <ActivePromotionItem key={Math.random()} item={service} service={service} />
    ))}
  </div>
);

export default ActivePromotionList;

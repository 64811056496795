import {instrumentsService} from 'api/instruments/instruments.service';
import {productsService} from 'api/products/products.service';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';
import {refreshCounterState} from 'states/common/common.state';

interface Props {
  name?: string;
  linkTo?: any;
  alt?: string;
  src?: any;
  subCatId?: number;
  onClick?: () => void;
  type?: string;
  count?: number;
}

const UIOrangeCategoryBox: React.FC<Props> = ({
  name,
  alt,
  linkTo,
  src,
  subCatId,
  onClick,
  type,
  count,
}) => {
  const [itemsCount, setItemsCount] = useState<number>();
  const isDark2 = useRecoilValue(isDarkTheme);
  const refreshCounter = useRecoilValue(refreshCounterState);

  useEffect(() => {
    typeof count === 'number' && setItemsCount(count);
    subCatId &&
      (type === 'instruments'
        ? instrumentsService.getInstruments(subCatId).then((response) => {
            setItemsCount(response.data.length);
          })
        : productsService.getProducts(subCatId).then((response) => {
            setItemsCount(response.data.length);
          }));
  }, [refreshCounter]);

  return (
    <Link to={linkTo!} className=''>
      <div className='flex justify-center mx-4'>
        <div
          className='border-2 rounded-xl shadow-lg
   shadow-orange-main border-orange-main w-full h-32 mt-6 xl:w-2/3 xl:h-44'
          onClick={onClick}
        >
          <div className='w-full flex flex-row h-32 xl:h-44'>
            <div
              className={`w-2/3 flex items-center px-10
           xl:text-lg font-medium ${isDark2 ? 'text-white' : 'text-black'}`}
            >
              {name}
            </div>
            <div className='w-1/3 bg-yellow-accent relative flex rounded-r-lg justify-start'>
              <div className='absolute'>
                {typeof itemsCount === 'number' && (
                  <div
                    className='bg-white border border-yellow-accent rounded-xl mt-10 px-2 py-0.5
                    w-12 relative right-9 text-center text-black xl:text-lg xl:mt-16'
                  >
                    {itemsCount}
                  </div>
                )}
              </div>
              <div className='flex justify-center w-full p-5'>
                <img src={src} alt={alt} style={{padding: 2}} className={`w-24 xl:w-36`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default UIOrangeCategoryBox;

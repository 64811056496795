import React, {useState, useEffect} from 'react';
import {getAllMessages, setMessagesInStorage} from 'api/chat/chat.service';
import {useIonRouter} from '@ionic/react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {refreshCounterState} from 'states/common/common.state';
import LoadingIndicator from 'components/uiLoading/UILoadingIndicator';
import {UIImage} from 'components/uiImages/UIImage';
import {newMessagesState} from 'states/messages/message.state';
import {isDarkTheme} from 'states/account/account.state';
import {dateHandler} from 'utils/dateHandler';

interface messageUser {
  chatId: number;
  entityId: number;
  item: string;
  lastMessage: string;
  lastDate: string;
  name: string;
  photo: string;
}

const MessagesList = () => {
  const [showFirstMessage, setShowFristMessage] = useState<messageUser[]>();
  const [loading, setLoading] = useState(false);
  const shouldRefreshData = useRecoilValue(refreshCounterState);
  const setNewMessagesState = useSetRecoilState(newMessagesState);
  const router = useIonRouter();

  useEffect(() => {
    setLoading(true);
    getAllMessages().then((res) => {
      setShowFristMessage(res.data.chats);
      setMessagesInStorage(res.data);
      setNewMessagesState(false);
    });
    setLoading(false);
  }, [shouldRefreshData]);

  if (loading) {
    return <LoadingIndicator />;
  }
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <div className='mt-2'>
      {showFirstMessage &&
        showFirstMessage.map((field: messageUser) => {
          return (
            <div key={field.chatId} className='w-full p-4'>
              <button
                className='w-full'
                onClick={() =>
                  router.push(
                    `/message/${field.item.toLowerCase()}s/${field.entityId}/${field.name}/${
                      field.chatId
                    }`
                  )
                }
              >
                <div className='h-20 flex'>
                  <div className='h-20 w-20'>
                    <UIImage
                      photoLink={field.photo}
                      type='messages'
                      customClassName='h-20 w-20 rounded-md'
                    />
                  </div>
                  <div className='w-5/6 h-20 pl-2'>
                    <div className='flex-col pl-2'>
                      <div
                        className={`py-1 flex justify-start
                       ${isDark2 ? 'text-white' : 'text-gray-600'}`}
                      >
                        <b className={`${isDark2 ? 'text-white' : 'text-gray-600'}`}>
                          {field.name}
                        </b>
                      </div>
                      <div
                        className={`pl-1 break-all flex text-left
                        ${isDark2 ? 'text-white' : 'text-black'}`}
                      >
                        {field.lastMessage.length > 85
                          ? field.lastMessage.slice(0, 85) + '...'
                          : field.lastMessage}
                      </div>
                      <p className='text-xs text-gray-700 mt-6 text-left'>
                        {dateHandler(field.lastDate, '', true)}
                      </p>
                    </div>
                  </div>
                </div>
              </button>
              <hr className='bg-gray-400 mx-4 mt-4' />
            </div>
          );
        })}
    </div>
  );
};

export default MessagesList;

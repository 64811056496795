import React, {useEffect} from 'react';
import {IonIcon} from '@ionic/react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {star, starOutline} from 'ionicons/icons';
import {Instrument} from 'interfaces/instruments/instrument.interface';
import {accountState, isDarkTheme} from 'states/account/account.state';
import {accountService} from 'api/account/account.service';
import {Service} from 'interfaces/services/service.interface';
import {Product} from 'interfaces/products/product.interface';

type Props = {
  setIsFavorite: Function;
  isFavorite?: boolean;
  fields: Instrument | Service | Product;
  favHandler: Function;
};

const FavouritesButton: React.FC<Props> = ({favHandler, fields, isFavorite, setIsFavorite}) => {
  const setAccountData = useSetRecoilState(accountState);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await accountService.getCurrentUser();
        setAccountData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, [isFavorite]);
  const isDark2 = useRecoilValue(isDarkTheme);

  return (
    <button
      onClick={async () => {
        fields.id && (await favHandler(fields.id));
        setIsFavorite(!isFavorite);
      }}
    >
      <IonIcon
        icon={isFavorite ? star : starOutline}
        className={`h-6 w-6 ${isDark2 ? 'text-[#F0A249] ' : 'text-yellow-400 '}`}
      />
    </button>
  );
};

export default FavouritesButton;

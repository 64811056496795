import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar} from '@ionic/react';
import {instrumentsService} from 'api/instruments/instruments.service';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UIOrangeCategoryBox from 'components/uiContainer/UIOrangeCategoryBox';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import UILogo from 'components/uiLogo/UILogo';
import UIResponsivePadding from 'components/uiResponsive/UIResponsivePadding';
import {getCategoryIcon} from 'utils/categoryIconProvider';
import {Subcategory} from 'interfaces/instruments/instrument.interface';
import React, {useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';
import UIButtonSearch from 'components/uiButtons/UIButtonSearch';
import UILoadingIndicator from 'components/uiLoading/UILoadingIndicator';

export const ProductsSubCatToolsListPage: React.FC = () => {
  const [subCategories, setCategories] = useState<Subcategory[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>();
  const isDark2 = useRecoilValue(isDarkTheme);
  useEffect(() => {
    setIsLoading(true);
    instrumentsService.getCategories('buy').then((response) => {
      response.data.results.filter(
        (category: any) => category.id === 2 && setCategories(category.subcategories)
      );
      setIsLoading(false);
    });
  }, []);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
          <IonButtons slot='end'>
            <UIButtonSearch itemsType='products' category={'2'} />
          </IonButtons>
        </IonToolbar>
        <UITopTextBar>Werkzeug Kaufen</UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        {isLoading ? (
          <UILoadingIndicator />
        ) : (
          <UIResponsivePadding>
            <div className='mb-10'>
              {subCategories.map((subCat, id) => (
                <UIOrangeCategoryBox
                  key={subCat.id}
                  name={subCat.name}
                  src={getCategoryIcon(id + 1)}
                  alt={subCat.name}
                  subCatId={subCat.id}
                  type='products'
                  linkTo={`/products/subcategories/${subCat.id}/Werkzeug/${subCat.name}`}
                />
              ))}
            </div>
          </UIResponsivePadding>
        )}
      </IonContent>
    </IonPage>
  );
};

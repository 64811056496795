/* eslint-disable max-len */
import React, {useState} from 'react';
import {Redirect, Route} from 'react-router';
import {map, star, person, chatbubbles, home} from 'ionicons/icons';
import {IonBadge, IonIcon, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs} from '@ionic/react';
import {useRecoilValue} from 'recoil';
import AccountDatail from 'pages/account/accountDetail/AccountDetail';
import FavoritesPage from 'pages/common/favourites/FavoritesPage';
import MapPage from 'pages/common/map/MapPage';
import InstrumentsListPage from 'pages/instruments/instrumentsList/InstrumentsListPage';
import JobListPage from 'pages/jobs/jobsList/JobsListPage';
import ChatsListPage from 'pages/messages/chat/chatList/ChatsListPage';
import ForumList from 'pages/messages/forum/forumList/ForumList';
import {ServicesListPage} from 'pages/services/servicesList/ServicesListPage';
import {ServiceDetailPage} from 'pages/services/serviceDetail/ServiceDetailPage';
import InstrumentAddPage from 'pages/instruments/instrumentAdd/InstrumentAddPage';
import AccountEditPage from 'pages/account/accountEdit/AccountEdit';
import ServiceAddPage from 'pages/services/serviceAdd/ServiceAddPage';
import HomePage from 'pages/common/home/HomePage';
import AgricultureMenuPage from 'pages/common/agriculture/AgricultureMenuPage';
import PageCommunity from 'pages/common/community/CommunityPage';
import SearchPage from 'pages/common/search/SearchPage';
import JobAddPage from 'pages/jobs/jobAdd/JobAddPage';
import JobDetail from 'pages/jobs/jobDetail/JobDetailPage';
import {ChatDetail} from 'pages/messages/chat/chatDetail/ChatDetail';
import AccountServicesListPage from 'pages/account/accountServicesList/AccountServicesListPage';
import PromotionPaymentPage from 'pages/promotions/promotionPayment/PromotionPaymentPage';
import {InstrumentDetailPage} from 'pages/instruments/instrumentDetail/InstrumentDetailPage';
import AccountInstrumentsListPage from 'pages/account/accountInstrumentsList/AccountInstrumentsListPage';
import ProductsAddPage from 'pages/products/addProducts/ProductsAddPage';
import {DetailProductsPage} from 'pages/products/detailProducts/DetailProductsPage';
import ReportPage from 'pages/common/report/ReportPage';
import {PromotionsCostsListPage} from 'pages/promotions/promotionsCostsList/PromotionsCostsListPage';
import FormPost from 'forms/forum/post.form';
import DIYPage from 'pages/messages/diy/diyList/DIYPage';
import DIYDetailPage from 'pages/messages/diy/diyDetail/DIYDetailPage';
import PostForumDetailsPage from 'pages/messages/forum/forumDetail/PostForumDetailsPage';
import PromotionsItemsListPage from 'pages/promotions/promotionsItemsList/PromotionsItemsListPage';
import ProductsListPage from 'pages/products/productsList/ProductsListPage';
import {PurchasePage} from 'pages/common/purchase/PurchasePage';
import {newMessagesState} from 'states/messages/message.state';
import AccountPleaseSignInPage from 'pages/account/accountPleaseSignIn/AccountPleaseSignInPage';
import SearchMapPage from 'pages/common/search/SearchMapPage';
import PromotionsPaymentResultPage from 'pages/promotions/promotionPayment/PromotionsPaymentResultPage';
import NotificationsEdit from 'pages/account/notifications/NotificationsEdit';
import ContactDataVisability from 'pages/account/contactDataVisibility/ContactDataVisibility';
import PriceListPage from 'pages/common/priceList/PriceListPage';
import JobsMenuPage from 'pages/jobs/jobsMenu/JobsMenuPage';
import ServicesMenuPage from 'pages/services/servicesMenu/ServicesMenuPage';
import ToolsMenuPage from 'pages/common/tools/ToolsMenuPage';
import MachinesMenuPage from 'pages/common/machines/MachinesMenuPage';
import ContactPage from 'pages/common/contact/ContactPage';
import AccountTypeEdit from 'pages/account/accountEdit/AccountTypeEdit';
import {isDarkTheme} from 'states/account/account.state';
import {InstrumentsSubCatAgricultureListPage} from 'pages/instruments/instrumentsSubcategoriesList/InstrumentsSubCatAgricultureListPage';
import {InstrumentsSubCatMachinesListPage} from 'pages/instruments/instrumentsSubcategoriesList/InstrumentsSubCatMachinesListPage';
import {InstrumentsSubCatToolsListPage} from 'pages/instruments/instrumentsSubcategoriesList/InstrumentsSubCatToolsListPage';
import {ProductsSubCatToolsListPage} from 'pages/products/productsSubcategoriesList/ProductsSubCatToolsListPage';
import {ProductsSubCatMachinesListPage} from 'pages/products/productsSubcategoriesList/ProductsSubCatMachinesListPage';
import {ProductsSubCatAgricultureListPage} from 'pages/products/productsSubcategoriesList/ProductsSubCatAgricultureListPage';
import EditPhotosPage from 'pages/common/editPhotos/EditPhotosPage';
import AgricultureServicesPage from 'pages/common/agriculture/AgricultureServicesPage';
import UserItemsPage from 'pages/common/user/UserItemsPage';

const UserRoutes: React.FC = () => {
  const isDark2 = useRecoilValue(isDarkTheme);
  const anyNewMessages = useRecoilValue(newMessagesState);

  const [selectedTab, setSelectedTab] = useState<string>('home');

  const path = location.pathname.split('/')[1];

  if (path == 'home' && selectedTab !== 'home') {
    setSelectedTab('home');
  } else if (path == 'community' && selectedTab !== 'community') {
    setSelectedTab('community');
  } else if (path == 'map' && selectedTab !== 'map') {
    setSelectedTab('map');
  } else if (path == 'favorites' && selectedTab !== 'favorites') {
    setSelectedTab('favorites');
  } else if (path == 'profile' && selectedTab !== 'profile') {
    setSelectedTab('profile');
  }

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route path='/chat'>
          <ChatsListPage />
        </Route>
        <Route path='/forum'>
          <ForumList />
        </Route>
        <Route path='/map'>
          <MapPage />
        </Route>
        <Route path='/favorites'>
          <FavoritesPage />
        </Route>
        <Route path='/profile'>
          <AccountDatail />
        </Route>
        <Route path='/profile-edit'>
          <AccountEditPage />
        </Route>
        <Route path='/instruments/categories/1/loan'>
          <InstrumentsSubCatToolsListPage />
        </Route>
        <Route path='/instruments/categories/2/loan'>
          <InstrumentsSubCatMachinesListPage />
        </Route>
        <Route path='/instruments/categories/3/loan'>
          <InstrumentsSubCatAgricultureListPage />
        </Route>
        <Route path='/instruments/categories/1/buy'>
          <ProductsSubCatToolsListPage />
        </Route>
        <Route path='/instruments/categories/2/buy'>
          <ProductsSubCatMachinesListPage />
        </Route>
        <Route path='/instruments/categories/3/buy'>
          <ProductsSubCatAgricultureListPage />
        </Route>
        <Route path='/products/subcategories/:subCatId/:catName/:subCatName'>
          <ProductsListPage />
        </Route>
        <Route path='/instruments/subcategories/:subCatId/:catName/:subCatName'>
          <InstrumentsListPage />
        </Route>
        <Route path='/instruments-my/:listType'>
          <AccountInstrumentsListPage />
        </Route>
        <Route path='/instruments-add'>
          <InstrumentAddPage />
        </Route>
        <Route path='/products-add'>
          <ProductsAddPage />
        </Route>
        <Route path='/instrument/:catName/:subCatName/:instrumentId'>
          <InstrumentDetailPage />
        </Route>
        <Route path='/product/:catName/:subCatName/:instrumentId'>
          <DetailProductsPage />
        </Route>
        <Route path={'/photos/:itemId/:type/'}>
          <EditPhotosPage />
        </Route>
        <Route path='/promotions'>
          <PromotionsItemsListPage />
        </Route>
        <Route path='/offers'>
          <ServicesListPage />
        </Route>
        <Route path='/agricultural-offers'>
          <AgricultureServicesPage />
        </Route>
        <Route path='/offers-my'>
          <AccountServicesListPage />
        </Route>
        <Route path='/offers-add/:agricultureCategory'>
          <ServiceAddPage />
        </Route>
        <Route path='/service/:offerId'>
          <ServiceDetailPage />
        </Route>
        <Route path='/search/:itemsType/:category'>
          <SearchPage />
        </Route>
        <Route path='/search-map'>
          <SearchMapPage />
        </Route>
        <Route path='/jobs'>
          <JobListPage />
        </Route>
        <Route path='/jobs-add'>
          <JobAddPage />
        </Route>
        <Route path='/job/:jobId'>
          <JobDetail />
        </Route>
        <Route path='/jobs-menu'>
          <JobsMenuPage />
        </Route>
        <Route path='/services-menu'>
          <ServicesMenuPage />
        </Route>
        <Route path='/promote/:type/:id'>
          <PromotionsCostsListPage />
        </Route>
        <Route path='/purchase/:type/:id'>
          <PurchasePage />
        </Route>
        <Route path='/loan/instrument/:instrumentId'>
          <InstrumentDetailPage />
        </Route>
        <Route path='/agriculture-menu'>
          <AgricultureMenuPage />
        </Route>
        <Route path='/community'>
          <PageCommunity />
        </Route>
        <Route path='/forum'>
          <ForumList />
        </Route>
        <Route path='/forum-add'>
          <FormPost />
        </Route>
        <Route path='/forum-post/:id'>
          <PostForumDetailsPage />
        </Route>
        <Route path='/report/:reportedElement/:reportedElementId'>
          <ReportPage />
        </Route>
        <Route path='/message/:type/:id/:itemName/:conversationId'>
          <ChatDetail />
        </Route>
        <Route path='/diys'>
          <DIYPage />
        </Route>
        <Route path='/sign-in'>
          <AccountPleaseSignInPage />
        </Route>
        <Route path='/diy/:id'>
          <DIYDetailPage />
        </Route>
        <Route path={'/notifications'}>
          <NotificationsEdit />
        </Route>
        <Route path={'/contact-data-visability'}>
          <ContactDataVisability />
        </Route>
        <Route path='/payment-result/:authToken/:paymentId/:cartId'>
          <PromotionsPaymentResultPage />
        </Route>
        <Route path='/payment/:paymentId/:authToken/:checkoutId/:cartId'>
          <PromotionPaymentPage />
        </Route>
        <Route path='/pricelist'>
          <PriceListPage />
        </Route>
        <Route path='/tools-menu'>
          <ToolsMenuPage />
        </Route>
        <Route path='/machines-menu'>
          <MachinesMenuPage />
        </Route>
        <Route path='/contact'>
          <ContactPage />
        </Route>
        <Route path='/account-type-edit'>
          <AccountTypeEdit />
        </Route>
        <Route path='/' exact={true}>
          <Redirect to='/home' />
        </Route>
        <Route path='/login' render={() => <Redirect to='/home' />} exact />
        <Route path='/home'>
          <HomePage />
        </Route>
        <Route path='/user-items/:id/:name/'>
          <UserItemsPage />
        </Route>
      </IonRouterOutlet>
      <IonTabBar
        color={`${isDark2 ? 'dark' : 'light'}`}
        onIonTabsWillChange={(e) => setSelectedTab(e.detail.tab)}
        className={`lg:hidden  ${isDark2 ? 'bg-[#5B6267]' : 'bg-gray-500 '}`}
        slot='bottom'
      >
        <IonTabButton
          className={`text-white ${isDark2 ? 'text-[#CED0D1]]' : 'bg-gray-500 '}`}
          tab='home'
          href='/home'
        >
          <IonIcon color={selectedTab === 'home' ? 'primary' : ''} icon={home} />
        </IonTabButton>
        <IonTabButton
          className={`text-white ${isDark2 ? 'text-[#CED0D1]]' : 'bg-gray-500 '}`}
          tab='community'
          href='/community'
        >
          <IonIcon color={selectedTab === 'community' ? 'primary' : ''} icon={chatbubbles} />
          {anyNewMessages && <IonBadge className='bg-red-500' />}
        </IonTabButton>
        <IonTabButton
          className={`text-white ${isDark2 ? 'text-[#CED0D1]]' : 'bg-gray-500 '}`}
          tab='map'
          href='/map'
        >
          <IonIcon color={selectedTab === 'map' ? 'primary' : ''} icon={map} />
        </IonTabButton>
        <IonTabButton
          className={`text-white ${isDark2 ? 'text-[#CED0D1]]' : 'bg-gray-500 '}`}
          tab='favorites'
          href='/favorites'
        >
          <IonIcon color={selectedTab === 'favorites' ? 'primary' : ''} icon={star} />
        </IonTabButton>
        <IonTabButton
          className={`text-white ${isDark2 ? 'text-[#CED0D1]]' : 'bg-gray-500 '}`}
          tab='profile'
          href='/profile'
        >
          <IonIcon color={selectedTab === 'profile' ? 'primary' : ''} icon={person} />
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};
export default UserRoutes;

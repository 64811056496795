import React, {useEffect, useState} from 'react';
import {IonContent, IonPage} from '@ionic/react';
import {useHistory, useParams} from 'react-router';
import UIOrangeButton from 'components/uiButtons/UIOrangeButton';
import {deleteCart, finalizePromotion} from 'api/promotions/promotions.service';
import {isDarkTheme} from 'states/account/account.state';
import {useRecoilValue} from 'recoil';
import {authService} from 'api/account/auth.service';

export const PromotionPaymentResultPage = () => {
  const {authToken, paymentId, cartId}: any = useParams();
  const [resultText, setResultText] = useState('Laden...');
  const [isSuccess, setIsSuccess] = useState(false);
  const history = useHistory();

  let isMounted = true;

  useEffect(() => {
    const getPaymentConfirm = async () => {
      if (authToken) {
        authService.loginViaAppToken(authToken);
      }

      if (resultText == 'Laden...') {
        await finalizePromotion(paymentId!)
          .then(() => {
            if (isMounted) {
              setIsSuccess(true);
              setResultText('Bezahlung erfolgreich!');
              deleteCart(cartId);
            }
          })
          .catch(() => {
            if (isMounted) {
              setResultText('Bezahlung fehlgeschlagen!');
              deleteCart(cartId);
            }
          });
      }

      return () => {
        isMounted = false;
      };
    };

    getPaymentConfirm();
  }, []);

  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <IonPage>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <div className='h-full space-y-10 flex flex-col justify-center items-center'>
          <p className='text-center w-full'>
            Wenn Sie die Zahlung über die mobile Anwendung vorgenommen haben, können Sie den Browser
            schließen
          </p>
          <p className='text-2xl text-center underline w-full'>
            {isSuccess ? 'Bezahlung erfolgreich!' : resultText}
          </p>
          <UIOrangeButton type={'button'} title='Home' onClick={() => history.replace('/')} />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PromotionPaymentResultPage;

import {IonIcon, IonSelect, IonSelectOption, IonTextarea, useIonRouter} from '@ionic/react';
import UIInput from 'components/uiInputs/UIInput';
import AddPhotos from 'components/uiAddPhoto/UIAddPhotos';
import UIYellowButton from 'components/uiButtons/UIYellowButton';
import {OrangeLine} from 'components/uiContainer/UIOrangeLine';
import {BFormAddressField} from 'forms/common/places/BFormAddressField';
import {Job, JobType, jobTypeTranslated} from 'interfaces/jobs/jobs.interface';
import {callOutline, eyeOutline, mailOpenOutline, star} from 'ionicons/icons';
import React, {useState, useEffect, useRef} from 'react';
import {useForm} from 'react-hook-form';
import {useParams} from 'react-router';
import {jobsService} from 'api/jobs/jobs.service';
import LineColored from '../instruments/LineColored';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {refreshCounterState} from 'states/common/common.state';
import {isDarkTheme} from 'states/account/account.state';

const JobOfferEditForm: React.FC = () => {
  const setRefreshCount = useSetRecoilState(refreshCounterState);
  const {handleSubmit, register, setValue, control} = useForm();
  const {jobId: jobId} = useParams<{jobId: string}>();
  const [apiResultMessage, setApiResultMessage] = useState('');
  const [addressPlaceholder, setAddressPlaceholder] = useState<string>('Wähle einen Adresse');
  const [oldPhotos, setOldPhotos] = useState<{photo: string}[]>();
  const [jobTypeIdx, setJobTypeIdx] = useState<number>();
  const [job, setJob] = useState<Job>();
  const inputRef = useRef<any>();

  const router = useIonRouter();

  useEffect(() => {
    jobsService.getDetails(+jobId).then((response) => {
      setJob(response.data);
      setValue('name', response.data.name);
      setValue('jobOffer', response.data.jobOffer);
      setValue('salary', response.data.salary);
      setValue('email', response.data.email);
      setValue('description', response.data.description);
      setValue('lat', response.data.lat.toString());
      setValue('lng', response.data.lng.toString());
      setValue('postCode', response.data.postCode);
      setValue('Address', response.data.Address);
      setValue('location', response.data.location);
      setValue('position', response.data.position);
      setValue('phoneNumber', response.data.phoneNumber);

      if (response.data.jobType === jobTypeTranslated(0)) {
        setJobTypeIdx(0);
      } else if (response.data.jobType === jobTypeTranslated(1)) {
        setJobTypeIdx(1);
      } else {
        setJobTypeIdx(2);
      }
      setOldPhotos(response.data.photos);
      setAddressPlaceholder(response.data.location);
    });
  }, [jobId]);

  const onSubmit = async (data: Job) => {
    if (oldPhotos) {
      data.not_delete_photos = [];
      oldPhotos.forEach((photo) => {
        data.not_delete_photos.push(photo.photo.substring(33, photo.photo.length));
      });
    }

    data.jobType = jobTypeTranslated(jobTypeIdx!);
    data.photos && (data.photos = data.photos[0].photo);
    if (typeof data.photos === 'undefined') delete data.photos;
    if (data.Address) {
      data.location = data.Address.label;
      data.postCode = data.Address.postCode;
      data.lat = data.Address.lat.toFixed(7);
      data.lng = data.Address.lng.toFixed(7);
      setAddressPlaceholder(data.location!);
    }

    jobsService
      .updateJob(data, jobId)
      .then((res) => {
        setApiResultMessage('Erfolg!');
        setRefreshCount((v) => v + 1);
        if (!job!.issuedPromoted) {
          router.push(`/purchase/jobs/${job!.id}`);
          setApiResultMessage('');
        }
      })
      .catch(() => {
        setApiResultMessage('Error!');
      });
  };

  const handleInputChange = (e: any) => {
    if (e == null) {
      return null;
    }

    setJobTypeIdx(e.detail.value);
  };
  const isDark2 = useRecoilValue(isDarkTheme);

  return (
    <form className='h-full pb-3 font-sans' onSubmit={handleSubmit(onSubmit)}>
      <div
        className={`border-2 border-orange-main rounded-md pb-10
       ${isDark2 ? 'bg-[#393939] text-white' : 'bg-[#F8F8F8] text-black'}`}
      >
        <div className={`w-full h-8 ${isDark2 ? 'bg-[#F0A249] ' : 'bg-orange-main '}`} />
        <div className={`flex my-4 ${isDark2 ? 'bg-[#393939]' : 'bg-[#F8F8F8]'}`}>
          <div
            className='flex-col w-auto space-y-2 ml-2 text-sm'
            onClick={() => router.push(`/photos/${jobId}/jobs`)}
          >
            <AddPhotos
              photosType={'jobs'}
              control={control}
              photos={oldPhotos}
              setPhotos={setOldPhotos}
              name='photos'
            />
          </div>
          <div
            className={`flex-col w-3/5 space-y-2 mx-4 text-sm $${
              isDark2 ? 'bg-[#393939] text-white' : ' bg-[#F8F8F8] text-black'
            }`}
          >
            Firmenname:{' '}
            <UIInput
              required='required'
              customClassName={`w-full text-sm break-all
              ${isDark2 ? 'bg-[#393939] text-white' : ' bg-[#F8F8F8] text-black'}`}
              placeholder='Dein Firmenname'
              register={register}
              name='name'
            />
            <LineColored />
            Stellenanzeige:{' '}
            <UIInput
              required='required'
              customClassName={`w-full text-sm break-all
              ${isDark2 ? 'bg-[#393939] text-white' : ' bg-[#F8F8F8] text-black'}`}
              placeholder='Bauarbeiter'
              register={register}
              name='jobOffer'
            />
            <LineColored />
            Berufsart:{' '}
            <UIInput
              required='required'
              customClassName={`w-full text-sm break-all
              ${isDark2 ? 'bg-[#393939] text-white' : ' bg-[#F8F8F8] text-black'}`}
              placeholder='z.B Metalltechnikerin'
              register={register}
              name='position'
            />
            <LineColored />
            Bezahlung: (pro Tag/ pro Stunde usw.)
            <div className='flex'>
              <UIInput
                required='required'
                customClassName={`w-full text-sm 
                ${isDark2 ? 'bg-[#393939] text-white' : ' bg-[#F8F8F8] text-black'}`}
                placeholder='2.400,00 €/Monat'
                register={register}
                name='salary'
              />
            </div>
            <LineColored />
            <div className='mt-8 w-full'>
              <p>Arbeitszeit:</p>
              <IonSelect
                okText='Weiter'
                cancelText='Abbrechen'
                onIonChange={(e) => handleInputChange(e)}
                ref={inputRef}
                value={jobTypeIdx}
              >
                {JobType.map((job: any) => (
                  <IonSelectOption key={job} value={job.value}>
                    {job.label}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </div>
          </div>
        </div>
        <OrangeLine name='Kontakt' />
        <div className='m-3 text-sm space-y-2'>
          <div className='w-screen'>
            <div
              className={`flex space-x-1 w-full
          ${isDark2 ? 'bg-[#393939] text-white' : ' bg-[#F8F8F8] text-black'}`}
            >
              <IonIcon icon={callOutline} className='mt-0.5' />
              <UIInput
                required='required'
                customClassName={`bg-transparent ${
                  isDark2 ? 'bg-[#393939] text-white' : ' bg-[#F8F8F8] text-black'
                }`}
                placeholder='0660 1234567'
                register={register}
                name='phoneNumber'
                type='tel'
                maxLength={16}
                minLength={9}
              />
            </div>
            <div
              className={`flex space-x-1 
          ${isDark2 ? 'bg-[#393939] text-white' : ' bg-[#F8F8F8] text-black'}`}
            >
              <IonIcon icon={mailOpenOutline} className='mt-0.5' />
              <UIInput
                required='required'
                customClassName='bg-transparent w-3/4'
                placeholder='beispiel@email.com'
                register={register}
                name='email'
              />
            </div>
          </div>
        </div>
        <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
          <div className='text-orange-main text-sm'>Adresse</div>
          <div className='bg-orange-main w-full h-px'></div>
        </div>
        <div className='mx-2 mt-2'>
          <BFormAddressField
            control={control}
            fieldName='Address'
            key={Math.random()}
            placeholder={addressPlaceholder}
            latLng={{lat: +job?.lat, lng: +job?.lng}}
          />
        </div>
        <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
          <div className={`${isDark2 ? 'text-sm text-[#F0A249]' : 'text-orange-main text-sm '}`}>
            Beschreibung
          </div>
          <div
            className={`${isDark2 ? 'w-4/5 h-px bg-[#F0A249]' : 'bg-orange-main w-4/5 h-px'}`}
          ></div>
        </div>
        <IonTextarea
          maxlength={1000}
          className={`input break-words px-3 text-sm ${isDark2 ? 'text-white ' : 'text-black'}`}
          placeholder='Beschreibe die Aufgaben, Anforderungen und Aussichten'
          {...register('description')}
          name='description'
        />
        <div
          className={`flex text-sm place-content-center h-10
        ${isDark2 ? 'text-gray-300' : 'text-black]'}`}
        >
          <div className='ml-1'>
            <div className='flex justify-between ml-2'>
              <p className={`${isDark2 ? 'text-gray-300 pr-2' : 'text-black pr-2'}`}>
                Anzeige verfügbar bis:{' '}
                {job?.issuedPromoted && job?.issuedPromotedUntil
                  ? new Date(job?.issuedPromotedUntil).toLocaleDateString()
                  : '-'}
              </p>
            </div>
            <div className='flex ml-2'>
              <p className={`${isDark2 ? 'text-gray-300 pr-2' : 'text-black pr-2'}`}>
                Anzeige Hervorhebung verfügbar bis:{' '}
                {job?.promoted && job?.promotedUntil
                  ? new Date(job?.promotedUntil).toLocaleDateString()
                  : '-'}
              </p>
            </div>
            <div className='flex ml-2'>
              <p className={`${isDark2 ? 'text-gray-300 pr-2' : 'text-black pr-2'}`}>
                Anzeige Logo verfügbar bis:{' '}
                {job?.mapPromoted && job?.mapPromotedUntil
                  ? new Date(job?.mapPromotedUntil).toLocaleDateString()
                  : '-'}
              </p>
            </div>
          </div>
          <div className='flex justify-center mt-2'>
            <IonIcon
              icon={star}
              className={`h-4 w-5 
                  ${isDark2 ? 'text-[#F0A249]' : 'text-yellow-400 '}`}
            />
            <p className='pr-5 text-md'>{job?.countFavourite ? job?.countFavourite : 0}</p>
            <IonIcon icon={eyeOutline} className='h-5 w-5 pr-1' />
            <p className='pr-7'>{job?.visitors}</p>
          </div>
        </div>
      </div>
      <div className='flex flex-col justify-end text-white'>
        <p className='text-yellow-accent text-center font-bold mb-5'>{apiResultMessage}</p>
      </div>
      <div className='flex flex-col py-5 justify-end text-white'>
        <UIYellowButton type='submit' text='Weiter' />
      </div>
    </form>
  );
};

export default JobOfferEditForm;

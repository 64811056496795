import React from 'react';
import UILogo from 'components/uiLogo/UILogo';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  useIonAlert,
  useIonRouter,
} from '@ionic/react';
import {useRecoilValue} from 'recoil';
import {Browser} from '@capacitor/browser';
import {useHistory} from 'react-router';
import {authService} from 'api/account/auth.service';
import {accountService} from 'api/account/account.service';
import UIYellowButton from 'components/uiButtons/UIYellowButton';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import FormProfileEdit from 'forms/account/formProfile/profile.form';
import {isDarkTheme} from 'states/account/account.state';

const AccountEditPage: React.FC = () => {
  const router = useIonRouter();
  const history = useHistory();
  const [presentAlert] = useIonAlert();
  const handleDeleteUser = () => {
    presentAlert({
      header: 'Sind Sie sicher, dass Sie Ihr Konto löschen wollen?',
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Nicht',
          cssClass: 'alert-button-cancel',
        },
        {
          text: 'Ja',
          cssClass: 'alert-button-confirm',
          handler: async () => {
            try {
              await accountService.deleteCurrentUser();
              authService.logout();
              history.replace('/');
            } catch (error) {
              console.log(error);
            }
          },
        },
      ],
    });
  };

  const isDark2 = useRecoilValue(isDarkTheme);

  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar>Profil Einstellungen</UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <div>
          <div className='flex justify-center'>
            <FormProfileEdit />
          </div>
          <div className='flex justify-center'>
            <div className='lg:w-1/3 w-full mb-5 space-y-3'>
              <UIYellowButton
                text='Kontotyp Bearbeiten'
                onClick={() => router.push(`/account-type-edit`)}
              />
              <UIYellowButton
                text='Benachrichtigungen'
                onClick={() => router.push(`/notifications`)}
              />
              <UIYellowButton
                text='Sichtbarkeit von Kontaktdaten'
                onClick={() => router.push(`/contact-data-visability`)}
              />
              <UIYellowButton
                text='AGB`s'
                onClick={() => Browser.open({url: 'https://app.wubmal.com/agb.html'})}
              />
              <UIYellowButton
                text='Datenschutzerklärung'
                onClick={() =>
                  Browser.open({
                    url: 'https://app.wubmal.com/datenschutz.html',
                  })
                }
              />
              <UIYellowButton
                text='Impressum'
                onClick={() =>
                  Browser.open({
                    url: 'https://app.wubmal.com/impressum.html',
                  })
                }
              />
              <UIYellowButton
                text='Ausloggen'
                onClick={() => {
                  authService.logout();
                  history.replace('/');
                }}
              />
              <div className='pt-5'>
                <UIYellowButton text='Konto löschen' onClick={handleDeleteUser} />
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AccountEditPage;

import client from '../common/client';
import {RegisterRequest} from 'interfaces/account/registerRequest.interface';
import {CheckCodeRequest} from 'interfaces/account/checkCodeRequest.interface';
import {NewPasswordRequest} from 'interfaces/account/newPasswordRequest.interface';

type CheckEmailAvailabilityResponse = {
  available: boolean;
};

const checkEmailAvailability = async (email: string): Promise<boolean> => {
  const response = await client.post<CheckEmailAvailabilityResponse>(
    '/auth/check-email-availability/',
    {email}
  );
  return response.data.available;
};

const register = async (data: RegisterRequest) => {
  return client.post('/auth/register/', data);
};

const resendSms = async (phoneNumber: string) => {
  return client.post('/auth/resend-sms/', {phone_number: phoneNumber});
};

const activateAccount = (email: string, activationCode: string) => {
  return client.post('/auth/activate-account/', {email, activationCode});
};

const forgetPasswordMail = async (email: string) => {
  return client.post('/auth/initialize-reset-password/', email);
};

const checkCodeValidation = async (data: CheckCodeRequest) => {
  return client.post('/auth/verify-reset-password/', data);
};

const newPasswordValidation = async (data: NewPasswordRequest) => {
  return client.post('/auth/reset-password/', data);
};

const getCurrentUser = () => {
  const response = client.get('/users/current-user/');
  return response;
};
const deleteCurrentUser = () => {
  const response = client.delete('/users/current-user/');
  return response;
};

export const accountService = {
  checkEmailAvailability,
  register,
  activateAccount,
  forgetPasswordMail,
  checkCodeValidation,
  newPasswordValidation,
  getCurrentUser,
  deleteCurrentUser,
  resendSms,
};

import React from 'react';
import {IonBackButton} from '@ionic/react';
import {chevronBackSharp} from 'ionicons/icons';
import {isPlatform} from '@ionic/core';

const UIButtonBack: React.FC = () => (
  <IonBackButton
    icon={chevronBackSharp}
    text={isPlatform('ios') ? 'Zurück' : ''}
    className='text-yellow-accent absolute text-xs'
  />
);

export default UIButtonBack;

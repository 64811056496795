import React, {useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import {useRecoilValue} from 'recoil';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router';
import {IonIcon, IonSelect, IonSelectOption, IonTextarea} from '@ionic/react';
import {authService} from 'api/account/auth.service';
import {userService} from 'api/users/users.service';
import {instrumentsService} from 'api/instruments/instruments.service';
import UIInput from 'components/uiInputs/UIInput';
import AddPhotos from 'components/uiAddPhoto/UIAddPhotos';
import UIYellowButton from 'components/uiButtons/UIYellowButton';
import UIToggleButton from 'components/uiButtons/UIToggleButton';
import {
  Category,
  InstrumentRequest,
  Subcategory,
} from 'interfaces/instruments/instrument.interface';
import {BFormAddressField} from 'forms/common/places/BFormAddressField';
import {isDarkTheme} from 'states/account/account.state';
import EditPhotos from 'pages/common/editPhotos/EditPhotos';
import {errorCategoryTranslator} from 'utils/translateHelper';
import LineColored from './LineColored';

import euroIcon from 'assets/icons/euro_icon.svg';

function InstrumentForm() {
  const isDark2 = useRecoilValue(isDarkTheme);
  const [categories, setCategories] = useState<Category[]>([]);
  const [subcategories, setSubcategories] = useState<Subcategory[]>([]);
  const [subcatId, setSubcatId] = useState<string>('');
  const [catId, setCatId] = useState<string>('');
  const [prevState, setToggle] = useState<boolean>(true);
  const [apiResultMessage, setApiResultMessage] = useState('');
  const [instrumentId, setInstrumentId] = useState<any>();
  const [address, setAddress] = useState<any>();
  const [isPhotoPopUp, setIsPhotoPopUp] = useState<boolean>();
  const [photos, setPhotos] = useState<any>();
  const {
    handleSubmit,
    register,
    setValue,
    control,
    formState: {errors},
    watch,
  } = useForm();

  const history = useHistory();

  useEffect(() => {
    if (!authService.tokenValue) {
      watch(() => history.replace('/sign-in'));
      return;
    }

    const fetchCategoriesAndUser = async () => {
      try {
        const [instrumentRes, userRes] = await Promise.all([
          instrumentsService.getCategories('loan'),
          userService.getUserData(),
        ]);

        setCategories(instrumentRes.data.results);
        setValue('email', userRes.data.email);
        setValue('phoneNumber', userRes.data.phoneNumber);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchCategoriesAndUser();
  }, [setValue, watch, history]);

  useEffect(() => {
    if (!catId) return;

    const fetchSubcategories = async () => {
      try {
        const response = await instrumentsService.getCategories('loan');
        const selectedCategory = response.data.results.find(
          (category: any) => category.id === catId
        );
        if (selectedCategory) {
          setSubcategories(selectedCategory.subcategories);
        }
      } catch (error) {
        console.error('Error fetching subcategories:', error);
      }
    };

    fetchSubcategories();
  }, [catId]);

  useEffect(() => {
    if (photos) {
      const photoHelper = [];
      for (let i = 0; i < 4; i++) {
        if (typeof photos['photo_' + i] === 'undefined' || photos['photo_' + i] === 'deletePhoto') {
          continue;
        }
        photoHelper.push(photos['photo_' + i]);
      }
      if (photoHelper.length > 0) {
        setValue('photos', photoHelper);
      }
    }
  }, [photos, setValue]);

  const onSubmit = (data: InstrumentRequest) => {
    data.name = data.instrumentName;
    data.category = catId;
    data.subcategory = subcatId;
    data.pricePerDay = +data.pricePerDay;
    data.availability = prevState;
    data.productionYear = +data!.productionYear!;
    data.weekHours = '';
    data.weekendHours = '';

    if (data.Address) {
      data.location = data.Address.label;
      data.postCode = data.Address.postCode;
      data.lat = data.Address.lat.toFixed(7);
      data.lng = data.Address.lng.toFixed(7);
      setAddress(data.Address);
    } else if (address) {
      data.location = address.label;
      data.postCode = address.postCode;
      data.lat = address.lat.toString();
      data.lng = address.lng.toString();
    }

    const method = instrumentId
      ? instrumentsService.updateInstrument(data, instrumentId)
      : instrumentsService.addInstrument(data);

    setApiResultMessage('Ladung...');

    method
      .then((res) => {
        setApiResultMessage('');
        setInstrumentId(res.data.id);
        setAddress(data.Address);
        history.push(`/purchase/instruments/${res.data.id ? res.data.id : instrumentId}`);
      })
      .catch((error: AxiosError | any) => {
        setApiResultMessage('Achtung! ' + errorCategoryTranslator(error.response.data.message[0]));
      });
  };

  console.log(categories);

  return (
    <>
      {isPhotoPopUp && (
        <EditPhotos setPhotos={setPhotos} photos={photos} setIsPhotoPopUp={setIsPhotoPopUp} />
      )}
      {!isPhotoPopUp && (
        <form className='h-full pb-3' onSubmit={handleSubmit(onSubmit)}>
          <div
            className={`border-2 border-orange-main text-black rounded-md
      ${isDark2 ? 'bg-[#393939]' : 'bg-[#f8f8f8] '}`}
          >
            <div className={`h-6 ${isDark2 ? 'bg-[#F0A249]' : ' bg-yellow-accent'}`}></div>
            <div className={`flex my-4 ${isDark2 ? 'bg-[#393939]' : 'bg-[#F8F8F8]'}`}>
              <div className='flex-col mx-2 w-2/5 space-y-2 text-sm'>
                <div
                  className='flex-col w-2/5 space-y-2 text-sm mb-16 pt-2'
                  onClick={() => setIsPhotoPopUp(true)}
                >
                  <AddPhotos control={control} name='photos' disabled={true} />
                </div>
                <div>
                  <p className={`${isDark2 ? ' text-white' : 'text-black'}`}>Preis pro Tag:</p>
                  <div className='flex'>
                    <UIInput
                      customClassName={`w-full text-sm
                      ${isDark2 ? 'bg-[#393939] text-white' : 'bg-[#F8F8F8] text-black'}
                    `}
                      name='pricePerDay'
                      type='number'
                      placeholder='80'
                      register={register}
                      validation={{required: true}}
                      errors={errors}
                    />
                    <IonIcon
                      icon={euroIcon}
                      className='mt-1 text-gray-500 h-4 w-4 lg:absolute lg:left-[160px]'
                    />
                  </div>
                  <div className='lg:w-[150px] '>
                    <LineColored error={errors.pricePerDay} />
                  </div>
                </div>
                <UIToggleButton
                  name='availability'
                  title='Verfügbarkeit'
                  state={prevState}
                  setState={setToggle}
                  register={register}
                />
              </div>
              <div className='flex-col space-y-2 mr-[15px] w-3/5 text-sm '>
                <div className='space-y-2 mb-2 '>
                  <div
                    className='w-44 h-6 p-4 flex items-center justify-between
                    rounded-xl border-orange-main border text-orange-main '
                  >
                    <IonSelect
                      okText='Weiter'
                      cancelText='Abbrechen'
                      className={`${isDark2 ? ' text-white' : 'text-black'}`}
                      placeholder='Kategorie'
                      value={catId && catId}
                      onIonChange={(cat) => setCatId(cat.detail.value)}
                    >
                      {categories.map((cat) => (
                        <IonSelectOption key={cat.id} value={cat.id}>
                          {cat.name}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </div>
                  <div
                    className='w-44 h-6 p-4 flex items-center justify-between
                    rounded-xl border-orange-main border text-orange-main'
                  >
                    <IonSelect
                      okText='Weiter'
                      cancelText='Abbrechen'
                      className={`${isDark2 ? ' text-white' : 'text-black'}`}
                      placeholder='Unterkategorie'
                      value={subcatId && subcatId}
                      onIonChange={(cat) => setSubcatId(cat.detail.value)}
                    >
                      {subcategories.map((subcat) => (
                        <IonSelectOption key={subcat.id} value={subcat.id}>
                          {subcat.name}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </div>
                </div>
                <div>
                  <p className={`${isDark2 ? ' text-white' : 'text-black'}`}>Name:</p>
                  <UIInput
                    customClassName={`
                    ${isDark2 ? 'bg-[#393939] text-white' : 'bg-[#F8F8F8] text-black'}
                  `}
                    name='instrumentName'
                    placeholder='Mischmaschine'
                    required={'required'}
                    register={register}
                    validation={{required: true}}
                    errors={errors}
                  />
                  <LineColored error={errors.instrumentName} />
                </div>
                <div>
                  <p className={`${isDark2 ? ' text-white' : 'text-black'}`}>Geräte Bezeichnung:</p>
                  <UIInput
                    customClassName={`
                     ${isDark2 ? 'bg-[#393939] text-white' : 'bg-[#F8F8F8] text-black'}
                   `}
                    name='purpose'
                    placeholder='Zweck'
                    required={'required'}
                    register={register}
                    validation={{required: true}}
                    errors={errors}
                  />
                  <LineColored error={errors.purpose} />
                </div>
                <div>
                  <p className={`${isDark2 ? ' text-white' : 'text-black'}`}>Email:</p>
                  <UIInput
                    customClassName={`
                     ${isDark2 ? 'bg-[#393939] text-white ' : 'bg-[#F8F8F8] text-black '}
                   `}
                    name='email'
                    placeholder='beispiel@email.com'
                    required='required'
                    register={register}
                    validation={{required: true}}
                    errors={errors}
                  />
                  <LineColored error={errors.email} />
                </div>
                <div>
                  <p className={`${isDark2 ? ' text-white' : 'text-black'}`}>Baujahr:</p>
                  <UIInput
                    customClassName={`
                     ${isDark2 ? 'bg-[#393939] text-white' : 'bg-[#F8F8F8] text-black'}
                   `}
                    name='productionYear'
                    type='number'
                    placeholder='Jahr'
                    register={register}
                    errors={errors}
                  />
                  <LineColored error={errors.productionYear} />
                </div>
                <div>
                  <p className={`${isDark2 ? ' text-white' : 'text-black'}`}>Telefonnummer:</p>
                  <UIInput
                    customClassName={`
                     ${isDark2 ? 'bg-[#393939] text-white' : 'bg-[#F8F8F8] text-black'}
                   `}
                    name='phoneNumber'
                    type='tel'
                    placeholder='0660 1234567'
                    minLength={9}
                    maxLength={16}
                    register={register}
                    validation={{required: true}}
                    errors={errors}
                  />
                  <LineColored error={errors.phoneNumber} />
                </div>
                <div className='text-yellow-accent text-center font-bold mb-5 clear-both'>
                  {apiResultMessage}
                </div>
              </div>
            </div>
            <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
              <div
                className={`${isDark2 ? 'text-sm text-[#F0A249]' : 'text-orange-main text-sm '}`}
              >
                Adresse
              </div>
              <div
                className={`${isDark2 ? 'w-full h-px bg-[#F0A249]' : 'bg-orange-main w-full h-px'}`}
              ></div>
            </div>
            <div className='mx-2 mt-2'>
              <BFormAddressField
                placeholder={address?.label}
                control={control}
                fieldName='Address'
                key={Math.random()}
              />
            </div>
            <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
              <div
                className={`${isDark2 ? 'text-sm text-[#F0A249]' : 'text-orange-main text-sm '}`}
              >
                Beschreibung
              </div>
              <div
                className={`${isDark2 ? 'w-4/5 h-px bg-[#F0A249]' : 'bg-orange-main w-4/5 h-px'}`}
              ></div>
            </div>
            <IonTextarea
              rows={4}
              className={`ipnut px-3 text-sm h-[100px]
          ${isDark2 ? ' text-white' : 'text-black'}`}
              {...register('description')}
              placeholder='Bitte beschreibe dein Gerät'
            />
          </div>
          <div className='flex flex-col mt-2 justify-end text-white '>
            <UIYellowButton type='submit' text='Weiter' />
          </div>
        </form>
      )}
    </>
  );
}

export default InstrumentForm;

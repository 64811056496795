import React, {useEffect, useState} from 'react';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonToolbar,
} from '@ionic/react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import OneSignal from 'onesignal-cordova-plugin';
import {isMobile} from 'react-device-detect';

import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import UILogo from 'components/uiLogo/UILogo';
import {userService} from 'api/users/users.service';
import {User} from 'interfaces/users/user.interface';
import {accountState, isDarkTheme} from 'states/account/account.state';
import {refreshCounterState} from 'states/common/common.state';
import UILoadingIndicator from 'components/uiLoading/UILoadingIndicator';

const NotificationsEdit = () => {
  const [appNotifi, setAppNotifi] = useState<boolean>(false);
  const [emailNotifi, setEmailNotifi] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const isDark2 = useRecoilValue(isDarkTheme);
  const setAccount = useSetRecoilState(accountState);
  const setRefreshCounter = useSetRecoilState(refreshCounterState);

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);

      try {
        const userRes = await userService.getUserData();

        setAppNotifi(userRes.data.areAppNotificationsEnabled);
        setEmailNotifi(userRes.data.areEmailNotificationsEnabled);
      } catch (error: any) {
        throw error.message;
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => setRefreshCounter((val) => val + 1), [emailNotifi, appNotifi]);

  const onAppNotifiClick = async (e: any) => {
    setAppNotifi(e);

    const updatedData: Partial<User> = {areAppNotificationsEnabled: e};

    await userService.updateUserData(updatedData);
    setAccount(updatedData);

    if (isMobile) {
      if (e == true) {
        OneSignal.disablePush(false);
      } else if (e == false) {
        OneSignal.disablePush(true);
      }
    }
  };

  const onEmailNotifiClick = async (e: any) => {
    setEmailNotifi(e);

    const updatedData: Partial<User> = {areEmailNotificationsEnabled: e};

    await userService.updateUserData(updatedData);
    setAccount(updatedData);
  };

  if (loading) return <UILoadingIndicator />;

  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar customClassName='w-full bg-gray-600 h-10 flex justify-center z-10'>
          Benachrichtigungen
        </UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <div className='text-black mx-4 font-sans'>
          <p className='mt-5 text-center font-medium'>App</p>
          <div className='border border-yellow-accent border-t-8 rounded-md mt-2 py-0.5'>
            <IonRadioGroup value={appNotifi} onIonChange={(e) => onAppNotifiClick(e.detail.value)}>
              <IonItem color={`${isDark2 ? 'medium' : 'light'}`} lines='none'>
                <IonLabel>
                  <b className={`font-bold text-sm ${isDark2 ? 'text-white' : 'text-black '}`}>
                    Aktiviert
                  </b>
                </IonLabel>
                <IonRadio slot='start' value={true} />
              </IonItem>

              <hr className='bg-yellow-accent' />
              <IonItem color={`${isDark2 ? 'medium' : 'light'}`} lines='none'>
                <IonLabel>
                  <b className={`font-bold text-sm ${isDark2 ? 'text-white' : 'text-black '}`}>
                    Deaktiviert
                  </b>
                </IonLabel>
                <IonRadio slot='start' value={false} />
              </IonItem>
            </IonRadioGroup>
          </div>
          <p className='mt-5 text-center font-medium'>Email</p>
          <div className='border border-yellow-accent border-t-8 rounded-md mt-2 py-0.5'>
            <IonRadioGroup
              value={emailNotifi}
              onIonChange={(e) => onEmailNotifiClick(e.detail.value)}
            >
              <IonItem color={`${isDark2 ? 'medium' : 'light'}`} lines='none'>
                <IonLabel>
                  <b className={`font-bold text-sm ${isDark2 ? 'text-white' : 'text-black '}`}>
                    Aktiviert
                  </b>
                </IonLabel>
                <IonRadio slot='start' value={true} />
              </IonItem>
              <hr className='bg-yellow-accent' />
              <IonItem color={`${isDark2 ? 'medium' : 'light'}`} lines='none'>
                <IonLabel>
                  <b className={`font-bold text-sm ${isDark2 ? 'text-white' : 'text-black '}`}>
                    Deaktiviert
                  </b>
                </IonLabel>
                <IonRadio slot='start' value={false} />
              </IonItem>
            </IonRadioGroup>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NotificationsEdit;

import React from 'react';
import {IonIcon, useIonAlert} from '@ionic/react';
import {trashBin} from 'ionicons/icons';

interface Props {
  onDelete: Function;
}

export const UIDeleteButton = ({onDelete}:Props) => {
  const [present] = useIonAlert();
  return (
    <IonIcon
      onClick={() =>
        present({
          header: 'Löschen',
          message: 'Bist du dir sicher?',
          buttons: ['Nein', {text: 'Ja', handler: (d) => onDelete()}],
          onDidDismiss: (e) => {},
        })
      }
      className='text-red-500 mr-3'
      icon={trashBin}
    />
  );
};

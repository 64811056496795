import React from 'react';
import {useRecoilValue} from 'recoil';
import {IonIcon, useIonRouter} from '@ionic/react';
import {locationOutline, logoEuro} from 'ionicons/icons';
import {UIImage} from 'components/uiImages/UIImage';
import {isDarkTheme} from 'states/account/account.state';

import greenCircle from 'assets/temp/green_circle.png';
import redCircle from 'assets/temp/red_circle.png';

interface SearchResultProps {
  items: any[];
  itemsType: string;
}
export const SearchResult: React.FC<SearchResultProps> = ({items, itemsType}) => {
  const router = useIonRouter();
  const handleItemClicked = (item: any) => {
    if (itemsType === 'instruments') {
      router.push(`/instrument/${item.category.name}/${item.subcategory.name}/${item.id}`);
      return;
    }
    if (itemsType === 'jobs') {
      router.push(`/job/${item.id}`);
      return;
    }
    if (itemsType === 'services') {
      router.push(`/service/${item.id}`);
      return;
    }
    if (itemsType === 'products') {
      router.push(`/product/${item.category.name}/${item.subcategory.name}/${item.id}`);
      return;
    }
    router.push(`${itemsType.slice(0, -1)}/${item.id}`);
  };
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <div>
      {items &&
        items.map((item) => (
          <div
            key={item.id}
            className='w-[90%] md:w-2/3 max-w-screen-md mx-auto mt-5'
            onClick={() => handleItemClicked(item)}
          >
            <div
              className={`${
                item.promoted
                  ? 'shadow-custom shadow-orange-promoted border rounded-3xl border-orange-promoted'
                  : 'border rounded-3xl border-yellow-accent '
              } ${isDark2 ? 'bg-[#393939] drop-shadow-md' : 'bg-[#F8F8F8] drop-shadow-md'}`}
            >
              <div>
                <UIImage
                  photoLink={item.photos.length > 0 && item.photos[0].photo}
                  customClassName='h-20 w-full rounded-t-3xl'
                  type='items'
                />
                {itemsType === 'instruments' && (
                  <div className='flex justify-end'>
                    {item.availability ? (
                      <img alt='image' src={greenCircle} className='h-4 mt-2 mr-3 absolute top-0' />
                    ) : (
                      <img alt='image' src={redCircle} className='h-4 mt-2 mr-3 absolute top-10' />
                    )}
                  </div>
                )}
                <div className='flex justify-end mr-3'>
                  <div className='bg-yellow-accent text-white absolute p-2 top-16 rounded-2xl flex'>
                    <IonIcon icon={logoEuro} className='text-sm' />
                    <p className='text-xs'>
                      {itemsType === 'instruments' && <p>{item.pricePerDay} euro / pro Tag</p>}
                      {itemsType === 'services' && <p>{item.pricePerHour} euro / pro Stunde</p>}
                      {itemsType === 'jobs' && <p>{item.salary} brutto / Monat</p>}
                      {itemsType === 'products' && <p>{item.price}</p>}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className='mt-2'>
                  <p
                    className={` text-md font-medium ml-5
            ${isDark2 ? 'text-gray-300' : 'text-gray-600'}`}
                  >
                    {item.name}
                  </p>
                  <p
                    className={`text-sm ml-7 
            ${isDark2 ? 'text-gray-300' : 'text-gray-600'}`}
                  >
                    {itemsType === 'instruments' && item.instrumentName}
                    {itemsType === 'products' && item.productName}
                    {itemsType === 'service' && item.company}
                    {itemsType === 'jobs' && item.industry}
                  </p>
                  <div className='flex justify-between'>
                    <p
                      className={`text-[12px] ml-7 mb-4
              ${isDark2 ? 'text-gray-300' : 'text-gray-600'}`}
                    >
                      {item.location}
                    </p>
                    {itemsType === 'instruments' && item.distance && (
                      <div className='flex'>
                        <IonIcon
                          icon={locationOutline}
                          className={`h-4 w-4 ml-3 ${isDark2 ? 'text-white' : 'text-black '}`}
                        />
                        <div className={`text-x mr-3 ${isDark2 ? 'text-white' : 'text-black '}`}>
                          {item.distance.toFixed() + ' '}
                          km
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

import React from 'react';
import {Job} from 'interfaces/jobs/jobs.interface';
import {FavoritesJobsListItem} from './FavouritesJobsListItem';

export interface Props {
  fields: Job[];
  onJobClick: (offerId: number) => void;
}

export const FavoritesJobsList: React.FC<Props> = ({fields, onJobClick}) => {
  return (
    <div>
      {fields.map((field) => (
        <FavoritesJobsListItem field={field} onOfferClick={onJobClick} key={field.id} />
      ))}
    </div>
  );
};

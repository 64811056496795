import React, {useEffect, useState} from 'react';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonToolbar,
  useIonRouter,
  useIonToast,
} from '@ionic/react';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UILogo from 'components/uiLogo/UILogo';
import {OrangeLine} from 'components/uiContainer/UIOrangeLine';
import UIYellowButton from 'components/uiButtons/UIYellowButton';
import {useParams} from 'react-router';
import {reportsService} from 'api/reports/reports.service';
import {isDarkTheme} from 'states/account/account.state';
import {useRecoilValue} from 'recoil';
import {userService} from 'api/users/users.service';

export default function ReportPage() {
  const {reportedElement, reportedElementId}: any = useParams();
  const router = useIonRouter();
  const [reportType, setReportType] = useState();
  const [message, setMessage] = useState('');
  const [present] = useIonToast();
  const [email, setEmail] = useState('');

  useEffect(() => {
    userService.getUserData().then((res) => {
      setEmail(res.data.email);
    });
  });

  const handleSubmit = async () => {
    try {
      if (email === '' || null) {
        present('unbekannte Email', 3000);
        return;
      }
      await reportsService.send({
        reportedElement,
        reportedElementId: +reportedElementId,
        reportType,
        message,
        email,
      });
      present('Antrag gesendet', 1000);
    } catch (error) {
      present('Error!', 1000);
    } finally {
      router.goBack();
    }
  };
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <div className='mx-6'>
          <b>Probleme melden</b>
        </div>
        <div
          className={`rounded-xl border border-t-8
          border-yellow-accent mx-6 my-3  drop-shadow-md
          ${isDark2 ? 'bg-[#474747]' : 'bg-[#F8F8F8]'}`}
        >
          <div className='flex flex-col p-4'>
            <IonSelect
              okText='Weiter'
              cancelText='Abbrechen'
              className={`${isDark2 ? 'bg-[#474747]' : 'bg-[#F8F8F8]'}`}
              onIonChange={(e) => setReportType(e.detail.value)}
              placeholder='Grund'
            >
              <IonSelectOption value='1'>Anstößig/ Unserios</IonSelectOption>
              <IonSelectOption value='2'>Falsche Kategorie</IonSelectOption>
              <IonSelectOption value='3'>Unerwünschte Werbung/Spam</IonSelectOption>
            </IonSelect>
            <OrangeLine name='' />
            <div className='mt-2'>Beschreibe das Problem</div>
            <IonTextarea
              rows={4}
              className={`border-2 rounded-md border-gray-600 h-[120px]
              ${isDark2 ? 'bg-[#474747]' : 'bg-[#F8F8F8]'}`}
              onIonChange={(e) => setMessage(e.detail!.value!)}
            />
          </div>
        </div>
        {reportType && (
          <div className='mb-5 absolute bottom-2 w-full'>
            <UIYellowButton text='Senden' onClick={handleSubmit} />
          </div>
        )}
      </IonContent>
    </IonPage>
  );
}

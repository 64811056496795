import React, {useEffect, useState} from 'react';
import {IonIcon} from '@ionic/react';
import {useForm} from 'react-hook-form';
import AddPhoto from 'components/uiAddPhoto/UIAddPhoto';
import {closeCircle} from 'ionicons/icons';

type Props = {
  photos?: any;
  setPhotos: any;
  setIsPhotoPopUp: any;
};

const EditPhotos: React.FC<Props> = ({photos, setPhotos, setIsPhotoPopUp}) => {
  const {handleSubmit, control, setValue} = useForm();
  const [arePhotos, setArePhotos] = useState<boolean[]>();
  const arePhotosDefault = [false, false, false, false];

  useEffect(() => {
    for (let i = 0; i < 4; i++) {
      setValue('photo_' + i, photos ? photos['photo_' + i] : undefined);
      if (photos && photos['photo_' + i] && photos['photo_' + i] != 'deletePhoto') {
        arePhotosDefault[i] = true;
      }
      setArePhotos(arePhotosDefault);
    }
  }, []);

  const onSubmit = (data: any) => {
    setPhotos(data);
    setIsPhotoPopUp(false);
  };

  const handleDelete = (photoId: number) => {
    setValue('photo_' + photoId, 'deletePhoto');
    switch (photoId) {
      case 0:
        setArePhotos([false, arePhotos![1], arePhotos![2], arePhotos![3]]);
        break;
      case 1:
        setArePhotos([arePhotos![0], false, arePhotos![2], arePhotos![3]]);
        break;
      case 2:
        setArePhotos([arePhotos![0], arePhotos![1], false, arePhotos![3]]);
        break;
      case 3:
        setArePhotos([arePhotos![0], arePhotos![1], arePhotos![2], false]);
        break;
    }
  };

  const handleChangePhoto = (photoId: number) => {
    switch (photoId) {
      case 0:
        setArePhotos([true, arePhotos![1], arePhotos![2], arePhotos![3]]);
        break;
      case 1:
        setArePhotos([arePhotos![0], true, arePhotos![2], arePhotos![3]]);
        break;
      case 2:
        setArePhotos([arePhotos![0], arePhotos![1], true, arePhotos![3]]);
        break;
      case 3:
        setArePhotos([arePhotos![0], arePhotos![1], arePhotos![2], true]);
        break;
    }
  };

  return (
    <form className='h-full pb-3 space-y-2' onSubmit={handleSubmit(onSubmit)}>
      {arePhotos && (
        <div className='flex flex-wrap'>
          {arePhotos.map((isPhoto: boolean, idx: number) => (
            <div className='mt-4 ml-4' key={idx}>
              <AddPhoto
                name={'photo_' + idx}
                control={control}
                onValueChange={() => handleChangePhoto(idx)}
              />
              {isPhoto === true && (
                <IonIcon
                  icon={closeCircle}
                  className='text-yellow-accent text-4xl mt-[-10px] ml-[79px]'
                  onClick={() => handleDelete(idx)}
                />
              )}
            </div>
          ))}
        </div>
      )}
      <div className='w-full flex justify-center'>
        <div
          className='border-[2px] border-yellow-accent text-yellow-accent rounded-xl
          absolute top-[83%] h-10 text-xl text-center
          pt-1 hover:bg-yellow-50 w-11/12'
        >
          <button type='button' onClick={() => setIsPhotoPopUp(false)}>
            Schließen
          </button>
        </div>
        <div
          className='border-[2px] border-yellow-accent text-yellow-accent rounded-xl
          absolute top-[90%] h-10 text-xl text-center
          pt-1 hover:bg-yellow-50 w-11/12'
        >
          <button type='submit'>Fotos speichern</button>
        </div>
      </div>
    </form>
  );
};

export default EditPhotos;

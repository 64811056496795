import React, {useEffect} from 'react';
import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar} from '@ionic/react';
import UILogo from 'components/uiLogo/UILogo';
import {useParams} from 'react-router';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import {saveLastPayment} from 'utils/paymentHelper';
import {isDarkTheme} from 'states/account/account.state';
import {useRecoilValue} from 'recoil';
import {BASE_URL_APP} from 'utils/constants';

export const PromotionPaymentPage = () => {
  const isDark2 = useRecoilValue(isDarkTheme);
  const {paymentId, authToken, checkoutId, cartId}: any = useParams();

  useEffect(() => {
    const langScript = document.createElement('script');

    langScript.innerHTML = 'var wpwlOptions = { locale: "de"}';

    document.body.appendChild(langScript);

    const script = document.createElement('script');

    script.src = `https://eu-prod.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
    script.async = true;

    document.body.appendChild(script);

    saveLastPayment(paymentId);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <form
          action={`${BASE_URL_APP}/payment-result/${authToken}/${paymentId}/${cartId}`}
          className='paymentWidgets'
          data-brands='VISA MASTER PAYPAL SOFORTUEBERWEISUNG'
        >
          <button type='submit' name='pay' className='wpwl-button-pay' />
        </form>
      </IonContent>
    </IonPage>
  );
};

export default PromotionPaymentPage;

import React, {useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination} from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/zoom';
import 'swiper/css/bundle';
import '@ionic/react/css/ionic-swiper.css';
import {instrumentsService} from 'api/instruments/instruments.service';
import {IonIcon} from '@ionic/react';
import {send} from 'ionicons/icons';
import {servicesService} from 'api/services/services.service';
import {accountService} from 'api/account/account.service';
import {useRecoilValue} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';

interface OwnerData {
  email: string;
  firstName: string;
  id: number;
  lastName?: string;
}

interface CommentData {
  dateAdd: string;
  owner: OwnerData;
  text: string;
}

type commentHelperProps = {
  comments?: Array<CommentData> | any;
  commented?: boolean;
  id?: any;
  type?: string;
};

const UICommentSpace: React.FC<commentHelperProps> = ({
  comments,
  commented,
  id,
  type = 'instruments',
}) => {
  const isDark2 = useRecoilValue(isDarkTheme);

  const [text, setText] = useState(String);
  const [localText, setLocalText] = useState('');
  const [userName, setUserName] = useState('');
  const [isCommented, setIsCommented] = useState(commented);

  const comment = {
    text: text,
  };

  const addCommentClicked = async () => {
    setLocalText(text);
    try {
      if (type === 'instruments') {
        await instrumentsService.commentInstrument(comment, id);

        const response = await accountService.getCurrentUser();
        setUserName(`#${response.data.firstName}`);
        setLocalText(text);
      } else if (type === 'services') {
        await servicesService.commentService(comment, id);

        const response = await accountService.getCurrentUser();
        setUserName(`#${response.data.firstName}`);
        setLocalText(text);
      }
    } catch (error) {
      console.log('error');
    } finally {
      setText('');
      setIsCommented(true);
    }
  };

  return (
    <div
      className={`
       mx-4 py-2 rounded-xl text-sm 
       ${isDark2 ? 'bg-[#393939] border-[#F0A249]' : 'bg-[#F8F8F8] border-orange-main'}`}
    >
      <div className='w-full mb-6'>
        <Swiper
          modules={[Navigation, Pagination]}
          slidesPerView={1}
          navigation
          spaceBetween={50}
          className='mySwiper'
        >
          <div className='w-full text-sm mx-2'>
            {comments.length === 0 ||
              (localText === '' && (
                <p className={`${isDark2 ? 'text-white' : 'text-black'}`}>Noch keine Kommentare</p>
              ))}
            {comments.map((item: any, index: number) => {
              return (
                <SwiperSlide key={index}>
                  <div className='w-5/6'>
                    <div
                      className={`text-sm mx-2 whitespace-normal
                    ${isDark2 ? 'text-white' : 'text-black'}`}
                    >
                      {item.text}
                    </div>
                    <div className='flex justify-end pr-8 pt-2 text-xs text-orange-main'>
                      #{item.owner.firstName}
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </div>
          <>
            {localText === '' && userName === '' ? (
              <></>
            ) : (
              <div>
                <SwiperSlide>
                  <div className='w-5/6'>
                    <div className='text-sm mx-2 text-gray-400 whitespace-normal'>{localText}</div>
                    <div className='flex justify-end pr-8 pt-2 text-xs text-orange-main'>
                      {userName}
                    </div>
                  </div>
                </SwiperSlide>
              </div>
            )}
          </>
        </Swiper>
      </div>
      <div className='flex flex-row space-x-3'>
        <input
          placeholder='Hinterlasse einen Kommentar...'
          className='w-full border-2 border-gray-300 rounded-lg p-1 pl-2'
          onChange={(e) => setText(e.target.value)}
          value={text}
        ></input>
        <button
          className='text-2xl text-white bg-orange-main rounded-xl px-3'
          onClick={addCommentClicked}
          disabled={isCommented}
        >
          <IonIcon icon={send} className='text-xl' />
        </button>
      </div>
      <p className='text-red-500 text-right'>{isCommented ? 'Already comented' : ''}</p>
    </div>
  );
};

export default UICommentSpace;

import React, {useEffect, useState} from 'react';
import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar, useIonToast} from '@ionic/react';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import UILogo from 'components/uiLogo/UILogo';
import {useRecoilValue} from 'recoil';
import {accountState, isDarkTheme} from 'states/account/account.state';
import UIResponsivePadding from 'components/uiResponsive/UIResponsivePadding';
import UIOrangeCategoryBox from 'components/uiContainer/UIOrangeCategoryBox';
import {refreshCounterState} from 'states/common/common.state';
import {accountService} from 'api/account/account.service';
import {User} from 'interfaces/users/user.interface';
import searchJob from 'assets/community/services&jobs/search_job.svg';
import createJob from 'assets/community/services&jobs/create_job.svg';

const JobsMenuPage: React.FC = () => {
  const [accType, setAccType] = useState();
  const refresh = useRecoilValue(refreshCounterState);
  const accountData = useRecoilValue<User | undefined>(accountState);
  const [present] = useIonToast();

  useEffect(() => {
    if (accountData) {
      accountService.getCurrentUser().then((res) => {
        setAccType(res.data.accountType);
      });
    }
  }, [refresh]);

  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar>Jobs</UITopTextBar>
      </IonHeader>
      <IonContent fullscreen color={`${isDark2 ? 'medium' : 'light'}`}>
        <UIResponsivePadding>
          <UIOrangeCategoryBox
            name='JOBSUCHE'
            src={searchJob}
            alt='search for jobs'
            linkTo='/jobs'
          />
          <UIOrangeCategoryBox
            name='JOBINSERAT ERSTELLEN'
            src={createJob}
            alt='create job offer'
            linkTo={accType === 'COMPANY' && '/jobs-add'}
            onClick={() =>
              accType !== 'COMPANY' &&
              present(
                'um eine Anzeige erstellen zu können müssen Sie über ein Firmenkonto' +
                  ' verfügen dieses können Sie im Profil unter „Einstellungen“ auswählen',
                3000
              )
            }
          />
        </UIResponsivePadding>
      </IonContent>
    </IonPage>
  );
};

export default JobsMenuPage;

import client from 'api/common/client';
import {ContactInterface} from 'interfaces/users/contact.interface';
import {createFormData} from 'utils/formDataHelper';
import {User} from 'interfaces/users/user.interface';
import {getPlayerId} from 'utils/oneSignalInit';

const updateUser = async (userData: any) => {
  const data = createFormData(userData);
  return client.patch('users/current-user/', data);
};

const updateUserData = async (user: Partial<User>) => {
  return client.patch('users/current-user/', user);
};

const updateUserPhoto = async (data: any) => {
  const fd = new FormData();
  fd.append('image', data);

  return client.patch('users/current-user/', fd);
};

const getUserData = () => {
  return client.get('users/current-user/');
};

const getUserItems = (userId: string) => {
  return client.get(`/users/owned/?user_id=${userId}`);
};

const sendMessageToAdmin = async (data: ContactInterface) => {
  return client.post('/users/contact/', data);
};

const rateUserService = async (rate: object, userId: number) => {
  const formData = createFormData(rate);
  const response = await client.post(`/users/${userId}/rate/`, formData);
  return response;
};

const updateUserPlayerIdAndNotifications = async (
  appNotification: boolean,
  emailNotification: boolean
) => {
  const newPlayerId = await getPlayerId();

  if (newPlayerId === null) {
    return;
  }

  await userService.updateUser({
    playerId: newPlayerId,
    areAppNotificationsEnabled: appNotification,
    areEmailNotificationsEnabled: emailNotification,
  });
};

export const userService = {
  updateUser,
  updateUserData,
  updateUserPhoto,
  getUserData,
  getUserItems,
  sendMessageToAdmin,
  rateUserService,
  updateUserPlayerIdAndNotifications,
};

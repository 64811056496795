/* eslint-disable max-len */
import React, {useState} from 'react';
import {useRecoilValue} from 'recoil';
import {IonIcon, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs} from '@ionic/react';
import {map, star, person, chatbubbles, home} from 'ionicons/icons';
import AccountLoginPage from 'pages/account/accountLogin/AccountLoginPage';
import AccountRecoveryPage from 'pages/account/accountPassword/AccountRecoveryPage';
import AccountRegisterPage from 'pages/account/accountRegister/AccountRegisterPage';
import AccountRegister2Page from 'pages/account/accountRegister/AccountRegisterPage2';
import AccountRegisterConfirmationPage from 'pages/account/accountRegister/AccountRegisterConfirmationPage';
import HomePage from 'pages/common/home/HomePage';
import AgricultureMenuPage from 'pages/common/agriculture/AgricultureMenuPage';
import MapPage from 'pages/common/map/MapPage';
import AccountPleaseSignInPage from 'pages/account/accountPleaseSignIn/AccountPleaseSignInPage';
import {Route} from 'react-router';
import {InstrumentDetailPage} from 'pages/instruments/instrumentDetail/InstrumentDetailPage';
import InstrumentsListPage from 'pages/instruments/instrumentsList/InstrumentsListPage';
import SearchPage from 'pages/common/search/SearchPage';
import InstrumentAddPage from 'pages/instruments/instrumentAdd/InstrumentAddPage';
import ProductsAddPage from 'pages/products/addProducts/ProductsAddPage';
import {DetailProductsPage} from 'pages/products/detailProducts/DetailProductsPage';
import ProductsListPage from 'pages/products/productsList/ProductsListPage';
import ReportPage from 'pages/common/report/ReportPage';
import SearchMapPage from 'pages/common/search/SearchMapPage';
import {ServiceDetailPage} from 'pages/services/serviceDetail/ServiceDetailPage';
import JobDetail from 'pages/jobs/jobDetail/JobDetailPage';
import PromotionsPaymentResultPage from 'pages/promotions/promotionPayment/PromotionsPaymentResultPage';
import PromotionPaymentPage from 'pages/promotions/promotionPayment/PromotionPaymentPage';
import PriceListPage from 'pages/common/priceList/PriceListPage';
import ToolsMenuPage from 'pages/common/tools/ToolsMenuPage';
import MachinesMenuPage from 'pages/common/machines/MachinesMenuPage';
import ContactPage from 'pages/common/contact/ContactPage';
import {isDarkTheme} from 'states/account/account.state';
import JobListPage from 'pages/jobs/jobsList/JobsListPage';
import JobsMenuPage from 'pages/jobs/jobsMenu/JobsMenuPage';
import ServicesMenuPage from 'pages/services/servicesMenu/ServicesMenuPage';
import {InstrumentsSubCatMachinesListPage} from 'pages/instruments/instrumentsSubcategoriesList/InstrumentsSubCatMachinesListPage';
import {InstrumentsSubCatToolsListPage} from 'pages/instruments/instrumentsSubcategoriesList/InstrumentsSubCatToolsListPage';
import {InstrumentsSubCatAgricultureListPage} from 'pages/instruments/instrumentsSubcategoriesList/InstrumentsSubCatAgricultureListPage';
import {ProductsSubCatToolsListPage} from 'pages/products/productsSubcategoriesList/ProductsSubCatToolsListPage';
import {ProductsSubCatMachinesListPage} from 'pages/products/productsSubcategoriesList/ProductsSubCatMachinesListPage';
import {ProductsSubCatAgricultureListPage} from 'pages/products/productsSubcategoriesList/ProductsSubCatAgricultureListPage';
import ServicesListPage from 'pages/services/servicesList/ServicesListPage';
import AgricultureServicesPage from 'pages/common/agriculture/AgricultureServicesPage';
import UserItemsPage from 'pages/common/user/UserItemsPage';

const UnauthorizedRoutes: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>('home');
  const path = location.pathname.split('/')[1];
  if (path == 'home' && selectedTab !== 'home') {
    setSelectedTab('home');
  } else if (path == 'community' && selectedTab !== 'community') {
    setSelectedTab('community');
  } else if (path == 'map' && selectedTab !== 'map') {
    setSelectedTab('map');
  } else if (path == 'favorites' && selectedTab !== 'favorites') {
    setSelectedTab('favorites');
  } else if (path == 'profile' && selectedTab !== 'profile') {
    setSelectedTab('profile');
  }
  const isDark2 = useRecoilValue(isDarkTheme);

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route path='/home'>
          <HomePage />
        </Route>
        <Route path='/login' exact={true}>
          <AccountLoginPage />
        </Route>
        <Route path='/register'>
          <AccountRegisterPage />
        </Route>
        <Route path='/registerSecondStage'>
          <AccountRegister2Page />
        </Route>
        <Route path='/registerConfirmation'>
          <AccountRegisterConfirmationPage />
        </Route>
        <Route path='/report/:reportedElement/:reportedElementId'>
          <ReportPage />
        </Route>
        <Route path='/passwordRecovery'>
          <AccountRecoveryPage />
        </Route>
        <Route path='/home'>
          <HomePage />
        </Route>
        <Route path='/loan/instrument/:instrumentId'>
          <InstrumentDetailPage />
        </Route>
        <Route path='/instrument/:catName/:subCatName/:instrumentId'>
          <InstrumentDetailPage />
        </Route>
        <Route path='/instruments/categories/1/loan'>
          <InstrumentsSubCatToolsListPage />
        </Route>
        <Route path='/instruments/categories/2/loan'>
          <InstrumentsSubCatMachinesListPage />
        </Route>
        <Route path='/instruments/categories/3/loan'>
          <InstrumentsSubCatAgricultureListPage />
        </Route>
        <Route path='/instruments/categories/1/buy'>
          <ProductsSubCatToolsListPage />
        </Route>
        <Route path='/instruments/categories/2/buy'>
          <ProductsSubCatMachinesListPage />
        </Route>
        <Route path='/instruments/categories/3/buy'>
          <ProductsSubCatAgricultureListPage />
        </Route>
        <Route path='/instruments/subcategories/:subCatId/:catName/:subCatName'>
          <InstrumentsListPage />
        </Route>
        <Route path='/agricultural-offers'>
          <AgricultureServicesPage />
        </Route>
        <Route path='/product-main'>
          <AgricultureMenuPage />
        </Route>
        <Route path='/search/:itemsType/:category'>
          <SearchPage />
        </Route>
        <Route path='/search-map'>
          <SearchMapPage />
        </Route>
        <Route path='/map'>
          <MapPage />
        </Route>
        <Route path='/message/:id/:name'>
          <AccountPleaseSignInPage />
        </Route>
        <Route path='/sign-in'>
          <AccountPleaseSignInPage />
        </Route>
        <Route path='/instruments-add'>
          <InstrumentAddPage />
        </Route>
        <Route path='/products-add'>
          <ProductsAddPage />
        </Route>
        <Route path='/agriculture-menu'>
          <AgricultureMenuPage />
        </Route>
        <Route path='/instrument/:catName/:subCatName/:instrumentId'>
          <InstrumentDetailPage />
        </Route>
        <Route path='/products-add'>
          <ProductsAddPage />
        </Route>
        <Route path='/products/subcategories/:subCatId/:catName/:subCatName'>
          <ProductsListPage />
        </Route>
        <Route path='/product/:catName/:subCatName/:instrumentId'>
          <DetailProductsPage />
        </Route>
        <Route path='/service/:offerId'>
          <ServiceDetailPage />
        </Route>
        <Route path='/services-menu'>
          <ServicesMenuPage />
        </Route>
        <Route path='/job/:jobId'>
          <JobDetail />
        </Route>
        <Route path='/jobs'>
          <JobListPage />
        </Route>
        <Route path='/jobs-menu'>
          <JobsMenuPage />
        </Route>
        <Route path='/offers'>
          <ServicesListPage />
        </Route>
        <Route path='/pricelist'>
          <PriceListPage />
        </Route>
        <Route path='/tools-menu'>
          <ToolsMenuPage />
        </Route>
        <Route path='/machines-menu'>
          <MachinesMenuPage />
        </Route>
        <Route path='/contact'>
          <ContactPage />
        </Route>
        <Route path='/payment-result/:authToken/:paymentId/:cartId'>
          <PromotionsPaymentResultPage />
        </Route>
        <Route path='/payment/:paymentId/:authToken/:checkoutId/:cartId'>
          <PromotionPaymentPage />
        </Route>
        <Route path='/user-items/:id/:name/'>
          <UserItemsPage />
        </Route>
      </IonRouterOutlet>
      <IonTabBar
        color={`${isDark2 ? 'dark' : 'light'}`}
        onIonTabsWillChange={(e) => setSelectedTab(e.detail.tab)}
        className='bg-gray-500 lg:hidden'
        slot='bottom'
      >
        <IonTabButton className='bg-gray-500 text-white' tab='home' href='/home'>
          <IonIcon color={selectedTab === 'home' ? 'primary' : ''} icon={home} />
        </IonTabButton>
        <IonTabButton className='bg-gray-500 text-white' tab='community' href='/sign-in'>
          <IonIcon color={selectedTab === 'community' ? 'primary' : ''} icon={chatbubbles} />
        </IonTabButton>
        <IonTabButton className='bg-gray-500 text-white' tab='map' href='/map'>
          <IonIcon color={selectedTab === 'map' ? 'primary' : ''} icon={map} />
        </IonTabButton>
        <IonTabButton className='bg-gray-500 text-white' tab='favorites' href='/sign-in'>
          <IonIcon color={selectedTab === 'favorites' ? 'primary' : ''} icon={star} />
        </IonTabButton>
        <IonTabButton className='bg-gray-500 text-white' tab='profile' href='/sign-in'>
          <IonIcon color={selectedTab === 'profile' ? 'primary' : ''} icon={person} />
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default UnauthorizedRoutes;

import React from 'react';
import {IonPage} from '@ionic/react';
import {Link} from 'react-router-dom';
import BForm from 'forms/common/bForm/BForm';
import {formNewPassword} from 'forms/account/formPassword/newPassword.form';
import AppLoginBackground from 'pages/account/accountLogin/AccountLoginBackground';

import background from 'assets/background.png';
import logo from 'assets/logo.png';

type Props = {
  isLoading: boolean;
  onSubmit: Function;
  errors: any;
  submitPassword: boolean;
};

const AccountNewPasswrodPage = ({isLoading, onSubmit, errors, submitPassword}: Props) => {
  return (
    <IonPage className='bg-gray-200'>
      <div className='lg:flex lg:justify-center'>
        <div className='h-screen bg-gray-200 lg:w-1/3'>
          <img
            alt='background'
            src={background}
            className='absolute w-screen h-screen z-0 blur-sm lg:hidden'
          />
          <div className='flex justify-center h-2/6 flex-col'>
            <div className='h-1/6'></div>
            <div className='flex justify-center'>
              <img alt='logo' src={logo} className='w-64 h-28 z-20' />
            </div>
          </div>
          <AppLoginBackground>
            <div>
              {isLoading && <p>Loading...</p>}
              <p className='text-xl mt-4 mx-3 font-bold'>Passwort Vergessen</p>
              <div className='mx-3 mb-5'>
                <BForm
                  btnMargin={1}
                  btnText='Weiter'
                  fields={formNewPassword}
                  preloadedData={{}}
                  submit={onSubmit}
                  formErrors={errors}
                />
              </div>
              {submitPassword && (
                <>
                  <p className='my-2 text-yellow-accent text-center'>
                    Password has been changed correctly!
                  </p>
                  <p className='text-xs text-center mt-3'>Klicken Sie hier, um sich anzumelden</p>
                  <Link to='/login' className='text-yellow-accent w-full'>
                    <div
                      className='bg-white mt-1 mb-4 text-sm
            text-center rounded-lg border border-yellow-accent p-2 mx-3'
                    >
                      <b>Zum Einloggen</b>
                    </div>
                  </Link>
                </>
              )}
            </div>
          </AppLoginBackground>
        </div>
      </div>
    </IonPage>
  );
};

export default AccountNewPasswrodPage;

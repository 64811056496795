import {atom, useRecoilState} from 'recoil';
import {User} from 'interfaces/users/user.interface';

export const accountState = atom<User | undefined>({
  key: 'userState',
  default: undefined,
});

export const refreshTokenAtom = atom({
  key: 'refresh',
  default: '',
});

export const accessTokenAtom = atom({
  key: 'access',
  default: '',
});

export const isDarkTheme = atom({
  key: 'darkTheme',
  default: localStorage.getItem('theme') === 'dark',
  // Retrieves a value from local memory
});

export const useDarkTheme = () => {
  const [isDark2, setIsDark2] = useRecoilState(isDarkTheme);

  const toggleDarkTheme = () => {
    const newValue = !isDark2;
    setIsDark2(newValue);
    localStorage.setItem('theme', newValue ? 'dark' : 'light');
    // Writes the background value to memory
  };

  return {isDark2, toggleDarkTheme};
};

import React, {useEffect} from 'react';
import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar} from '@ionic/react';
import UILogo from 'components/uiLogo/UILogo';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import MessagesList from 'forms/messages/MessagesList';
import {accountState, isDarkTheme} from 'states/account/account.state';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {accountService} from 'api/account/account.service';

const ChatsListPage = () => {
  const isDark2 = useRecoilValue(isDarkTheme);
  const setAccountData = useSetRecoilState(accountState);

  const fetchCurrentUser = async () => {
    const res = await accountService.getCurrentUser();
    setAccountData(res.data);
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <IonButtons slot='end'></IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar>Kontakte</UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <div className='mb-10 flex justify-center'>
          <MessagesList />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ChatsListPage;

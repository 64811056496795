import React, {useEffect, useState} from 'react';
import {IonIcon, IonSelect, IonSelectOption, IonTextarea} from '@ionic/react';
import AddPhotos from 'components/uiAddPhoto/UIAddPhotos';
import UIYellowButton from 'components/uiButtons/UIYellowButton';
import {useForm} from 'react-hook-form';
import {BFormAddressField} from 'forms/common/places/BFormAddressField';
import UIInput from 'components/uiInputs/UIInput';
import {OrangeLine} from 'components/uiContainer/UIOrangeLine';
import {Service} from 'interfaces/services/service.interface';
import {servicesService} from 'api/services/services.service';
import LineColored from '../instruments/LineColored';
import {useHistory} from 'react-router';
import {userService} from 'api/users/users.service';
import {Category} from 'interfaces/instruments/instrument.interface';
import {useRecoilValue} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';
import EditPhotos from 'pages/common/editPhotos/EditPhotos';
import euroIcon from 'assets/icons/euro_icon.svg';

type Props = {
  agricultureCategory: number;
};

const FormOffer: React.FC<Props> = ({agricultureCategory}) => {
  const [apiResultMessage, setApiResultMessage] = useState('');
  const [serviceId, setInstrumentId] = useState<number>();
  const [address, setAddress] = useState<any>();
  const [subcategories, setSubcategories] = useState<Category[]>();
  const [subcatId, setSubcatId] = useState();
  const [isPhotoPopUp, setIsPhotoPopUp] = useState<boolean>();
  const [photos, setPhotos] = useState<any>();

  const {
    handleSubmit,
    register,
    setValue,
    control,
    formState: {errors},
  } = useForm();

  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userRes = await userService.getUserData();
        setValue('email', userRes.data.email);
        setValue('phoneNumber', userRes.data.phoneNumber);
        servicesService.getCategories().then((res) => {
          setSubcategories(res.data.results[agricultureCategory - 1].subcategories);
        });
      } catch {}
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (photos) {
      const photoHelper = [];
      for (let i = 0; i < 4; i++) {
        if (typeof photos['photo_' + i] === 'undefined' || photos['photo_' + i] == 'deletePhoto') {
          continue;
        }
        photoHelper.push(photos['photo_' + i]);
      }
      photoHelper.length > 0 && setValue('photos', photoHelper);
    }
  }, [photos]);

  const onSubmit = async (data: Service) => {
    data.companyName = data.name;
    data.pricePerHour = data.pricePerHour;
    data.category = agricultureCategory;
    data.subcategory = subcatId;
    data.location = data.Address.label;
    data.street = data.Address.street;
    data.postCode = data.Address.postCode;
    data.lat = data.Address.lat.toFixed(7);
    data.lng = data.Address.lng.toFixed(7);
    setAddress(data.Address);

    const method = servicesService.addService(data);

    setApiResultMessage('Ladung...');

    method
      .then((res) => {
        setApiResultMessage('');
        setInstrumentId(res.data.id);
        setAddress(data.Address);
        history.push(`/purchase/services/${res.data.id ? res.data.id : serviceId}`);
      })
      .catch((error) => {
        setApiResultMessage('Error! ' + error.response.data.message[0]);
      });
  };
  const isDark2 = useRecoilValue(isDarkTheme);

  return (
    <>
      {isPhotoPopUp && (
        <EditPhotos setPhotos={setPhotos} photos={photos} setIsPhotoPopUp={setIsPhotoPopUp} />
      )}
      {!isPhotoPopUp && (
        <form className='h-full pb-3 ' onSubmit={handleSubmit(onSubmit)}>
          <div
            className={`border-2 border-orange-main text-black rounded-md
      ${isDark2 ? 'bg-[#393939]' : 'bg-white'}`}
          >
            <div className={`h-6 ${isDark2 ? 'bg-[#F0A249]' : ' bg-yellow-accent'}`}></div>
            <div className='flex my-4 mx-2'>
              <div className='flex-col mx-2 w-2/5 space-y-1 text-sm'>
                <div
                  className='flex-col w-2/5 space-y-2 text-sm mb-16'
                  onClick={() => setIsPhotoPopUp(true)}
                >
                  <AddPhotos control={control} name='photos' disabled={true} />
                </div>
                <div className={`${isDark2 ? ' text-white' : 'text-black'}`}>
                  Mo - Fr:
                  <UIInput
                    customClassName={`w-full mb-2 text-sm break-all
                ${isDark2 ? 'bg-[#393939] text-white ' : 'bg-white text-gray-700'}`}
                    name='mondayToFriday'
                    placeholder='10:00 - 18:00'
                    register={register}
                    validation={{required: true}}
                    errors={errors}
                  />
                  <LineColored error={errors.mondayToFriday} />
                </div>
                <div className={`${isDark2 ? ' text-white' : 'text-black'}`}>
                  Sa - So:
                  <UIInput
                    customClassName={`w-full mb-2 text-sm break-all
                ${isDark2 ? 'bg-[#393939] text-white ' : 'bg-white text-gray-700'}`}
                    name='saturdayToSunday'
                    placeholder='10:00 - 14:00'
                    register={register}
                    validation={{required: true}}
                    errors={errors}
                  />
                  <LineColored error={errors.saturdayToSunday} />
                </div>
                <div className={`${isDark2 ? ' text-white' : 'text-black'}`}>
                  Preis: (pro Tag/ pro Stunde usw.)
                  <div className='flex text-gray-700'>
                    <UIInput
                      customClassName={`w-full mb-2 text-sm break-all
                  ${isDark2 ? 'bg-[#393939] text-white ' : 'bg-white text-gray-700'}`}
                      type='text'
                      placeholder='15'
                      name='pricePerHour'
                      register={register}
                      validation={{required: true}}
                      errors={errors}
                    />
                    <IonIcon
                      icon={euroIcon}
                      className={`h-4 w-[18px] mt-1 text-gray-500 lg:absolute lg:left-[165px]
                  ${isDark2 ? ' text-white' : 'text-black'}`}
                    />
                  </div>
                  <div className='lg:w-[150px]'>
                    <LineColored error={errors.pricePerHour} />
                  </div>
                </div>
              </div>
              <div className='flex-col space-y-2 mx-2 w-3/5 text-sm'>
                <div
                  className='w-44 h-6 p-4 flex items-center justify-between
              rounded-xl border-orange-main border text-orange-main '
                >
                  <IonSelect
                    okText='Weiter'
                    cancelText='Abbrechen'
                    className={`${isDark2 ? ' text-white' : ' text-black'}`}
                    placeholder='Kategorie'
                    value={subcatId && subcatId}
                    onIonChange={(cat) => setSubcatId(cat.detail.value)}
                  >
                    {subcategories &&
                      subcategories.map((cat) => (
                        <IonSelectOption key={cat.id} value={cat.id}>
                          {cat.name}
                        </IonSelectOption>
                      ))}
                  </IonSelect>
                </div>
                <div className={`${isDark2 ? ' text-white' : 'text-black'}`}>
                  Firmenname:{' '}
                  <UIInput
                    required='required'
                    placeholder='Firma'
                    customClassName={`w-full mb-2 text-sm break-all
                ${isDark2 ? 'bg-[#393939] text-white ' : 'bg-white text-gray-700'}`}
                    register={register}
                    name='name'
                    validation={{required: true}}
                    errors={errors}
                  />
                  <LineColored error={errors.name} />
                </div>
                <div className={`${isDark2 ? ' text-white' : 'text-black'}`}>
                  Branche:{' '}
                  <UIInput
                    required='required'
                    customClassName={`w-full mb-2 text-sm break-all
                ${isDark2 ? 'bg-[#393939] text-white ' : 'bg-white text-gray-700'}`}
                    placeholder='Geschäft'
                    register={register}
                    name='industry'
                    validation={{required: true}}
                    errors={errors}
                  />
                  <LineColored error={errors.industry} />
                </div>
                <div className={`${isDark2 ? ' text-white' : 'text-black'}`}>
                  Email:{' '}
                  <UIInput
                    customClassName={`w-full mb-2 text-sm break-all
                ${isDark2 ? 'bg-[#393939] text-white ' : 'bg-white text-gray-700'}`}
                    type='text'
                    register={register}
                    name='email'
                    placeholder='beispiel@email.com'
                    validation={{required: true}}
                    errors={errors}
                  />
                  <LineColored error={errors.email} />
                </div>
                <div className={`${isDark2 ? ' text-white' : 'text-black'}`}>
                  Telefonnummer{' '}
                  <UIInput
                    required='required'
                    customClassName={`w-full mb-2 text-sm break-all
                ${isDark2 ? 'bg-[#393939] text-white ' : 'bg-white text-gray-700'}`}
                    register={register}
                    name='phoneNumber'
                    type='tel'
                    placeholder='0660 1234567'
                    validation={{required: true}}
                    errors={errors}
                    maxLength={16}
                    minLength={9}
                  />
                  <LineColored error={errors.phoneNumber} />
                </div>
              </div>
            </div>
            <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
              <div className='text-orange-main text-sm'>Adresse</div>
              <div className='bg-orange-main w-full h-px'></div>
            </div>
            <div className='mx-2 mt-2'>
              <BFormAddressField
                placeholder={address?.label}
                control={control}
                fieldName='Address'
                key={Math.random()}
              />
            </div>
            <OrangeLine name='Beschreibung' />
            <IonTextarea
              maxlength={1000}
              rows={4}
              className={`mx-2 pr-2 h-[120px] ${isDark2 ? ' text-white' : 'text-black'}`}
              {...register('description')}
              placeholder='Beschreibe deine Dienstleistung'
            />
          </div>
          <p className='text-yellow-accent text-center font-bold mb-5'>{apiResultMessage}</p>
          <div className='flex flex-col justify-end text-white'>
            <UIYellowButton type='submit' text='Weiter' />
          </div>
        </form>
      )}
    </>
  );
};

export default FormOffer;

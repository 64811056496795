import {IonButton} from '@ionic/react';
import React from 'react';

interface UIOrangeProps {
  title: string;
  type: any;
  onClick?: any;
}

const UIOrangeButton: React.FC<UIOrangeProps> = ({title, type, onClick}) => {
  return (
    <div className='flex justify-center items-center p-2'>
      <IonButton
        type={type}
        onClick={onClick}
        className='w-[387px] h-[44px] bg-orange-main rounded-xl'
      >
        <p className='text-center text-[16px] py-3 font-bold text-white'>
          {title}
        </p>
      </IonButton>
    </div>
  );
};

export default UIOrangeButton;

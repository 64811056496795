import React from 'react';
import {useForm} from 'react-hook-form';
import UIYellowButton from 'components/uiButtons/UIYellowButton';
import {diysService} from 'api/diy/diy.service';
import {postsService} from 'api/forum/forum.service';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {refreshCounterState} from 'states/common/common.state';
import {IonTextarea} from '@ionic/react';
import {isDarkTheme} from 'states/account/account.state';

type Props = {
  id: string;
  type: string;
  count: number;
  setCount: any;
};

const CreateComment: React.FC<Props> = ({id, type, count, setCount}) => {
  const {register, handleSubmit, reset} = useForm();
  const setRefreshCounter = useSetRecoilState(refreshCounterState);

  const onSubmit = (data: any) => {
    data.rating = 0;

    if (type === 'post') {
      postsService.addPostComment(id, data).then(() => {
        reset();
        setCount(count + 1);
        setRefreshCounter((prev) => prev + 1);
      });
    }

    if (type === 'diy') {
      diysService.postDIYComment(id, data).then(() => {
        reset();
        setCount(count + 1);
        setRefreshCounter((prev) => prev + 1);
      });
    }
  };
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <form onSubmit={handleSubmit(onSubmit)} className='mx-2 text-sm w-full mb-4'>
      <div className='flex mt-2 w-full'>
        <IonTextarea
          placeholder='Antwort...'
          className={`px-4 text-[13px] rounded-lg border border-gray-400 bg-transparent w-full
          ${isDark2 ? 'text-white' : 'text-black'}`}
          {...register('comment')}
          maxlength={1000}
          rows={1}
          clearOnEdit={true}
        />
        <UIYellowButton text='Antworten' customClassName='ml-2 pr-4 text-xs font-sans' />
      </div>
    </form>
  );
};

export default CreateComment;

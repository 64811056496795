import React from 'react';
import {IonTabBar, IonTabButton} from '@ionic/react';
import {useRecoilValue} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';

export default function UITopBar({
  activeTab,
  setActiveTab,
  firstTab,
  secondTab,
  thridTab,
  fourthTab,
  fifthTab,
}: any) {
  const sizeFont = fourthTab ? `text-[11px]` : `text-[17px]`;
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <IonTabBar
      color={`${isDark2 ? 'dark' : 'light'}`}
      className='h-[44px] overflow-auto'
      onIonTabsWillChange={(e) => setActiveTab(e.detail.tab)}
    >
      <IonTabButton
        tab={firstTab}
        className={
          activeTab === firstTab
            ? `border-b-[2px] border-orange-400 text-orange-300 ${sizeFont}`
            : `${sizeFont} border-b-[2px]
            ${isDark2 ? 'text-white' : 'text-black'}`
        }
      >
        {firstTab}
      </IonTabButton>

      <IonTabButton
        tab={secondTab}
        className={
          activeTab === secondTab
            ? `border-b-[1px] border-orange-400 text-orange-300 ${sizeFont}`
            : `${sizeFont} border-b-[2px]
            ${isDark2 ? 'text-white' : 'text-black'}`
        }
      >
        {secondTab}
      </IonTabButton>

      {thridTab && (
        <IonTabButton
          tab={thridTab}
          className={
            activeTab === thridTab
              ? `border-b-[1px] border-orange-400 text-orange-300 ${sizeFont}`
              : `${sizeFont} border-b-[2px]
            ${isDark2 ? 'text-white' : 'text-black'}`
          }
        >
          {thridTab}
        </IonTabButton>
      )}

      {fourthTab && (
        <IonTabButton
          tab={fourthTab}
          className={
            activeTab === fourthTab
              ? `border-b-[1px] border-orange-400 text-orange-300 ${sizeFont}`
              : `${sizeFont} border-b-[2px]
            ${isDark2 ? 'text-white' : 'text-black'}`
          }
        >
          {fourthTab}
        </IonTabButton>
      )}

      {fifthTab && (
        <IonTabButton
          tab={fifthTab}
          className={
            activeTab === fifthTab
              ? `border-b-[1px] border-orange-400 text-orange-300 ${sizeFont}`
              : `${sizeFont} border-b-[2px]
            ${isDark2 ? 'text-white' : 'text-black'}`
          }
        >
          {fifthTab}
        </IonTabButton>
      )}
    </IonTabBar>
  );
}

import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar} from '@ionic/react';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import UILogo from 'components/uiLogo/UILogo';
import React, {useEffect, useState} from 'react';
import buyToolsImg from 'assets/buy_tools.svg';
import rentToolsImg from 'assets/rent_tool.svg';
import sellToolsImg from 'assets/sell_tool.svg';
import putToolsForRentImg from 'assets/put_tool_for_rent.svg';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UIResponsivePadding from 'components/uiResponsive/UIResponsivePadding';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';
import UIOrangeCategoryBox from 'components/uiContainer/UIOrangeCategoryBox';
import {instrumentsService} from 'api/instruments/instruments.service';
import {authService} from 'api/account/auth.service';
import {ItemsCounterInterface} from 'interfaces/common/itemsCounter.interface';
import {refreshCounterState} from 'states/common/common.state';

const ToolsMenuPage: React.FC = () => {
  const token = authService.tokenValue;
  const isDark2 = useRecoilValue(isDarkTheme);
  const [itemsCounter, setItemsCounter] = useState<ItemsCounterInterface>();
  const setRefreshCounter = useSetRecoilState(refreshCounterState);

  useEffect(() => {
    instrumentsService.getInstrumentsCount().then((res)=>{
      setItemsCounter(res.data);
      setRefreshCounter((prev) => prev + 1);
    });
  }, []);

  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar>Werkzeug</UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <UIResponsivePadding>
          <div className='mt-4'>
            <UIOrangeCategoryBox
              name={'WERKZEUG MIETEN'}
              src={rentToolsImg}
              alt={'BuyTools'}
              count={itemsCounter?.werkzeugInstruments}
              linkTo={'/instruments/categories/1/loan'}
            />
            <UIOrangeCategoryBox
              name={'WERKZEUG VERMIETEN'}
              src={putToolsForRentImg}
              alt={'SellTools'}
              linkTo={!token ? '/sign-in' : '/instruments-add'}
            />
            <UIOrangeCategoryBox
              name={'WERKZEUG KAUFEN'}
              src={buyToolsImg}
              alt={'BuyTools'}
              count={itemsCounter?.werkzeugProducts}
              linkTo={'/instruments/categories/1/buy'}
            />
            <UIOrangeCategoryBox
              name={'WERKZEUG VERKAUFEN'}
              src={sellToolsImg}
              alt={'SellTools'}
              linkTo={!token ? '/sign-in' : '/products-add'}
            />
          </div>
        </UIResponsivePadding>
      </IonContent>
    </IonPage>
  );
};

export default ToolsMenuPage;

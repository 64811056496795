import {Instrument} from 'interfaces/instruments/instrument.interface';
import {Job} from 'interfaces/jobs/jobs.interface';
import {Product} from 'interfaces/products/product.interface';
import {Service} from 'interfaces/services/service.interface';

export const sameLocationItemsLister = (
  lat: string,
  lng: string,
  werkzeugMieten?: Instrument[],
  baumaschinenMieten?: Instrument[],
  agricultureMieten?: Instrument[],
  services?: Service[],
  jobs?: Job[],
  werkzeugKaufen?: Product[],
  baumaschinenKaufen?: Product[],
  agricultureKaufen?: Product[]
) => {
  let length = 0;

  const sameLocationWerkzeugMieten = werkzeugMieten?.filter((x) => x.lat === lat && x.lng === lng);
  const sameLocationBaumaschinenMieten = baumaschinenMieten?.filter(
    (x) => x.lat === lat && x.lng === lng
  );
  const sameLocationWerkzeugKaufen = werkzeugKaufen?.filter((x) => x.lat === lat && x.lng === lng);
  const sameLocationLandAndForsttechnikMieten = agricultureMieten?.filter(
    (x) => x.lat === lat && x.lng === lng
  );
  const sameLocationBaumaschinenKaufen = baumaschinenKaufen?.filter(
    (x) => x.lat === lat && x.lng === lng
  );
  const sameLocationLandAndForsttechnikKaufen = agricultureKaufen?.filter(
    (x) => x.lat === lat && x.lng === lng
  );
  const sameLocationServices = services?.filter((x) => x.lat === lat && x.lng === lng);
  const sameLocationJobs = jobs?.filter((x) => x.lat === lat && x.lng === lng);

  sameLocationWerkzeugMieten && (length += sameLocationWerkzeugMieten.length);
  sameLocationBaumaschinenKaufen && (length += sameLocationBaumaschinenKaufen.length);
  sameLocationServices && (length += sameLocationServices.length);
  sameLocationJobs && (length += sameLocationJobs.length);
  sameLocationBaumaschinenMieten && (length += sameLocationBaumaschinenMieten.length);
  sameLocationWerkzeugKaufen && (length += sameLocationWerkzeugKaufen.length);
  sameLocationLandAndForsttechnikMieten && (length += sameLocationLandAndForsttechnikMieten.length);
  sameLocationLandAndForsttechnikKaufen && (length += sameLocationLandAndForsttechnikKaufen.length);

  if (length <= 1) return;

  const itemsList: {
    werkzeugMieten?: Instrument[];
    baumaschinenMieten?: Instrument[];
    agricultureMieten?: Instrument[];
    services?: Service[];
    jobs?: Job[];
    werkzeugKaufen?: Product[];
    baumaschinenKaufen?: Product[];
    agricultureKaufen?: Product[];
  } = {
    werkzeugMieten: [],
    baumaschinenMieten: [],
    agricultureMieten: [],
    services: [],
    jobs: [],
    werkzeugKaufen: [],
    baumaschinenKaufen: [],
    agricultureKaufen: [],
  };

  sameLocationWerkzeugMieten && (itemsList.werkzeugMieten = sameLocationWerkzeugMieten);
  sameLocationBaumaschinenKaufen && (itemsList.baumaschinenKaufen = sameLocationBaumaschinenKaufen);
  sameLocationServices && (itemsList.services = sameLocationServices);
  sameLocationJobs && (itemsList.jobs = sameLocationJobs);
  sameLocationBaumaschinenMieten && (itemsList.baumaschinenMieten = sameLocationBaumaschinenMieten);
  sameLocationWerkzeugKaufen && (itemsList.werkzeugKaufen = sameLocationWerkzeugKaufen);
  sameLocationLandAndForsttechnikMieten &&
    (itemsList.agricultureMieten = sameLocationLandAndForsttechnikMieten);
  sameLocationLandAndForsttechnikKaufen &&
    (itemsList.agricultureKaufen = sameLocationLandAndForsttechnikKaufen);

  return itemsList;
};

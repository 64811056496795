import {BFormFieldType} from 'forms/common/bForm/BFormField';

export const formRegisterConfirmation: BFormFieldType[] = [
  {
    fieldName: 'phoneNumber',
    fieldType: 'text',
    placeHolder: 'Telefonnummer',
    isConfirmationNumber: true,
    validation: {required: true},
  },
  {
    fieldName: 'activationCode',
    fieldType: 'text',
    placeHolder: 'Code',
    validation: {
      required: true,
    },
  },
];

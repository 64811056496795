import React from 'react';
import {useRecoilValue} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';

type Props = {
  children: JSX.Element;
};

const AppSignInBackground = ({children}: Props) => {
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <div>
      <div
        className={`w-full h-full flex justify-center
      ${isDark2 ? 'bg-[#474747]' : 'bg-[#f8f8f8] '}`}
      >
        <div
          className={`w-3/4 h-54 rounded-lg drop-shadow-xl
            ${isDark2 ? 'bg-[#F0A249]' : ' bg-yellow-accent'}`}
        >
          <div
            className='h-full text-black  bg-white border-2 border-yellow-accent mt-2
          rounded-b-lg'
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppSignInBackground;

import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar} from '@ionic/react';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UILogo from 'components/uiLogo/UILogo';
import {postsService} from 'api/forum/forum.service';
import {dateHandler} from 'utils/dateHandler';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import CreateComment from 'components/uiComments/CreateComment';
import LineColored from 'forms/instruments/LineColored';
import CommentBox from 'components/uiComments/CommentBox';
import {ForumPostInterface} from 'interfaces/posts/forumPost.interface';
import {useRecoilValue} from 'recoil';
import {refreshCounterState} from 'states/common/common.state';
import {isDarkTheme} from 'states/account/account.state';
import {urlConverter} from 'utils/urlConverter';

const PostForumDetailsPage = () => {
  const {id: id} = useParams<{id: string}>();

  const shouldRefreshData = useRecoilValue(refreshCounterState);

  const [post, setPost] = useState<ForumPostInterface>();
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    postsService.getPost(id).then((res) => {
      setPost(res.data);
    });
  }, [shouldRefreshData]);
  const isDark2 = useRecoilValue(isDarkTheme);

  let photo = '';
  if (post && post.profilePictures) {
    photo = urlConverter(post.profilePictures);
  }

  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar>Forum</UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        {post && (
          <div>
            <div
              className={`rounded-xl border
              border-yellow-accent mx-3 mt-2 mb-4 font-sans
              ${isDark2 ? 'bg-[#393939]' : 'bg-[#F8F8F8]'}`}
            >
              <div
                className={`h-8 rounded-t-lg
                w-full text-xs px-3 bg-yellow-accent`}
              >
                <div className='absolute flex'>
                  <img src={photo} className='h-6 w-6 mt-1' />
                  <p className='text-white mt-2 ml-2'>{post.firstName}</p>
                </div>
                <p className='text-white pt-2 flex justify-end'>{dateHandler(post.dateAdded)}</p>
              </div>
              <p
                className={`pl-2 text-sm pt-2 font-bold ${isDark2 ? 'text-white ' : 'text-black'}`}
              >
                {post.title}
              </p>
              <p className={`pl-2 text-xs my-4 ${isDark2 ? ' text-white ' : 'text-black '}`}>
                {post.description}
              </p>
            </div>
            <div className='mx-3'>
              <CreateComment count={count} setCount={setCount} id={post.id} type='post' />
            </div>
            <LineColored color='yellow' colorIntensity='accent' />
            {post.comments.map((comment) => (
              <CommentBox key={comment.id} comment={comment} />
            ))}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default PostForumDetailsPage;

import React, {useEffect, useState} from 'react';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonIcon,
  IonTabButton,
  IonTabBar,
} from '@ionic/react';
import {useRecoilValue} from 'recoil';
import {useParams} from 'react-router';
import UILogo from 'components/uiLogo/UILogo';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import locationoutline from '../../../assets/tab/location_outline.svg';
import location from '../../../assets/tab/location.svg';
import notificationsoutline from '../../../assets/tab/notifications_outline.svg';
import notifications from '../../../assets/tab/notifications.svg';
import star from '../../../assets/tab/star.svg';
import staroutline from '../../../assets/tab/star_outline.svg';
import {PromotionsCostsList} from './PromotionsCostsList';
import {cart, cartOutline} from 'ionicons/icons';
import PromotionsCart from '../promotionsCart/PromotionsCart';
import {accountState, isDarkTheme} from 'states/account/account.state';
import {refreshCounterState} from 'states/common/common.state';

const firstTab = 'list';
const secondTab = 'map';
const thirdTab = 'push';
const fourthTab = 'cart';

export const PromotionsCostsListPage = () => {
  const [activeTab, setActiveTab] = useState(firstTab);
  const {type} = useParams<{type: string}>();
  const shouldRefreshData = useRecoilValue(refreshCounterState);
  const userData = useRecoilValue(accountState);

  useEffect(() => {
    setActiveTab(firstTab);
  }, [shouldRefreshData]);

  const getBreadCrumb = () => {
    switch (type) {
      case 'instruments':
        return 'Mietgeräte';
      case 'products':
        return 'Verkaufen';
      case 'services':
        return 'Dienstleistungen';
      case 'jobs':
        return 'Jobinserat';
    }
  };

  const isDark2 = useRecoilValue(isDarkTheme);

  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar
          customClassName='h-10 bg-gray-600 text-center'
          customTextClassName='w-full pt-1.5'
        >
          {getBreadCrumb()} &gt; Meine Werbungen
        </UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <div className='h-3/4'>
          <IonTabBar color={`${isDark2 ? 'dark' : 'light'}`} slot='bottom' className='h-[44px]'>
            <IonTabButton
              tab={firstTab}
              className={` ${
                activeTab === firstTab
                  ? 'border-b-[1px] border-orange-300 text-orange-300 text-[17px]'
                  : 'text-[17px] border-b-[1px] border-gray-500 '
              } ${isDark2 ? 'text-yellow-accent' : 'text-black '}`}
            >
              {activeTab === firstTab ? <IonIcon icon={star} /> : <IonIcon icon={staroutline} />}
            </IonTabButton>
            {type !== 'products' && userData?.accountType !== 'PRIVATE' && (
              <IonTabButton
                tab={secondTab}
                className={` ${
                  activeTab === secondTab
                    ? 'border-b-[1px] border-orange-300 text-orange-300 text-[17px]'
                    : 'text-[17px] border-b-[1px] border-gray-500 '
                } ${isDark2 ? 'text-yellow-accent' : 'text-black '}`}
              >
                {activeTab === secondTab ? (
                  <IonIcon icon={location} />
                ) : (
                  <IonIcon icon={locationoutline} />
                )}
              </IonTabButton>
            )}
            {(type == 'services' || type == 'jobs') && (
              <IonTabButton
                tab={thirdTab}
                className={` ${
                  activeTab === thirdTab
                    ? 'border-b-[1px] border-orange-300 text-orange-300 text-[17px]'
                    : 'text-black  text-[17px] border-b-[1px] border-gray-500 '
                } ${isDark2 ? 'text-yellow-accent' : 'text-black '}`}
              >
                {activeTab === thirdTab ? (
                  <IonIcon icon={notifications} />
                ) : (
                  <IonIcon icon={notificationsoutline} />
                )}
              </IonTabButton>
            )}
            <IonTabButton
              tab={fourthTab}
              className={` ${
                activeTab === fourthTab
                  ? 'border-b-[1px] border-orange-300 text-orange-300 text-[17px]'
                  : 'text-black text-[17px] border-b-[1px] border-gray-500 '
              }${isDark2 ? 'text-yellow-accent' : 'text-black '}`}
            >
              <IonIcon icon={activeTab === fourthTab ? cart : cartOutline} />
            </IonTabButton>
          </IonTabBar>
          {activeTab === firstTab && (
            <PromotionsCostsList
              prefix='Anzeige hervorheben für'
              suffix='Tage'
              promotionType={'list'}
              nextTab={type === 'products' || userData?.accountType == 'PRIVATE' ? 'cart' : 'map'}
              setActiveTab={setActiveTab}
            />
          )}
          {activeTab === secondTab && (
            <PromotionsCostsList
              prefix='Logo einstellen bei Maps für'
              suffix='Tage'
              promotionType={'map'}
              setActiveTab={setActiveTab}
              nextTab={type === 'instruments' ? 'cart' : 'push'}
            />
          )}
          {activeTab === thirdTab && (
            <PromotionsCostsList
              prefix='1 x Push Nachricht an '
              suffix='User'
              promotionType={'push'}
              setActiveTab={setActiveTab}
              nextTab={'cart'}
            />
          )}
          {activeTab === fourthTab && <PromotionsCart type={type} />}
        </div>
      </IonContent>
    </IonPage>
  );
};

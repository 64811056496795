import React, {useState} from 'react';
import {TextFieldTypes} from '@ionic/core';
import formatError from 'utils/formatError';
import {eyeOffOutline, eyeOutline} from 'ionicons/icons';
import {IonIcon} from '@ionic/react';
import AccountTypeSelect from 'pages/account/accountEdit/AccountTypeSelect';
import {useRecoilValue} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';

export type BFormFieldType = {
  fieldName: string;
  placeHolder: string;
  validation: any;
  isNumberPrefix?: boolean;
  isConfirmationNumber?: boolean;
  fieldType: TextFieldTypes | 'checkbox';
};

const BFormField = ({
  field,
  errors,
  register,
  setCheckedValue,
  value,
  onChange,
}: {
  field: BFormFieldType;
  errors: any;
  register: Function;
  setCheckedValue: Function;
  value?: string;
  onChange?: Function;
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const isDark2 = useRecoilValue(isDarkTheme);
  const hasFieldErrors: boolean = errors && field.fieldName in errors;

  return (
    <div className={`text-sm mt-3 ${isDark2 ? 'text-white' : 'text-black'}`} key={field.fieldName}>
      {field.placeHolder}
      <div className='flex'>
        {field.isNumberPrefix && (
          <div className='absolute border-r border-black h-9 w-11 text-center pt-2'>+43</div>
        )}
        {onChange ? (
          <input
            className={
              'appearance-none border border-black rounded-lg w-full p-2 ' +
              'bg-transparent focus:outline-none focus:shadow-outline ' +
              (hasFieldErrors ? 'border-red-500 ' : 'border-black ') +
              (field.fieldType === 'checkbox' ? 'border-white ' : 'border-black ') +
              (field.isNumberPrefix && 'pl-12')
            }
            value={field.isConfirmationNumber ? value : null}
            placeholder={null}
            type={visible ? 'text' : field.fieldType}
            {...register(field.fieldName, {...field.validation})}
            onChange={onChange}
          />
        ) : (
          <input
            className={
              'appearance-none border border-black rounded-lg w-full p-2 ' +
              'bg-transparent focus:outline-none focus:shadow-outline ' +
              (hasFieldErrors ? 'border-red-500 ' : 'border-black ') +
              (field.fieldType === 'checkbox' ? 'border-white ' : 'border-black ') +
              (field.isNumberPrefix && 'pl-12')
            }
            value={field.isConfirmationNumber ? value : null}
            placeholder={null}
            type={visible ? 'text' : field.fieldType}
            {...register(field.fieldName, {...field.validation})}
          />
        )}
        {field.fieldType === 'password' && (
          <div className='absolute right-6 flex justify-end'>
            <div className='w-full'></div>
            <div>
              {!visible && (
                <IonIcon
                  icon={eyeOutline}
                  className='h-5 w-5 pt-2'
                  onClick={() => setVisible(true)}
                />
              )}
              {visible && (
                <IonIcon
                  icon={eyeOffOutline}
                  className='h-5 w-5 pt-2'
                  onClick={() => setVisible(false)}
                />
              )}
            </div>
          </div>
        )}
      </div>
      {field.fieldType === 'checkbox' && <AccountTypeSelect setCheckedValue={setCheckedValue} />}
      {hasFieldErrors && formatError(errors[field.fieldName])}
    </div>
  );
};

export default BFormField;

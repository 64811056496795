import {ShortUser} from 'interfaces/users/user.interface';

export interface Category {
  id: number;
  name: string;
}

export interface avgRating {
  avgRating: number;
}

export interface Subcategory {
  id: number;
  name: string;
}

export interface FavoriteInstrument {
  createdAt: string;
  instrument: Instrument;
}

export interface Instrument {
  availability: boolean;
  avgRating: avgRating;
  comments?: any;
  promoted: boolean;
  promotedUntil: string;
  category: Category;
  description: string;
  id?: any;
  photos: any;
  lat?: any;
  lng?: any;
  dateAdded: Date;
  instrumentName: string;
  purpose?: string;
  location: string;
  name: string;
  pricePerDay: number;
  subcategory: Category;
  email: string;
  existedChat?: number;
  phoneNumber: string;
  owner: ShortUser;
  instrument: number;
  price: number;
  productName: string;
  productsName: string;
  distance?: number;
  productionYear?: number;
  createdAt: string;
  issuedPromotedUntil: string;
  mapPromotedUntil: string;
  countFavourite: string;
  visitors: string;
  isVisibleEmail?: boolean;
  isVisiblePhoneNumber?: boolean;
  commented?: boolean;
}

export interface InstrumentRequest {
  id?: any;
  name?: string;
  instrumentName?: string;
  purpose?: string;
  availability?: any;
  email?: string;
  productionYear?: string | number;
  location?: string;
  lat?: any;
  lng?: any;
  postCode?: string;
  phoneNumber?: string;
  category?: any;
  subcategory?: any;
  photos?: any;
  comments?: any;
  weekHours?: string;
  weekendHours?: string;
  pricePerDay?: any;
  description?: string;
  Address?: any;
  instrument?: any;
  price?: any;
  productName?: string;
  issuedPromoted?: boolean;
  promoted?: boolean;
  mapPromoted?: boolean;
  visitors?: string;
  promotedUntil?: string;
  issuedPromotedUntil?: string;
  mapPromotedUntil?: string;
  countFavourite?: string;
  not_delete_photos?: any;
}

export const DefaultInstrumentRequestValues = {
  name: '',
  instrumentName: '',
  purpose: '',
  availability: undefined,
  email: '',
  productionYear: undefined,
  location: '',
  lat: 0,
  lng: 0,
  postCode: '',
  phoneNumber: '',
  category: '',
  subcategory: '',
  images: undefined,
  weekHours: '',
  weekendHours: '',
  pricePerDay: undefined,
  description: '',
  issuedPromoedUntil: '',
  mapPromotedUntil: '',
  visitors: '',
};

import React from 'react';
import {UIImage} from 'components/uiImages/UIImage';
import {IonIcon, useIonRouter} from '@ionic/react';
import {logoEuro} from 'ionicons/icons';
import {distanceCalculator} from 'utils/distanceCalculator';
import {geolocationState} from 'states/common/common.state';
import {useRecoilValue} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';

interface SearchResultProps {
  items: any[];
}
export const SearchMapResults: React.FC<SearchResultProps> = ({items}) => {
  const router = useIonRouter();
  const geolocation = useRecoilValue(geolocationState);
  items.forEach((item: any) => {
    item.distance = distanceCalculator(item.lat, item.lng, geolocation);
  });
  items.sort((a: any, b: any) => (a.distance! > b.distance! ? 1 : -1));
  const handleItemClicked = (item: any) => {
    if (item.pricePerDay) {
      router.push(`/instrument/${item.category.name}/${item.subcategory.name}/${item.id}`);
      return;
    }
    if (item.pricePerHour) {
      router.push(`/service/${item.id}`);
      return;
    }
    if (item.salary) {
      router.push(`/job/${item.id}`);
      return;
    }
  };
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <div>
      {items &&
        items.map((item) => (
          <div
            key={item.name! + item.id}
            onClick={() => handleItemClicked(item)}
            className='w-[90%] md:w-2/3 max-w-screen-md mx-auto my-3'
          >
            <div
              className={`${
                item.promoted
                  ? 'shadow-custom shadow-orange-promoted border rounded-3xl border-orange-promoted'
                  : 'border rounded-3xl border-yellow-accent'
              } ${isDark2 ? 'bg-[#393939]  drop-shadow-md' : 'bg-[#F8F8F8] drop-shadow-md'}`}
            >
              <div>
                <UIImage
                  type={'item'}
                  photoLink={item.photos.length > 0 && item.photos[0].photo}
                  customClassName='h-20 w-full rounded-t-3xl'
                />
                <div className='flex justify-end mr-3'>
                  <div className='bg-yellow-accent text-white absolute p-2 top-16 rounded-2xl flex'>
                    <IonIcon icon={logoEuro} className='text-sm' />
                    <p className='text-xs'>{item.pricePerDay && item.pricePerDay}</p>
                    <p className='text-xs'>{item.pricePerHour && item.pricePerHour}</p>
                    <p className='text-xs'>{item.salary && item.salary}</p>
                  </div>
                </div>
              </div>
              <div>
                <div className='mt-2'>
                  <p
                    className={`text-md font-medium ml-5
              ${isDark2 ? 'text-white' : 'text-gray-600'}`}
                  >
                    {item.jobOffer && item.jobOffer}
                    {item.instrumentName && item.instrumentName}
                    {item.productName && item.productName}
                    {item.industry}
                  </p>
                  <p
                    className={` text-sm ml-7
              ${isDark2 ? 'text-white' : 'text-gray-600'}`}
                  >
                    {item.name}
                  </p>
                  <div className='flex justify-between'>
                    <p
                      className={`text-[12px] ml-7 mb-4
                ${isDark2 ? 'text-gray-300' : 'text-gray-600'}`}
                    >
                      {item.location}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

import {IonButtons, IonContent, IonHeader, IonPage, IonTextarea, IonToolbar} from '@ionic/react';
import {accountService} from 'api/account/account.service';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UIYellowButton from 'components/uiButtons/UIYellowButton';
import UIInput from 'components/uiInputs/UIInput';
import UILogo from 'components/uiLogo/UILogo';
import AppLoginBackground from 'pages/account/accountLogin/AccountLoginBackground';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import background from 'assets/background.png';
import {userService} from 'api/users/users.service';
import {useRecoilValue} from 'recoil';
import {accountState, isDarkTheme} from 'states/account/account.state';
import {useHistory} from 'react-router';
import {User} from 'interfaces/users/user.interface';

const ContactPage = () => {
  const [erflogMessage, setErflogMessage] = useState('');
  const [message, setMessage] = useState('');
  const history = useHistory();
  const accountData = useRecoilValue<User | undefined>(accountState);

  const {
    handleSubmit,
    register,
    setValue,
    formState: {errors},
    reset,
  } = useForm();

  useEffect(() => {
    accountData &&
      accountService.getCurrentUser().then((response) => {
        if (response.data.firstName) {
          setValue('name', response.data.firstName);
        }
        if (response.data.email) {
          setValue('email', response.data.email);
        }
        if (response.data.phoneNumber) {
          setValue('phone', response.data.phoneNumber);
        }
      });
  }, []);
  const onSubmit = (data: any) => {
    data.name = data.name;
    data.email = data.email;
    data.phone = data.phone;
    data.msg = data.msg;

    userService.sendMessageToAdmin(data).then(() => {
      setErflogMessage('Erfolg!');
      reset();
      setMessage('');
      history.push('/home');
    });
  };
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <img
          alt='Background'
          src={background}
          className='absolute w-screen h-screen z-0 blur-sm lg:hidden'
        />
        <div className='pt-12'>
          <AppLoginBackground>
            <div className='pt-2'>
              <b className={`text-xl mx-3 ${isDark2 ? 'text-white' : 'text-black '}`}>
                Kontaktiere uns
              </b>
              <form className='h-full pb-3 mx-2 mt-2' onSubmit={handleSubmit(onSubmit)}>
                <p className={`text-lg mt-2 ${isDark2 ? 'text-white' : 'text-black'}`}>Name:</p>
                <UIInput
                  customClassName={`border-2 border-gray-400 w-full pl-2 p-1 rounded-lg
                    ${isDark2 ? 'text-white bg-[#393939] ' : 'text-black bg-[#F8F8F8] '}`}
                  name='name'
                  type='string'
                  placeholder='Name'
                  register={register}
                  maxLength={25}
                  validation={{required: true}}
                  errors={errors}
                />
                <p className={`text-lg mt-2 ${isDark2 ? 'text-white' : 'text-black'}`}>E-Mail:</p>
                <UIInput
                  customClassName={`border-2 border-gray-400 w-full pl-2 p-1 rounded-lg
                    ${isDark2 ? 'text-white bg-[#393939] ' : 'text-black bg-[#F8F8F8] '}`}
                  name='email'
                  type='email'
                  placeholder='E-Mail'
                  register={register}
                  validation={{required: true}}
                  errors={errors}
                />
                <p className={`text-lg mt-2 ${isDark2 ? 'text-white' : 'text-black'}`}>Telefon:</p>
                <UIInput
                  customClassName={`border-2 border-gray-400 w-full pl-2 p-1 rounded-lg
                    ${isDark2 ? 'text-white bg-[#393939] ' : 'text-black bg-[#F8F8F8] '}`}
                  name='phone'
                  type='tel'
                  maxLength={12}
                  placeholder='Telefon'
                  validation={{required: true}}
                  register={register}
                  errors={errors}
                />
                <p className={`text-lg mt-2 ${isDark2 ? 'text-white ' : 'text-black '}`}>
                  Nachricht:
                </p>
                <IonTextarea
                  maxlength={255}
                  placeholder='Nachricht...'
                  className={`input break-words text-sm border-2 border-gray-400 px-1 rounded-lg
                  ${isDark2 ? 'text-white ' : 'text-black '}`}
                  {...register('msg')}
                  name='msg'
                  rows={4}
                  required={true}
                  clearOnEdit={true}
                  onChange={(e) => setMessage((e.target as HTMLTextAreaElement).value)}
                  value={message}
                />
                <div className='pt-8'>
                  <UIYellowButton type='submit' text='Senden' />
                </div>
                <p className='flex justify-center pt-4 text-xl text-yellow-accent'>
                  {erflogMessage}
                </p>
              </form>
            </div>
          </AppLoginBackground>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ContactPage;

import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar} from '@ionic/react';
import {instrumentsService} from 'api/instruments/instruments.service';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UIOrangeCategoryBox from 'components/uiContainer/UIOrangeCategoryBox';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import UILogo from 'components/uiLogo/UILogo';
import UIResponsivePadding from 'components/uiResponsive/UIResponsivePadding';
import {getAgricultureIcon} from 'utils/categoryIconProvider';
import {Subcategory} from 'interfaces/instruments/instrument.interface';
import React, {useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';
import UIButtonSearch from 'components/uiButtons/UIButtonSearch';

export const InstrumentsSubCatAgricultureListPage: React.FC = () => {
  const [subCategories, setCategories] = useState<Subcategory[]>([]);
  const isDark2 = useRecoilValue(isDarkTheme);
  useEffect(() => {
    instrumentsService.getCategories('loan').then((response) => {
      response.data.results.filter(
        (category: any) => category.id === 3 && setCategories(category.subcategories)
      );
    });
  }, []);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
          <IonButtons slot='end'>
            <UIButtonSearch itemsType='instruments' category={'3'} />
          </IonButtons>
        </IonToolbar>
        <UITopTextBar>Land & Forsttechnik mieten</UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <UIResponsivePadding>
          <div className='mb-10'>
            {subCategories.map((subCat) => (
              <UIOrangeCategoryBox
                key={subCat.id}
                name={subCat.name}
                src={getAgricultureIcon(subCat.id)}
                alt={subCat.name}
                subCatId={subCat.id}
                type='instruments'
                linkTo={`/instruments/subcategories/${subCat.id}/Land_&_forttechnik/${subCat.name}`}
              />
            ))}
          </div>
        </UIResponsivePadding>
      </IonContent>
    </IonPage>
  );
};

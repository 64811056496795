import React from 'react';
import {useRecoilValue} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';

type Props = {
  leftComponent?: any;
  children?: any;
  customClassName?: string;
  customTextClassName?: any;
  rightComponent?: any;
  text?: string;
};

const UITopTextBar = ({
  children,
  leftComponent,
  customClassName,
  customTextClassName,
  rightComponent,
  text,
}: Props) => {
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <div
      className={
        customClassName
          ? customClassName
          : `${
              isDark2
                ? 'w-full h-10 flex justify-between z-10 bg-[#5B6267]'
                : 'w-full bg-gray-600 h-10 flex justify-between z-10 '
            }`
      }
    >
      {!text ? (
        <>
          {leftComponent}
          <div
            className={
              customTextClassName
                ? customTextClassName
                : 'absolute left-1/2 transform -translate-x-1/2 mt-1.5'
            }
          >
            <b className='text-sm text-white'>{children}</b>
          </div>
          {rightComponent}
        </>
      ) : (
        <b>{text}</b>
      )}
    </div>
  );
};

export default UITopTextBar;

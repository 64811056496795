export const errorCategoryTranslator = (errorValue: string) => {
  if (errorValue === 'category must be a positive number') {
    return 'Kategorie muss eine positive Zahl sein';
  }
  if (errorValue === 'email must be an email') {
    return 'Deine E-Mail Adresse ist falsch!';
  }
  if (errorValue === 'subcategory must be a positive number') {
    return 'Unterkategorie muss eine positive Zahl sein';
  }
  return errorValue;
};

import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {IonIcon, isPlatform, useIonRouter} from '@ionic/react';
import {OrangeLine} from 'components/uiContainer/UIOrangeLine';
import {Job} from 'interfaces/jobs/jobs.interface';
import {callOutline, flag, homeOutline, mailOutline, starOutline} from 'ionicons/icons';
import {useRecoilValue} from 'recoil';
import {jobsService} from 'api/jobs/jobs.service';
import FavouritesButton from 'pages/common/favourites/FavouritesButton';
import {Link} from 'react-router-dom';
import {UIClickImage} from 'components/uiImages/UIClickImage';
import UIButtonShare from 'components/uiButtons/UIButtonShare';
import BMiniMap from 'forms/common/places/BMiniMap';
import {accountState, isDarkTheme} from 'states/account/account.state';
import euroIcon from 'assets/icons/euro_icon.svg';

interface DetailsJobProps {
  fields: Job;
  defaultFavorite?: boolean;
  setIsPopUpVisible: Dispatch<SetStateAction<boolean>>;
}

const JobDetails: React.FC<DetailsJobProps> = ({fields, defaultFavorite, setIsPopUpVisible}) => {
  const [isFavorite, setIsFavorite] = useState(defaultFavorite);
  const accountData = useRecoilValue<any>(accountState);
  const router = useIonRouter();

  useEffect(() => setIsFavorite(defaultFavorite), [defaultFavorite]);

  const favHandler = (id: number) => {
    jobsService.addFavoriteJob(id);
  };
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <div
      className={`rounded-lg border border-yellow-accent mx-4 mt-3 mb-10
    drop-shadow-md ${isDark2 ? 'bg-[#393939]' : 'bg-yellow-slight'}`}
    >
      <UIClickImage
        customClassName='w-full h-52 rounded-3xl'
        type='instruments'
        photoLink={fields.photos.length > 0 && fields.photos[0].photo}
        setIsPopUpVisible={setIsPopUpVisible}
      />
      <div className={`absolute ${isPlatform('ios') ? ' w-[94%] ' : ' w-full '} top-40`}>
        <div
          className={`'rounded-t-3xl pl-4 border-t-2 border-orange-main
          ${isDark2 ? 'bg-[#393939]' : 'bg-[#F8F8F8]'}`}
        >
          <div className='flex justify-between'>
            <div>
              <p
                className={`text-lg font-bold mt-2 pt-5
                ${isDark2 ? 'text-white' : 'text-gray-600'}`}
              >
                {fields.jobOffer}
              </p>
              <p className={`text-sm font-thin ${isDark2 ? 'text-white' : 'text-black '}`}>
                {fields.companyName}
              </p>
              <p className={`text-sm font-thin ${isDark2 ? 'text-white' : 'text-black '}`}>
                {fields.owner.firstName + ' ' + fields.owner.lastName}
              </p>
            </div>
            <div>
              <div className='pt-9 text-black space-x-2 flex items-center mr-5'>
                <Link to={`/report/instrument/${fields.id}`}>
                  <IonIcon
                    icon={flag}
                    className={`mt-2 h-6 w-6  ${isDark2 ? 'text-[#F0A249]' : 'text-orange-main'}`}
                  />
                </Link>
                {fields && accountData && accountData.id !== fields!.owner!.id ? (
                  <FavouritesButton
                    favHandler={favHandler}
                    fields={fields}
                    isFavorite={isFavorite}
                    setIsFavorite={setIsFavorite}
                  />
                ) : (
                  <IonIcon
                    icon={starOutline}
                    className='mt-1 text-yellow-400 h-6 w-6'
                    onClick={() => {
                      router.push('/sign-in');
                    }}
                  />
                )}
                <UIButtonShare />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex justify-end mr-4 absolute top-36 right-0'>
        <div className='flex flex-row space-x-4'>
          <div
            className='bg-yellow-accent text-white pr-2
             rounded-3xl flex p-1'
          >
            <IonIcon icon={euroIcon} className='mt-1 ml-3 text-white' />
            <p className='mr-2 text-sm mt-1 text-white'>{fields.salary}</p>
          </div>
        </div>
      </div>
      <div className='mt-16'>
        <OrangeLine name='Kontakt' />
        <div
          className={`flex-col mt-1 ml-4 mr-8  break-words
        ${isDark2 ? ' text-white' : 'text-gray-500'}`}
        >
          <div>
            {fields.isVisiblePhoneNumber && (
              <div className='flex'>
                <IonIcon icon={callOutline} className='mt-0.5' />
                <p className='text-sm ml-2 '>{fields.phoneNumber}</p>
              </div>
            )}
            <div className='flex mt-3'>
              <IonIcon icon={homeOutline} className='h-5 w-5 absolute' />
              <p className='text-sm ml-7'>{fields.location}</p>
            </div>
            {fields.isVisibleEmail && (
              <div className='flex mt-3'>
                <IonIcon icon={mailOutline} className='h-5 w-5 mr-3' />
                <p className='text-sm'>{fields.email}</p>
              </div>
            )}
          </div>
        </div>
        <div className='mx-3 mb-4'>
          <BMiniMap geolocation={{lat: +fields.lat, lng: +fields.lng}} />
        </div>
        <OrangeLine name='Jobbeschreibung' />
        <div className='text-gray-400 text-sm flex justify-between mx-3'>
          <p
            className={`mx-2 mt-2 mb-8 break-words
        ${isDark2 ? ' text-white' : 'text-gray-500 '}`}
          >
            {fields.description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default JobDetails;

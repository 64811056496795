import client from 'api/common/client';
import {Service} from 'interfaces/services/service.interface';
import {createFormData} from 'utils/formDataHelper';

const getAll = async () => {
  const response = await client.get('/services/');
  return response;
};

const getSortedServices = async (orderBy: string, subCatId?: number, catId?: number) => {
  if (subCatId) {
    return await client.get(
      `/services/?category=${catId}&subcategory=${subCatId}&ordering=${orderBy}`
    );
  }
  return await client.get(`/services/?category=${catId}&ordering=${orderBy}`);
};

const getServices = async (catId: number, subCatId?: number) => {
  if (subCatId) return await client.get(`/services/?category=${catId}&subcategory=${subCatId}`);
  return await client.get(`/services/?category=${catId}`);
};

const getCategories = async () => {
  const response = await client.get('/services/categories/');
  return response;
};

const addService = async (data: Service) => {
  const formData = createFormData(data);
  const response = await client.post('services/', formData);
  return response;
};

const deleteService = async (id: any) => {
  const response = await client.delete('services/' + id);
  return response;
};

const updateService = async (data: any, offerId: any) => {
  const formData = createFormData(data);
  const response = client.patch(`services/${offerId}/`, formData);
  return response;
};

const search = async (q: string) => {
  const response = await client.get(`/services?search=${q}/`);
  return response.data;
};

const getDetails = async (offerId: number) => {
  const response = await client.get(`/services/${offerId}/`);
  return response;
};

const addFavoriteService = async (id: number) => {
  const response = await client.put(`/services/favourites/${id}`);
  return response;
};

const getAvailablePromotions = async (id: number) => {
  const response = await client.get(`/payments/promotions/?service=${id}`);
  return response;
};

const getAllPromotions = async () => {
  const response = await client.get(`/payments/promotions-all/`);
  return response;
};

const commentService = async (comment: object, serviceId: number) => {
  const formData = createFormData(comment);
  const response = await client.post(`/services/${serviceId}/comments/`, formData);
  return response;
};

const rateService = async (rate: object, serviceId: number) => {
  const formData = createFormData(rate);
  const response = await client.post(`/services/${serviceId}/rate/`, formData);
  return response;
};

export const servicesService = {
  getAll,
  addService,
  deleteService,
  search,
  getDetails,
  addFavoriteService,
  getSortedServices,
  updateService,
  getAvailablePromotions,
  getAllPromotions,
  commentService,
  rateService,
  getCategories,
  getServices,
};

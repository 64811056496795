import React from 'react';

type Props = {
  promotions: any;
  activeTab: string;
  accType: string;
  nameOfShowingPrices: string;
  itemType: string;
  isPushPromotion?: boolean;
  category?: string;
  mapHidden?: boolean;
};

const PriceList: React.FC<Props> = ({
  promotions,
  activeTab,
  accType,
  nameOfShowingPrices,
  itemType,
  isPushPromotion,
  category,
  mapHidden,
}) => {
  let activeTabPromotions;
  activeTab === 'issue' && (activeTabPromotions = promotions.issuePromotions),
    activeTab === 'list' && (activeTabPromotions = promotions.listPromotions),
    activeTab === 'map' && (activeTabPromotions = promotions.mapPromotions),
    activeTab === 'push' && (activeTabPromotions = promotions.pushPromotion);

  const areHiddenJobsAndServices =
    (activeTab === 'map' || activeTab === 'list') && mapHidden;
  const areHiddenServiceAndJobsConnected =
    nameOfShowingPrices === 'Jobinserat / Dienstleistungen' &&
    activeTab === 'issue';

  return (
    <>
      {activeTab !== 'push' && (
        <p className='text-center mt-4 font-bold'>
          {nameOfShowingPrices[0] === '/'
            ? 'Werkzeug ' +
              (activeTab === 'issue' ? '- frei ' : '') +
              nameOfShowingPrices
            : !(
                (activeTab === 'map' && itemType === 'product') ||
                areHiddenJobsAndServices || areHiddenServiceAndJobsConnected
              ) && nameOfShowingPrices}
        </p>
      )}
      {!areHiddenJobsAndServices &&
        !areHiddenServiceAndJobsConnected &&
        promotions &&
        activeTabPromotions[`${accType}`].map((promotion: any) => (
          <>
            {((activeTab === 'push' && isPushPromotion) ||
              (promotion.itemType === itemType &&
                promotion.category === category)) && (
              <div
                className='flex justify-between mx-5 text-[15px]'
                key={promotion.id}
              >
                {activeTab === 'push' ? (
                  <p className='border-b border-yellow-accent text-[14px] pt-4 w-full'>
                    für {promotion.users} Benutzer
                  </p>
                ) : (
                  <p className='border-b border-yellow-accent text-[14px] pt-4 w-full'>
                    für {promotion.duration} Tage
                  </p>
                )}
                <p className='border-b border-yellow-accent w-14 text-center mt-1 pt-3'>
                  {promotion.price}€
                </p>
              </div>
            )}
          </>
        ))}
    </>
  );
};

export default PriceList;

import React, {useState, useEffect} from 'react';
import {IonIcon, IonSelect, IonSelectOption, IonTextarea, useIonRouter} from '@ionic/react';
import AddPhotos from 'components/uiAddPhoto/UIAddPhotos';
import UIYellowButton from 'components/uiButtons/UIYellowButton';
import {useForm} from 'react-hook-form';
import {BFormAddressField} from 'forms/common/places/BFormAddressField';
import UIInput from 'components/uiInputs/UIInput';
import {OrangeLine} from 'components/uiContainer/UIOrangeLine';
import {Service} from 'interfaces/services/service.interface';
import {servicesService} from 'api/services/services.service';
import {eyeOutline, logoEuro, star} from 'ionicons/icons';
import LineColored from '../instruments/LineColored';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {refreshCounterState} from 'states/common/common.state';
import {Subcategory} from 'interfaces/instruments/instrument.interface';
import {isDarkTheme} from 'states/account/account.state';

function ServiceEditForm({offerId}: any) {
  const setRefreshCount = useSetRecoilState(refreshCounterState);
  const [apiResultMessage, setApiResultMessage] = useState('');
  const [addressPlaceholder, setAddressPlaceholder] = useState<string>('Wähle einen Adresse');
  const [oldPhotos, setOldPhotos] = useState<{photo: string}[]>();
  const [service, setService] = useState<Service>();
  const [categories, setCategories] = useState<Subcategory[]>();
  const [catId, setCatId] = useState();
  const [catValue, setCatValue] = useState<string>();
  const router = useIonRouter();
  const {
    handleSubmit,
    register,
    setValue,
    formState: {errors},
    control,
  } = useForm();

  useEffect(() => {
    servicesService.getDetails(offerId).then((response) => {
      servicesService.getCategories().then((res) => {
        setCategories(res.data.results[response.data.category - 1].subcategories);
      });
      setService(response.data);

      setValue('mondayToFriday', response.data.mondayToFriday);
      setValue('saturdayToSunday', response.data.saturdayToSunday);
      setValue('pricePerHour', response.data.pricePerHour);
      setValue('name', response.data.name);
      setValue('industry', response.data.industry);
      setValue('email', response.data.email);
      setValue('phoneNumber', response.data.phoneNumber);
      setValue('description', response.data.description);
      setValue('lat', response.data.lat.toString());
      setValue('lng', response.data.lng.toString());
      setValue('postCode', response.data.postCode);
      setValue('Address', response.data.Address);
      setValue('location', response.data.location);
      setValue('category', response.data.category);
      setValue('subcategory', response.data.subcategory);

      setOldPhotos(response.data.photos);
      setAddressPlaceholder(response.data.location);
      setCatId(response.data.subcategory);
    });
  }, [offerId]);

  useEffect(() => {
    categories &&
      categories.map((category) => {
        category.id === catId && setCatValue(category.name);
      });
  }, [categories, catId]);
  const onSubmit = async (data: Service) => {
    if (oldPhotos) {
      data.not_delete_photos = [];
      oldPhotos.forEach((photo) => {
        data.not_delete_photos.push(photo.photo.substring(33, photo.photo.length));
      });
    }

    data.subcategory = catId;
    data.photos && (data.photos = data.photos[0].photo);
    if (typeof data.photos === 'undefined') delete data.photos;
    if (data.Address) {
      data.location = data.Address.label;
      data.postCode = data.Address.postCode;
      data.lat = data.Address.lat.toFixed(7);
      data.lng = data.Address.lng.toFixed(7);
      setAddressPlaceholder(data.location!);
    }

    servicesService
      .updateService(data, offerId)
      .then((res) => {
        setApiResultMessage('Erfolg!');
        setRefreshCount((v) => v + 1);
        if (!service!.issuedPromoted) {
          router.push(`/purchase/services/${service!.id}`);
          setApiResultMessage('');
        }
      })
      .catch(() => {
        setApiResultMessage('Error!');
      });
  };
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <form className='h-full' onSubmit={handleSubmit(onSubmit)}>
      <div
        className={`border-2 border-orange-mai
        ${isDark2 ? 'bg-[#393939] text-white' : ' bg-[#F8F8F8] text-black'}`}
      >
        <div className='h-6 bg-yellow-accent'></div>
        <div className='flex my-4 mx-2'>
          <div className='flex-col mx-2 w-2/5 space-y-1 text-sm'>
            <div
              className='flex-col w-2/5 space-y-2 text-sm mb-16'
              onClick={() => router.push(`/photos/${service?.id}/services`)}
            >
              <AddPhotos
                control={control}
                name='photos'
                photos={oldPhotos}
                setPhotos={setOldPhotos}
                photosType={'services'}
              />
            </div>
            <div>
              Mo - Fr:
              <UIInput
                customClassName={`w-full mb-2 text-sm
                ${isDark2 ? 'bg-[#393939] text-white' : ' bg-[#F8F8F8] text-black'}`}
                name='mondayToFriday'
                placeholder='08:00 - 16:00'
                register={register}
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.mondayToFriday} />
            </div>
            <div>
              Sa - So:
              <UIInput
                customClassName={`w-full mb-2 text-sm
                ${isDark2 ? 'bg-[#393939] text-white' : ' bg-[#F8F8F8] text-black'}`}
                name='saturdayToSunday'
                placeholder='10:00 - 14:00'
                register={register}
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.saturdayToSunday} />
            </div>
            <div>
              Preis:
              <div className='flex'>
                <UIInput
                  customClassName={`w-full mb-2 text-sm
                ${isDark2 ? 'bg-[#393939] text-white' : ' bg-[#F8F8F8] text-black'}`}
                  name='pricePerHour'
                  type='text'
                  placeholder='80'
                  register={register}
                  validation={{required: true}}
                  errors={errors}
                />
                <IonIcon icon={logoEuro} className='h-4 w-[18px] mt-1' />
              </div>
              <LineColored error={errors.pricePerHour} />
            </div>
          </div>
          <div className='flex-col space-y-2 mx-2 w-3/5 text-sm'>
            <div
              className='w-44 h-6 p-4 flex items-center justify-between
              rounded-xl border-orange-main border text-orange-main '
            >
              <IonSelect
                okText='Weiter'
                cancelText='Abbrechen'
                className={`${isDark2 ? ' text-white' : ' text-black'}`}
                placeholder={catValue}
                onIonChange={(cat) => setCatId(cat.detail.value)}
              >
                {categories &&
                  categories.map((cat) => (
                    <IonSelectOption key={cat.id} value={cat.id}>
                      {cat.name}
                    </IonSelectOption>
                  ))}
              </IonSelect>
            </div>
            <div>
              Firmenname:
              <UIInput
                customClassName={`w-full mb-2 text-sm
                ${isDark2 ? 'bg-[#393939] text-white' : ' bg-[#F8F8F8] text-black'}`}
                name='name'
                placeholder='Firma'
                required='required'
                register={register}
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.name} />
            </div>
            <div>
              Branche:
              <UIInput
                customClassName={`w-full mb-2 text-sm
                ${isDark2 ? 'bg-[#393939] text-white' : ' bg-[#F8F8F8] text-black'}`}
                name='industry'
                placeholder='Geschäft'
                required='required'
                register={register}
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.industry} />
            </div>
            <div>
              Email:
              <UIInput
                customClassName={`w-full mb-2 text-sm
                ${isDark2 ? 'bg-[#393939] text-white' : ' bg-[#F8F8F8] text-black'}`}
                name='email'
                type='text'
                placeholder='beispiel@email.com'
                register={register}
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.email} />
            </div>
            <div>
              Telefonnummer:
              <UIInput
                customClassName={`w-full mb-2 text-sm
                ${isDark2 ? 'bg-[#393939] text-white' : ' bg-[#F8F8F8] text-black'}`}
                name='phoneNumber'
                type='tel'
                placeholder='0660 1234567'
                minLength={9}
                maxLength={16}
                register={register}
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.phoneNumber} />
            </div>
          </div>
        </div>{' '}
        <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
          <div className='text-orange-main text-sm'>Adresse</div>
          <div className='bg-orange-main w-full h-px'></div>
        </div>
        <div className='mx-2 mt-2'>
          <BFormAddressField
            control={control}
            fieldName='Address'
            key={Math.random()}
            placeholder={addressPlaceholder}
            latLng={{lat: +service?.lat, lng: +service?.lng}}
          />
        </div>
        <OrangeLine name='Beschreibung' />
        <IonTextarea
          maxlength={1000}
          rows={4}
          className='mx-2 pr-2 h-[120px]'
          {...register('description')}
          placeholder='Beschreibe deine Dienstleistung'
        />
        <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
          <div className='text-orange-main text-sm'>Datum und Statistik</div>
          <div className='bg-orange-main w-[1400px] h-px'></div>
        </div>
        <div
          className={`text-sm place-content-center
            ${isDark2 ? 'text-gray-300' : 'text-black'}`}
        >
          <div className='pr-11 ml-1'>
            <div className='flex justify-between ml-2'>
              <p className={`${isDark2 ? 'text-gray-300 pr-2' : 'text-black pr-2'}`}>
                Anzeige verfügbar bis:{' '}
                {service?.issuedPromoted && service?.issuedPromotedUntil
                  ? new Date(service?.issuedPromotedUntil).toLocaleDateString()
                  : '-'}
              </p>
            </div>
            <div className='flex ml-2'>
              <p className={`${isDark2 ? 'text-gray-300 pr-2' : 'text-black pr-2'}`}>
                Anzeige Hervorhebung verfügbar bis:{' '}
                {service?.promoted && service?.promotedUntil
                  ? new Date(service?.promotedUntil).toLocaleDateString()
                  : '-'}
              </p>
            </div>
            <div className='flex ml-2'>
              <p className={`${isDark2 ? 'text-gray-300 pr-2' : 'text-black pr-2'}`}>
                Anzeige Logo verfügbar bis:{' '}
                {service?.mapPromoted && service?.mapPromotedUntil
                  ? new Date(service?.mapPromotedUntil).toLocaleDateString()
                  : '-'}
              </p>
            </div>
          </div>
          <div className='flex justify-center mt-3'>
            <IonIcon
              icon={star}
              className={`h-4 w-5 pr-1 ${isDark2 ? 'text-[#F0A249]' : 'text-yellow-400 '}`}
            />
            <p className='pr-2 text-md'>{service?.countFavourite ? service?.countFavourite : 0}</p>
            <IonIcon icon={eyeOutline} className='h-5 w-5 pr-1' />
            <p>{service?.visitors}</p>
          </div>
        </div>
      </div>
      <p className='text-yellow-accent text-center font-bold mb-5'>{apiResultMessage}</p>
      <div className='flex flex-col mb-5 justify-end text-white'>
        <UIYellowButton type='submit' text='Weiter' />
      </div>
    </form>
  );
}

export default ServiceEditForm;

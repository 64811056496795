export const dateHandler = (date: string, dateType?: string, isTime?: boolean) => {
  if (!date) return;
  const formatDate = new Date(date);
  if (isTime) {
    return (
      formatDate.toLocaleTimeString(('en-UK'), {hour: '2-digit', minute: '2-digit'}) +
      ' ' +
      formatDate.toLocaleDateString()
    );
  }
  return formatDate.toLocaleDateString(dateType);
};

import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar, useIonRouter} from '@ionic/react';
import JobDetails from 'pages/jobs/jobDetail/JobDetails';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UIYellowButton from 'components/uiButtons/UIYellowButton';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import LoadingIndicator from 'components/uiLoading/UILoadingIndicator';
import UILogo from 'components/uiLogo/UILogo';
import {Job} from 'interfaces/jobs/jobs.interface';
import React, {useEffect, useState} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {accountState, isDarkTheme} from 'states/account/account.state';
import {useParams} from 'react-router';
import {jobsService} from 'api/jobs/jobs.service';
import JobOfferEditForm from 'forms/jobs/editJobOffer.form';
import {refreshCounterState} from 'states/common/common.state';
import {UIDeleteButton} from 'components/uiButtons/UIDeleteButton';
import UIPopUpPhoto from 'components/uiImages/UIPopUpPhoto';
import {User} from 'interfaces/users/user.interface';

const JobDetail = () => {
  const {jobId: jobId} = useParams<{jobId: string}>();
  const [isOwn, setIsOwn] = useState(false);
  const [job, setJob] = useState<Job>();
  const accountData = useRecoilValue<User | undefined>(accountState);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFavorite, setIsFavorite] = useState<boolean>(true);
  const [photos, setPhotos] = useState([]);
  const [isPopUpVisible, setIsPopUpVisible] = useState<boolean>(false);
  const router = useIonRouter();

  const [refreshCounter, setRefreshCounter] = useRecoilState(refreshCounterState);

  useEffect(() => {
    async function getJobDetails() {
      try {
        setIsLoading(true);
        const response = await jobsService.getDetails(+jobId);
        if (accountData) {
          setJob(response.data);
          setPhotos(response.data.photos);
          setIsOwn(accountData!.id! == response.data.owner.id);
          const isFavourite = accountData.favourites!.jobs!.some((dto: any) => dto.id == jobId);
          setIsFavorite(isFavourite);
          setIsPopUpVisible(false);
        }
        setJob(response.data);
        setPhotos(response.data.photos);
      } catch {
      } finally {
        setIsLoading(false);
      }
    }
    getJobDetails();
  }, [refreshCounter]);

  const handleDelete = () => {
    jobsService.deleteJob(job!.id!).then(() => {
      setRefreshCounter(refreshCounter + 1);
      router.goBack();
    });
  };
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <IonPage>
      {isPopUpVisible && (
        <UIPopUpPhoto
          type='jobs'
          photoLink={photos}
          onClosePopUp={() => {
            setIsPopUpVisible(false);
          }}
        />
      )}
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
          {isOwn && (
            <IonButtons slot='end'>
              <UIDeleteButton onDelete={handleDelete} />
            </IonButtons>
          )}
        </IonToolbar>
        <UITopTextBar>Jobinserat</UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          job && (
            <div className={`w-full h-full ${isDark2 ? ' bg-[#474747]' : 'bg-white'}`}>
              <div className='pt-2 px-2 pb-5 space-y-5'>
                {!isOwn ? (
                  <JobDetails
                    defaultFavorite={isFavorite}
                    fields={job}
                    setIsPopUpVisible={setIsPopUpVisible}
                  />
                ) : (
                  <JobOfferEditForm />
                )}
                <UIYellowButton
                  text={`Andere Benutzeranzeigen`}
                  onClick={() => {
                    router.push(`/user-items/${job.owner.id}/${job.owner.firstName}/`);
                  }}
                />
                {!isOwn && (
                  <UIYellowButton
                    text='Jobanbieter kontaktieren'
                    onClick={() =>
                      router.push(
                        `/message/jobs/${job.id}/${job.jobOffer ? job.jobOffer : job.name}/${
                          job.existedChat ? job.existedChat : 0
                        }`
                      )
                    }
                  />
                )}
              </div>
            </div>
          )
        )}
      </IonContent>
    </IonPage>
  );
};

export default JobDetail;

import React from 'react';
import {useRecoilValue} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';

interface Props {
  name: string;
}

export const OrangeLine: React.FC<Props> = ({name}) => {
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <div className='flex items-center space-x-0.5 text-sm m-'>
      <div className={`text-sm ml-2 ${isDark2 ? 'text-[#F0A249]' : 'text-orange-main'}`}>
        {name}
      </div>
      <div className={`w-screen h-px ${isDark2 ? 'bg-[#F0A249]' : 'bg-orange-main'}`}></div>
    </div>
  );
};

import client from 'api/common/client';

const send = async (report: any) => {
  const response = await client.post(`/users/reports/`, report);
  return response;
};

export const reportsService = {
  send,
};

import React from 'react';
import {IonContent, IonGrid, IonHeader, IonPage, IonToolbar} from '@ionic/react';
import {useRecoilValue} from 'recoil';
import UILogo from 'components/uiLogo/UILogo';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import UIResponsivePadding from 'components/uiResponsive/UIResponsivePadding';
import {isDarkTheme} from 'states/account/account.state';
import UIOrangeCategoryBox from 'components/uiContainer/UIOrangeCategoryBox';
import diy from 'assets/community/diy.svg';
import forum from 'assets/community/forum.svg';
import contacts from 'assets/community/contacts.svg';
import diyDark from 'assets/community/diy_dark.svg';
import contactsDark from 'assets/community/contacts_dark.svg';
import forumDark from 'assets/community/forum_dark.svg';

const CommunityPage: React.FC = () => {
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <UILogo />
        </IonToolbar>
        <UITopTextBar>Gemeinschaft</UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <UIResponsivePadding>
          <IonGrid
            className={`mt-24xl:w-2/3 w-full h-full mb-12
              ${isDark2 ? 'bg-[#474747] text-white' : ' bg-[#F8F8F8] text-black'}`}
          >
            <UIOrangeCategoryBox
              name='NACHRICHTEN'
              src={`${isDark2 ? contactsDark : contacts}`}
              alt='contacts'
              linkTo={'/chat'}
            />
            <UIOrangeCategoryBox
              name='DO IT YOURSELF'
              src={`${isDark2 ? diyDark : diy}`}
              alt='DIY'
              linkTo={'/diys'}
            />
            <UIOrangeCategoryBox
              name='FORUM'
              src={`${isDark2 ? forumDark : forum}`}
              alt='forum'
              linkTo={'/forum'}
            />
          </IonGrid>
        </UIResponsivePadding>
      </IonContent>
    </IonPage>
  );
};

export default CommunityPage;

import client from 'api/common/client';
import {createFormData} from 'utils/formDataHelper';

const addJob = async (jobdata: any) => {
  const formData = createFormData(jobdata);
  const response = await client.post('jobs/', formData);
  return response;
};

const getAll = async () => {
  const response = await client.get('jobs/');
  return response;
};

const search = async (q: string) => {
  const response = await client.get(`/jobs?search=${q}/`);
  return response.data;
};

const getDetails = (id: number) => {
  return client.get(`jobs/${id}/`);
};

const updateJob = (data: any, id: string) => {
  const formData = createFormData(data);
  const response = client.patch(`jobs/${id}/`, formData);
  return response;
};

const deleteJob = async (job: string) => {
  const response = await client.delete(`jobs/${job}/`);
  return response;
};

const getSortedJobs = async (sortedBy: string) => {
  const response = await client.get(`/jobs?ordering=${sortedBy}`);
  return response;
};

const getAvailablePromotions = async (id: number) => {
  const response = await client.get(`/payments/promotions/?job=${id}`);
  return response;
};

const addFavoriteJob = async (id: number) => {
  const response = await client.put(`/jobs/favourites/${id}`);
  return response;
};

export const jobsService = {
  addJob,
  getAll,
  search,
  getDetails,
  deleteJob,
  getSortedJobs,
  updateJob,
  getAvailablePromotions,
  addFavoriteJob,
};

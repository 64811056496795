import React from 'react';
import {Control, Controller} from 'react-hook-form';
import AddPhotosInner from './UIAddPhotosInner';

interface AddPhotosProps {
  control: Control;
  name: string;
  setPhotos?: any;
  photos?: { photo: string; }[] | undefined;
  photosType?: string | undefined;
  disabled?: boolean;
}

const AddPhotos: React.FC<AddPhotosProps> = ({
  control,
  name,
  photos,
  photosType,
  setPhotos,
  disabled
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({field: {onChange, value}}) => (
        <AddPhotosInner
          photosType={photosType}
          oldPhotos={photos}
          onPhotoAdd={onChange}
          setOldPhotos={setPhotos}
          addedPhots={value}
          disabled={disabled}
        />
      )}
    />
  );
};

export default AddPhotos;

import React, {useEffect, useState} from 'react';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import UILogo from 'components/uiLogo/UILogo';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import {servicesService} from 'api/services/services.service';
import {ServicesList} from 'pages/services/servicesList/ServiceList';
import LoadingIndicator from 'components/uiLoading/UILoadingIndicator';
import {Service} from 'interfaces/services/service.interface';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UIButtonSearch from 'components/uiButtons/UIButtonSearch';
import {funnel} from 'ionicons/icons';
import sorting from 'assets/sorting.svg';
import {distanceCalculator} from 'utils/distanceCalculator';
import UIFilter from 'components/uiFilter/UIFilter';
import {geolocationState, refreshCounterState} from 'states/common/common.state';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {defaultDistanceMax, defaultDistanceMin} from 'utils/constants';
import UIResponsivePadding from 'components/uiResponsive/UIResponsivePadding';
import {isDarkTheme} from 'states/account/account.state';
import UICategoriesTopBar from 'components/uiCategoriesTopBar/UICategoriesTopBar';
import {Category} from 'interfaces/instruments/instrument.interface';

export const AgricultureServicesPage = () => {
  const [originalServices, setOriginalServices] = useState<Service[]>();
  const [services, setServices] = useState<Service[]>();
  const [isFilterShown, setIsFilterShown] = useState<boolean>(false);
  const [distanceMin, setDistanceMin] = useState<number>(defaultDistanceMin);
  const [distanceMax, setDistanceMax] = useState<number>(defaultDistanceMax);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [categories, setCategories] = useState<Category[]>();
  const [currentCategory, setCurrentCategory] = useState<Category>();

  const router = useIonRouter();

  const shouldRefreshData = useRecoilValue(refreshCounterState);
  const geolocation = useRecoilValue(geolocationState);
  const setRefreshCount = useSetRecoilState(refreshCounterState);

  useEffect(() => {
    setIsLoading(true);
    servicesService
      .getCategories()
      .then((response) => {
        setCategories(response.data.results[1].subcategories);
        setCurrentCategory({id: 0, name: 'all'});
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setIsLoading(true);
    servicesService
      .getServices(2, currentCategory ? currentCategory.id : 12)
      .then((response) => {
        response.data &&
          response.data.forEach((service: Service) => {
            service.distance = distanceCalculator(service.lat, service.lng, geolocation);
          });
        setServices(response.data);
        setOriginalServices(response.data);
      })
      .finally(() => setIsLoading(false));
  }, [shouldRefreshData, currentCategory]);

  useEffect(() => {
    services && getOrderBy('Entfernung aufsteigend');
  }, [originalServices, shouldRefreshData]);

  const handleFilter = () => {
    let tempData = originalServices;
    tempData = tempData?.filter((item) => {
      return item.distance! >= distanceMin && item.distance! <= distanceMax;
    });

    setServices(tempData);
  };
  const handleSort = (sortValue: string) => {
    getOrderBy(sortValue);
    // instrumentsService.getSortedInstruments(sortedBy!).then((response) => {
    // });
  };

  // will be handled by API
  const getOrderBy = (sortValue: string) => {
    switch (sortValue) {
      case 'Datum aufsteigend':
        const sorted = [
          ...services!.sort((a, b) =>
            new Date(a.dateAdded!).getTime() < new Date(b.dateAdded!).getTime() ? 1 : -1
          ),
        ];
        setServices(sorted);
        return 'dateAdded';
      case 'Datum absteigend':
        const sorted2 = [
          ...services!.sort((a, b) =>
            new Date(a.dateAdded!).getTime() > new Date(b.dateAdded!).getTime() ? 1 : -1
          ),
        ];
        setServices(sorted2);
        return '-dateAdded';
      case 'Entfernung aufsteigend':
        const sorted5 = [...services!.sort((a, b) => (a.distance! > b.distance! ? 1 : -1))];
        setServices(sorted5);
        return 'distance';
      case 'Entfernung absteigend':
        const sorted6 = [...services!.sort((a, b) => (a.distance! < b.distance! ? 1 : -1))];
        setServices(sorted6);
        return '-distance';
    }
  };
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
          <IonButtons slot='end'>
            <UIButtonSearch itemsType='services' />
          </IonButtons>
        </IonToolbar>
        <UITopTextBar
          leftComponent={
            <button title='button' onClick={() => setIsFilterShown(!isFilterShown)}>
              <IonIcon className='mt-1 ml-3 text-white' icon={funnel} />
            </button>
          }
          rightComponent={
            <div className='flex w-7/8 mr-2'>
              <IonSelect
                okText='Weiter'
                cancelText='Abbrechen'
                onIonChange={(e) => handleSort(e.detail.value)}
                placeholder='Sortieren'
                className='text-xs text-transparent w-8 -mr-8 z-10'
              >
                <IonSelectOption className='text-black'>Datum aufsteigend</IonSelectOption>
                <IonSelectOption className='text-black'>Datum absteigend</IonSelectOption>
                <IonSelectOption className='text-black'>Preis aufsteigend</IonSelectOption>
                <IonSelectOption className='text-black'>Preis absteigend</IonSelectOption>
                <IonSelectOption className='text-black'>Entfernung aufsteigend</IonSelectOption>
                <IonSelectOption className='text-black'>Entfernung absteigend</IonSelectOption>
              </IonSelect>
              <img alt='img' className='my-2 mr-3' src={sorting} />
            </div>
          }
        >
          Land & Forsttechnik
        </UITopTextBar>
        {categories && (
          <UICategoriesTopBar
            categories={categories}
            currentCategory={currentCategory && currentCategory}
            setCurrentCategory={setCurrentCategory}
          />
        )}
      </IonHeader>
      {isFilterShown && (
        <UIFilter
          onFilter={handleFilter}
          setDistanceMin={setDistanceMin}
          setDistanceMax={setDistanceMax}
        />
      )}
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <UIResponsivePadding>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            services && (
              <ServicesList
                services={services}
                isOwnService={false}
                onServiceClick={(offerId: any) => {
                  router.push(`/service/${offerId}`);
                  setRefreshCount((v) => v + 1);
                }}
                type='services'
              />
            )
          )}
        </UIResponsivePadding>
      </IonContent>
    </IonPage>
  );
};

export default AgricultureServicesPage;

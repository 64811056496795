import {IonIcon} from '@ionic/react';
import {search} from 'ionicons/icons';
import React from 'react';
import {Link} from 'react-router-dom';

interface Props {
  itemsType: string;
  category?: string;
}

const UIButtonSearch: React.FC<Props> = ({itemsType, category}) => (
  <Link
    to={itemsType === 'map' ? '/search-map' : `/search/${itemsType}/${category ? category : '0'}`}
  >
    <IonIcon icon={search} className='text-yellow-accent h-7 w-7' />
  </Link>
);

export default UIButtonSearch;

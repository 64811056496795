import React, {useEffect, useState} from 'react';
import {IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonToolbar} from '@ionic/react';
import {GoogleMap, withScriptjs, withGoogleMap} from 'react-google-maps';
import {servicesService} from 'api/services/services.service';
import {jobsService} from 'api/jobs/jobs.service';
import {Instrument} from 'interfaces/instruments/instrument.interface';
import {Service} from 'interfaces/services/service.interface';
import {Job} from 'interfaces/jobs/jobs.interface';
import {Product} from 'interfaces/products/product.interface';
import LoadingIndicator from 'components/uiLoading/UILoadingIndicator';
import {instrumentsService} from 'api/instruments/instruments.service';
import {useHistory} from 'react-router';
import MapPines from './MapPines';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import UIButtonSearch from 'components/uiButtons/UIButtonSearch';
import UILogo from 'components/uiLogo/UILogo';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';
import {defaultGeolocationState, refreshCounterState} from 'states/common/common.state';
import {closeOutline, menuOutline} from 'ionicons/icons';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import {productsService} from 'api/products/products.service';
import {sameLocationItemsLister} from 'utils/sameLocationItemsLister';
import SameLocationItemsList from './SameLocationItemsList';
import {getGoogleClusterInlineSvg} from 'utils/getGoogleStyles';

const MapPage: React.FC = () => {
  const shouldRefreshData = useRecoilValue(refreshCounterState);
  const defaultLocation = useRecoilValue(defaultGeolocationState);
  const setDefaultGeolocation = useSetRecoilState(defaultGeolocationState);

  const [services, setServices] = useState<Service[]>();
  const [werkzeug, setWerkzeug] = useState<Instrument[]>();
  const [landAndForstKaufen, setLandAndForstKaufen] = useState<Product[]>();
  const [landAndForst, setLandAndForsttechnik] = useState<Instrument[]>();
  const [werkzeugKaufen, setWerkzeugKaufen] = useState<Product[]>();
  const [baumaschinen, setBaumaschinen] = useState<Instrument[]>();
  const [baumaschinenKaufen, setBaumaschinenKaufen] = useState<Product[]>();
  const [jobs, setJobs] = useState<Job[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [displayOption, setDisplayOption] = useState('alle');
  const [isItemsListPopUp, setIsItemsListPopUp] = useState(false);
  const [location, setLocation] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);
  const [sameLocationItemsList, setSameLocationItemsList] = useState<{
    werkzeugMieten?: Instrument[];
    baumaschinenMieten?: Instrument[];
    agricultureMieten?: Instrument[];
    services?: Service[];
    jobs?: Job[];
    werkzeugKaufen?: Product[];
    baumaschinenKaufen?: Product[];
    agricultureKaufen?: Product[];
  }>();

  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const servicesResponse = await servicesService.getAll();
        setServices(servicesResponse.data);
        const jobsResponse = await jobsService.getAll();
        setJobs(jobsResponse.data.resuluts);
        const baumaschinenKaufenResponse = await productsService.getProducts(0, 1);
        setBaumaschinenKaufen(baumaschinenKaufenResponse.data);
        const werkzeugKaufenResponse = await productsService.getProducts(0, 2);
        setWerkzeugKaufen(werkzeugKaufenResponse.data);
        const landAndForstKaufenResponse = await productsService.getProducts(0, 3);
        setLandAndForstKaufen(landAndForstKaufenResponse.data);
        const werkzeugResponse = await instrumentsService.getInstruments(0, 2);
        setWerkzeug(werkzeugResponse.data);
        const baumaschinenResponse = await instrumentsService.getInstruments(0, 1);
        setBaumaschinen(baumaschinenResponse.data);
        const landResponse = await instrumentsService.getInstruments(0, 3);
        setLandAndForsttechnik(landResponse.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };

    // eslint-disable-next-line space-before-function-paren
    navigator.geolocation.getCurrentPosition(function (position) {
      setDefaultGeolocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });

    fetchData();
  }, [shouldRefreshData]);

  const Map = ({children}: {children: any}) => {
    return (
      <GoogleMap
        defaultOptions={{
          disableDefaultUI: true,
        }}
        defaultZoom={10}
        defaultCenter={defaultLocation}
      >
        {children}
      </GoogleMap>
    );
  };

  const WrappedMap = withScriptjs(withGoogleMap(Map));

  const selectedPlaceHandler = (
    id: string,
    type: string,
    lat: string,
    lng: string,
    cat?: string,
    subCat?: string
  ) => {
    const itemList = sameLocationItemsLister(
      lat,
      lng,
      werkzeug,
      baumaschinen,
      landAndForst,
      services,
      jobs,
      werkzeugKaufen,
      baumaschinenKaufen,
      landAndForstKaufen
    );
    if (itemList) {
      setIsItemsListPopUp(true);
      setSameLocationItemsList(itemList);
      return;
    }

    if (type === 'instrument' || type === 'product') {
      history.push(`/${type}/${cat}/${subCat}/${id}/`);
      return;
    } else {
      history.push(`/${type}/${id}`);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handlePinesSort = (sortPinesValue: string) => {
    getOrderBy(sortPinesValue);
  };

  const getOrderBy = (sortPinesValue: string) => {
    switch (sortPinesValue) {
      case 'Alle':
        setDisplayOption('alle');
        return 'alle';
      case 'Werkzeug':
        setDisplayOption('werkzeug');
        return 'werkzeug';
      case 'Service':
        setDisplayOption('service');
        return 'service';
      case 'Job':
        setDisplayOption('job');
        return 'job';
      case 'Baumaschinen':
        setDisplayOption('baumaschinen');
        return 'baumaschinen';
      case 'LandAndForsttechnik':
        setDisplayOption('landAndForsttechnik');
        return 'landAndForsttechnik';
    }
  };

  const clusterStyles = [
    {
      width: 50,
      height: 50,
      url: getGoogleClusterInlineSvg('orange'),
      textColor: 'black',
      textSize: 12,
    },
    {
      width: 50,
      height: 50,
      url: getGoogleClusterInlineSvg('orange'),
      textColor: 'black',
      textSize: 14,
    },
    {
      width: 60,
      height: 60,
      url: getGoogleClusterInlineSvg('orange'),
      textColor: 'black',
      textSize: 16,
    },
  ];
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <UILogo />
          <IonButtons slot='end'>
            {isItemsListPopUp ? (
              <IonIcon
                icon={closeOutline}
                className='text-2xl p-2 text-black'
                onClick={() => {
                  setIsItemsListPopUp(false);
                  setSameLocationItemsList({
                    werkzeugMieten: [],
                    werkzeugKaufen: [],
                    jobs: [],
                    services: [],
                    baumaschinenMieten: [],
                    baumaschinenKaufen: [],
                    agricultureMieten: [],
                    agricultureKaufen: [],
                  });
                }}
              />
            ) : (
              <UIButtonSearch itemsType='map' />
            )}
          </IonButtons>
        </IonToolbar>
        <UITopTextBar
          leftComponent={
            <div className='relative'>
              <button className='flex items-center focus:outline-none' onClick={toggleDropdown}>
                <IonIcon
                  icon={isOpen ? closeOutline : menuOutline}
                  className='w-6 h-6 mr-2 pt-2 pl-2'
                />
              </button>

              {isOpen && (
                <div
                  className={`absolute mt-2 py-2 border border-gray-700 rounded shadow-md
        ${isDark2 ? 'bg-[#393939] text-white ' : 'bg-[#f8f8f8] text-black '}`}
                >
                  <button
                    className={`block w-full px-4 py-2 text-left
             ${isDark2 ? 'hover:bg-[#706f6f] ' : 'hover:bg-gray-200 '}`}
                    onClick={(e) => handlePinesSort('Alle')}
                  >
                    Alle
                  </button>
                  <button
                    className={`block w-full px-4 py-2 text-left
            ${isDark2 ? 'hover:bg-[#706f6f] ' : 'hover:bg-gray-200 '}`}
                    onClick={(e) => handlePinesSort('Werkzeug')}
                  >
                    Werkzeug
                  </button>
                  <button
                    className={`block w-full px-4 py-2 text-left
             ${isDark2 ? 'hover:bg-[#706f6f] ' : 'hover:bg-gray-200 '}`}
                    onClick={(e) => handlePinesSort('Baumaschinen')}
                  >
                    Baumaschinen
                  </button>
                  <button
                    className={`block w-full px-4 py-2 text-left
             ${isDark2 ? 'hover:bg-[#706f6f] ' : 'hover:bg-gray-200 '}`}
                    onClick={(e) => handlePinesSort('LandAndForsttechnik')}
                  >
                    Land & Forsttechnik
                  </button>
                  <button
                    className={`block w-full px-4 py-2 text-left
              ${isDark2 ? 'hover:bg-[#706f6f] ' : 'hover:bg-gray-200 '}`}
                    onClick={(e) => handlePinesSort('Service')}
                  >
                    Dienstleistungen
                  </button>
                  <button
                    className={`block w-full px-4 py-2 text-left
             ${isDark2 ? 'hover:bg-[#706f6f] ' : 'hover:bg-gray-200 '}`}
                    onClick={(e) => handlePinesSort('Job')}
                  >
                    Jobs
                  </button>
                </div>
              )}
            </div>
          }
        >
          <p className='mt-1'>{isItemsListPopUp ? location : 'Karte'}</p>
        </UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        {isItemsListPopUp && sameLocationItemsList && (
          <SameLocationItemsList itemsList={sameLocationItemsList} setLocation={setLocation} />
        )}
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <WrappedMap
            containerElement={<div className='overflow-hidden h-full' />}
            mapElement={<div className='h-full' />}
            // eslint-disable-next-line max-len
            googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawind,places&key=AIzaSyDO-5cVZigsb3Qc_FTuQHMhCb7GaIzzmk8`}
            loadingElement={<div className='h-full' />}
          >
            <MarkerClusterer gridSize={30} averageCenter maxZoom={17} styles={clusterStyles}>
              {werkzeug && (displayOption === 'werkzeug' || displayOption === 'alle') && (
                <MapPines
                  instruments={werkzeug}
                  selectedPlaceHandler={selectedPlaceHandler}
                  type={'instrument'}
                />
              )}
              {services && (displayOption === 'service' || displayOption === 'alle') && (
                <MapPines
                  services={services}
                  selectedPlaceHandler={selectedPlaceHandler}
                  type={'service'}
                />
              )}
              {jobs && (displayOption === 'job' || displayOption === 'alle') && (
                <MapPines jobs={jobs} selectedPlaceHandler={selectedPlaceHandler} type={'job'} />
              )}
              {baumaschinen && (displayOption === 'baumaschinen' || displayOption === 'alle') && (
                <MapPines
                  instruments={baumaschinen}
                  selectedPlaceHandler={selectedPlaceHandler}
                  type={'instrument'}
                />
              )}
              {landAndForst &&
                (displayOption === 'landAndForsttechnik' || displayOption === 'alle') && (
                  <MapPines
                    instruments={landAndForst}
                    selectedPlaceHandler={selectedPlaceHandler}
                    type={'instrument'}
                  />
                )}
              {baumaschinenKaufen &&
                (displayOption === 'baumaschinen' || displayOption === 'alle') && (
                  <MapPines
                    products={baumaschinenKaufen}
                    selectedPlaceHandler={selectedPlaceHandler}
                    type={'product'}
                  />
                )}
              {werkzeugKaufen && (displayOption === 'werkzeug' || displayOption === 'alle') && (
                <MapPines
                  products={werkzeugKaufen}
                  selectedPlaceHandler={selectedPlaceHandler}
                  type={'product'}
                />
              )}
              {landAndForstKaufen &&
                (displayOption === 'landAndForsttechnik' || displayOption === 'alle') && (
                  <MapPines
                    products={landAndForstKaufen}
                    selectedPlaceHandler={selectedPlaceHandler}
                    type={'product'}
                  />
                )}
            </MarkerClusterer>
          </WrappedMap>
        )}
      </IonContent>
    </IonPage>
  );
};
export default MapPage;

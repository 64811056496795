import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {userService} from 'api/users/users.service';
import {UserDataDefaultValues, User} from 'interfaces/users/user.interface';
import {dateHandler} from '../../../utils/dateHandler';
import AddPhoto from '../../../components/uiAddPhoto/UIAddPhoto';
import LoadingIndicator from 'components/uiLoading/UILoadingIndicator';
import UIInput from '../../../components/uiInputs/UIInput';
import UIButton from 'components/uiButtons/UIYellowButton';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {accountState, isDarkTheme} from 'states/account/account.state';
import {refreshCounterState} from 'states/common/common.state';
import {IonSelect, IonSelectOption} from '@ionic/react';

function ProfileEdit() {
  const setAccountState = useSetRecoilState(accountState);
  const setRefreshCounter = useSetRecoilState(refreshCounterState);

  const [message, setMessage] = useState('');
  const [userData, setUserData] = useState<User>(UserDataDefaultValues);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFill, setIsFill] = useState(userData.birthDate ? true : false);
  const [gender, setGender] = useState();

  const {handleSubmit, register, setValue, control} = useForm();

  useEffect(() => {
    async function getUserDataDetails() {
      setIsLoading(true);
      try {
        userService.getUserData().then((response) => {
          setUserData({
            ...response.data,
            birthDate: dateHandler(response.data.birthDate, 'en-CA'),
            phoneNumber: response.data.phoneNumber.slice(3),
          });
          setGender(response.data.gender);
          setAccountState(response.data);
        });
      } catch {
      } finally {
        setIsLoading(false);
      }
    }
    getUserDataDetails();
  }, [message]);

  useEffect(() => {
    for (const [key, value] of Object.entries(userData)) {
      setValue(key, value);
    }
  }, [userData]);

  const onSubmit = (data: User) => {
    if (typeof data.profilePicture === 'string') delete data.profilePicture;
    if (data.birthDate === undefined || null) data.birthDate = '';
    if (data.profilePicture === (undefined || null)) data.profilePicture = '';
    data.phoneNumber = '+43' + data.phoneNumber;
    if (data.gender === null) data.gender = '';
    if (data.jobTitle === null) data.jobTitle = '';
    userService
      .updateUser(data)
      .then((res) => {
        setMessage('Erfolg!');
        setUserData(res.config.data);
        setRefreshCounter((val) => val + 1);
      })
      .catch(() => {
        setMessage('Fehler! Geben Sie Geschlecht und Adresse ein!');
      });
  };

  const translateGendersToGer = (gender: string) => {
    if (gender === 'MAN') {
      return 'Herr';
    }
    if (gender === 'WOMAN') {
      return 'Frau';
    }
    return 'Herr/Frau';
  };
  const isDark2 = useRecoilValue(isDarkTheme);

  return (
    <>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <form className='pb-3' onSubmit={handleSubmit(onSubmit)}>
          <div
            className={`mx-4 mt-3 pt-2 rounded-lg mb-5
      ${isDark2 ? 'bg-[#F0A249] text-white ' : 'text-black bg-yellow-accent'}`}
          >
            <div
              className={` border-2  rounded-b-lg
         ${isDark2 ? 'border-[#F0A249]  ' : 'bg-yellow-slight border-yellow-accent'}`}
            >
              <div className={`flex flex-row ${isDark2 ? 'bg-[#393939]' : 'bg-[#F8F8F8]'}`}>
                <div
                  className={`flex items-center mt-4 pl-6
            ${isDark2 ? 'bg-[#393939]' : 'bg-[#F8F8F8] '}`}
                >
                  <AddPhoto control={control} name='profilePicture' />
                </div>
                <div className='flex flex-col space-y-4 mx-5 mt-4 '>
                  <div
                    className='w-full h-6 p-4 flex items-center justify-center
                rounded-xl border-orange-main border text-orange-main '
                  >
                    <IonSelect
                      okText='Weiter'
                      cancelText='Abbrechen'
                      className={`${isDark2 ? 'text-white' : 'text-black '}`}
                      placeholder={translateGendersToGer(gender!)}
                      onIonChange={(e) => setGender(e.detail.value)}
                    >
                      <IonSelectOption value={'MAN'}>Herr</IonSelectOption>
                      <IonSelectOption value={'WOMAN'}>Frau</IonSelectOption>
                    </IonSelect>
                  </div>
                  <UIInput
                    required={'required'}
                    customClassName={`border-b-2 border-gray-400 w-full pl-2
                ${isDark2 ? 'bg-[#393939] text-white ' : ' bg-[#F8F8F8] '}`}
                    placeholder='Name'
                    name='firstName'
                    register={register}
                  />
                  <UIInput
                    customClassName={`border-b-2 border-gray-400 w-full pl-2
                ${isDark2 ? 'bg-[#393939] text-white ' : ' bg-[#F8F8F8] '}`}
                    placeholder='Nachname'
                    register={register}
                    name='lastName'
                  />
                </div>
              </div>
              <div
                className={`flex flex-col space-y-4 
          ${isDark2 ? 'bg-[#393939] text-white ' : 'bg-[#F8F8F8] '}`}
              >
                <div className='text-sm text-gray-500 mx-3 pt-4'>{userData.email}</div>
                <UIInput
                  customClassName={`border-b-2 border-gray-400 w-full pl-2
              ${isDark2 ? 'bg-[#393939] text-white ' : ' bg-[#F8F8F8] '}`}
                  defaultValue={userData.street}
                  placeholder='Straße'
                  name='street'
                  register={register}
                />
                <div className='flex min-w-fit'>
                  <UIInput
                    customClassName={`border-b-2 border-gray-400 w-full pl-2
                ${isDark2 ? 'bg-[#393939] text-white ' : ' bg-[#F8F8F8] '}`}
                    defaultValue={userData.city}
                    placeholder='Adresse'
                    name='city'
                    register={register}
                  />
                  <UIInput
                    customClassName={`border-b-2 border-gray-400 w-full pl-2
                ${isDark2 ? 'bg-[#393939] text-white ' : ' bg-[#F8F8F8] '}`}
                    defaultValue={userData.zipCode}
                    placeholder='PLZ'
                    name='zipCode'
                    register={register}
                  />
                </div>
                <div
                  className={`flex border-b-2 border-gray-400 pl-2 ${
                    userData.birthDate || isFill ? 'text' : 'text-gray-400'
                  } ${isDark2 ? 'text-white bg-[#393939]' : ''}`}
                >
                  Geburtsdatum:
                  <UIInput
                    customClassName={`w-full pl-10
                ${isDark2 ? 'bg-[#393939] text-white ' : 'bg-[#F8F8F8] '}`}
                    type='date'
                    defaultValue={'2005-05-15'}
                    placeholder='birthDate'
                    min='1922-01-01'
                    max='2079-12-31'
                    name='birthDate'
                    register={register}
                    onChange={(_: any) => setIsFill(true)}
                  />
                </div>
                <UIInput
                  customClassName={`border-b-2 border-gray-400 w-full pl-2
              ${isDark2 ? 'bg-[#393939] ' : 'bg-[#F8F8F8] '}`}
                  defaultValue={userData.phoneNumber}
                  placeholder='0660 1234567'
                  name='phoneNumber'
                  register={register}
                  isNumberPrefix={true}
                />
                <UIInput
                  defaultValue={userData.jobTitle === null ? '' : userData.jobTitle}
                  customClassName={`border-b-2 border-gray-400 w-full pl-2
              ${isDark2 ? 'bg-[#393939] text-white ' : 'bg-[#F8F8F8] '}`}
                  placeholder='Optional Beruf (Jobsuche /Profil)'
                  name='jobTitle'
                  register={register}
                />
                <p
                  className={`text-yellow-accent text-center font-bold mb-5
            ${isDark2 ? 'bg-[#393939] text-white ' : 'bg-[#F8F8F8]'}`}
                >
                  {message}
                </p>
              </div>
            </div>
          </div>
          <div className='flex drop-shadow-lg flex-col '>
            <UIButton text='Profil speichern' />
          </div>
          <hr className={`h-0.5 mx-4 mt-5 ${isDark2 ? 'bg-gray-400' : 'bg-gray-300'}`} />
        </form>
      )}
    </>
  );
}

export default ProfileEdit;

import React from 'react';
import {Link} from 'react-router-dom';
import {IonContent, IonPage} from '@ionic/react';

import AppSignInBackground from 'pages/account/accountPleaseSignIn/AccountSignInBackground';

import UIYellowButton from 'components/uiButtons/UIYellowButton';

import logo from 'assets/logo.png';
import background from 'assets/background.png';
import {useRecoilValue} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';

const AccountPleaseSignInPage = () => {
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <IonPage>
      <IonContent color={`${isDark2 ? 'medium ' : 'light '}`}>
        <div className={`lg:flex lg:justify-center ${isDark2 ? 'bg-[#474747]' : 'bg-[#F8F8F8] '}`}>
          <div className='h-screen lg:w-1/3'>
            <img
              alt='background'
              src={background}
              className='absolute w-screen h-screen z-0 blur-sm lg:hidden '
            />
            <div
              className={`flex justify-center h-2/6 flex-col
            ${isDark2 ? 'bg-[#474747]' : 'bg-[#F8F8F8] '}`}
            >
              <div className='h-1/6'></div>
              <div className='flex justify-center'>
                <img alt='logo' src={logo} className='w-52 h-24 z-20' />
              </div>
            </div>
            <AppSignInBackground>
              <div
                className={`h-full flex  flex-col space-y-2 p-2 justify-center items-center
              ${isDark2 ? 'bg-[#393939]' : 'bg-[#F8F8F8] '}`}
              >
                <h5 className={`text-center ${isDark2 ? ' text-white' : 'text-black'}`}>
                  Diese Funktion ist nach dem Einloggen verfügbar
                </h5>
                <div className={`w-2/3 text-center ${isDark2 ? 'bg-[#393939]' : 'bg-[#F8F8F8]'}`}>
                  <Link to='/login'>
                    <UIYellowButton text='Anmeldung' />
                  </Link>
                  <p className={`mt-3 ${isDark2 ? ' text-white' : 'text-black'}`}>oder</p>
                  <Link to='/register'>
                    <UIYellowButton text='Registrieren' />
                  </Link>
                </div>
              </div>
            </AppSignInBackground>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
export default AccountPleaseSignInPage;

import React from 'react';
import UIListItem from 'components/uiListItems/UIListItem';
import {Product} from 'interfaces/products/product.interface';

export interface ProductsProps {
  products: any;
  isOwnInstruments?: boolean;
  onProductClick: (product: any) => void;
  type?: string;
}

const ProductsListBuyPage = ({products, isOwnInstruments, onProductClick, type}: ProductsProps) => {
  return (
    <div className='space-y-12 mt-4'>
      {products.map((product: Product) => (
        <UIListItem
          key={product.id}
          product={product}
          onItemClick={onProductClick}
          isOwn={isOwnInstruments}
          type={type}
        />
      ))}
    </div>
  );
};

export default ProductsListBuyPage;

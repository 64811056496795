import axios from 'axios';
import {authService} from 'api/account/auth.service';
import {BASE_URL_API} from 'utils/constants';

const client = axios.create({
  baseURL: BASE_URL_API,
});

client.interceptors.request.use((config) => {
  authService.tokenValue && (config.headers!.Authorization = `Bearer ${authService.tokenValue}`);
  return config;
});

client.interceptors.response.use(undefined, async (error) => {
  if (error.config && error.response && error.response.status === 401) {
    authService.refreshAccessToken().then(() => {
      error.config.headers.Authorization = `Bearer ${authService.tokenValue}`;
      return axios.request(error.config);
    });
  }
  return Promise.reject(error);
});

export default client;

import battery from 'assets/tools/battery.svg';
import building from 'assets/tools/building.svg';
import farm from 'assets/tools/farm.svg';
import garden from 'assets/tools/garden.svg';
import gasoline from 'assets/tools/gasoline.svg';
import grinder from 'assets/tools/grinder.svg';
import trail from 'assets/machinery/trail.svg';
import excavator from 'assets/machinery/excavator.svg';
import bridge from 'assets/machinery/bridge.svg';
import crane from 'assets/machinery/crane.svg';
import forkLift from 'assets/machinery/fork_lift.svg';
import crawler from 'assets/machinery/crawler.svg';
import transporter from 'assets/machinery/transporter.svg';
import landForest from 'assets/machinery/land_forest.svg';
import machine from 'assets/agriculture/maschine.svg';
import forestTool from 'assets/agriculture/forstwerkzeug.svg';
import others from 'assets/agriculture/sonstiges.svg';
import excavator2 from 'assets/excavator.svg';
import sweeper from 'assets/agriculture/sweeper.svg';

export const getCategoryIcon = (id: number) => {
  switch (id) {
    case 1:
      return battery;
    case 2:
      return building;
    case 3:
      return gasoline;
    case 4:
      return garden;
    case 5:
      return farm;
    case 6:
      return grinder;
    case 7:
      return trail;
    case 8:
      return crawler;
    case 9:
      return excavator;
    case 10:
      return crane;
    case 11:
      return forkLift;
    case 12:
      return bridge;
    case 13:
      return transporter;
    case 14:
      return landForest;
  }
};
export const getMachineryIcon = (name: string) => {
  switch (name) {
    case 'Anhänger':
      return trail;
    case 'Bagger':
      return excavator;
    case 'Baukräne':
      return crane;
    case 'Hebebühne':
      return bridge;
    case 'Anbauteile':
      return excavator2;
    case 'Raupen & Lader':
      return crawler;
    case 'Stapler':
      return forkLift;
    case 'Auto & Transporter':
      return transporter;
  }
};

export const getAgricultureIcon = (id: Number) => {
  switch (id) {
    case 15:
      return machine;
    case 16:
      return forestTool;
    case 17:
      return trail;
    case 18:
      return sweeper;
    case 19:
      return excavator2;
    case 20:
      return others;
  }
};

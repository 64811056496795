import {diysService} from 'api/diy/diy.service';
import LoadingIndicator from 'components/uiLoading/UILoadingIndicator';
import LineColored from 'forms/instruments/LineColored';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';

const DIYList = () => {
  const [diys, setDiys] = useState<any[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    diysService
      .getAll()
      .then((res) => {
        const alphaDiys = res.data;
        alphaDiys.sort((a: any, b: any) => a.title.localeCompare(b.title));
        setDiys(alphaDiys);
      })
      .finally(() => setIsLoading(false));
  }, []);
  const isDark2 = useRecoilValue(isDarkTheme);

  return (
    <div className='text-black p-5 space-y-5'>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        diys &&
        diys.map((diy, idx, list) => {
          return (
            <div key={diy.id}>
              {(idx === 0 || diy.title[0] !== list[idx - 1].title[0]) && (
                <div className='text-orange-main'>{diy.title[0].toUpperCase()}</div>
              )}
              <Link to={`diy/${diy.id}`}>
                <div className={`w-full ${isDark2 ? ' text-white' : 'text-black'}`}>
                  {diy.title}
                </div>
              </Link>
              <LineColored />
            </div>
          );
        })
      )}
    </div>
  );
};

export default DIYList;

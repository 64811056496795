import React, {useState} from 'react';
import {IonItem, IonLabel, IonRadio, IonRadioGroup} from '@ionic/react';
import {useRecoilValue} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';

type Props = {
  setCheckedValue: Function;
};

const AccountTypeSelect: React.FC<Props> = ({setCheckedValue}) => {
  const [selected, setSelected] = useState<string>();
  const isDark2 = useRecoilValue(isDarkTheme);

  return (
    <div>
      <IonRadioGroup
        value={selected}
        onIonChange={(e: any) => {
          setSelected(e.detail.value);
          if (e.detail.value === 'Privater Account') setCheckedValue('PRIVATE');
          if (e.detail.value === 'Geschäftskonto') setCheckedValue('COMPANY');
        }}
      >
        <IonItem color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonLabel>
            <b className={`font-bold text-xs ${isDark2 ? ' text-white' : ' text-black'}`}>
              Privates Konto
            </b>
          </IonLabel>
          <IonRadio slot='start' value='Privater Account' />
        </IonItem>
        <IonItem color={`${isDark2 ? 'medium' : 'light'}`} lines='none'>
          <IonLabel>
            <b className={`font-bold text-xs ${isDark2 ? ' text-white' : ' text-black'}`}>
              Geschäftskonto
            </b>
          </IonLabel>
          <IonRadio slot='start' value='Geschäftskonto' />
        </IonItem>
      </IonRadioGroup>
    </div>
  );
};

export default AccountTypeSelect;

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import React, {useEffect, useState} from 'react';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import LoadingIndicator from 'components/uiLoading/UILoadingIndicator';
import {jobsService} from 'api/jobs/jobs.service';
import {Job} from 'interfaces/jobs/jobs.interface';
import {JobsList} from 'pages/jobs/jobsList/JobsList';
import UILogo from 'components/uiLogo/UILogo';
import UIButtonSearch from 'components/uiButtons/UIButtonSearch';
import sorting from 'assets/sorting.svg';
import {funnel} from 'ionicons/icons';
import UIButtonAdd from 'components/uiButtons/UIButtonAdd';
import {distanceCalculator} from 'utils/distanceCalculator';
import UIFilter from 'components/uiFilter/UIFilter';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {geolocationState, refreshCounterState} from 'states/common/common.state';
import {defaultDistanceMax, defaultDistanceMin} from 'utils/constants';
import UIResponsivePadding from 'components/uiResponsive/UIResponsivePadding';
import {isDarkTheme} from 'states/account/account.state';

const JobListPage: React.FC = () => {
  const [originalJobs, setOriginalJobs] = useState<Job[]>();
  const [jobs, setJobs] = useState<Job[]>();
  const [isFilterShown, setIsFilterShown] = useState<boolean>(false);
  const [distanceMin, setDistanceMin] = useState(defaultDistanceMin);
  const [distanceMax, setDistanceMax] = useState(defaultDistanceMax);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const geolocation = useRecoilValue(geolocationState);
  const shouldRefreshData = useRecoilValue(refreshCounterState);
  const setRefreshCount = useSetRecoilState(refreshCounterState);

  const router = useIonRouter();

  useEffect(() => {
    setIsLoading(true);
    jobsService
      .getAll()
      .then((response) => {
        response.data &&
          response.data.forEach((job: Job) => {
            job.distance = distanceCalculator(job.lat, job.lng, geolocation);
          });
        setJobs(response.data);
        setOriginalJobs(response.data);
      })
      .finally(() => setIsLoading(false));
  }, [shouldRefreshData]);

  useEffect(() => {
    jobs && getOrderBy('Entfernung aufsteigend');
  }, [originalJobs, shouldRefreshData]);

  const handleFilter = () => {
    let tempData = originalJobs;
    tempData = tempData?.filter((item) => {
      return item.distance! >= distanceMin && item.distance! <= distanceMax;
    });

    setJobs(tempData);
  };

  const handleSort = (sortValue: string) => {
    getOrderBy(sortValue);
  };

  const getOrderBy = (sortValue: string) => {
    switch (sortValue) {
      case 'Datum aufsteigend':
        jobsService.getSortedJobs('date_updated').then((res) => {
          setJobs(res.data.results);
        });
        return 'dateAdded';
      case 'Datum absteigend':
        jobsService.getSortedJobs('-date_updated').then((res) => {
          setJobs(res.data.results);
        });
        return '-dateAdded';
      case 'Preis aufsteigend':
        jobsService.getSortedJobs('salary').then((res) => {
          setJobs(res.data.results);
        });
        return 'salary';
      case 'Preis absteigend':
        jobsService.getSortedJobs('-salary').then((res) => {
          setJobs(res.data.results);
        });
        return '-salary';
      case 'Entfernung aufsteigend':
        const sorted5 = [
          ...jobs!.sort((a, b) => (Number(a.distance!) > Number(b.distance!) ? 1 : -1)),
        ];
        setJobs(sorted5);
        return 'distance';
      case 'Entfernung absteigend':
        const sorted6 = [
          ...jobs!.sort((a, b) => (Number(a.distance!) < Number(b.distance!) ? 1 : -1)),
        ];
        setJobs(sorted6);
        return '-distance';
    }
  };
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
          <IonButtons slot='end'>
            <UIButtonSearch itemsType='jobs' />
            <UIButtonAdd onClick={() => router.push('/jobs-add')} />
          </IonButtons>
        </IonToolbar>
        <UITopTextBar
          leftComponent={
            <button title='button' onClick={() => setIsFilterShown(!isFilterShown)}>
              <IonIcon className='mt-1 ml-3 text-white' icon={funnel} />
            </button>
          }
          rightComponent={
            <div className='flex w-7/8 mr-2'>
              <IonSelect
                okText='Weiter'
                cancelText='Abbrechen'
                onIonChange={(e) => handleSort(e.detail.value)}
                placeholder='Sortieren'
                className='text-xs text-transparent w-8 -mr-8 z-10'
              >
                <IonSelectOption className='text-black'>Datum aufsteigend</IonSelectOption>
                <IonSelectOption className='text-black'>Datum absteigend</IonSelectOption>
                <IonSelectOption className='text-black'>Preis aufsteigend</IonSelectOption>
                <IonSelectOption className='text-black'>Preis absteigend</IonSelectOption>
                <IonSelectOption className='text-black'>Entfernung aufsteigend</IonSelectOption>
                <IonSelectOption className='text-black'>Entfernung absteigend</IonSelectOption>
              </IonSelect>
              <img alt='img' className='my-2 mr-3' src={sorting} />
            </div>
          }
        >
          Jobsuche
        </UITopTextBar>
      </IonHeader>
      {isFilterShown && (
        <UIFilter
          onFilter={handleFilter}
          setDistanceMin={setDistanceMin}
          setDistanceMax={setDistanceMax}
        />
      )}
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <UIResponsivePadding>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            jobs && (
              <JobsList
                jobs={jobs}
                isOwnJob={false}
                onJobClick={(jobId: any) => {
                  router.push(`/job/${jobId}`);
                  setRefreshCount((v) => v + 1);
                }}
                type='jobs'
                // onContackClic={}
              />
            )
          )}
        </UIResponsivePadding>
      </IonContent>
    </IonPage>
  );
};

export default JobListPage;

import React from 'react';

interface LineColoredProps {
  colorIntensity?: number | string;
  color?: string;
  error?: any;
}

const LineColored: React.FC<LineColoredProps> = ({
  colorIntensity = 500,
  color = 'gray',
  error,
}) => (
  <div
    className={`bg-${error? 'red': color}-${colorIntensity}
     min-w-fit rounded-md h-px mb-3`}
  ></div>
);

export default LineColored;

import React, {useEffect, useState} from 'react';
import {instrumentsService} from 'api/instruments/instruments.service';
import {jobsService} from 'api/jobs/jobs.service';
import {servicesService} from 'api/services/services.service';
import {SearchMapResults} from './SearchMapResults';
import {isDarkTheme} from 'states/account/account.state';
import {useRecoilValue} from 'recoil';

const SearchMapComponent = () => {
  const [items, setItems] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const search = async () => {
      try {
        const instruments = await instrumentsService.search(searchTerm, '');
        const services = await servicesService.search(searchTerm);
        const jobs = await jobsService.search(searchTerm);
        setItems([...instruments?.results, ...services?.results, ...jobs?.results]);
      } catch (error) {
        console.log(error);
      }
    };
    search();
  }, [searchTerm]);
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <div className='w-full -mt-1.5'>
      <div className='w-full bg-gray-500 flex p-2'>
        <input
          className={`rounded-md w-full pl-4 py-2 bg-gray-300
          ${isDark2 ? 'text-black' : 'text-gray-500 '}`}
          type='text'
          placeholder='Suche'
          onChange={(e) => setSearchTerm(e.currentTarget.value)}
        />
      </div>
      <div className='w-full'>{items && <SearchMapResults items={items} />}</div>
    </div>
  );
};
export default SearchMapComponent;

import React from 'react';
import {useRecoilValue} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';

interface Props {
  text: string;
  onClick?: any;
  type?: 'button' | 'reset' | 'submit' | undefined;
  customClassName?: string;
}

const UIYellowButton: React.FC<Props> = ({text, onClick, type, customClassName}) => {
  const isDark2 = useRecoilValue(isDarkTheme);

  return (
    <div
      onClick={onClick}
      className={`border text-center
        mx-4 py-2 rounded-xl text-sm 
        ${isDark2 ? 'bg-[#F0A249] border-[#F0A249]' : 'bg-yellow-accent border-yellow-accent'}`}
    >
      <button type={type} className={`h-full w-full text-white ${customClassName}`}>
        <b>{text}</b>
      </button>
    </div>
  );
};

export default UIYellowButton;

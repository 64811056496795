import React from 'react';
import {urlConverter} from 'utils/urlConverter';

import placeholderImage from 'assets/placeholder_image2.png';

type photoHelperProps = {
  photoLink: string;
  customClassName?: string;
  type?: string;
};

export const UIImage: React.FC<photoHelperProps> = ({customClassName, photoLink, type}) => {
  photoLink = urlConverter(photoLink);
  return (
    <div className='flex justify-center'>
      <img
        src={photoLink ? photoLink : placeholderImage}
        className={`
          ${
            customClassName && photoLink ? customClassName : 'h-28 w-full mb-3 rounded-xl '
          } centered
        `}
      />
    </div>
  );
};

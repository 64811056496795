import React from 'react';
import {Link} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';

interface Props {
  name: string;
  src: any;
  linkTo?: any;
  alt: string;
  disabled?: boolean;
  onClick?: () => void;
}

const UIOrangeSquareBox: React.FC<Props> = ({linkTo, name, src, alt, disabled, onClick}) => {
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <Link className='w-1/2 p-2' to={linkTo!}>
      <div
        className={`border-2  rounded-xl shadow-lg shadow-orange-main
        ${isDark2 ? 'bg-[#393939] border-[#F0A249]' : 'border-orange-main bg-[#F8F8F8]'} 
        ${disabled && 'opacity-50'}`}
        onClick={onClick}
      >
        <div
          className={`h-[108px] flex justify-center items-center rounded-t-xl
        ${isDark2 ? 'bg-[#F0A249]' : 'bg-orange-main'}`}
        >
          <img src={src} alt={alt} className='w-18 mt-6' />
        </div>
        <div
          className={`h-16 rounded-b-xl flex items-center justify-center
          text-center text-sm font-bold 
          ${isDark2 ? 'text-white' : 'text-grey'}`}
        >
          {name}
        </div>
      </div>
    </Link>
  );
};

export default UIOrangeSquareBox;

import {IonContent, IonHeader, IonPage, IonToolbar, IonIcon, IonButtons} from '@ionic/react';
import UILogo from 'components/uiLogo/UILogo';
import React, {useState, useEffect, useRef} from 'react';
import {postMessage, getMessages, createChat} from 'api/chat/chat.service';
import {useParams} from 'react-router';
import {send} from 'ionicons/icons';
// import {attach} from 'ionicons/icons';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import {useSetRecoilState, useRecoilValue} from 'recoil';
// import {datesTypeState, selectedDatesState} from 'states/messages/message.state';
import UILoadingIndicator from 'components/uiLoading/UILoadingIndicator';
import {refreshCounterState} from 'states/common/common.state';
import {dateHandler} from 'utils/dateHandler';
import {accountState, isDarkTheme} from 'states/account/account.state';

export const ChatDetail = () => {
  const {type, id: receiverId, itemName, conversationId}: any = useParams();
  // const resetDates = useResetRecoilState(selectedDatesState);
  // const dates = useRecoilValue(selectedDatesState);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const setRefreshCounter = useSetRecoilState(refreshCounterState);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  // const datesType = useRecoilValue(datesTypeState);
  const [mesClass, setMesClass] = useState('opacity-0');
  const [chatId, setChatId] = useState(conversationId);
  const accountData = useRecoilValue(accountState);
  const [chatDetails, setChatDetails] = useState<{
    firstName: string;
    email: string;
    id: number;
    lastName: string;
  }>();

  useEffect(() => {
    // if (dates) sendDates();
    setIsLoading(true);
    fetchMessages(receiverId).finally(() => {
      const timer = setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({behavior: 'auto'});
        setMesClass('');
      }, 500);
      setIsLoading(false);
      return () => clearTimeout(timer);
    });
  }, [chatId, refreshCounterState]);

  useEffect(() => {
    const interval = setInterval(() => fetchMessages(receiverId), 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  // Waiting for client
  // const sendDates = async () => {
  //   let mes = '';
  //   if (dates?.length !== 0) {
  //     mes =
  //       datesType === 'instruments'
  //         ? 'ist das Gerät am '
  //         : 'hätten Sie Zeit von ';
  //     dates!.forEach((date) => {
  //       mes += `${new Date(date.name).toLocaleDateString()} `;
  //     });
  //     mes += datesType === 'instruments' ? 'verfügbar' : '';
  //   } else if (dates?.length == 0) {
  //     mes;
  //   };
  //   if (chatId === '0') {
  //     try {
  //       await createChat({message: mes}, receiverId, type).then((res) => {
  //         if (res.data.id) resetDates();
  //         setChatId(res.data.id);
  //       }
  //         );
  //         fetchMessages(receiverId);
  //         setRefreshCounter((prev) => prev +1);
  //   } catch (error) {}
  //   setMessage('');
  //   resetDates();
  //   return;
  // }
  //   try {
  //     await postMessage({message: mes}, receiverId, type, chatId);
  //     fetchMessages(receiverId);
  //     setRefreshCounter((prev) => prev + 1);
  //   } catch (error) {}
  //   setMessage('');
  //   resetDates();
  // };

  const fetchMessages = async (receiverId: any) => {
    try {
      const response = await getMessages(receiverId, type, chatId);

      response.data.participants.filter((participant: any) => {
        participant.id !== accountData!.id && setChatDetails(participant);
      });
      setMessages(response.data.messages);
      messagesEndRef.current?.scrollIntoView({behavior: 'auto'});
    } catch (error) {}
  };

  const handleSendMessage = async () => {
    if (chatId === '0') {
      try {
        await createChat({message}, receiverId, type).then((res) => setChatId(res.data.id));
        fetchMessages(receiverId);
        setRefreshCounter((prev) => prev + 1);
      } catch (error) {}
      setMessage('');
      return;
    }

    try {
      await postMessage({message}, receiverId, type, chatId);
      fetchMessages(receiverId);
      setRefreshCounter((prev) => prev + 1);
    } catch (error) {
      console.log(error);
    }
    setMessage('');
  };
  const isDark2 = useRecoilValue(isDarkTheme);

  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar>
          {chatDetails ? chatDetails.firstName + ' ' + chatDetails.lastName : itemName}
        </UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        {isLoading ? (
          <UILoadingIndicator />
        ) : (
          <div className='h-full'>
            <div className={`flex overflow-y-scroll h-full flex-col-reverse ${mesClass}`}>
              <div
                ref={messagesEndRef}
                className={`h-16 flex items-center w-full
                justify-center pb-5 ${isDark2 ? 'bg-[#393939] pt-5' : 'bg-[#F8F8F8]'}`}
              >
                <input
                  className='bg-orange-200 text-[#393E46] h-8 w-3/4 px-2 rounded-lg'
                  type='text'
                  placeholder='Text…'
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  value={message}
                />
                <button
                  className='w-8 h-8 ml-1 rounded-xl bg-orange-400'
                  onClick={handleSendMessage}
                >
                  <IonIcon className='text-white' icon={send} />
                </button>
              </div>
              <div className='h-full' />
              {messages &&
                messages.map((data: any) => (
                  <>
                    {data.sender.id == accountData?.id ? (
                      <div key={data.id} className='flex items-end flex-col'>
                        <div
                          className='bg-orange-300 text-[#393E46] p-2 rounded-2xl
                         w-fit ml-3 mr-1 mt-3 break-all'
                        >
                          {data.message}
                        </div>
                        <p className='p-2 text-xs '>{dateHandler(data.dateSent, '', true)}</p>
                      </div>
                    ) : (
                      <div key={data.id} className='flex flex-col'>
                        <div className='bg-gray-300 rounded-2xl w-fit p-2 mr-3 ml-1 mt-3'>
                          {data.message}
                        </div>
                        <p className='p-2 text-xs '>{dateHandler(data.dateSent, '', true)}</p>
                      </div>
                    )}
                  </>
                ))}
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

import {Camera, CameraResultType, CameraSource} from '@capacitor/camera';
import {isPlatform, useIonActionSheet} from '@ionic/react';
import addphoto from 'assets/add_photo.png';
import React, {useEffect, useRef, useState} from 'react';
import {base64ToFile, urlToFile} from 'utils/base64ToBlobConverter';
import {BASE_URL_API, ONE_MB_IN_BYTES} from 'utils/constants';
import {urlConverter} from 'utils/urlConverter';

interface AddPhotoInnerProps {
  onChange: (...event: any[]) => void;
  value: string | any;
  availableExtensions?: string[];
  onValueChange?: Function;
}

const AddPhotoInner: React.FC<AddPhotoInnerProps> = ({
  onChange,
  value,
  availableExtensions = ['image/jpg', 'image/jpeg', 'image/png'],
  onValueChange,
}) => {
  const [photoUrl, setPhotoUrl] = useState<string | undefined>(value);
  const imageInput = useRef<any>(null);
  const [present] = useIonActionSheet();

  const handleAndroidCamera = async (source: CameraSource) => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      source: source,
      resultType: CameraResultType.Base64,
    });
    if (!image) return;
    const file = base64ToFile(image.base64String!);
    handleChange([file]);
  };

  const handleAndroidGallery = async () => {
    const images = await Camera.pickImages({
      quality: 90,
      limit: 1,
    });
    if (!images.photos) return;

    const files: File[] = [];

    for (let i = 0; i < images.photos.length; i++) {
      const file = await urlToFile(images.photos[i].webPath!, images.photos[i].format);
      files.push(file);
    }

    handleChange([...files]);
  };

  useEffect(() => {
    if (!value) {
      return;
    }
    if (value === 'deletePhoto') {
      setPhotoUrl(undefined);
      return;
    }

    if (typeof value == 'string' && value.substring(0, 4) === 'http') {
      setPhotoUrl(urlConverter(value));
      return;
    }

    if (typeof value === 'string') {
      setPhotoUrl(`${BASE_URL_API + value.substring(1)}`);
      return;
    }

    setPhotoUrl(URL.createObjectURL(value));
  }, [value]);

  const handleChange = (files: FileList | any) => {
    const file: File = files[0];

    if (!file) {
      return;
    }

    const availableSizeInMb = 20;

    if (file.size > availableSizeInMb * ONE_MB_IN_BYTES) {
      // eslint-disable-next-line no-alert
      alert('Die Datei ist zu groß!');
      return;
    }

    onChange(file);
    onValueChange && onValueChange();
  };

  return (
    <>
      <input
        type='file'
        onChange={(event) => handleChange(event.currentTarget.files)}
        ref={imageInput}
        className='filetype'
        style={{display: 'none'}}
        accept={availableExtensions.join(', ')}
      />
      <div
        className='border border-yellow-accent rounded-xl h-[112px]
    w-[109px]'
      >
        <button
          className='h-full w-full'
          type='button'
          onClick={() =>
            isPlatform('android')
              ? present([
                  {
                    text: 'Fotomediathek',
                    handler: () => handleAndroidGallery(),
                  },
                  {
                    text: 'Foto aufnehmen',
                    handler: () => handleAndroidCamera(CameraSource.Camera),
                  },
                ])
              : imageInput.current?.click()
          }
        >
          <div className='flex justify-center'>
            {!photoUrl && <img alt='added_photos' src={addphoto} className='h-8 w-9' />}
          </div>
          {photoUrl && (
            <img
              alt='added_photos'
              src={photoUrl}
              className='centered h-[110px] w-[107px] rounded-xl'
            />
          )}
        </button>
      </div>
    </>
  );
};

export default AddPhotoInner;

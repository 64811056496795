import {IonIcon, IonSelect, IonSelectOption, IonTextarea} from '@ionic/react';
import UIInput from 'components/uiInputs/UIInput';
import AddPhotos from 'components/uiAddPhoto/UIAddPhotos';
import UIYellowButton from 'components/uiButtons/UIYellowButton';
import {OrangeLine} from 'components/uiContainer/UIOrangeLine';
import {BFormAddressField} from 'forms/common/places/BFormAddressField';
import {callOutline, mailOpenOutline} from 'ionicons/icons';
import React, {useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {jobsService} from 'api/jobs/jobs.service';
import LineColored from '../instruments/LineColored';
import {Job, JobType, jobTypeTranslated} from 'interfaces/jobs/jobs.interface';
import {useHistory} from 'react-router';
import {accountService} from 'api/account/account.service';
import {useRecoilValue} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';
import EditPhotos from 'pages/common/editPhotos/EditPhotos';

const JobOfferForm: React.FC = () => {
  const [apiResultMessage, setApiResultMessage] = useState<string>('');
  const [jobTypeIdx, setJobTypeIdx] = useState<number>();
  const [jobId, setJobId] = useState<number>();
  const [address, setAddress] = useState<any>();
  const inputRef = useRef<any>();
  const [isPhotoPopUp, setIsPhotoPopUp] = useState<boolean>();
  const [photos, setPhotos] = useState<any>();

  const {
    handleSubmit,
    register,
    setValue,
    control,
    formState: {errors},
  } = useForm();

  const history = useHistory();

  useEffect(() => {
    accountService.getCurrentUser().then((response) => {
      setValue('email', response.data.email);
      setValue('phoneNumber', response.data.phoneNumber);
    });
  }, []);

  useEffect(() => {
    if (photos) {
      const photoHelper = [];
      for (let i = 0; i < 4; i++) {
        if (typeof photos['photo_' + i] === 'undefined' || photos['photo_' + i] == 'deletePhoto') {
          continue;
        }
        photoHelper.push(photos['photo_' + i]);
      }
      photoHelper.length > 0 && setValue('photos', photoHelper);
    }
  }, [photos]);

  const onSubmit = async (data: Job) => {
    data.jobType = jobTypeTranslated(jobTypeIdx!);
    data.companyName = data.name;
    data.salary = data.salary;

    if (data.address) {
      data.location = data.address.label;
      data.postCode = data.address.postCode;
      data.lat = data.address.lat.toFixed(7);
      data.lng = data.address.lng.toFixed(7);
      setAddress(data.address);
    } else if (address) {
      data.location = address.label;
      data.postCode = address.postCode;
      data.lat = address.lat.toString();
      data.lng = address.lng.toString();
    }

    const method = jobId ? jobsService.updateJob(data, jobId.toString()) : jobsService.addJob(data);

    setApiResultMessage('Ladung...');

    method
      .then((res) => {
        setApiResultMessage('');
        setJobId(res.data.id);
        setAddress(data.address);
        history.push(`/purchase/jobs/${res.data!.id! ? res.data.id : jobId}`);
      })
      .catch((error) => {
        setApiResultMessage('Error! ' + error.response.data.message[0]);
      });
  };

  const handleInputChange = (e: any) => {
    if (e == null) {
      return null;
    }

    setJobTypeIdx(e.detail.value);
  };
  const isDark2 = useRecoilValue(isDarkTheme);

  return (
    <>
      {isPhotoPopUp && (
        <EditPhotos setPhotos={setPhotos} photos={photos} setIsPhotoPopUp={setIsPhotoPopUp} />
      )}
      {!isPhotoPopUp && (
        <form className='h-full mb-3 font-sans font-semibold' onSubmit={handleSubmit(onSubmit)}>
          <div
            className={`border-2 border-orange-main rounded-md mb-5 ${
              isDark2 ? 'bg-[#393939] text-white' : 'bg-[#F8F8F8] text-black'
            }`}
          >
            <div className={`w-full h-8 ${isDark2 ? 'bg-[#F0A249] ' : 'bg-orange-main '}`} />
            <div className={`flex my-4 ${isDark2 ? 'bg-[#393939]' : 'bg-[#F8F8F8]'}`}>
              <div
                className='flex-col w-auto space-y-2 ml-2 text-sm'
                onClick={() => setIsPhotoPopUp(true)}
              >
                <AddPhotos control={control} name='photos' disabled={true} />
              </div>
              <div
                className={`flex-col w-3/5 space-y-2 mx-4 text-sm $${
                  isDark2 ? 'bg-[#393939] text-white' : ' bg-[#F8F8F8] text-black'
                }`}
              >
                Firmenname:{' '}
                <UIInput
                  required='required'
                  customClassName={`w-full text-sm break-all ${
                    isDark2 ? 'bg-[#393939] text-white' : ' bg-[#F8F8F8] text-black'
                  }`}
                  placeholder='Dein Firmenname'
                  register={register}
                  name='name'
                />
                <LineColored />
                Stellenanzeige:{' '}
                <UIInput
                  required='required'
                  customClassName={`w-full text-sm break-all ${
                    isDark2 ? 'bg-[#393939] text-white' : ' bg-[#F8F8F8] text-black'
                  }`}
                  placeholder='Bauarbeiter'
                  register={register}
                  name='jobOffer'
                />
                <LineColored />
                Berufsart:{' '}
                <UIInput
                  required='required'
                  customClassName={`w-full text-sm break-all ${
                    isDark2 ? 'bg-[#393939] text-white' : ' bg-[#F8F8F8] text-black'
                  }`}
                  placeholder='z.B Metalltechnikerin'
                  register={register}
                  name='position'
                />
                <LineColored />
                Bezahlung: (pro Tag/ pro Stunde usw.)
                <div className='flex'>
                  <UIInput
                    customClassName={`w-full text-sm ${
                      isDark2 ? 'bg-[#393939] text-white' : ' bg-[#F8F8F8] text-black'
                    }`}
                    placeholder='2.400,00 €/Monat'
                    register={register}
                    name='salary'
                    validation={{required: true}}
                    errors={errors}
                    type='text'
                  />
                  <LineColored error={errors.salary}></LineColored>
                </div>
                <LineColored error={errors.price} />
                <div className='mt-8 w-full'>
                  <p>Arbeitszeit:</p>
                  <IonSelect
                    okText='Weiter'
                    cancelText='Abbrechen'
                    placeholder='Wählen...'
                    onIonChange={(e) => handleInputChange(e)}
                    ref={inputRef}
                  >
                    <LineColored />
                    {JobType.map((job: any) => (
                      <IonSelectOption key={job.id} value={job.value}>
                        {job.label}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </div>
              </div>
            </div>
            <OrangeLine name='Kontakt' />
            <div className='m-3 text-sm space-y-2'>
              <div
                className={`flex space-x-1 w-full
          ${isDark2 ? 'bg-[#393939] text-white' : ' bg-[#F8F8F8] text-black'}`}
              >
                <IonIcon icon={callOutline} className='mt-0.5' />
                <UIInput
                  customClassName={`bg-transparent ${
                    isDark2 ? 'bg-[#393939] text-white' : ' bg-[#F8F8F8] text-black'
                  }`}
                  type='phoneNumber'
                  name='phoneNumber'
                  register={register}
                  placeholder='0660 1234567'
                  validation={{required: true}}
                  minLength={9}
                  maxLength={16}
                  errors={errors}
                />
              </div>
              <div
                className={`flex space-x-1 
          ${isDark2 ? 'bg-[#393939] text-white' : ' bg-[#F8F8F8] text-black'}`}
              >
                <IonIcon icon={mailOpenOutline} className='mt-0.5' />
                <UIInput
                  customClassName={`bg-transparent ${
                    isDark2 ? 'bg-[#393939] text-white ' : 'bg-[#F8F8F8] text-black'
                  }`}
                  type='email'
                  name='email'
                  // value={user?.email}
                  register={register}
                  placeholder='beispiel@email.com'
                  validation={{required: true}}
                  required='required'
                  errors={errors}
                />
              </div>
            </div>
            <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
              <div className='text-orange-main text-sm'>Adresse</div>
              <div className='bg-orange-main w-full h-px'></div>
            </div>
            <div className='mx-2 mt-2'>
              <BFormAddressField
                placeholder={address?.label}
                control={control}
                fieldName='address'
                key={Math.random()}
              />
            </div>
            <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
              <div
                className={`${isDark2 ? 'text-sm text-[#F0A249]' : 'text-orange-main text-sm '}`}
              >
                Beschreibung
              </div>
              <div
                className={`${isDark2 ? 'w-4/5 h-px bg-[#F0A249]' : 'bg-orange-main w-4/5 h-px'}`}
              ></div>
            </div>
            <IonTextarea
              maxlength={1000}
              className={`input break-words px-3 text-sm ${isDark2 ? 'text-white ' : 'text-black'}`}
              placeholder='Beschreibe die Aufgaben, Anforderungen und Aussichten'
              {...register('description')}
            />
          </div>
          <div className='flex flex-col justify-end text-white'>
            <p className='text-yellow-accent text-center font-bold mb-5'>{apiResultMessage}</p>
            <UIYellowButton type='submit' text='Weiter' />
          </div>
        </form>
      )}
    </>
  );
};

export default JobOfferForm;

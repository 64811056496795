import React, {useState} from 'react';
import {useHistory} from 'react-router';
import {Link} from 'react-router-dom';
import {IonProgressBar, IonPage} from '@ionic/react';
import {accountService} from 'api/account/account.service';
import {formRegister} from 'forms/account/formRegister/register.form';
import BForm from 'forms/common/bForm/BForm';
import AppLoginBackground from 'pages/account/accountLogin/AccountLoginBackground';
import logo from 'assets/logo.png';
import background from 'assets/background.png';
import {isDarkTheme} from 'states/account/account.state';
import {useRecoilValue} from 'recoil';

const AccountRegisterPage = () => {
  const isDark2 = useRecoilValue(isDarkTheme);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any[]>([]);

  const history = useHistory();

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    setErrors([]);
    const femail = data.email.trim();
    const available = await accountService.checkEmailAvailability(femail);
    setIsLoading(false);
    if (available) {
      history.push('/registerSecondStage', {
        email: femail,
        password: data.password,
        name: data.name,
      });
      return;
    }
    setErrors([{name: 'email', message: 'E-Mail-Adresse ist bereits vergeben'}]);
    throw new Error('E-Mail-Adresse ist bereits vergeben');
  };
  return (
    <IonPage className='bg-gray-200'>
      <div
        className={`lg:flex lg:justify-center
      ${isDark2 ? 'bg-[#474747]' : 'bg-[#F8F8F8]'}`}
      >
        <div
          className={`h-screen lg:w-1/3
        ${isDark2 ? 'bg-[#474747]' : 'bg-[#F8F8F8]'}`}
        >
          <img
            alt='Background'
            src={background}
            className='absolute w-screen h-screen z-0 blur-sm lg:hidden'
          />
          <div className='flex justify-center h-1/4 flex-col'>
            <div className='flex justify-center'>
              <img alt='logo' src={logo} className='w-44 h-20 z-20' />
            </div>
          </div>
          <AppLoginBackground>
            <div className='flex justify-center flex-col'>
              {isLoading && <p>Loading...</p>}
              <b className={`text-xl mt-2 mx-3 ${isDark2 ? ' text-white' : 'text-black'}`}>
                Registrierung
              </b>
              <div className='mx-3 mt-2'>
                <IonProgressBar color='primary' value={1 / 3} />
              </div>
              <div className='mx-3'>
                <BForm
                  btnMargin={10}
                  btnText='Weiter'
                  fields={formRegister}
                  preloadedData={{}}
                  submit={onSubmit}
                  formErrors={errors}
                />
                <hr className='bg-yellow-accent' />
                <p
                  className={`text-xs text-center mt-3
                ${isDark2 ? ' text-white' : 'text-black'}`}
                >
                  Sie haben bereits ein Konto?
                </p>
                <Link to='/login' className='text-yellow-accent w-full'>
                  <div
                    className={`mt-1 mb-4 text-sm
                    text-center rounded-lg border border-yellow-accent p-2
                    ${isDark2 ? ' text-white bg-[#F0A249] ' : ' bg-yellow-accent text-white '}`}
                  >
                    <b>Zum Einloggen</b>
                  </div>
                </Link>
              </div>
            </div>
          </AppLoginBackground>
        </div>
      </div>
    </IonPage>
  );
};

export default AccountRegisterPage;

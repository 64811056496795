import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar} from '@ionic/react';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import UILogo from 'components/uiLogo/UILogo';
import JobOfferForm from 'forms/jobs/jobOffer.form';
import React from 'react';
import {useRecoilValue} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';

const JobAddPage: React.FC = () => {
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar>Jobinserat erstellen</UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <div className='m-3'>
          <JobOfferForm />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default JobAddPage;

import React, {useEffect, useState} from 'react';
import {IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonToolbar} from '@ionic/react';
import UILogo from 'components/uiLogo/UILogo';
import {useParams} from 'react-router';
import {Instrument} from 'interfaces/instruments/instrument.interface';
import UIYellowButton from 'components/uiButtons/UIYellowButton';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import {useIonRouter} from '@ionic/react';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import LoadingIndicator from 'components/uiLoading/UILoadingIndicator';
import {useRecoilState, useRecoilValue} from 'recoil';
import {accountState, isDarkTheme} from 'states/account/account.state';
import {chevronForward} from 'ionicons/icons';
import {productsService} from 'api/products/products.service';
import {DetailsProducts} from './DetailsProducts';
import FormEditProduct from 'forms/products/editProduct.form';
import {refreshCounterState} from 'states/common/common.state';
import {UIDeleteButton} from 'components/uiButtons/UIDeleteButton';
import UIPopUpPhoto from 'components/uiImages/UIPopUpPhoto';
import {User} from 'interfaces/users/user.interface';
import UIRatePopUp from 'components/uiRatePopUp/UIRatePopUp';

export const DetailProductsPage = () => {
  const router = useIonRouter();
  const [isFavorite, setIsFavorite] = useState(false);
  const [isOwn, setIsOwn] = useState(false);
  const {instrumentId, catName, subCatName}: any = useParams();
  const [product, setProduct] = useState<Instrument>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const accountData = useRecoilValue<User | any>(accountState);
  const [isPopUpVisible, setIsPopUpVisible] = useState<boolean>(false);
  const [showRatingPopUp, setShowRatingPopUp] = useState<boolean>(false);
  const [photos, setPhotos] = useState([]);

  const [refreshCounter, setRefreshCounter] = useRecoilState(refreshCounterState);

  useEffect(() => {
    async function getOfferDetails() {
      try {
        setIsLoading(true);
        const response = await productsService.getDetails(+instrumentId);
        if (accountData) {
          setProduct(response.data);
          setPhotos(response.data.photos);
          setIsPopUpVisible(false);
          setIsOwn(accountData && accountData.id == response.data.owner.id);
          const isFavourite = accountData.favourites.products.some(
            (dto: any) => dto.id == instrumentId
          );
          setIsFavorite(isFavourite);
        }
        setProduct(response.data);
        setPhotos(response.data.photos);
        setIsPopUpVisible(false);
      } catch {
      } finally {
        setIsLoading(false);
      }
    }
    getOfferDetails();
  }, [refreshCounter]);

  const handleDelete = () => {
    productsService.deleteProduct(+product!.id!).then(() => {
      setRefreshCounter(refreshCounter + 1);
      router.goBack();
    });
  };
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <IonPage>
      {isPopUpVisible && (
        <UIPopUpPhoto
          type='products'
          photoLink={photos}
          onClosePopUp={() => {
            setIsPopUpVisible(false);
          }}
        />
      )}
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        {showRatingPopUp && (
          <UIRatePopUp closePopUp={() => setShowRatingPopUp(false)} id={product!.owner.id} />
        )}
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
          {isOwn && (
            <IonButtons slot='end'>
              <UIDeleteButton onDelete={handleDelete} />
            </IonButtons>
          )}
        </IonToolbar>
        <UITopTextBar
          leftComponent={
            <div className='text-white text-xs flex w-80 ml-2 items-center'>
              <div>
                <p className='w-fit  font-thin'>{catName} kaufen</p>
              </div>
              <IonIcon icon={chevronForward} className='h-5 w-5' />
              <p className=' font-thin'>{subCatName}</p>
              <IonIcon icon={chevronForward} className='h-5 w-5' />
              <p className='text-sm'>{product?.productName}</p>
            </div>
          }
        ></UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          product && (
            <div className={`w-full h-full${isDark2 ? 'bg-[#474747]' : 'bg-[#F8F8F8]'}`}>
              <div className='pt-2 px-2 pb-5 space-y-5'>
                {!isOwn ? (
                  <>
                    <DetailsProducts
                      isDefaultFavorite={isFavorite}
                      fields={product}
                      setIsPopUpVisible={setIsPopUpVisible}
                      onClick={() => {
                        setShowRatingPopUp(true);
                      }}
                    />
                    <div className='space-y-3'>
                      <UIYellowButton
                        text={`Andere Benutzeranzeigen`}
                        onClick={() => {
                          router.push(
                            `/user-items/${product.owner.id}/${product.owner.firstName}/`
                          );
                        }}
                      />
                      <UIYellowButton
                        text={`Bewertung hinzufügen`}
                        onClick={() => {
                          setShowRatingPopUp(true);
                        }}
                      />
                      <UIYellowButton
                        text='Nachricht senden'
                        onClick={() => {
                          if (accountData) {
                            router.push(
                              `/message/products/${product.id}/${
                                product.name ? product.name : product.productsName
                              }/${product.existedChat ? product.existedChat : 0}`
                            );
                          } else if (!accountData) {
                            router.push('/sign-in');
                          }
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <FormEditProduct productId={product.id} />
                )}
              </div>
            </div>
          )
        )}
      </IonContent>
    </IonPage>
  );
};

import React, {Dispatch, SetStateAction} from 'react';
import {useSetRecoilState} from 'recoil';
import {photoState} from 'states/common/common.state';
import {BASE_URL_API} from 'utils/constants';

import placeholderImage from 'assets/placeholder_image.png';

type photoHelperProps = {
  photoLink: string;
  customClassName?: string;
  type?: string;
  setIsPopUpVisible: Dispatch<SetStateAction<boolean>>;
};

export const UIClickImage: React.FC<photoHelperProps> = ({
  customClassName,
  photoLink,
  type,
  setIsPopUpVisible,
}) => {
  const setPhoto = useSetRecoilState(photoState);
  type && photoLink && (photoLink = photoLink.substring(21, photoLink.length));
  const url = BASE_URL_API + photoLink;

  const handleClick = () => {
    setPhoto(url);
    setIsPopUpVisible(true);
  };

  return (
    <div>
      <div onClick={handleClick}>
        <img
          alt='img'
          src={photoLink ? url : placeholderImage}
          className={`
            ${customClassName ? customClassName : 'h-44 w-36 my-12 mb-3 rounded-xl'} centered
          `}
        />
      </div>
    </div>
  );
};

import React, {useEffect, useState} from 'react';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import UILogo from 'components/uiLogo/UILogo';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import {postsService} from 'api/forum/forum.service';
import PostForumComponent from 'forms/messages/PostForumComponent';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import UIButtonAdd from 'components/uiButtons/UIButtonAdd';
import LoadingIndicator from 'components/uiLoading/UILoadingIndicator';
import sorting from 'assets/sorting.svg';
import {ForumPostInterface} from 'interfaces/posts/forumPost.interface';
import {useRecoilValue} from 'recoil';
import {lastAddedPostState} from 'states/messages/message.state';
import {isDarkTheme} from 'states/account/account.state';

const ForumList = () => {
  const lastAddedPost = useRecoilValue(lastAddedPostState);

  const [formPostForum, setFormPostForum] = useState<ForumPostInterface[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const router = useIonRouter();

  useEffect(() => {
    const getAll = async () => {
      try {
        setIsLoading(true);
        const postResponse = await postsService.getAllPosts();
        setFormPostForum(postResponse.data);
        setIsLoading(false);
      } catch {}
    };
    getAll();
  }, [lastAddedPost]);

  const handleSort = (sortValue: string) => {
    getOrderBy(sortValue);
  };

  const getOrderBy = (sortValue: string) => {
    switch (sortValue) {
      case 'A-z':
        const sorted0 = [...formPostForum!.sort((a, b) => (a.title > b.title ? 1 : -1))];
        setFormPostForum(sorted0);
        return 'alpha';
      case 'Z-a':
        const sorted00 = [...formPostForum!.sort((a, b) => (a.title < b.title ? 1 : -1))];
        setFormPostForum(sorted00);
        return '-alpha';
      case 'Datum aufsteigend':
        const sorted = [...formPostForum!.sort((a, b) => (a.dateAdded > b.dateAdded ? 1 : -1))];
        setFormPostForum(sorted);
        return 'dateAdded';
      case 'Datum absteigend':
        const sorted2 = [...formPostForum!.sort((a, b) => (a.dateAdded < b.dateAdded ? 1 : -1))];
        setFormPostForum(sorted2);
        return '-dateAdded';
    }
  };
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <IonButtons slot='end'>
            <UIButtonAdd onClick={() => router.push('/forum-add')} />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar
          leftComponent={<div></div>}
          rightComponent={
            <div className='flex w-7/8 mr-2'>
              <IonSelect
                okText='Weiter'
                cancelText='Abbrechen'
                onIonChange={(e) => handleSort(e.detail.value)}
                placeholder='Sortieren'
                className='text-xs text-transparent w-8 -mr-8 z-10'
              >
                <IonSelectOption className='text-black'>A-z</IonSelectOption>
                <IonSelectOption className='text-black'>Z-a</IonSelectOption>
                <IonSelectOption className='text-black'>Datum aufsteigend</IonSelectOption>
                <IonSelectOption className='text-black'>Datum absteigend</IonSelectOption>
              </IonSelect>
              <img className='my-2 mr-3' src={sorting} />
            </div>
          }
        >
          Forum
        </UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <div>
          {isLoading ? <LoadingIndicator /> : <PostForumComponent fields={formPostForum} />}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ForumList;

import React from 'react';

type Props = {
  children: string;
};

const UIErrorText = ({children}: Props) => {
  return <p className='text-[10px] text-red-500 absolute'>{children}</p>;
};

export default UIErrorText;

import React from 'react';
import {dateHandler} from 'utils/dateHandler';
import {useIonRouter} from '@ionic/react';
import {useRecoilValue} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';
import {urlConverter} from 'utils/urlConverter';

export type PostForumFieldType = {
  title: string;
  name: string;
  photo: any;
  date: string;
  text: string;
};

const PostForumComponent = ({fields}: any) => {
  const router = useIonRouter();
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <div>
      {fields &&
        fields.map((field: any) => {
          let photo = '';
          field.profilePictures && (photo = urlConverter(field.profilePictures));
          return (
            <div
              key={field.id}
              className={`rounded-xl border border-yellow-accent mx-3 my-2 font-sans
            ${isDark2 ? 'bg-[#393939]' : 'bg-white'}`}
              onClick={() => router.push(`/forum-post/${field.id}`)}
            >
              <div
                className={`h-8 rounded-t-lg
              w-full flex justify-between text-xs px-3
              ${isDark2 ? 'bg-[#F0A249]' : ' bg-yellow-accent'}`}
              >
                <div className=' my-1 flex justify-around'>
                  <img src={photo} className='w-6 h-6' />
                  <p className='text-white ml-2 mt-1'>{field.firstName}</p>
                </div>
                <p className='mt-2 text-white flex justify-end'>{dateHandler(field.dateAdded)}</p>
              </div>
              <p className={`pl-2 text-sm pt-2 font-bold ${isDark2 ? 'text-white' : 'text-black'}`}>
                {field.title}
              </p>
              <p
                className={`pl-2 text-xs my-4
            ${isDark2 ? 'text-white' : 'text-black'}`}
              >
                {field.description}
              </p>
            </div>
          );
        })}
    </div>
  );
};

export default PostForumComponent;

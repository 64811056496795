import React, {useEffect, useState} from 'react';
import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar, useIonRouter} from '@ionic/react';
import {accountService} from 'api/account/account.service';
import {Instrument} from 'interfaces/instruments/instrument.interface';
import InstrumentsList from 'pages/instruments/instrumentsList/InstrumentsList';
import UILogo from 'components/uiLogo/UILogo';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UITopBar from 'components/uiTopBar/UITopBar';
import ProductsList from 'pages/products/productsList/ProductsList';
import {ServicesList as ServicesList} from 'pages/services/servicesList/ServiceList';
import {JobsList} from 'pages/jobs/jobsList/JobsList';
import {Service} from 'interfaces/services/service.interface';
import {Job} from 'interfaces/jobs/jobs.interface';
import {useRecoilValue} from 'recoil';
import {accountState, isDarkTheme} from 'states/account/account.state';
import {Product} from 'interfaces/products/product.interface';
import ActivePromotionList from 'pages/promotions/promotionsItemsList/ActivePromotionList';

const PromotionsItemsListPage = () => {
  const loan = 'Mietgeräte';
  const buy = 'Verkaufen';
  const service = 'Dienstleistungen';
  const job = 'Jobinserat';
  const onPromotion = 'Werbeartikel';

  const accountData = useRecoilValue(accountState);

  const [activeTab, setActiveTab] = useState(loan);
  const [products, setProducts] = useState<Product[]>();
  const [instruments, setInstruments] = useState<Instrument[]>();
  const [jobs, setJobs] = useState<Job[]>();
  const [services, setServices] = useState<Service[]>();

  const router = useIonRouter();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userRes = await accountService.getCurrentUser();
        setInstruments(userRes.data.mine.instruments);
        setProducts(userRes.data.mine.products);
        setServices(userRes.data.mine.services);
        setJobs(userRes.data.mine.jobs);
      } catch {}
    };

    fetchData();
  }, []);

  const handleItemClick = (item: Instrument) => {
    router.push(
      activeTab === loan ? `/promote/instruments/${item.id}` : `/promote/products/${item.id}`
    );
  };

  const handleOfferClick = (serviceId: string) => {
    router.push(
      activeTab === service ? `/promote/services/${serviceId}` : `/promote/jobs/${serviceId}`
    );
  };
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar>Meine Werbung</UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <UITopBar
          firstTab={loan}
          secondTab={buy}
          thridTab={accountData?.accountType == 'COMPANY' ? service : undefined}
          fourthTab={accountData?.accountType == 'COMPANY' ? job : undefined}
          fifthTab={onPromotion}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        {activeTab === loan
          ? instruments && (
              <InstrumentsList
                instruments={instruments}
                isOwnInstruments={true}
                onInstrumentClick={handleItemClick}
              />
            )
          : ''}
        {activeTab === buy
          ? products && (
              <ProductsList
                products={products}
                isOwnInstruments={true}
                onProductClick={handleItemClick}
              />
            )
          : ''}
        {activeTab === service
          ? services && (
              <ServicesList
                services={services}
                isOwnService={true}
                onServiceClick={handleOfferClick}
              />
            )
          : ''}
        {activeTab === job
          ? jobs && <JobsList jobs={jobs} isOwnJob={true} onJobClick={handleOfferClick} />
          : ''}
        {activeTab === onPromotion && (
          <ActivePromotionList
            products={products!.filter((x: any) => x.promoted)}
            instruments={instruments!.filter((x: any) => x.promoted)}
            jobs={jobs!.filter((x: any) => x.promoted)}
            services={services!.filter((x: any) => x.promoted)}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default PromotionsItemsListPage;

import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar} from '@ionic/react';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UILogo from 'components/uiLogo/UILogo';
import React from 'react';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import FormProduct from 'forms/products/product.form';
import {isDarkTheme} from 'states/account/account.state';
import {useRecoilValue} from 'recoil';

const ProductsAddPage: React.FC = () => {
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar>Geräte zum Verkauf</UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <div className='m-3'>
          <FormProduct />
        </div>
      </IonContent>
    </IonPage>
  );
};
export default ProductsAddPage;

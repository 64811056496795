import React, {useEffect, useState} from 'react';
import {IonIcon, IonTextarea, IonSelect, IonSelectOption, useIonRouter} from '@ionic/react';
import {useForm} from 'react-hook-form';
import {instrumentsService} from 'api/instruments/instruments.service';
import {BFormAddressField} from 'forms/common/places/BFormAddressField';
import UIInput from 'components/uiInputs/UIInput';
import LineColored from './LineColored';
import UIYellowButton from 'components/uiButtons/UIYellowButton';
import UIToggleButton from 'components/uiButtons/UIToggleButton';
import AddPhotos from 'components/uiAddPhoto/UIAddPhotos';
import {useRecoilValue} from 'recoil';
import {accountState, isDarkTheme} from 'states/account/account.state';
import {useSetRecoilState} from 'recoil';
import {refreshCounterState} from 'states/common/common.state';
import {eyeOutline, star} from 'ionicons/icons';
import {
  InstrumentRequest,
  DefaultInstrumentRequestValues,
  Category,
  Subcategory,
} from 'interfaces/instruments/instrument.interface';
import euroIcon from 'assets/icons/euro_icon.svg';

function FormEditInstrument({instrumentId}: any) {
  const router = useIonRouter();

  const [categories, setCategories] = useState<Category[]>([]);
  const [subcategories, setSubcategories] = useState<Subcategory[]>([]);
  const [subCatId, setSubcatId] = useState<number>();
  const [subCatDefaultId, setSubCatDefaultId] = useState<number>();
  const [catId, setCatId] = useState<string>();
  const [catValue, setCatvalue] = useState<string>();
  const [subCatValue, setSubCatValue] = useState<string>();
  const [addressPlaceholder, setAddressPlaceholder] = useState<string>('Wähle einen Adresse');
  const [oldPhotos, setOldPhotos] = useState<{photo: string}[]>();
  const [availability, setAvailability] = useState(false);
  const [instrument, setInstrument] = useState<InstrumentRequest>(DefaultInstrumentRequestValues);
  const [apiResultMessage, setApiResultMessage] = useState<string>('');

  const isDark2 = useRecoilValue(isDarkTheme);
  const accountData = useRecoilValue(accountState);
  const setRefreshCount = useSetRecoilState(refreshCounterState);

  const {
    handleSubmit,
    register,
    setValue,
    formState: {errors},
    control,
  } = useForm();

  useEffect(() => {
    instrumentsService.getDetails(instrumentId).then((response) => {
      setInstrument(response.data);
      setCatvalue(response.data.category.name);
      setSubCatValue(response.data.subcategory.name);
      setSubCatDefaultId(response.data.subcategory.id);
      setCatId(response.data.category.id);

      setValue('weekHours', response.data.weekHours);
      setValue('weekendHours', response.data.weekendHours);
      setValue('pricePerDay', response.data.pricePerDay);
      setValue('category', response.data.category.id);
      setValue('subcategory', response.data.subcategory.id);
      setValue('instrumentName', response.data.instrumentName);
      setValue('name', response.data.name);
      setValue('email', response.data.email);
      setValue('productionYear', response.data.productionYear);
      setValue('phoneNumber', accountData?.phoneNumber);
      setValue('description', response.data.description);
      setValue('lat', response.data.lat.toString());
      setValue('lng', response.data.lng.toString());
      setValue('postCode', response.data.postCode);
      setValue('Address', response.data.Address);
      setValue('location', response.data.location);
      setValue('availability', response.data.availability);
      setAvailability(response.data.availability);
      setOldPhotos(response.data.photos);
      setAddressPlaceholder(response.data.location);
    });

    instrumentsService.getCategories('loan').then((response) => {
      setCategories(response.data.results);
    });
  }, [instrumentId]);

  useEffect(() => {
    instrumentsService.getCategories('loan').then((response) => {
      response.data.results.filter(
        (category: any) => category.id === catId && setSubcategories(category.subcategories)
      );
    });
  }, [catId]);

  const onSubmit = (data: InstrumentRequest) => {
    if (oldPhotos) {
      data.not_delete_photos = [];
      oldPhotos.forEach((photo) => {
        data.not_delete_photos.push(photo.photo.substring(33, photo.photo.length));
      });
    }

    data.category = catId;
    data.subcategory = subCatId ? subCatId : subCatDefaultId;
    data.pricePerDay = +data.pricePerDay;
    data.productionYear = +data!.productionYear!;
    data.availability = availability;

    if (data.Address) {
      data.location = data.Address.label;
      data.postCode = data.Address.postCode;
      data.lat = data.Address.lat.toFixed(7);
      data.lng = data.Address.lng.toFixed(7);
      setAddressPlaceholder(data.location!);
    }

    instrumentsService
      .updateInstrument(data, instrumentId)
      .then((res) => {
        setApiResultMessage('Erfolg!');
        setRefreshCount((v) => v + 1);
        if (!instrument.issuedPromoted) {
          router.push(`/purchase/instruments/${instrumentId}`);
          setApiResultMessage('');
        }
      })
      .catch(() => {
        setApiResultMessage('Error!');
      });
  };

  return (
    <form className='h-full pb-3 ' onSubmit={handleSubmit(onSubmit)}>
      <div
        className={`border-2 border-orange-main text-black bg-white rounded-md pb-10
      ${isDark2 ? ' bg-[#393939]' : ' bg-[#F8F8F8]'}`}
      >
        {addressPlaceholder && (
          <div>
            <div className={`h-6 ${isDark2 ? '  bg-[#F0A249]' : '  bg-yellow-accent'}`}></div>
            <div className='flex my-4 bg-red'>
              <div className='flex-col mx-2 w-2/5 space-y-2 text-sm'>
                <div
                  className='flex-col w-2/5 space-y-2 text-sm mb-16'
                  onClick={() => router.push(`/photos/${instrumentId}/instruments`)}
                >
                  <AddPhotos
                    name='photos'
                    photosType='instruments'
                    photos={oldPhotos}
                    control={control}
                    setPhotos={setOldPhotos}
                  />
                </div>
                <div>
                  <p className={`${isDark2 ? ' text-white' : 'text-black'}`}>Preis pro Tag:</p>
                  <div className='flex'>
                    <UIInput
                      customClassName={`bg-white w-full text-sm
                      ${isDark2 ? 'bg-[#393939] text-white ' : 'bg-[#F8F8F8] text-black '}`}
                      name='pricePerDay'
                      type='number'
                      placeholder='80'
                      register={register}
                      validation={{required: true}}
                      errors={errors}
                    />
                    <IonIcon
                      icon={euroIcon}
                      className={`mt-0.5 ${isDark2 ? 'text-white' : 'text-gray-500'}`}
                    />
                  </div>
                  <LineColored error={errors.pricePerDay} />
                </div>
                <div className={`${isDark2 ? ' text-white' : 'text-black'}`}>
                  <UIToggleButton
                    name='availability'
                    title='Verfügbarkeit'
                    state={availability}
                    register={register}
                    setState={setAvailability}
                  />
                </div>
              </div>
              <div className='flex-col space-y-2 mr-[15px] w-3/5 text-sm'>
                <div className='space-y-2 mb-2'>
                  <div
                    className='w-44 h-6 p-4 flex items-center justify-between
              rounded-xl border-orange-main border text-orange-main'
                  >
                    <IonSelect
                      className={`${isDark2 ? ' text-white' : 'text-black'}`}
                      okText='Weiter'
                      cancelText='Abbrechen'
                      placeholder={catValue}
                      onIonChange={(cat) => setCatId(cat.detail.value)}
                    >
                      {categories.map((cat) => (
                        <IonSelectOption key={cat.id} value={cat.id}>
                          {cat.name}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </div>
                  <div
                    className='w-44 h-6 p-4 flex items-center justify-between
              rounded-xl border-orange-main border text-orange-main'
                  >
                    <IonSelect
                      className={`${isDark2 ? ' text-white' : 'text-black'}`}
                      okText='Weiter'
                      cancelText='Abbrechen'
                      placeholder={subCatValue}
                      onIonChange={(subcat) => setSubcatId(subcat.detail.value)}
                    >
                      {subcategories.map((subcat) => (
                        <IonSelectOption key={subcat.id} value={subcat.id}>
                          {subcat.name}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </div>
                </div>
                <div>
                  <p className={`${isDark2 ? ' text-white' : 'text-black'}`}>Name:</p>
                  <UIInput
                    customClassName={`${
                      isDark2 ? ' text-white bg-[#393939] ' : 'text-black bg-[#F8F8F8] '
                    }`}
                    name='instrumentName'
                    placeholder='Mischmaschine'
                    required={'required'}
                    register={register}
                    validation={{required: true}}
                    errors={errors}
                  />
                  <LineColored error={errors.instrumentName} />
                </div>
                <div>
                  <p className={`${isDark2 ? ' text-white' : 'text-black'}`}>Geräte Bezeichnung:</p>
                  <UIInput
                    customClassName={`${
                      isDark2 ? ' text-white bg-[#393939] ' : 'text-black bg-[#F8F8F8] '
                    }`}
                    name='name'
                    placeholder='Zweck'
                    required={'required'}
                    register={register}
                    validation={{required: true}}
                    errors={errors}
                  />
                  <LineColored error={errors.purpose} />
                </div>
                <div>
                  <p className={`${isDark2 ? ' text-white' : 'text-black'}`}>Kontakt:</p>
                  <UIInput
                    customClassName={`${
                      isDark2 ? ' text-white bg-[#393939] ' : 'text-black bg-[#F8F8F8] '
                    }`}
                    name='email'
                    placeholder='beispiel@email.com'
                    required='required'
                    register={register}
                    validation={{required: true}}
                    errors={errors}
                  />
                  <LineColored error={errors.email} />
                </div>
                <div>
                  <p className={`${isDark2 ? ' text-white' : 'text-black'}`}>Baujahr:</p>
                  <UIInput
                    customClassName={`${
                      isDark2 ? ' text-white bg-[#393939] ' : 'text-black bg-[#F8F8F8] '
                    }`}
                    name='productionYear'
                    type='number'
                    placeholder='Jahr'
                    register={register}
                    errors={errors}
                  />
                  <LineColored error={errors.productionYear} />
                </div>
                <div>
                  <p className={`${isDark2 ? ' text-white' : 'text-black'}`}>Telefonnummer:</p>
                  <UIInput
                    customClassName={`${
                      isDark2 ? ' text-white bg-[#393939] ' : 'text-black bg-[#F8F8F8] '
                    }`}
                    name='phoneNumber'
                    type='tel'
                    placeholder='0660 1234567'
                    minLength={9}
                    maxLength={16}
                    register={register}
                    validation={{required: true}}
                    errors={errors}
                  />
                  <LineColored error={errors.phoneNumber} />
                </div>
                <div className='text-yellow-accent text-center font-bold mb-5 clear-both'>
                  {apiResultMessage}
                </div>
              </div>
            </div>
            <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
              <div className='text-orange-main text-sm'>Adresse</div>
              <div className='bg-orange-main w-full h-px'></div>
            </div>
            <div className='mx-2 mt-2'>
              <BFormAddressField
                control={control}
                fieldName='Address'
                key={Math.random()}
                placeholder={addressPlaceholder}
                latLng={{lat: +instrument.lat, lng: +instrument.lng}}
              />
            </div>
            <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
              <div className='text-orange-main text-sm'>Beschreibung</div>
              <div className='bg-orange-main w-full h-px'></div>
            </div>
            <IonTextarea
              rows={4}
              className={`ipnut text-sm h-[100px]
              ${isDark2 ? ' text-white bg-[#393939]' : 'text-black bg-[#F8F8F8'}`}
              {...register('description')}
              placeholder='230 V Mischmaschine mit 140 Liter Fassungsvermögen
          in sehr guten Zustand inkl 10m Anschlusskabel'
              value={instrument?.description}
              name='description'
            />
            <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
              <div className='text-orange-main text-sm'>Datum und Statistik</div>
              <div className='bg-orange-main w-full h-px'></div>
            </div>
            <div
              className={`flex text-sm place-content-centerh-10
            ${isDark2 ? 'text-gray-300 ' : 'text-black'}`}
            >
              <div className='ml-1'>
                <div className='flex justify-between ml-2'>
                  <p className={`${isDark2 ? 'text-gray-300 pr-2' : 'text-black pr-2'}`}>
                    Anzeige verfügbar bis:{' '}
                    {instrument.issuedPromoted === true && instrument.issuedPromotedUntil
                      ? new Date(instrument.issuedPromotedUntil).toLocaleDateString()
                      : '-'}
                  </p>
                </div>
                <div className='flex ml-2'>
                  <p className={`${isDark2 ? 'text-gray-300 pr-2' : 'text-black pr-2'}`}>
                    Anzeige Hervorhebung verfügbar bis:{' '}
                    {instrument.promoted === true && instrument.promotedUntil
                      ? new Date(instrument.promotedUntil).toLocaleDateString()
                      : '-'}
                  </p>
                </div>
                <div className='flex ml-2'>
                  <p className={`${isDark2 ? 'text-gray-300 pr-2' : 'text-black pr-2'}`}>
                    Anzeige Logo verfügbar bis:{' '}
                    {instrument.mapPromoted === true && instrument.mapPromotedUntil
                      ? new Date(instrument.mapPromotedUntil).toLocaleDateString()
                      : '-'}
                  </p>
                </div>
              </div>
              <div className='flex justify-center mt-2'>
                <IonIcon
                  icon={star}
                  className={`h-4 w-5 
                  ${isDark2 ? 'text-[#F0A249]' : 'text-yellow-400 '}`}
                />
                <p className='pr-5 text-md'>
                  {instrument.countFavourite ? instrument.countFavourite : 0}
                </p>
                <IonIcon icon={eyeOutline} className='h-5 w-5 pr-1' />
                <p className='pr-7'>{instrument.visitors}</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='flex flex-col py-5 justify-end text-white'>
        <UIYellowButton type='submit' text='Weiter' />
      </div>
    </form>
  );
}

export default FormEditInstrument;

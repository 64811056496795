import React from 'react';
import {useRecoilValue} from 'recoil';
import {isDarkTheme} from 'states/account/account.state';

type Props = {
  children: JSX.Element;
};

const AppLoginBackground = ({children}: Props) => {
  const isDark2 = useRecoilValue(isDarkTheme);
  return (
    <div>
      <div className='w-full h-full flex justify-center '>
        <div
          className={`w-5/6 rounded-lg drop-shadow-xl 
        ${isDark2 ? 'bg-[#F0A249]' : ' bg-yellow-accent'}`}
        >
          <div
            className={`text-black border-2 border-yellow-accent mt-2
          rounded-b-lg ${isDark2 ? 'bg-[#393939]' : 'bg-white'}`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLoginBackground;

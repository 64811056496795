import React, {useEffect, useState} from 'react';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonToolbar,
} from '@ionic/react';
import UIButtonBack from 'components/uiButtons/UIButtonBack';
import UITopTextBar from 'components/uiLabels/UITopTextBar';
import UILogo from 'components/uiLogo/UILogo';
import {userService} from 'api/users/users.service';
import {User} from 'interfaces/users/user.interface';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {accountState, isDarkTheme} from 'states/account/account.state';
import {accountService} from 'api/account/account.service';
import {refreshCounterState} from 'states/common/common.state';

const ContactDataVisability = () => {
  const [user, setUser] = useState<User>();
  const [emailVisability, setEmailVisibility] = useState<boolean>(false);
  const [phoneVisability, setPhoneVisibility] = useState<boolean>(false);
  const [valuesChanged, setValuesChanged] = useState<number>(0);

  const isDark2 = useRecoilValue(isDarkTheme);
  const setAccount = useSetRecoilState(accountState);
  const [refreshCounter, setRefreshCounter] = useRecoilState(refreshCounterState);

  useEffect(() => {
    if (user) {
      user.isVisibleEmail = emailVisability;
      user.isVisiblePhoneNumber = phoneVisability;
      delete user.gender;
      delete user.profilePicture;

      userService.updateUser(user).then(() => {
        setRefreshCounter(refreshCounter + 1);
        accountService.getCurrentUser().then((res) => {
          setAccount(res.data);
        });
      });
    }
  }, [valuesChanged]);

  useEffect(() => {
    userService.getUserData().then((res) => {
      setUser(res.data);
      setEmailVisibility(res.data.isVisibleEmail);
      setPhoneVisibility(res.data.isVisiblePhoneNumber);
    });
  }, []);

  return (
    <IonPage>
      <IonHeader color={`${isDark2 ? 'medium' : 'light'}`}>
        <IonToolbar color={`${isDark2 ? 'medium' : 'light'}`}>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar customClassName='w-full= bg-gray-600 h-10 flex justify-center z-10'>
          <p className='w-screen text-center mt-1'>Sichtbarkeit von Kontaktdaten</p>
        </UITopTextBar>
      </IonHeader>
      <IonContent color={`${isDark2 ? 'medium' : 'light'}`}>
        <div className={`mx-4 font-sans ${isDark2 ? 'text-white' : 'text-black ]'}`}>
          <p className='text-[13px] mt-1 text-center px-10'>
            Verwalten Sie die Sichtbarkeit Ihrer Kontaktdaten in Anzeigen
          </p>
          <p className='mt-5 text-center font-medium'>Email</p>
          <div className='border border-yellow-accent border-t-8 rounded-md mt-2 py-0.5'>
            <IonRadioGroup
              value={emailVisability}
              onIonChange={(e: any) => {
                setEmailVisibility(e.detail.value);
                setValuesChanged((x) => x + 1);
              }}
            >
              <IonItem color={`${isDark2 ? 'medium' : 'light'}`} lines='none'>
                <IonLabel>
                  <b className={`font-bold text-sm ${isDark2 ? 'text-white' : 'text-black '}`}>
                    Sichtbar
                  </b>
                </IonLabel>
                <IonRadio slot='start' value={true} />
              </IonItem>
              <hr className='bg-yellow-accent' />
              <IonItem color={`${isDark2 ? 'medium' : 'light'}`} lines='none'>
                <IonLabel>
                  <b className={`font-bold text-sm ${isDark2 ? 'text-white' : 'text-black '}`}>
                    Versteckt
                  </b>
                </IonLabel>
                <IonRadio slot='start' value={false} />
              </IonItem>
            </IonRadioGroup>
          </div>
          <p className='mt-5 text-center font-medium'>Telefonnummer</p>
          <div className='border border-yellow-accent border-t-8 rounded-md mt-2 py-0.5'>
            <IonRadioGroup
              value={phoneVisability}
              onIonChange={(e: any) => {
                setPhoneVisibility(e.detail.value);
                setValuesChanged((x) => x + 1);
              }}
            >
              <IonItem color={`${isDark2 ? 'medium' : 'light'}`} lines='none'>
                <IonLabel>
                  <b className={`font-bold text-sm ${isDark2 ? 'text-white' : 'text-black '}`}>
                    Sichtbar
                  </b>
                </IonLabel>
                <IonRadio slot='start' value={true} />
              </IonItem>
              <hr className='bg-yellow-accent' />
              <IonItem color={`${isDark2 ? 'medium' : 'light'}`} lines='none'>
                <IonLabel>
                  <b className={`font-bold text-sm ${isDark2 ? 'text-white' : 'text-black '}`}>
                    Versteckt
                  </b>
                </IonLabel>
                <IonRadio slot='start' value={false} />
              </IonItem>
            </IonRadioGroup>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ContactDataVisability;
